import { fabric } from "fabric";

import "./controls/prototypes/crop.control.prototype";
import "./controls/prototypes/rotate.control.prototype";
import "./controls/prototypes/scale.control.prototype";

import "./controls/image-replace.control";
import "./controls/move-inner.control";
import "./controls/move.control";
import "./controls/remove.control";
import "./controls/zoom-inner-minus-disabled.control";
import "./controls/zoom-inner-minus.control";
import "./controls/zoom-inner-plus.control";
import "./controls/zoom-panel.control";

import "./objects/prototypes/textbox.prototype";

fabric.textureSize = 16 * 1024;

// @ts-expect-error
fabric.maxCacheSideLimit = 4096;

// @ts-expect-error
fabric.minCacheSideLimit = 256;

// @ts-expect-error
fabric.perfLimitSizeTotal = 4096 * 4096;
