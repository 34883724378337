import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { SharedModule } from '../shared/shared.module';
import { RecoverDialogComponent } from './recover-dialog.component';
import { RecoverSuccessDialogComponent } from './recover-success-dialog.component';
import { RecoverEffects } from './recover.effects';
import { RecoverService } from './recover.service';

@NgModule({
  imports: [SharedModule, StoreModule.forFeature('recover', reducer), EffectsModule.forFeature([RecoverEffects])],
  declarations: [RecoverDialogComponent, RecoverSuccessDialogComponent],
  providers: [RecoverService]
})
export class RecoverModule {}
