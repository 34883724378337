import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { roundToHalf } from '../../../../utils/element.utils';
import { GetTextService } from '../../../../services';
import { CanvasDimensions } from '../../../../models';

@Component({
  selector: 'ed-dimensions',
  templateUrl: 'dimensions.component.html',
  styleUrls: ['../coordinates-and-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionsComponent {
  @Input() width: number;
  @Input() maxWidth = Infinity;
  @Input() minWidth = 0;

  @Input() height: number;
  @Input() maxHeight = Infinity;
  @Input() minHeight = 0;

  @Output() changeDimensions = new EventEmitter<CanvasDimensions>();

  @Input() @HostBinding('class.height-adjusted-for-input') showInputFields: boolean;

  get roundWidth() {
    return roundToHalf(this.width);
  }

  get roundHeight() {
    return roundToHalf(this.height);
  }

  get ratio() {
    return this.height / this.width;
  }

  constructor(public getTextService: GetTextService) {}

  changeWidth(value: string) {
    const width = Math.min(Math.max(Math.abs(Number(value)), this.minWidth), this.maxWidth);
    this.onChangeDimensions(width, width * this.ratio);
  }

  changeHeight(value: string) {
    const height = Math.min(Math.max(Math.abs(Number(value)), this.minHeight), this.maxHeight);
    this.onChangeDimensions(height / this.ratio, height);
  }

  onChangeDimensions(width: number, height: number) {
    this.changeDimensions.emit({ width, height });
  }
}
