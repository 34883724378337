import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageData } from '../../../../services/image-upload.service';
import { AddMenuText } from '../../../../models/text';
import { CanvasActions } from '../../../../actions';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { GetTextService } from '../../../../services';
import { UploadButtonSize } from '../../../edit/upload/image-upload-mob-new.component';
import { FunctionPermission } from '../../../../models';

@Component({
  selector: 'ed-add-menu',
  templateUrl: 'add-menu.component.html',
  styleUrls: ['../../../../shared/mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMenuComponent {
  @Input() addChildElements: boolean;
  @Input() text: AddMenuText;

  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openAddImageMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openAddBackgroundMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  uploadButtonSize = UploadButtonSize;
  permissions = FunctionPermission;

  constructor(private store: Store<AppState>, private getTextService: GetTextService) {}

  uploadImage() {
    return (imageData: ImageData) => {
      this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(imageData.width, imageData.height, imageData.sid));
    };
  }
}
