import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContextMenuDialogComponent } from './context-menu-dialog.component';
import { CanvasElement, ElementType } from '../../models';
import { CopyService, GetTextService } from '../../services';
import { AppState } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CanvasActions, UiActions } from '../../actions';

export class ContextMenuShortcuts {
  cut: string;
  copy: string;
  paste: string;
  delete: string;
  moveToFront: string;
  moveToBack: string;
  moveStepForward: string;
  moveStepBackward: string;
}

@Injectable()
export class ContextMenuService {
  dialogRef: MatDialogRef<ContextMenuDialogComponent>;
  dialogText = this.getTextService.text.dialog.contextMenuText;
  shortcut: ContextMenuShortcuts;
  isMac: boolean;
  showBackspaceShortcut: boolean;
  configSubscription$: Subscription;
  positioningTooltipText = this.getTextService.text.edit.order.tooltip;

  constructor(
    public dialog: MatDialog,
    public getTextService: GetTextService,
    public store: Store<AppState>,
    public copyService: CopyService
  ) {
    this.configSubscription$ = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.isMac = config.isMac;
      this.showBackspaceShortcut = config.isMac;
    });
    this.shortcut = this.getShortcuts();
  }

  openContextMenu(x: number, y: number, element: CanvasElement) {
    if (this.dialogRef) {
      this.dialog.closeAll();
    }
    this.dialogRef = this.dialog.open(ContextMenuDialogComponent, {
      position: { left: x + 'px', top: y + 'px' },
      panelClass: 'context-menu-dialog',
      hasBackdrop: false,
      data: {
        contextMenuText: this.dialogText,
        shortcut: this.shortcut,
        showBackspaceShortcut: this.showBackspaceShortcut,
        duplicatable: this.copyService.allowDuplicating,
        pastable: !!this.copyService.getClipboardElement() && this.copyService.allowPasting,
        removable: element.permissions.isRemovable && element.permissions.isMovable,
        orderable: element.permissions.isOrderable,
        element,
        positioningTooltipText: this.positioningTooltipText
      }
    });

    this.dialogRef.componentInstance.cutElement.subscribe(() => {
      this.copyService.cut();
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.copyElement.subscribe(() => {
      this.copyService.copy();
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.pasteElement.subscribe(() => {
      this.copyService.paste();
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.deleteElement.subscribe(() => {
      this.store.dispatch(new CanvasActions.DeActivateOverlay(element.route));
      this.store.dispatch(new CanvasActions.RemoveElement(element.route));
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.stepForward.subscribe(() => {
      this.store.dispatch(new CanvasActions.MoveStepForward(element.route));
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.stepBackward.subscribe(() => {
      this.store.dispatch(new CanvasActions.MoveStepBackward(element.route));
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.toFront.subscribe(() => {
      this.store.dispatch(new CanvasActions.MoveToFront(element.route));
      this.dialogRef.close();
    });

    this.dialogRef.componentInstance.toBack.subscribe(() => {
      this.store.dispatch(new CanvasActions.MoveToBack(element.route));
      this.dialogRef.close();
    });
  }

  closeContextMenu() {
    if (this.dialogRef) {
      this.dialog.closeAll();
    }
  }

  getShortcuts() {
    const prefix = this.isMac ? 'Cmd' : 'Ctrl';
    return {
      cut: prefix + ' + X',
      copy: prefix + ' + C',
      paste: prefix + ' + V',
      delete: this.isMac ? '' : 'Delete',
      moveToFront: prefix + ' + Shift + ↑',
      moveToBack: prefix + ' + Shift + ↓',
      moveStepForward: prefix + ' + ↑',
      moveStepBackward: prefix + ' + ↓'
    };
  }
}
