import { Inject, Injectable, InjectionToken } from '@angular/core';

export const LOCAL_STORAGE = new InjectionToken<Storage>('local Storage', {
  providedIn: 'root',
  factory: () => localStorage
});

export const SESSION_STORAGE = new InjectionToken<Storage>('session Storage', {
  providedIn: 'root',
  factory: () => sessionStorage
});

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {
  constructor(
    @Inject(LOCAL_STORAGE) public localStorage: Storage,
    @Inject(SESSION_STORAGE) public sessionStorage: Storage
  ) {}

  getLocal(key: string): string {
    return this.localStorage.getItem(key);
  }

  setLocal(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getSession(key: string): string {
    return this.sessionStorage.getItem(key);
  }

  setSession(key: string, value: string) {
    this.sessionStorage.setItem(key, value);
  }

  removeSessionItem(key: string) {
    this.sessionStorage.removeItem(key);
  }
}
