import { EditorAction } from '../action';
import { UPDATE_SPOTUV_FOR_JPG_PERMISSION_WAS_CHANGED } from './canvas.action.types';

export class UpdateSpotUvForJpgPermissionWasChanged implements EditorAction {
  readonly type = UPDATE_SPOTUV_FOR_JPG_PERMISSION_WAS_CHANGED;
  readonly undoable = false;
  readonly save = true;

  constructor(public spotUvPermissionForJpgByDefault: boolean) {}
}
