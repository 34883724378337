import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, shareReplay, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { ConfigService, UiService } from '../../services';
import { Design } from '../../models';
import { cloneDeep } from 'lodash-es';
import { GetTextService } from '../../services';
import { TooltipPosition } from '@angular/material/tooltip';
import { canEditElementSettings, getCanEditStyle } from '../../reducers/permissions.reducer';
import { CanvasActions, UiActions } from '../../actions';
import { designLoaded, getDesignSet } from '../../selectors';
import { DesignSet } from '../../models/design-set';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'ed-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {
  design: Design;
  designSet$: Observable<DesignSet>;
  tooltipPosition: TooltipPosition = 'below';
  tooltipClass = 'left-below';
  tooltipShowDelay$: Observable<number>;

  settingsMenuWidthValue = 300;

  shortEditing: boolean;
  useAlternativeInputInlineText: boolean;
  canEditSettings: boolean;
  canEditStyle$: Observable<boolean>;
  showElementSettings: boolean;
  showStyleMenu: boolean;
  designLoaded: boolean;
  isAndroid: boolean;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public getTextService: GetTextService,
    public uiService: UiService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.designSet$ = this.store.pipe(
      select(getDesignSet),
      map(set => cloneDeep(set)),
      shareReplay()
    );

    this.designSet$.pipe(takeUntil(this.unsubscribe$)).subscribe(designSet => (this.design = designSet.activeDesign));

    this.store
      .pipe(select(designLoaded), takeUntil(this.unsubscribe$))
      .subscribe(loaded => (this.designLoaded = loaded));

    combineLatest([this.designSet$.pipe(filter(set => set.init)), this.store.pipe(select(canEditElementSettings))])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([, can]) => this.handleCanEditSettings(can));

    this.uiService.showElementSettings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(show => (this.showElementSettings = show));

    this.uiService.showStyleMenu$.pipe(takeUntil(this.unsubscribe$)).subscribe(show => (this.showStyleMenu = show));

    this.tooltipShowDelay$ = this.store.pipe(select(s => s.config)).pipe(map(config => config.tooltipShowDelay));

    this.uiService.shortEditingInlineText$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(shortEditing => (this.shortEditing = shortEditing));

    this.uiService.useAlternativeInputInlineText$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(use => (this.useAlternativeInputInlineText = use));

    this.canEditStyle$ = this.store.pipe(select(getCanEditStyle));

    this.store
      .pipe(
        select(s => s.config),
        take(1)
      )
      .subscribe(config => (this.isAndroid = config.isAndroid));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new CanvasActions.Deselect());
  }

  handleCanEditSettings(allow: boolean) {
    if (this.showElementSettings && !allow) {
      this.emitToggleSettingsMenu(false);
    }
    this.canEditSettings = allow;
  }

  openSettingsMenu() {
    this.emitToggleSettingsMenu(true);
  }

  openStyleMenu() {
    this.store.dispatch(new UiActions.ToggleStyleMenu(true));
  }

  closeElementSettingsMenu() {
    this.emitToggleSettingsMenu(false);
  }

  emitToggleSettingsMenu(open: boolean) {
    this.store.dispatch(new UiActions.ToggleSettingsMenu(open));
  }

  @HostListener('window:resize')
  onResize() {
    if (this.showElementSettings) {
      this.emitToggleSettingsMenu(false);
    }
  }
}
