import { Component, Input, OnInit } from '@angular/core';
import { CopyService } from '../../services/copy.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CopyPasteTooltipText } from '../../models/text';
import { TooltipPosition } from '@angular/material/tooltip';

export class CopyPasteShortcut {
  public cut: string;
  public copy: string;
  public paste: string;
}

@Component({
  selector: 'ed-copy-paste',
  templateUrl: 'copy-paste.component.html',
  styleUrls: ['copy-paste.component.scss', '../button/buttons.scss']
})
export class CopyPasteComponent implements OnInit {
  @Input() tooltipShowDelay: number;
  @Input() isMac = false;
  @Input() tooltipPosition: TooltipPosition;
  @Input() tooltipText: CopyPasteTooltipText;
  @Input() buttonSize: string;
  shortcut: CopyPasteShortcut = new CopyPasteShortcut();

  constructor(public store: Store<AppState>, public copyService: CopyService) {}

  ngOnInit() {
    this.setTooltipShortcuts();
  }

  get duplicatable(): boolean {
    return this.copyService.allowDuplicating;
  }

  get pastable(): boolean {
    return !!this.copyService.getClipboardElement() && this.copyService.allowPasting;
  }

  copy() {
    this.copyService.copy();
  }

  paste() {
    this.copyService.paste();
  }

  cut() {
    this.copyService.cut();
  }

  setTooltipShortcuts() {
    let prefix;
    this.isMac ? (prefix = 'Cmd') : (prefix = 'Ctrl');

    this.shortcut.cut = '(' + prefix + ' + X)';
    this.shortcut.copy = '(' + prefix + ' + C)';
    this.shortcut.paste = '(' + prefix + ' + V)';
  }
}
