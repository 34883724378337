import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TextEditorService } from '../../text-editor/text-editor.service';
import { DesignComponent } from './design.component';
import { ImageComponent } from './image/image.component';
import { PageNavigatorComponent } from './page-navigator/page-navigator.component';
import { PageOverlayComponent } from './page-overlay/page-overlay.component';
import { PageViewComponent } from './page-view/page-view.component';
import { ZoomComponent } from './zoom/zoom.component';

import './canvas/fabric';
import './canvas/models';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    DesignComponent,
    ImageComponent,
    PageOverlayComponent,
    ZoomComponent,
    PageViewComponent,
    PageNavigatorComponent
  ],
  exports: [DesignComponent],
  providers: [TextEditorService]
})
export class DesignModule { }
