import { Injectable } from '@angular/core';
import { RegisterUser, ResponseUser } from '../models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';

@Injectable()
export class RegisterService {
  registerUrl: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.registerUrl = config.registerUrl;
    });
  }

  register(regUser: RegisterUser): Observable<ResponseUser> {
    return this.http.post<ResponseUser>(this.registerUrl, regUser);
  }
}
