import { EditorAction } from '../action';
import { CHECK_DOUBLE_BACKGROUND_IMAGE } from './canvas.action.types';

export class CheckDoubleBackgroundImage implements EditorAction {
  readonly type = CHECK_DOUBLE_BACKGROUND_IMAGE;
  readonly undoable = false;
  readonly save = false;

  constructor(public width: number, public height: number, public sid?: string, public url?: string) {}
}
