import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { HueColors, HSB } from '../../../utils/color-utils';

@Component({
  selector: 'ed-color-slider',
  templateUrl: 'color-slider.component.html',
  styleUrls: ['color-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSliderComponent implements AfterViewInit {
  @Input() width: number;

  @Input() set newColorByInput(newColorByInput: HSB) {
    if (newColorByInput && newColorByInput !== this.currentColor) {
      this.currentColor = newColorByInput;
      this.selectedPosition = this.getColorCoordinates(newColorByInput);
      this.redrawGradientAndSlider();
    }
  }

  @Input() currentColor: HSB;

  @Output() hue: EventEmitter<number> = new EventEmitter();

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('slider') slider: ElementRef<HTMLCanvasElement>;

  @HostBinding('style.height.px') height = 16;
  gradientContext: CanvasRenderingContext2D;
  sliderContext: CanvasRenderingContext2D;
  selectedPosition: { x: number; y: number };

  sliding = false;

  ngAfterViewInit() {
    this.sliderContext = this.drawElementRef(this.slider);
    this.gradientContext = this.drawElementRef(this.canvas);

    this.selectedPosition = this.getColorCoordinates(this.currentColor);

    this.drawGradient();
    this.drawSlider();
  }

  redrawGradientAndSlider() {
    if (this.sliderContext && this.gradientContext) {
      this.drawGradient();
      this.drawSlider();
    }
  }

  drawGradient() {
    const gradient = this.gradientContext.createLinearGradient(0, 0, this.width, 0);
    gradient.addColorStop(0, HueColors.red);
    gradient.addColorStop(0.17, HueColors.yellow);
    gradient.addColorStop(0.34, HueColors.green);
    gradient.addColorStop(0.5, HueColors.cyan);
    gradient.addColorStop(0.68, HueColors.blue);
    gradient.addColorStop(0.85, HueColors.pink);
    gradient.addColorStop(1, HueColors.red);

    this.gradientContext.fillStyle = gradient;
    this.gradientContext.fill();
  }

  drawSlider() {
    this.resetContext(this.sliderContext);
    if (this.selectedPosition) {
      const sliderLineWidth = this.height / 2;
      this.sliderContext.strokeStyle = 'white';
      this.sliderContext.fillStyle = 'white';
      this.sliderContext.shadowBlur = 2;
      this.sliderContext.shadowOffsetX = 1;
      this.sliderContext.shadowOffsetY = 1;
      this.sliderContext.shadowColor = 'rgba(0,0,0,0.3)';
      this.sliderContext.beginPath();
      this.sliderContext.arc(this.selectedPosition.x, this.selectedPosition.y, sliderLineWidth / 2, 0, 2 * Math.PI);
      this.sliderContext.lineWidth = sliderLineWidth;
      this.sliderContext.stroke();
    }
  }

  resetContext(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, this.width, this.height);
    ctx.beginPath();
    ctx.rect(0, 0, this.width, this.height);
    ctx.closePath();
  }

  drawElementRef(elementRef: ElementRef<HTMLCanvasElement>) {
    const ctx = elementRef.nativeElement.getContext('2d');
    this.resetContext(ctx);
    return ctx;
  }

  onMouseDown(event: MouseEvent) {
    if ((event as MouseEvent).button !== undefined && (event as MouseEvent).button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }
    this.sliding = true;
    this.changeColor(event.offsetX, event.offsetY);
  }

  onMouseMove(event: MouseEvent) {
    if (this.sliding) {
      this.changeColor(event.offsetX, event.offsetY);
    }
  }

  @HostListener('window:mouseup')
  @HostListener('window:touchend')
  onMouseUp() {
    this.sliding = false;
  }

  changeColor(offsetX: number, offsetY: number) {
    // selectedPosition.y is always (height / 2) in slider to be sure the scroller is in the middle of the gradient bar
    this.selectedPosition = { x: offsetX, y: this.height / 2 };
    this.drawSlider();
    const newHue = Math.round(360 / (this.width / offsetX));
    this.hue.emit(newHue);
  }

  getColorCoordinates(color: HSB) {
    const offsetX = this.width / (360 / color.h) || 0;

    return { x: offsetX, y: this.height / 2 };
  }
}
