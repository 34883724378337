import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { EditInlineTextMobComponent } from './edit-inline-text-mob.component';
import { editStyleOption } from '../../../../../react-text-editor/models/text-editor.model';

import { EditOption } from '../edit-text/edit-text-mob.component';
import { ConfigService, GetTextService, UiService } from '../../../services';
import { TextEditorService } from '../../../text-editor/text-editor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { UndoActions } from '../../../actions';
import { DomSanitizer } from '@angular/platform-browser';
import { InlineTextElement } from '../../../models';

export type editOptionName =
  | editStyleOption.text
  | editStyleOption.font
  | editStyleOption.fontSize
  | editStyleOption.fontStyle
  | editStyleOption.lineHeight
  | editStyleOption.transparency
  | editStyleOption.horizontalAlign;

export type EditTextOptions = { [E in editOptionName]: EditOption };

@Component({
  selector: 'ed-edit-inline-text-mob-short',
  templateUrl: './edit-inline-text-mob-short.component.html',
  styleUrls: ['./edit-inline-text-mob-short.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInlineTextMobShortComponent extends EditInlineTextMobComponent {
  buttonCount: number;
  editOptions: EditTextOptions = {
    text: new EditOption(editStyleOption.text),
    font: new EditOption(editStyleOption.font),
    fontSize: new EditOption(editStyleOption.fontSize),
    fontStyle: new EditOption(editStyleOption.fontStyle),
    lineHeight: new EditOption(editStyleOption.lineHeight),
    transparency: new EditOption(editStyleOption.transparency),
    horizontalAlign: new EditOption(editStyleOption.horizontalAlign)
  };

  showColorHeader: boolean;

  @Input() showAlternativeHeader: boolean;
  @Output() toggleGeneralMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set element(element: InlineTextElement) {
    this._element = element;
    this._inlineTextElement = element;
    this.editFullRange = element.editFullRange;

    this.editOptions.transparency.enabled = element.permissions.adjustTransparency;

    this.buttonCount = Object.keys(this.editOptions).length;
    Object.keys(this.editOptions).map(option => {
      this.buttonCount = !this.editOptions[option].enabled ? this.buttonCount - 1 : this.buttonCount;
    });
  }

  get element() {
    return this._element;
  }

  constructor(
    public getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected store: Store<AppState>,
    protected config: ConfigService,
    public uiService: UiService,
    protected changeDetector: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) {
    super(getTextService, textEditorService, store, config, uiService, changeDetector);
  }

  toggleColorHeader(show: boolean) {
    this.showColorHeader = show;
  }

  closeAlternativeHeader() {
    // do this always to make sure page is zoomed and rotated
    this.closeEditTextMode();
  }

  undo(event: Event) {
    // preventDefault prevents zooming on touch devices
    event.preventDefault();

    // reset alternative input value
    this.uiService.alternativeInputValue = this.element.textValue;

    this.store.dispatch(new UndoActions.Undo());
  }

  openEditMenu(editOption: EditOption) {
    this.openEditOption(editOption);
    this.toggleGeneralMenu.emit(false);
  }

  closeEditMenu() {
    this.closeAllEditOptions();
    this.toggleGeneralMenu.emit(true);
  }
}
