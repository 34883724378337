import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Color, ElementPermission, FoilTypes, FunctionPermission } from '../../models';
import { isEqual, round } from 'lodash-es';
import { ColorMenuDialogText } from '../../models/text';
import { TooltipPosition } from '@angular/material/tooltip';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

enum MessageType {
  FOIL,
  SPOT_UV
}

@Component({
  selector: 'ed-color-menu',
  templateUrl: './color-menu.component.html',
  styleUrls: ['../menu-dialog.component.scss', './color-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          display: 'none',
          opacity: 0
        })
      ),
      transition('open => closed', [animate('0.4s 0s ease-in')]),
      transition('closed => open', [
        animate(
          '0.5s',
          keyframes([
            style({ display: 'block', offset: 0 }),
            style({ opacity: 0.2, offset: 0.1 }),
            style({ opacity: 0.5, offset: 0.4 }),
            style({ opacity: 0.8, offset: 0.8 }),
            style({ opacity: 1, offset: 1 })
          ])
        )
      ])
    ])
  ]
})
export class ColorMenuComponent implements AfterViewInit {
  @ViewChild('foilMessage', { static: false }) foilMessage: ElementRef;
  @ViewChild('spotUvMessage', { static: false }) spotUvMessage: ElementRef;

  @Input() colors: Color[] = [];
  @Input() spotUvColors: Color[] = [];
  @Input() foilColors: Color[] = [];
  @Input() customColors: Color[] = [];
  @Input() designColors: Color[] = [];
  @Input() rainbowBackgroundImage: string;

  @Input() set elementColor(color: Color) {
    const elementColor = color ? color : new Color('', '#fff');
    this._elementColor = elementColor;
    this.elementColor$.next(elementColor);
  }

  get elementColor() {
    return this._elementColor;
  }

  private _elementColor: Color;
  elementColor$ = new BehaviorSubject<Color>(new Color('', '#fff'));

  @Input() tooltipShowDelay: number;
  @Input() showFoilPanel: boolean;
  @Input() showSpotUvColors: boolean;
  @Input() originalColorOption: boolean;
  @Input() dataLayerIdPrefix: string;
  @Input() text: ColorMenuDialogText;
  @Input() foilInfoUrl: string;
  @Input() spotUvInfoUrl: string;
  @Input() isMobile: boolean;
  @Input() designHasFoiledElement: boolean;
  @Input() designHasSpotUvElement: boolean;

  @Output() selectedColor = new EventEmitter<Color>();
  @Output() customColor = new EventEmitter<Color>();
  @Output() originalColor = new EventEmitter<boolean>();
  @Output() showCustomColorMenu = new EventEmitter<boolean>();
  @Output() showCustomColorMenuForSpotUv = new EventEmitter<boolean>();
  @Output() updateAllSpecialColorElements = new EventEmitter<Color>();
  @Output() resetAllSpotUvElements = new EventEmitter<boolean>();

  tooltipPosition: TooltipPosition = 'below';
  dialogPadding = 14; // 15px padding = -1px margin
  chipMargin = 2; // 1px each side
  chipDefaultSize = 35;
  showFoilMessage = false;
  foilMessageHeight: number;
  foilColorHeaderId = 'foilColorHeader';
  foilColorMessageId = 'foilColorMessage';
  showHasNoFoilMessage: boolean;
  showHasNoSpotUvMessage: boolean;
  showHasSpecialColorMessageTimer: ReturnType<typeof setTimeout>;

  showSpotUvMessage = false;
  spotUvMessageHeight: number;
  spotUvHeaderId = 'spotUvHeader';
  spotUvMessageId = 'spotUvMessage';
  messageType = MessageType;

  permissions = ElementPermission;

  ngAfterViewInit(): void {
    if (this.foilMessage) {
      this.foilMessageHeight = this.foilMessage.nativeElement.offsetHeight;
    }
    if (this.spotUvMessage) {
      this.spotUvMessageHeight = this.spotUvMessage.nativeElement.offsetHeight;
    }
  }

  get chipWidth(): number {
    const colorListWidth = window.screen.width - this.dialogPadding * 2; // * 2 because of left and right
    const amountChips = round(colorListWidth / (this.chipDefaultSize + this.chipMargin), 0);
    const availableWidth = colorListWidth - amountChips * this.chipMargin;
    return availableWidth / amountChips;
  }

  getFoilChipBackground(foilColor: Color): string | SafeUrl {
    if (foilColor.foil === FoilTypes.rainbow) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`url(${this.rainbowBackgroundImage})`);
    } else {
      return 'linear-gradient(to top right, ' + foilColor.colorValue + ', white)';
    }
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    @Inject(DOCUMENT) protected document: Document
  ) {}

  openCustomColorMenu(spotUv = false) {
    if (spotUv) {
      this.showCustomColorMenuForSpotUv.emit();
    } else {
      this.showCustomColorMenu.emit();
    }
  }

  changeAllFoilColors(e: Event) {
    e.preventDefault();
    if (!this.designHasFoiledElement) {
      this.showHasNoFoilMessage = true;
      clearTimeout(this.showHasSpecialColorMessageTimer);
      this.showHasSpecialColorMessageTimer = setTimeout(() => {
        this.showHasNoFoilMessage = false;
        this.cdr.detectChanges();
      }, 3000);
      return;
    }
    this.updateAllSpecialColorElements.emit({
      name: '',
      colorValue: this.elementColor.colorValue,
      foil: null
    });
  }

  changeAllSpotUvColors(e: Event) {
    e.preventDefault();
    if (!this.designHasSpotUvElement) {
      this.showHasNoSpotUvMessage = true;
      clearTimeout(this.showHasSpecialColorMessageTimer);
      this.showHasSpecialColorMessageTimer = setTimeout(() => {
        this.showHasNoSpotUvMessage = false;
        this.cdr.detectChanges();
      }, 3000);
      return;
    }
    this.resetAllSpotUvElements.emit();
  }

  onSelect(color: Color): void {
    if (color.foil && !this.elementColor.foil && !this.showFoilMessage && !this.designHasFoiledElement) {
      this.toggleMessage(true, MessageType.FOIL);
    } else if (!color.foil && this.showFoilMessage) {
      this.toggleMessage(false, MessageType.FOIL);
    }
    if (color.spotUv && !this.elementColor.spotUv && !this.showSpotUvMessage && !this.designHasSpotUvElement) {
      this.toggleMessage(true, MessageType.SPOT_UV);
    } else if (!color.spotUv && this.showSpotUvMessage) {
      this.toggleMessage(false, MessageType.SPOT_UV);
    }
    if (!isEqual(color, this.elementColor)) {
      this.selectedColor.emit(color);
    }
  }

  resetColor() {
    this.originalColor.emit();
  }

  scrollToElement(id: string) {
    const element = this.document.getElementById(id);
    requestAnimationFrame(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    });
  }

  toggleMessage(open: boolean, messageType: MessageType) {
    const messageId = messageType === MessageType.FOIL ? this.foilColorMessageId : this.spotUvMessageId;
    this.showFoilMessage = messageType === MessageType.FOIL ? open : this.showFoilMessage;
    this.showSpotUvMessage = messageType === MessageType.SPOT_UV ? open : this.showSpotUvMessage;
    if (this.isMobile) {
      this.scrollToElement(messageId);
    }
  }
}
