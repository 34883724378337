import { createEffect, Effect, ofType } from '@ngrx/effects';
import {
  ADD_IMAGE,
  ADD_IMAGE_AS_PHOTOFRAME,
  ADD_TEXT,
  ADD_TEXT_INLINE,
  CHECK_SPECIAL_COLOR,
  OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG,
  OPEN_SET_FOIL_SAFETY_DIALOG,
  REPLACE_IMAGE,
  RESIZE,
  ROTATE,
  TRANSLATE,
  UPDATE_IMAGE
} from '../../../actions/canvas-actions/canvas.action.types';
import { exhaustMap, filter, map, mergeAll, withLatestFrom } from 'rxjs/operators';
import {
  AddImage,
  AddImageAsPhotoFrame,
  CheckSpecialColor,
  OpenMultipleSpecialColorsDialog,
  OpenSetFoilSafetyDialog,
  ReplaceImage,
  Resize,
  Rotate,
  ToggleElementPermission,
  ToggleElementPreset,
  Translate,
  UpdateAllSpecialColorElements,
  UpdateImageAction
} from '../../../actions/canvas-actions';
import { select } from '@ngrx/store';
import { canAddSpotUv, getEnableFoilableByDefault, showFoilSafetyMarginWarning } from '../../permissions.reducer';
import { ConfirmDialogComponent, WarningDialogComponent } from '../../../shared/dialogs';
import { Injectable } from '@angular/core';
import { Undo } from '../../../actions/undo-actions';
import { CanvasEffects } from './canvas.effects';
import { Color, ElementPermission, ImageElement, SpecialColorElement } from '../../../models';
import { elementIsJpgImage, fileExtensionIsJpg, fileExtensionIsSvg } from '../../../utils/element.utils';
import { replaceSpecialColorInText, resetSpecialColorAction } from '../../../utils/special-color.utils';

@Injectable()
export class SpecialColorEffects extends CanvasEffects {
  @Effect({ dispatch: true })
  updatePositionFoilElement$ = this.actions$.pipe(
    ofType(ROTATE, RESIZE, TRANSLATE, UPDATE_IMAGE),
    map((action: Rotate | Resize | Translate) => action),
    // filter out page-elements, because you don't want to calculate the intersection of a page-element with its own safetyPolygon
    filter(action => action.route.length > 1),
    withLatestFrom(this.store$.pipe(select(showFoilSafetyMarginWarning))),
    withLatestFrom(this.design$),
    filter(([[action, showWarning], design]) => {
      const element = design.getElement(action.route);
      return (
        showWarning &&
        (element as SpecialColorElement).foilType &&
        !this.elementsIntersectService.isInsideSafetyPolygon(action.route) &&
        // only show warningdialog when dialog for removing element is not shown (when it still intersects with parent)
        this.elementsIntersectService.intersectsWithParent(action.route)
      );
    }),
    exhaustMap(() => {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        id: 'set-foil-element-dialog',
        data: { text: this.getTextService.text.dialog.specialColor.updatePositionFoilSafety }
      });
      return dialogRef.afterClosed();
    }),
    map(() => new Undo())
  );

  @Effect({ dispatch: true })
  updateElementUsesSpecialColor$ = this.actions$.pipe(
    ofType(CHECK_SPECIAL_COLOR),
    map((action: CheckSpecialColor) => action),
    withLatestFrom(this.store$.pipe(select(showFoilSafetyMarginWarning))),
    withLatestFrom(this.design$),
    map(([[action, showWarning], design]) => {
      const selectedElement = design.getElement(action.route) as SpecialColorElement;
      const excludedRoutes = getExcludedRoutes(selectedElement);
      const spotUvElements = getSpecialColorElementsForRoutes(design.spotUvElements, excludedRoutes);
      const foilElements = getSpecialColorElementsForRoutes(design.foilElements, excludedRoutes);

      if (action.color.foil) {
        if (!this.elementsIntersectService.isInsideSafetyPolygon(action.route) && showWarning) {
          return new OpenSetFoilSafetyDialog(this.getTextService.text.dialog.specialColor.setFoilSafety);
        } else if (hasDifferentFoil(foilElements, action.color) || !!spotUvElements.length) {
          return new OpenMultipleSpecialColorsDialog(action.route, action.color);
        }
      }
      if (action.color.spotUv && !!foilElements.length) {
        return new OpenMultipleSpecialColorsDialog(action.route, action.color);
      }
      if (action.color.spotUv) {
        const actionElementIsJpg = elementIsJpgImage(design.getElement(action.route));

        if (hasDifferentSpotUv(spotUvElements, actionElementIsJpg)) {
          const message = actionElementIsJpg
            ? this.getTextService.text.dialog.differentSpotUvTypes.spotUvOnJpgElement
            : this.getTextService.text.dialog.differentSpotUvTypes.spotUvOnOtherElement;
          return new OpenMultipleSpecialColorsDialog(action.route, action.color, message);
        }
      }

      return new UpdateAllSpecialColorElements(action.route, action.color);
    })
  );

  @Effect({ dispatch: true })
  openMultipleSpecialColorsDialog$ = this.actions$.pipe(
    ofType(OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG),
    exhaustMap((action: OpenMultipleSpecialColorsDialog) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        id: 'multiple-special-colors-dialog',
        data: {
          confirmButtonText: this.getTextService.text.dialog.button.yes,
          cancelButtonText: this.getTextService.text.dialog.button.no,
          message:
            action.message ||
            replaceSpecialColorInText(
              this.getTextService.text.dialog.specialColor.multipleSpecialColor,
              this.getTextService.text.dialog.specialColor.replaceColorText,
              action.color
            ),
          title: this.getTextService.text.dialog.confirm.title
        }
      });
      return dialogRef.afterClosed().pipe(map(result => [result, action]));
    }),
    map(([result, action]) => {
      if (result) {
        return new UpdateAllSpecialColorElements(action.route, action.color);
      }
    }),
    filter(action => !!action)
  );

  @Effect({ dispatch: false })
  openSetFoilSafetyDialog$ = this.actions$.pipe(
    ofType(OPEN_SET_FOIL_SAFETY_DIALOG),
    map((action: OpenSetFoilSafetyDialog) => {
      this.dialog.open(WarningDialogComponent, {
        id: 'set-foil-element-dialog',
        data: {
          title: this.getTextService.text.dialog.warning.title,
          text: action.text,
          buttonText: this.getTextService.text.dialog.button.ok
        }
      });
    })
  );

  @Effect({ dispatch: true })
  checkEnableFoilByDefault$ = this.actions$.pipe(
    ofType(ADD_IMAGE, ADD_IMAGE_AS_PHOTOFRAME, REPLACE_IMAGE, ADD_TEXT, ADD_TEXT_INLINE),
    map((action: AddImage | AddImageAsPhotoFrame | ReplaceImage) => action),
    withLatestFrom(this.design$, this.store$.pipe(select(getEnableFoilableByDefault))),
    map(([action, design, enableFoilByDefault]) => {
      const element = design.selectedElement;
      const filename = action.sid || action.url;
      const isSvg = filename ? fileExtensionIsSvg(filename) : true;
      enableFoilByDefault = design.visiblePage.permissions.isFoilable && enableFoilByDefault;
      if (design.material.isFoilable && enableFoilByDefault && isSvg && !element.permissions.isFoilable) {
        return new ToggleElementPermission(ElementPermission.isFoilable, element.route);
      }
    }),
    filter(action => !!action)
  );

  updateSpecialColorPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_IMAGE),
      map((action: UpdateImageAction) => action),
      withLatestFrom(this.designSet$, this.store$.pipe(select(canAddSpotUv))),
      map(([action, designSet, canAddSpotUv]) => {
        const actions = [];

        const design = designSet.getDesign(action.route);
        const page = design.getPageElement(action.route[action.route.length - 2]);
        // update image action is done for all designs/pages, not only active design and visible page,
        // so permissions should be taken from the design and page that the element is in
        const pageIsSpotUvable = design.material.isSpotUvable && page.permissions.isSpotUvable;
        const pageIsFoilable = design.material.isFoilable && page.permissions.isFoilable;

        let element = design.getElement(action.route);

        if (element.isPhotoFrameChild) {
          element = element.parent;
        }

        const currentlySpotUvable = element.permissions.isSpotUvable;
        const currentlyFoilable = element.permissions.isFoilable;
        const image = element.firstChild as ImageElement;
        const sidOrUrl = image?.sid || image?.url;
        const isVectorOrJpg = action.isVectorImage || (sidOrUrl && fileExtensionIsJpg(sidOrUrl));

        if (currentlySpotUvable && (!isVectorOrJpg || !canAddSpotUv || !pageIsSpotUvable)) {
          actions.push(new ToggleElementPreset(element.route, ElementPermission.isSpotUvable));
          actions.push(resetSpecialColorAction(element, ElementPermission.isSpotUvable));
        } else if (currentlyFoilable && (!action.isVectorImage || !pageIsFoilable)) {
          actions.push(new ToggleElementPreset(element.route, ElementPermission.isFoilable));
          actions.push(resetSpecialColorAction(element, ElementPermission.isFoilable));
        }

        return actions;
      }),
      mergeAll(),
      filter(action => !!action)
    )
  );
}

function hasDifferentFoil(otherFoilElements: SpecialColorElement[], color: Color): boolean {
  return otherFoilElements.some(el => el.foilType !== color.foil);
}

function hasDifferentSpotUv(otherSpotUvElements: SpecialColorElement[], updateElementIsJpg: boolean): boolean {
  return otherSpotUvElements.some(el => elementIsJpgImage(el) !== updateElementIsJpg);
}

function getSpecialColorElementsForRoutes(specialColorElements: SpecialColorElement[], excludedRoutes: number[][]) {
  return specialColorElements.filter(el => !excludedRoutes.map(r => r.join()).includes(el.route.join()));
}

function getExcludedRoutes(element: SpecialColorElement) {
  return element.isPhotoFrameChild
    ? [element.route, element.parent.route]
    : element.isPhotoFrame()
    ? [element.route, element.firstChild.route]
    : [element.route];
}
