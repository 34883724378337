import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavMobComponent } from '../nav-mob.component';
import { CanvasActions } from '../../../actions';

@Component({
  selector: 'ed-nav-mob-new',
  templateUrl: 'nav-mob-new.component.html',
  styleUrls: ['nav-mob-new.component.scss', '../../../shared/mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMobNewComponent extends NavMobComponent {
  @Input() offsetTop: number;
  @Input() addChildElements: boolean;

  @Output() openAddMenuPanel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPagesMenuPanel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

  openAddMenu() {
    this.openAddMenuPanel.emit();
  }

  openPagesMenu() {
    this.openPagesMenuPanel.emit();
  }
}
