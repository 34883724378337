export class CustomFormField {
  constructor(
    public name: string,
    public type: string,
    public value = '',
    public placeholder = '',
    public title = '',
    public required = false,
    public minlength = 0,
    public options: Array<CustomFormFieldOption> = [],
    public hideIfValue: CustomFormFieldOption = null,
    public label = '',
    public hint = ''
  ) {}
}

export class CustomFormFieldOption {
  key: string;
  value: string;
}

export const FormFieldType = {
  Checkbox: 'checkbox',
  Email: 'email',
  Password: 'password',
  Radio: 'radio',
  Select: 'select',
  Text: 'text',
  Textbox: 'textbox'
};
