import { NgModule } from '@angular/core';
import { MainMenuComponent } from './main-menu.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [MainMenuComponent],
  providers: [],
  exports: [MainMenuComponent]
})
export class MainMenuModule {}
