import { fabric } from 'fabric';
import { X_TEMP_KEY_PREFIX, X_TRANSFORM_MATRIX } from '../fabric/constants/object-keys';

/**
 * On object transform, also transform (move/rotate/scale) object provided.
 */
export function onObjectTransform_TransformObject(event: fabric.IEvent<MouseEvent>, object: fabric.Object) {
  const target = event.transform.target;
  if (!object) return;

  const transformMatrix = object[X_TRANSFORM_MATRIX];
  if (!transformMatrix) {
    return;
  }

  const newTransformMatrix = fabric.util.multiplyTransformMatrices(
    target.calcTransformMatrix(),
    transformMatrix
  );

  const opts = fabric.util.qrDecompose(newTransformMatrix);
  object.setPositionByOrigin(
    new fabric.Point(opts.translateX, opts.translateY),
    'left',
    'top'
  );

  object.set(opts);
  object.setCoords();
}

/**
 * Set transform matrix based on target object provided.
 */
export function setTransformMatrix(object: fabric.Object, target: fabric.Object) {
  const opts = {
    flipX: object.flipX,
    flipY: object.flipY,
    originX: object.originX,
    originY: object.originY,
  };

  target.setCoords();

  object.set({
    flipX: target.flipX,
    flipY: target.flipY,
    originX: 'center',
    originY: 'center',
  });
  object.setCoords();

  const targetTransformMatrix = target.calcTransformMatrix();
  const invertedTargetTransformMatrix = fabric.util.invertTransform(targetTransformMatrix);

  object[X_TRANSFORM_MATRIX] = fabric.util.multiplyTransformMatrices(
    invertedTargetTransformMatrix,
    object.calcTransformMatrix()
  );

  object.set(opts);
  object.setCoords();
}

/**
 * On object transform, contain within provided object.
 * 
 * @param event Mouse event.
 * @param object Outer object.
 */
export function onObjectTransformContainWithinObject(event: fabric.IEvent<MouseEvent>, object: fabric.Object) {
  const target = event.transform.target;
  const original = event.transform.original;
  const objectCenter = object.getCenterPoint();

  // Calculate normalized position
  const { x: objectLeft, y: objectTop } = calculatePosition(object.left, object.top, -object.angle, objectCenter);
  const { x: targetLeft, y: targetTop } = calculatePosition(target.left, target.top, -target.angle, objectCenter);
  const { x: originalLeft, y: originalTop } = calculatePosition(original.left, original.top, -original.angle, objectCenter);

  // Calculate target scale
  let nextScaleX = target.scaleX;
  let nextScaleY = target.scaleY;

  const maxScaleX_l = ((object.width * object.scaleX) - (objectLeft + (object.width * object.scaleX) - (originalLeft + (target.width * original.scaleX)))) / (target.width * original.scaleX) * original.scaleX;
  const maxScaleY_t = ((object.height * object.scaleY) - (objectTop + (object.height * object.scaleY) - (originalTop + (target.height * original.scaleY)))) / (target.height * original.scaleY) * original.scaleY;
  const maxScaleX_r = ((object.width * object.scaleX) - (originalLeft - objectLeft)) / (target.width * original.scaleX) * original.scaleX;
  const maxScaleY_b = ((object.height * object.scaleY) - (originalTop - objectTop)) / (target.height * original.scaleY) * original.scaleY;

  switch (event.transform.corner) {
    case "tl":
      nextScaleX = Math.min(target.scaleX, maxScaleX_l);
      nextScaleY = Math.min(target.scaleY, maxScaleY_t);
      break;
    case "tr":
      nextScaleX = Math.min(target.scaleX, maxScaleX_r);
      nextScaleY = Math.min(target.scaleY, maxScaleY_t);
      break;
    case "br":
      nextScaleX = Math.min(target.scaleX, maxScaleX_r);
      nextScaleY = Math.min(target.scaleY, maxScaleY_b);
      break;
    case "bl":
      nextScaleX = Math.min(target.scaleX, maxScaleX_l);
      nextScaleY = Math.min(target.scaleY, maxScaleY_b);
      break;
    case "ml":
      nextScaleX = Math.min(target.scaleX, maxScaleX_l);
      break;
    case "mt":
      nextScaleY = Math.min(target.scaleY, maxScaleY_t);
      break;
    case "mr":
      nextScaleX = Math.min(target.scaleX, maxScaleX_r);
      break;
    case "mb":
      nextScaleY = Math.min(target.scaleY, maxScaleY_b);
      break;
  }

  // Calculate target position
  let { x: nextLeft, y: nextTop } = calculatePosition(
    Math.min(Math.max(targetLeft, objectLeft), (objectLeft + (object.width * object.scaleX)) - (target.width * nextScaleX)),
    Math.min(Math.max(targetTop, objectTop), (objectTop + (object.height * object.scaleY)) - (target.height * nextScaleY)),
    target.angle,
    objectCenter
  );

  // Set target position and scale
  target.set({
    left: nextLeft,
    top: nextTop,
    scaleX: nextScaleX,
    scaleY: nextScaleY,
  });
  target.setCoords();
}

/**
 * On object transform, contain provided object.
 * 
 * @param event Mouse event.
 * @param object Outer object.
 */
export function onObjectTransformContainObject(event: fabric.IEvent<MouseEvent>, object: fabric.Object) {
  const target = event.transform.target;
  const original = event.transform.original;
  const objectCenter = object.getCenterPoint();

  // Calculate normalized position
  const { x: objectLeft, y: objectTop } = calculatePosition(object.left, object.top, -object.angle, objectCenter);
  const { x: targetLeft, y: targetTop } = calculatePosition(target.left, target.top, -target.angle, objectCenter);
  const { x: originalLeft, y: originalTop } = calculatePosition(original.left, original.top, -original.angle, objectCenter);

  // Calculate target scale
  let nextScaleX = target.scaleX;
  let nextScaleY = target.scaleY;

  const maxScaleX_l = ((object.width * object.scaleX) - (objectLeft + (object.width * object.scaleX) - (originalLeft + (target.width * original.scaleX)))) / (target.width * original.scaleX) * original.scaleX;
  const maxScaleY_t = ((object.height * object.scaleY) - (objectTop + (object.height * object.scaleY) - (originalTop + (target.height * original.scaleY)))) / (target.height * original.scaleY) * original.scaleY;
  const maxScaleX_r = ((object.width * object.scaleX) - (originalLeft - objectLeft)) / (target.width * original.scaleX) * original.scaleX;
  const maxScaleY_b = ((object.height * object.scaleY) - (originalTop - objectTop)) / (target.height * original.scaleY) * original.scaleY;

  switch (event.transform.corner) {
    case "tl":
      nextScaleX = Math.max(target.scaleX, maxScaleX_l);
      nextScaleY = Math.max(target.scaleY, maxScaleY_t);
      break;
    case "tr":
      nextScaleX = Math.max(target.scaleX, maxScaleX_r);
      nextScaleY = Math.max(target.scaleY, maxScaleY_t);
      break;
    case "br":
      nextScaleX = Math.max(target.scaleX, maxScaleX_r);
      nextScaleY = Math.max(target.scaleY, maxScaleY_b);
      break;
    case "bl":
      nextScaleX = Math.max(target.scaleX, maxScaleX_l);
      nextScaleY = Math.max(target.scaleY, maxScaleY_b);
      break;
    case "ml":
      nextScaleX = Math.max(target.scaleX, maxScaleX_l);
      break;
    case "mt":
      nextScaleY = Math.max(target.scaleY, maxScaleY_t);
      break;
    case "mr":
      nextScaleX = Math.max(target.scaleX, maxScaleX_r);
      break;
    case "mb":
      nextScaleY = Math.max(target.scaleY, maxScaleY_b);
      break;
  }

  // Calculate target position
  let { x: nextLeft, y: nextTop } = calculatePosition(
    Math.max(Math.min(targetLeft, objectLeft), (objectLeft + (object.width * object.scaleX)) - (target.width * nextScaleX)),
    Math.max(Math.min(targetTop, objectTop), (objectTop + (object.height * object.scaleY)) - (target.height * nextScaleY)),
    target.angle,
    objectCenter
  );

  // Set target position and scale
  target.set({
    left: nextLeft,
    top: nextTop,
    scaleX: nextScaleX,
    scaleY: nextScaleY,
  });
  target.setCoords();
}

export function calculatePosition(left: number, top: number, angle: number, centerPoint: fabric.IPoint) {
  const theta = fabric.util.degreesToRadians(angle);
  const cosAngle = Math.cos(theta);
  const sinAngle = Math.sin(theta);

  return {
    x: cosAngle * (left - centerPoint.x) - sinAngle * (top - centerPoint.y) + centerPoint.x,
    y: sinAngle * (left - centerPoint.x) + cosAngle * (top - centerPoint.y) + centerPoint.y,
  };
}

/**
 * Checks if a specific point is on any edge of an object 
 */
export function isPointOnEdge(point: fabric.Point, object: fabric.Object) {
  const objectCoords = object.getBoundingRect();

  const left = objectCoords.left;
  const top = objectCoords.top;
  const width = objectCoords.width;
  const height = objectCoords.height;

  return (
    ((point.x === left || point.x === left + width) && (point.y >= top && point.y <= top + height)) ||
    ((point.y === top || point.y === top + height) && (point.x >= left && point.x <= left + width))
  );
}

/**
 * Clear temp keys of the object provided.
 * 
 * @param {fabric.Object} object Object that should have temp keys cleared.
 * @remark Function should be called on canvas event, as the temp keys are not always stored in the target object.
 * @example ```js
            this.canvas.on('object:modified', (e: fabric.IEvent<MouseEvent>) => {
              this.canvas
                .getObjects()
                .forEach((object) => clearObjectTempKeys(object));
            });
 * ```
 */
export function clearObjectTempKeys(object: fabric.Object) {
  Object.keys(object)
    .filter((key) => key.startsWith(X_TEMP_KEY_PREFIX))
    .forEach((tempKey) => {
      object[tempKey] = null;
    });
}
