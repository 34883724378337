import { Injectable } from '@angular/core';
import { Effect, ofType } from '@ngrx/effects';
import * as CanvasActionTypes from '../../../actions/canvas-actions/canvas.action.types';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ChangeTextValueInline, ConvertToInlineText, SetInlineTextEditMode } from '../../../actions/canvas-actions';
import { SET_INLINE_TEXT_EDIT_MODE } from '../../../actions/canvas-actions/canvas.action.types';
import { CanvasActions } from '../../../actions';
import { InlineTextElement, Paragraph } from '../../../models';
import { CHANGE_TEXT_VALUE_INLINE } from '../../../actions/canvas-actions/canvas.action.types';
import { cloneDeep } from 'lodash-es';
import { CanvasEffects } from './canvas.effects';

@Injectable()
export class InlineTextEffects extends CanvasEffects {
  @Effect({ dispatch: true })
  getInlineTextImages$ = this.actions$.pipe(
    ofType(CanvasActionTypes.CONVERT_TO_INLINE_TEXT),
    map((action: ConvertToInlineText) => action),
    // do not update images on first page (those are already loaded via image.component)
    filter(action => action.route[action.route.length - 1] !== 1),
    mergeMap(action =>
      this.cacheService
        .getImage(action.inlineTextElement)
        .pipe(map(item => this.cacheService.getUpdateImageAction(action.inlineTextElement, item)))
    )
  );

  @Effect({ dispatch: false })
  convertInlineTextFromInputValue$ = this.actions$.pipe(
    ofType(CHANGE_TEXT_VALUE_INLINE),
    map(action => action as ChangeTextValueInline),
    withLatestFrom(this.design$),
    map(([action, design]) => {
      const selectedElement = cloneDeep(design.selectedElement) as InlineTextElement;
      const firstParagraph = selectedElement.text[0];
      const firstLine = firstParagraph.lines[0];
      const firstSpan = firstLine.textSpans[0];
      const newParagraphs: Paragraph[] = [];

      action.text.split('\n').map(part => {
        const newPar = cloneDeep(firstParagraph);
        const newLine = cloneDeep(firstLine);
        const newSpan = cloneDeep(firstSpan);
        newSpan.text = part;
        newLine.textSpans = [newSpan];
        newPar.lines = [newLine];
        newParagraphs.push(newPar);
      });

      selectedElement.text = newParagraphs;
      selectedElement.textNotEdited = false;
      this.textEditorService.updateInput(selectedElement);
    })
  );

  /**
   * remove inline text element when exiting edit mode (set editFullRange to true) without a text value.
   * alternative input value gets processed after this so remove does not work for that
   */
  @Effect({ dispatch: true })
  removeEmptyInlineTextElement$ = this.actions$.pipe(
    ofType(SET_INLINE_TEXT_EDIT_MODE),
    withLatestFrom(this.design$),
    map(([action, design]) => design.getElement((action as SetInlineTextEditMode).route) as InlineTextElement),
    withLatestFrom(this.uiService.useAlternativeInputInlineText$),
    filter(([element, useAlternativeInput]) => {
      return !useAlternativeInput && this.config.isMobile && element && element.editFullRange && !element.hasText;
    }),
    map(([element]) => new CanvasActions.RemoveElement(element.route))
  );
}
