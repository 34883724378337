import { fabric } from "fabric";
import { BaseElement, Font, InlineTextElement } from "src/app/models";
import { FontUtils } from "../../utils/font.utils";
import { InlineTextElementUtils } from "../../utils/inline-text-element.utils";
import { ControlsVisibility } from "../controls-visibility";
import { TextboxFontStyle } from "../textbox-font-style";
import { TextboxFontWeight } from "../textbox-font-weight";
import { TextboxTextAlign } from "../textbox-text-align";

export type TextboxData = {
  element: InlineTextElement;
  fontLibrary: Font[];
}

declare module "src/app/models/inline-text-element" {
  interface InlineTextElement {
    createImageAsync(object?: fabric.Object, data?: any): Promise<fabric.Image>;
    getImageOptions(object: fabric.Object): fabric.IObjectOptions;
    getImageControlsVisibility(object: fabric.Object): ControlsVisibility;
    getTextboxText(): string;
    getTextboxTextAlign(): TextboxTextAlign;
    getTextboxFontSize(): number;
    getTextboxFontWeight(): TextboxFontWeight;
    getTextboxFontStyle(): TextboxFontStyle;
  }
}

InlineTextElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  object ??= new fabric.Textbox("", { data });

  if (!(object instanceof fabric.Textbox)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);
  object.set({ data });

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  // Set text editable
  if (!object.editable && object.isEditing) {
    object.exitEditing();
  }

  // Set character limit
  if (typeof element.characterLimit === "number") {
    object.hiddenTextarea?.setAttribute("maxlength", element.characterLimit.toString());
  } else {
    object.hiddenTextarea?.removeAttribute("maxlength");
  }

  return object;
}

InlineTextElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement) || !(object instanceof fabric.Textbox)) {
    return null;
  }

  const [firstParagraph] = element.text;
  const [fistLine] = firstParagraph.lines;
  const [firstSpan] = fistLine.textSpans;

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    text: element.getTextboxText(),
    textAlign: element.getTextboxTextAlign(),
    lineHeight: firstParagraph.lineHeight,
    fontSize: element.getTextboxFontSize(),
    fontFamily: FontUtils.getFontFamily(object.data.fontLibrary, firstSpan.font),
    fill: firstSpan.color,
    fontWeight: element.getTextboxFontWeight(),
    fontStyle: element.getTextboxFontStyle(),
    underline: firstSpan.underlined,
    lockMovementX: !element.permissions.isMovable || object.isEditing,
    lockMovementY: !element.permissions.isMovable || object.isEditing,
    editable: !!element.permissions.textEditable,
    breakWords: true
  };
}

InlineTextElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement) || !(object instanceof fabric.Textbox)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    "tl": !!element.permissions.isResizable,
    "tr": !!element.permissions.isResizable,
    "br": !!element.permissions.isResizable,
    "bl": !!element.permissions.isResizable,
    "ml": !!element.permissions.isResizable,
    "mr": !!element.permissions.isResizable,
    "mtr": !!element.permissions.isRotatable,
    "remove": !!element.permissions.isRemovable,
    "move": !!element.permissions.isMovable,
  };
}

//#region Image

InlineTextElement.prototype.createImageAsync = async function (object?: fabric.Object): Promise<fabric.Image> {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement) || !element.imgSource) {
    return null;
  }

  object ??= await new Promise((resolve: (image: fabric.Image) => void) => fabric.Image.fromURL(element.imgSource, (image) => resolve(image), {
    crossOrigin: "anonymous"
  }));

  if (!(object instanceof fabric.Image)) {
    return null;
  }

  if (object.getSrc() !== element.imgSource) {
    await new Promise((resolve: (image: fabric.Image) => void) => (object as fabric.Image).setSrc(element.imgSource, (image: fabric.Image) => resolve(image), {
      crossOrigin: "anonymous",
    }));
  }

  const objectOptions = element.getImageOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getImageControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

InlineTextElement.prototype.getImageOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement) || !(object instanceof fabric.Image)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    scaleX: element.width / object.width,
    scaleY: element.height / object.height,
  };
}

InlineTextElement.prototype.getImageControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: InlineTextElement = this;

  if (!(element instanceof InlineTextElement) || !(object instanceof fabric.Image)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
  };
}

//#endregion

//#region Util

InlineTextElement.prototype.getTextboxText = function (): string {
  const element: InlineTextElement = this;
  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  return element.text.map((p) => p.lines.map((l) => l.textSpans.map((ts) => ts.text).join('')).join('')).join('\n');
}

InlineTextElement.prototype.getTextboxTextAlign = function (): TextboxTextAlign {
  const element: InlineTextElement = this;
  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  const [firstParagraph] = element.text;

  return InlineTextElementUtils.getTextboxTextAlign(firstParagraph.textAlign, firstParagraph.isJustified);
}

InlineTextElement.prototype.getTextboxFontSize = function (): number {
  const element: InlineTextElement = this;
  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  const [firstParagraph] = element.text;
  const [fistLine] = firstParagraph.lines;
  const [firstSpan] = fistLine.textSpans;

  return InlineTextElementUtils.getTextboxFontSize(firstSpan.fontSize);
}

InlineTextElement.prototype.getTextboxFontWeight = function (): TextboxFontWeight {
  const element: InlineTextElement = this;
  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  const [firstParagraph] = element.text;
  const [fistLine] = firstParagraph.lines;
  const [firstSpan] = fistLine.textSpans;

  return InlineTextElementUtils.getTextboxFontWeight(firstSpan.bold);
}

InlineTextElement.prototype.getTextboxFontStyle = function (): TextboxFontStyle {
  const element: InlineTextElement = this;
  if (!(element instanceof InlineTextElement)) {
    return null;
  }

  const [firstParagraph] = element.text;
  const [fistLine] = firstParagraph.lines;
  const [firstSpan] = fistLine.textSpans;

  return InlineTextElementUtils.getTextboxFontStyle(firstSpan.italic);
}

//#endregion
