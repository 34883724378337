import { EditorAction } from '../action';
import { CONVERT_TO_INLINE_TEXT } from './canvas.action.types';
import { InlineTextElement } from '../../models';

export class ConvertToInlineText implements EditorAction {
  readonly type = CONVERT_TO_INLINE_TEXT;
  readonly undoable = false;
  readonly save = true;

  constructor(public inlineTextElement: InlineTextElement, public route: number[]) {}
}
