import { Fold } from './design';
import { FormatSpec } from './format-spec';

export enum ScaleOptions {
  auto,
  height,
  width
}

export interface PresetPage {
  width: number;
  height: number;
  bleed: number;
  id?: number;
  scale?: ScaleOptions;
  oldPageIndex?: number;
}

export interface ChangeFormatPreset {
  name: string;
  pages: Array<PresetPage>;
  fold: Fold;
  specs?: FormatSpec;
}
