import { Injectable } from '@angular/core';
import { CanvasElement, Design, PageElement } from '../models';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { CanvasActions } from '../actions';
import { BOTTOM, LEFT, RIGHT, TOP } from '../data/directions';
import { selectDesign } from '../selectors';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ElementTransformService {
  design: Design;
  selectedElement: CanvasElement;
  rotation: number;
  x: number;
  y: number;
  largeStep = 10;
  smallStep = 1;

  constructor(private store: Store<AppState>) {
    this.store
      .pipe(
        select(selectDesign),
        filter(d => !!d)
      )
      .subscribe(design => {
        this.design = design;
        this.selectedElement = design.selectedElement;
        if (design.selectedElement) {
          this.rotation = design.selectedElement.screenRotation;
          this.x = design.selectedElement.screenX;
          this.y = design.selectedElement.screenY;
        }
      });
  }

  get allowMoving() {
    return (
      !this.selectedElement ||
      (this.selectedElement.permissions.isMovable &&
        !this.selectedElement.inCroppingMode &&
        !this.design.editingInlineText)
    );
  }

  get allowRotating() {
    return (
      this.selectedElement &&
      this.selectedElement.permissions.isRotatable &&
      !this.selectedElement.inCroppingMode &&
      !this.design.editingInlineText
    );
  }

  get allowRemoving() {
    return this.selectedElement && this.selectedElement.permissions.isRemovable && !this.design.editingInlineText;
  }

  rotate(direction: string, shift: boolean) {
    if (!this.allowRotating) {
      return;
    }

    const step = shift ? this.smallStep : this.largeStep;
    const newRotation =
      (direction === LEFT ? this.rotation - step : this.rotation + step) - this.selectedElement.parent.screenRotation;
    const convertedRotation =
      newRotation < -180 ? newRotation + 360 : newRotation > 180 ? newRotation - 360 : newRotation;

    this.dispatchRotateAction(convertedRotation);
  }

  translate(direction: string, shift: boolean) {
    if (!this.allowMoving) {
      return;
    }

    const element = this.selectedElement || this.design.visiblePage;

    if (element.isPage()) {
      return;
    }

    const x = element.screenX;
    const y = element.screenY;
    const step = shift ? this.smallStep : this.largeStep;
    const newX = direction === LEFT ? x - step : direction === RIGHT ? x + step : x;
    const newY = direction === TOP ? y - step : direction === BOTTOM ? y + step : y;

    this.dispatchTranslateAction(element, newX, newY);
  }

  remove() {
    if (this.allowRemoving) {
      this.dispatchRemoveAction();
    }
  }

  private dispatchRotateAction(rotation: number) {
    this.store.dispatch(
      new CanvasActions.Rotate(
        this.selectedElement.route,
        this.selectedElement.screenWidth,
        this.selectedElement.screenHeight,
        this.selectedElement.screenX,
        this.selectedElement.screenY,
        rotation
      )
    );
  }

  private dispatchTranslateAction(element: PageElement | CanvasElement, x: number, y: number) {
    this.store.dispatch(new CanvasActions.Translate(element.route, element.screenWidth, element.screenHeight, x, y));
  }

  private dispatchRemoveAction() {
    this.store.dispatch(new CanvasActions.RemoveElement(this.selectedElement.route));
  }
}
