import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';

import { ConfigActionsTypes } from '../config/actions';
import { FontLibraryService } from './font-library.service';
import { Font } from '../models';
import * as FontLibActions from './actions';

import { TextService } from '../services';
import { AppState } from '../reducers';
import { fontLibraryStatus } from './reducer';
import { selectDesign } from '../selectors';

import { fabric } from 'fabric';

@Injectable()
export class FontLibraryEffects {
  loadFontLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigActionsTypes.LoadConfig),
      mergeMap(() =>
        this.libraryService.load().pipe(
          map((fontLibrary: Font[]) => FontLibActions.LoadFontLibrary({ fontLibrary })),
          catchError(error => of(FontLibActions.LoadFontLibraryFailure(error)))
        )
      )
    )
  );

  convertText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FontLibActions.LoadFont),
      withLatestFrom(this.store$.pipe(select(selectDesign))),
      filter(([action, design]) => {
        return !!design.designFonts.find(designFont =>
          action.font.oldNames.find(oldName => oldName === designFont.name)
        );
      }),
      mergeMap(([action, design]) => this.textService.convertAllTextForFont(design, action.font)),
      mergeMap(obs => obs)
    )
  );

  convertNotFoundText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FontLibActions.SetFontLibraryStatus),
      filter(action => action.status === fontLibraryStatus.active),
      // this effect is only if a font is not found/working to still covert text
      withLatestFrom(this.store$.pipe(select(selectDesign))),
      withLatestFrom(this.store$.pipe(select(s => s.fontlibrary))),
      mergeMap(([[action, design], fontLib]) => this.textService.convertRemainingText(design, fontLib.fontlibrary))
    )
  );

  clearFabricFontCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FontLibActions.SetFontLibraryStatus),
      filter(action => action.status === fontLibraryStatus.active)),
  ).subscribe(() => {
    fabric.util.clearFabricFontCache();
  })

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private libraryService: FontLibraryService,
    private textService: TextService
  ) {}
}
