import { ActionReducerMap } from '@ngrx/store';
import { canvasReducer } from './canvas-reducer/canvas.reducer';
import { uiReducer } from './ui.reducer';
import { UiState } from '../models';
import { undoMetaReducer } from './meta-reducers';
import * as fromConfig from '../config/reducer';
import * as fromImageLibrary from '../image-library/reducer';
import * as fromFontLibrary from '../font-library/reducer';
import * as fromPermissions from './permissions.reducer';
import * as fromSave from '../save/reducer';
import * as fromContinue from '../continue/reducer';
import { DesignSet } from '../models/design-set';
import { getDemoDesignSet } from '../data/demo-canvas.data';

export interface AppState {
  config: fromConfig.State;
  designSet: DesignSet;
  uiState: UiState;
  permissions: fromPermissions.PermissionsState;
  imagelibrary: fromImageLibrary.ImageLibraryState;
  fontlibrary: fromFontLibrary.FontLibraryState;
  save: fromSave.SaveState;
  continue: fromContinue.ContinueState;
}

export const reducers: ActionReducerMap<AppState> = {
  config: fromConfig.reducer,
  designSet: undoMetaReducer(canvasReducer),
  uiState: uiReducer,
  permissions: fromPermissions.permissionsReducer,
  imagelibrary: fromImageLibrary.reducer,
  fontlibrary: fromFontLibrary.reducer,
  save: fromSave.reducer,
  continue: fromContinue.reducer
};

// Static state
export const initialState: AppState = {
  config: new fromConfig.InitialState(),
  designSet: new DesignSet(),
  uiState: new UiState(),
  permissions: fromPermissions.initialState,
  imagelibrary: fromImageLibrary.initialState,
  fontlibrary: fromFontLibrary.initialState,
  save: fromSave.initialState,
  continue: fromContinue.initialState
};

// Static state
export const demoState: AppState = {
  config: new fromConfig.InitialState(),
  designSet: getDemoDesignSet(),
  uiState: new UiState(),
  permissions: fromPermissions.initialState,
  imagelibrary: fromImageLibrary.demoLibrary,
  fontlibrary: fromFontLibrary.initialState,
  save: fromSave.initialState,
  continue: fromContinue.initialState
};
