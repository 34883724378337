import { Action } from '@ngrx/store';
import { CategoryImage } from '../models';
import { ImageLibrary } from './image-library';

export enum ImageLibraryActionsTypes {
  LoadImageLibrary = '[ImageLibrary] Loaded',
  LoadImageLibraryFailure = '[ImageLibrary] Load error',
  ToggleImageLibrary = '[ImageLibrary] Toggle',
  Update = '[ImageLibrary] Update',
  SaveLocation = '[ImageLibrary] SaveLocation',
  SaveSearch = '[ImageLibrary] SaveSearch'
}

export class LoadImageLibrary implements Action {
  readonly type = ImageLibraryActionsTypes.LoadImageLibrary;

  constructor(public imageLibrary: ImageLibrary) {}
}

export class ToggleImageLibrary implements Action {
  readonly type = ImageLibraryActionsTypes.ToggleImageLibrary;

  constructor(public open: boolean) {}
}

export class LoadImageLibraryFailure implements Action {
  readonly type = ImageLibraryActionsTypes.LoadImageLibraryFailure;

  constructor(public payload: any) {}
}

export class Update implements Action {
  readonly type = ImageLibraryActionsTypes.Update;

  constructor(public imageType = '', public name: string, public images: CategoryImage[]) {}
}

export class SaveLocation implements Action {
  readonly type = ImageLibraryActionsTypes.SaveLocation;

  constructor(public location: number[]) {}
}

export class SaveSearch implements Action {
  readonly type = ImageLibraryActionsTypes.SaveSearch;

  constructor(public searchTerm: string, public imageLibrary: ImageLibrary) {}
}

export type ImageLibraryActions =
  | LoadImageLibrary
  | LoadImageLibraryFailure
  | ToggleImageLibrary
  | Update
  | SaveLocation
  | SaveSearch;
