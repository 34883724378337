import { EditorAction } from '../action';
import { CHECK_SPECIAL_COLOR } from './canvas.action.types';
import { Color } from '../../models';

export class CheckSpecialColor implements EditorAction {
  readonly type = CHECK_SPECIAL_COLOR;
  readonly undoable = false;
  readonly save = true;

  constructor(public route: Array<number>, public color: Color) {}
}
