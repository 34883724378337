import {
  BASELINE_PAGEWIDTH,
  BoxElement,
  CanvasCoordinate,
  CanvasElement,
  Color,
  CUT_THROUGH_BASE_COLOR,
  CUT_THROUGH_INVERTED_BASE_COLOR,
  flipType,
  FoilTypes,
  ImageElement,
  InlineTextElement,
  KC_LAYER_TYPE_MAPPING,
  KcBaseElement,
  KcClipPathType,
  KcElementType,
  KcImageElement,
  KcLayerType,
  KcPage,
  kcPermissionsMapping,
  KcRichTextElement,
  KcTextElement,
  LayerableElement,
  LayerType,
  Line,
  Material,
  MAX_CHAR_TEXT_SPAN,
  Paragraph,
  PhotoFrameElement,
  SpanPart,
  SPOT_UV_BLACK_COLOR,
  TextElement,
  TextSpan
} from '../models';
import {
  calculateRotatedPoint,
  coerceNumber,
  fileExtensionIsJpg,
  fileExtensionIsSvg,
  INPUT_DECIMALS
} from './element.utils';
import { DEFAULT_INLINE_TEXT_PADDING, SVG_VIEWBOX_SCALE } from '../../../react-text-editor/models/text-editor.model';
import { round } from 'lodash-es';

function getInlineTextPadding(defaultPadding: number, pageWidth: number): number {
  return (pageWidth / BASELINE_PAGEWIDTH) * defaultPadding;
}

// get foilColors to prevent that existing designs with foil keep old foil-colorValue
function getColor(
  color: string,
  foilType: FoilTypes,
  foilColors: Color[],
  is_cut_through = false,
  is_cut_through_inverted = false
) {
  const foilColor = foilColors.find(item => item.foil === foilType);
  const cutTroughColor = is_cut_through
    ? CUT_THROUGH_BASE_COLOR
    : is_cut_through_inverted
    ? CUT_THROUGH_INVERTED_BASE_COLOR
    : undefined;
  return cutTroughColor ? cutTroughColor : foilColor ? foilColor.colorValue : color;
}

export function getParagraphsFromInputElement(element: KcRichTextElement, blackFoilPresent = false): Paragraph[] {
  const textParagraphs = element.text as Paragraph[];
  const newText: Paragraph[] = [];
  const spanColor = (color: string) =>
    element.foil_type === FoilTypes.black && !blackFoilPresent ? SPOT_UV_BLACK_COLOR : color;

  textParagraphs.forEach(par => {
    const newPar = Object.assign(new Paragraph(), par);
    const newLines: Line[] = [];
    par.lines.forEach(line => {
      const newLine = Object.assign(new Line(), line);
      const newSpans: TextSpan[] = [];
      line.textSpans.map(span => {
        const newSpan = Object.assign(new TextSpan(), span);
        newSpan.spanParts = span.spanParts.map(part => new SpanPart(part.text.slice(0, MAX_CHAR_TEXT_SPAN), part.x));
        // eslint-disable-next-line no-control-regex
        newSpan.text = span.text.replace(/[\x00-\x1F]/g, '').slice(0, MAX_CHAR_TEXT_SPAN);
        newSpan.color = spanColor(span.color);
        newSpans.push(newSpan);
      });
      newLine.textSpans = newSpans;
      newLines.push(newLine);
    });
    newPar.lines = newLines;
    newText.push(newPar);
  });

  return newText;
}

function calcTextRotation(rotation: number) {
  if (rotation > 180) {
    return rotation - 360;
  } else if (rotation < -180) {
    return rotation + 360;
  }
  return rotation;
}

function calculateSide(delta_x: number, delta_y: number): number {
  // pythagoras
  return Math.sqrt(delta_x * delta_x + delta_y * delta_y);
}

export function setElementPosition(
  element: KcRichTextElement | KcImageElement | KcTextElement,
  newElement: CanvasElement,
  parent: CanvasElement
) {
  if (parent.isPhotoFrame() && !element.clip_path) {
    newElement.x = 0;
    newElement.y = 0;
  } else {
    const rotatedPoint = calculateRotatedPoint(-parent.screenRotation, -newElement.width / 2, -newElement.height / 2);
    const leftTopX = element.x + newElement.width / 2 + rotatedPoint.x;
    const leftTopY = element.y + newElement.height / 2 + rotatedPoint.y;
    // rotate back around parent center
    const deltaParentPosX = leftTopX - parent.leftTop.x;
    const deltaParentPosY = leftTopY - parent.leftTop.y;
    const rotatedDeltaParentPos = calculateRotatedPoint(parent.screenRotation, deltaParentPosX, deltaParentPosY);
    newElement.x = rotatedDeltaParentPos.x;
    newElement.y = rotatedDeltaParentPos.y;
  }
}
export function addCommonProperties(newElement: CanvasElement, element: KcBaseElement) {
  newElement.transparency = element.trans || 0;
  newElement.flipHorizontal = element.flip === flipType.horizontal || element.flip === flipType.both;
  newElement.flipVertical = element.flip === flipType.vertical || element.flip === flipType.both;
  newElement.templateId = element.template_id;
  newElement.tag = element.tag || '';
}

export function createParentElementFromClipPath(clipPath: CanvasCoordinate[], clipPathType: KcClipPathType) {
  const parent = clipPathType === KcClipPathType.box ? new BoxElement() : new PhotoFrameElement();

  // calculate width and height of element with clipPath
  let delta_x = clipPath[1].x - clipPath[0].x;
  let delta_y = clipPath[1].y - clipPath[0].y;
  const width = calculateSide(delta_x, delta_y);

  delta_x = clipPath[2].x - clipPath[1].x;
  delta_y = clipPath[2].y - clipPath[1].y;
  const height = calculateSide(delta_x, delta_y);

  parent.width = round(width, INPUT_DECIMALS);
  parent.height = round(height, INPUT_DECIMALS);

  // calculate center of element to calculate original x and y
  const center_x = (clipPath[2].x - clipPath[0].x) / 2;
  const center_y = (clipPath[2].y - clipPath[0].y) / 2;

  delta_x = center_x - width / 2;
  delta_y = center_y - height / 2;

  parent.x = round(clipPath[0].x + delta_x, INPUT_DECIMALS);
  parent.y = round(clipPath[0].y + delta_y, INPUT_DECIMALS);

  // calculate angle between x,y of element and first point of clipPath
  const rotation = 2 * Math.asin(calculateSide(delta_x, delta_y) / 2 / calculateSide(center_x, center_y));
  parent.rotation = round(rotation / (Math.PI / 180), 0);

  if (clipPath[0].y > parent.height + parent.y || clipPath[0].x <= parent.x) {
    // rotation calculation has a max of 180 degrees, so check if actual rotation should be negative (counterclockwise)
    parent.rotation = -parent.rotation;
  }

  return parent;
}

export function createCanvasElement(
  element: KcImageElement | KcTextElement | KcRichTextElement,
  parent: CanvasElement,
  useAbsoluteFontSizeDesign: boolean,
  material: Material,
  foilColors: Color[],
  addImageAsPhotoFrame: boolean,
  enableFoilableByDefault: boolean,
  spotUvPermissionForJpgByDefault: boolean,
  useAbsoluteFontSizeConfig: boolean,
  canAddSpotUv: boolean
): ImageElement | TextElement | InlineTextElement {
  let newElement: ImageElement | TextElement | InlineTextElement;
  const blackFoilPresent = !!foilColors.find(c => c.foil === FoilTypes.black);
  // Transform foil black to spot uv
  const foilType =
    material.isFoilable && (element.foil_type !== FoilTypes.black || blackFoilPresent) ? element.foil_type : undefined;
  const spotUv =
    material.isSpotUvable &&
    (element.layer_type === KcLayerType.spotUv ||
      element.layer_type === KcLayerType.spotUvImage ||
      (element.foil_type === FoilTypes.black && !blackFoilPresent));

  switch (element.type) {
    case KcElementType.photo: {
      const fgColor =
        element.foil_type === FoilTypes.black && !blackFoilPresent ? SPOT_UV_BLACK_COLOR : element.fg_color;

      const is_cut_through = element.layer_type === KcLayerType.cutThrough;
      const is_cut_through_inverted = element.layer_type === KcLayerType.cutThroughInverted;
      newElement = new ImageElement(-1);
      newElement.foilType = foilType;
      newElement.spotUv = spotUv;
      newElement.sid = element.sid || '';
      newElement.url = element.url || '';
      newElement.color = element.recolor
        ? getColor(fgColor, foilType, foilColors, is_cut_through, is_cut_through_inverted)
        : '';

      if (parent.isBox() || !addImageAsPhotoFrame) {
        newElement.rotation = element.r - parent.screenRotation || 0;
        newElement.layerType = getLayerType(newElement, element);
        newElement.setRegularImagePermissions();
      }
      setImageDimensions(newElement, element);
      break;
    }
    case KcElementType.text: {
      const color = element.foil_type === FoilTypes.black && !blackFoilPresent ? SPOT_UV_BLACK_COLOR : element.color;
      newElement = new TextElement(-1, {
        width: element.w,
        height: element.h,
        font: element.font,
        text: element.text,
        va: element.va,
        ha: element.ha,
        color: getColor(color, foilType, foilColors, element.is_cut_through),
        fontSize: element.s,
        isJustify: element.is_justify,
        useAbsoluteFontSize: element.use_absolute_font_size
          ? element.use_absolute_font_size
          : useAbsoluteFontSizeConfig && useAbsoluteFontSizeDesign
      });

      newElement.rotation = calcTextRotation(element.r) - parent.screenRotation || 0;
      newElement.foilType = foilType;
      newElement.spotUv = spotUv;
      newElement.layerType = getLayerType(newElement, element);
      newElement.isFoilablePermissionWasChanged =
        element.is_foilable_permission_was_changed !== undefined
          ? element.is_foilable_permission_was_changed
          : newElement.isFoilablePermissionWasChanged;
      break;
    }
    case KcElementType.richText: {
      newElement = new InlineTextElement(-1, {
        width: element.w,
        height: element.h,
        text: getParagraphsFromInputElement(element, blackFoilPresent)
      });

      const newPadding = getInlineTextPadding(DEFAULT_INLINE_TEXT_PADDING, parent.width);

      if (element.padding && element.padding !== newPadding) {
        const diff = element.padding - newPadding;

        newElement.text
          .flatMap(par => par.lines)
          .forEach(line => {
            line.y = coerceNumber(line.y - diff);
            line.textSpans.forEach(span => span.spanParts.forEach(part => (part.x = coerceNumber(part.x - diff))));
          });

        newElement.height = coerceNumber(element.h - (diff / SVG_VIEWBOX_SCALE) * 2);
        newElement.width = coerceNumber(element.w - (diff / SVG_VIEWBOX_SCALE) * 2);
        // set new y on old element because this is used to calculate definitive newElement position later
        element.y = element.y + diff / SVG_VIEWBOX_SCALE;
        element.x = element.x + diff / SVG_VIEWBOX_SCALE;
      }

      newElement.padding = newPadding;
      newElement.rotation = calcTextRotation(element.r) - parent.screenRotation || 0;
      newElement.foilType = foilType;
      newElement.spotUv = spotUv;
      newElement.layerType = getLayerType(newElement, element);
      newElement.characterLimit = element.character_limit;
      newElement.isFoilablePermissionWasChanged =
        element.is_foilable_permission_was_changed !== undefined
          ? element.is_foilable_permission_was_changed
          : newElement.isFoilablePermissionWasChanged;

      break;
    }
    default:
      break;
  }

  addCommonProperties(newElement, element);
  addElementPermissions(
    newElement,
    element,
    material,
    enableFoilableByDefault,
    spotUvPermissionForJpgByDefault,
    canAddSpotUv
  );
  setElementPosition(element, newElement, parent);

  return newElement;
}

export function setImageDimensions(newElement: ImageElement | PhotoFrameElement, element: KcImageElement) {
  if (element.aspect > 1) {
    newElement.width = element.size / element.aspect;
    newElement.height = element.size;
  } else {
    newElement.width = element.size;
    newElement.height = element.size * element.aspect;
  }
}

export function addElementPermissions(
  newElement: CanvasElement,
  element: KcRichTextElement | KcImageElement | KcTextElement,
  material: Material,
  enableFoilableByDefault: boolean,
  spotUvPermissionForJpgByDefault: boolean,
  canAddSpotUv: boolean
) {
  // note that this should only assign the permissions that exist on the canvasElement by default
  Object.keys(newElement.permissions).map(permission => {
    newElement.permissions[permission] =
      element[kcPermissionsMapping[permission]] !== undefined
        ? !!element[kcPermissionsMapping[permission]]
        : newElement.permissions[permission];
  });

  if (
    newElement.permissions.isReplaceable !== undefined &&
    element.is_replaceable === undefined &&
    element.is_instant_replaceable
  ) {
    newElement.permissions.isReplaceable = element.is_instant_replaceable;
  }

  if (newElement.permissions.hasInstantReplaceablePlaceholder !== undefined) {
    newElement.permissions.hasInstantReplaceablePlaceholder =
      element.has_instant_replaceable_placeholder !== undefined
        ? element.has_instant_replaceable_placeholder
        : element.is_instant_replaceable;
  }

  if (newElement.permissions.isFoilable !== undefined) {
    if (!material.isFoilable) {
      newElement.permissions.isFoilable = false;
    } else if (enableFoilableByDefault && !newElement.isFoilablePermissionWasChanged) {
      newElement.permissions.isFoilable =
        element.type === KcElementType.photo ? fileExtensionIsSvg(element.sid || element.url) : true;
    } else if (!enableFoilableByDefault && !newElement.isFoilablePermissionWasChanged) {
      newElement.permissions.isFoilable = false;
    }
  }

  if (newElement.permissions.isSpotUvable !== undefined) {
    const disallowSpotUv =
      !canAddSpotUv ||
      !material.isSpotUvable ||
      (element.type === KcElementType.photo &&
        !fileExtensionIsSvg(element.sid || element.url) &&
        !fileExtensionIsJpg(element.sid || element.url));

    const shouldApplyDefault =
      element.type === KcElementType.photo &&
      fileExtensionIsJpg(element.sid || element.url) &&
      newElement.isPhotoFrame() &&
      !newElement.isSpotUvForJpgImagePermissionWasChanged;

    if (disallowSpotUv) {
      newElement.permissions.isSpotUvable = false;
    }

    if (shouldApplyDefault && !disallowSpotUv) {
      newElement.permissions.isSpotUvable = spotUvPermissionForJpgByDefault;
    }
  }
}

export function getLayerType(
  newElement: LayerableElement,
  element: KcTextElement | KcImageElement | KcRichTextElement
): LayerType {
  let newLayerType = LayerType.standardRgb;
  Object.keys(LayerType).forEach(type => {
    if (KC_LAYER_TYPE_MAPPING[type] === element.layer_type) {
      newLayerType = LayerType[type];
    }
  });
  return newLayerType;
}

export function convertToLayerType(page: KcPage, oldProp: string, newLayerType: KcLayerType) {
  page.images.map(i => {
    if (i[oldProp]) {
      i.layer_type = newLayerType;
    }
  });
}
