import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { tap, map, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { GetTextService } from '../services';
import * as fromPermissions from '../reducers/permissions.reducer';
import * as fromContinue from './reducer';
import * as fromRegister from '../register/reducer';
import * as fromRecover from '../recover/reducer';
import * as fromAuth from '../auth/reducer';
import * as fromSave from '../save/reducer';
import * as Save from '../save/actions';

import {
  ContinueActionTypes,
  Continue,
  OpenLoginContinueDialog,
  OpenSaveContinueDialog,
  ContinueToUrl,
  SaveContinueSubmit,
  SaveContinueSuccess,
  SaveContinueFailure,
  CancelLogin,
  LoginSuccess,
  SaveSuccess,
  OpenSaveDialog,
  CancelRegister,
  RegisterSuccess,
  CancelSave
} from './actions';
import { SaveService } from '../save/save.service';
import { AppState } from '../reducers';
import { LoginSaveContinueDialogComponent } from './login-save-continue-dialog.component';
import { AuthActionTypes, OpenLoginDialogFromContinue } from '../auth/actions';
import { RegisterActionTypes } from '../register/actions';
import { ContinueService } from './continue.service';
import { CanvasActions } from '../actions';
import { ErrorDialogComponent } from '../shared/dialogs';
import { getDesignSet } from '../selectors';
import { isDesignSetSubmit } from '../models/design-submit';

@Injectable()
export class ContinueEffects {
  /* Navigate */
  @Effect()
  navigate$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.Navigate),
    map(() => new Continue())
  );

  /* Continue */
  @Effect()
  continue$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.Continue),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    withLatestFrom(this.store$.pipe(select(fromSave.getLastSavedDesign))),
    map(([[action, design], lastSavedDesign]) => this.saveService.designHasChanges(design, lastSavedDesign)),
    withLatestFrom(this.store$.pipe(select(fromAuth.isLoggedIn))),
    withLatestFrom(this.store$.pipe(select(fromPermissions.getHideSaveContinueDialog))),
    withLatestFrom(this.store$.pipe(select(fromPermissions.getCanSave))),
    map(([[[hasChanges, loggedIn], hideDialog], canSave]) => {
      if (!loggedIn) {
        return new OpenLoginContinueDialog();
      } else if (hasChanges && !hideDialog && canSave) {
        return new OpenSaveContinueDialog();
      } else {
        return new ContinueToUrl();
      }
    })
  );

  /* Save */
  @Effect()
  saveContinue$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.Save),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    map(([action, designSet]) => {
      if (designSet.userCollectionId) {
        return new SaveContinueSubmit();
      } else {
        return new OpenSaveDialog();
      }
    })
  );

  /* Open/Close loginContinueDialog */
  @Effect({ dispatch: false })
  openLoginContinueModal$ = this.actions$.pipe(
    ofType(ContinueActionTypes.OpenLoginContinueDialog),
    tap(
      () =>
        (this.loginContinueDialog = this.dialog.open(LoginSaveContinueDialogComponent, {
          width: '400px',
          data: {
            text: {
              dialogText: this.getTextService.text.dialog.loginSaveContinue,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  @Effect({ dispatch: false })
  closeLoginSaveModal$ = this.actions$.pipe(
    ofType(ContinueActionTypes.CloseLoginContinueDialog, ContinueActionTypes.ContinueWithoutSave),
    filter(() => !!this.loginContinueDialog),
    tap(() => this.loginContinueDialog.close())
  );

  /* Open/Close saveContinueDialog */
  @Effect({ dispatch: false })
  openSaveContinueModal$ = this.actions$.pipe(
    ofType(ContinueActionTypes.OpenSaveContinueDialog),
    tap(
      () =>
        (this.saveContinueDialog = this.dialog.open(LoginSaveContinueDialogComponent, {
          width: '400px',
          data: {
            text: {
              dialogText: this.getTextService.text.dialog.loginSaveContinue,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  @Effect({ dispatch: false })
  closeSaveContinueModal$ = this.actions$.pipe(
    ofType(
      ContinueActionTypes.CloseSaveContinueDialog,
      ContinueActionTypes.Save,
      ContinueActionTypes.ContinueWithoutSave
    ),
    filter(() => !!this.saveContinueDialog),
    tap(() => this.saveContinueDialog.close())
  );

  /* Open/Close saveDialog from Save Actions*/
  @Effect()
  openSaveModal$ = this.actions$.pipe(
    ofType(ContinueActionTypes.OpenSaveDialog),
    map(() => new Save.OpenSaveDialog())
  );

  @Effect()
  closeSaveModal$ = this.actions$.pipe(
    ofType(Save.SaveActionTypes.CloseSaveDialog),
    withLatestFrom(this.store$.pipe(select(fromContinue.getSavePending))),
    filter(([action, savePending]) => savePending),
    map(() => new CancelSave())
  );

  /* Save design and Continue */
  @Effect()
  saveAndContinue$ = this.actions$.pipe(
    ofType(ContinueActionTypes.SaveContinueSubmit),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    map(([action, designSet]) => this.saveService.transformToKc(designSet)),
    switchMap(designSetToSave => {
      const newTitle = isDesignSetSubmit(designSetToSave)
        ? designSetToSave.user_collection_title
        : designSetToSave.user_design_title;
      return this.saveService.saveDesign(newTitle, true).pipe(
        map(savedDesign => new SaveContinueSuccess(savedDesign)),
        catchError(error => of(new SaveContinueFailure(error.error)))
      );
    })
  );

  @Effect({ dispatch: false })
  openContinueFailureModal$ = this.actions$.pipe(
    ofType(ContinueActionTypes.SaveContinueFailure),
    tap(
      (action: SaveContinueFailure) =>
        (this.errorDialog = this.dialog.open(ErrorDialogComponent, {
          width: '400px',
          data: { message: action.payload[0].message }
        }))
    )
  );

  @Effect()
  saveContinueSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.SaveContinueSuccess),
    map((action: SaveContinueSuccess) => {
      return new CanvasActions.SetUserTitleAndId(
        action.designSet.userCollectionTitle,
        action.designSet.userCollectionId
      );
    }),
    tap(() =>
      this.snackBar.open(this.getTextService.text.toolBar.autoSave.saveSuccessText, '', {
        panelClass: 'save-snack-bar',
        duration: 1000,
        horizontalPosition: 'left',
        verticalPosition: 'top'
      })
    ),
    map(action => new ContinueToUrl())
  );

  /* Manual Save */
  @Effect()
  submitSucces$: Observable<Action> = this.actions$.pipe(
    ofType(Save.SaveActionTypes.SubmitSuccess),
    withLatestFrom(this.store$.pipe(select(fromContinue.getSavePending))),
    filter(([action, continuePending]) => continuePending),
    map(() => new SaveSuccess())
  );

  /* ContinueToUrl */
  @Effect()
  saveSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.SaveSuccess, ContinueActionTypes.ContinueWithoutSave),
    map(() => new ContinueToUrl())
  );

  /* ContinueToUrl */
  @Effect({ dispatch: false })
  continueToURL$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.ContinueToUrl),
    tap(() => this.continueService.continueToUrl())
  );

  /* Login */
  @Effect()
  loginOpenLoginDialog$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.Login),
    map(() => new OpenLoginDialogFromContinue())
  );

  @Effect()
  closeLoginModal$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.CloseLoginDialog),
    withLatestFrom(this.store$.pipe(select(fromContinue.getLoginOrRegisterPending))),
    withLatestFrom(this.store$.pipe(select(fromRegister.getDialogOpen))), // login can also be called from register
    withLatestFrom(this.store$.pipe(select(fromRecover.getDialogOpen))), // login can also be called from recover
    filter(
      ([[[action, loginOrRegisterPending], registerDialogOpen], recoverDialogOpen]) =>
        loginOrRegisterPending && !registerDialogOpen && !recoverDialogOpen
    ),
    map(() => new CancelLogin())
  );

  @Effect()
  cancelLogin$ = this.actions$.pipe(
    ofType(ContinueActionTypes.CancelLogin),
    map(() => new OpenLoginContinueDialog())
  );

  @Effect()
  loginSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    withLatestFrom(this.store$.pipe(select(fromContinue.getLoginOrRegisterPending))),
    withLatestFrom(this.store$.pipe(select(fromRegister.getSuccessDialogOpen))), // register success also does login success
    filter(([[action, loginOrRegisterPending], successDialogOpen]) => loginOrRegisterPending && !successDialogOpen),
    map(() => new LoginSuccess())
  );

  @Effect()
  loginSuccessContinue$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.LoginSuccess),
    map(() => new Continue())
  );

  @Effect()
  registerSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(RegisterActionTypes.CloseRegisterSuccessDialog),
    withLatestFrom(this.store$.pipe(select(fromContinue.getLoginOrRegisterPending))), // register can also be called from login
    filter(([action, loginOrRegisterPending]) => loginOrRegisterPending),
    map(() => new RegisterSuccess())
  );

  @Effect()
  registerSuccessContinue$: Observable<Action> = this.actions$.pipe(
    ofType(ContinueActionTypes.RegisterSuccess),
    map(() => new Continue())
  );

  loginContinueDialog: MatDialogRef<LoginSaveContinueDialogComponent>;
  saveContinueDialog: MatDialogRef<LoginSaveContinueDialogComponent>;
  errorDialog: MatDialogRef<ErrorDialogComponent>;

  constructor(
    private saveService: SaveService,
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<AppState>,
    public snackBar: MatSnackBar,
    private continueService: ContinueService,
    public getTextService: GetTextService
  ) {}
}
