import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { User } from '../models/user';
import { ChatConfig, ChatPosition } from '../models/chat';
import { Subject } from 'rxjs';
import { ConfigService } from './config-service';

declare let robin_settings: any;
declare let __robin: any;
declare let dataLayer: any;

@Injectable()
export class ChatService {
  init = false;
  showTop$ = new Subject<boolean>();

  constructor(@Inject(DOCUMENT) private readonly document: Document, private configService: ConfigService) {}

  addChatSourceScriptToDom(key: string) {
    const base_url = 'https://selfservice.robinhq.com/external/robin/';
    const script = document.createElement('script');
    script.src = `${base_url}${key}.js`;
    this.document.body.appendChild(script);
  }

  initChat(user: User, chatConfig: ChatConfig) {
    // hack because tag manager is too slow
    setTimeout(() => {
      if (!this.init) {
        if (dataLayer && dataLayer.find((e: any) => e.event && e.event === 'useChat')) {
          this.setConfig(user, chatConfig.settings, chatConfig.position);
          this.addChatSourceScriptToDom(chatConfig.key);
        }
      } else {
        this.setUser(user);
      }
    }, 5000);
  }

  setConfig(user: User, settings: { [key: string]: any }, position: ChatPosition[]) {
    robin_settings = {
      callback: (event: any, data: any) => {
        if (event === 'init') {
          this.init = true;
          this.setUser(user);
          this.setChatPosition(position);
          this.showTop$.next(!!position.find(p => p === 'top'));
        }
      },
      ...settings
    };
  }

  setUser(user: User) {
    if (user.isAuthenticated) {
      const ws = __robin.getWebStore();
      ws.conversationTemplate.useAnonymousChat = true;
      __robin.setShopper(user.email, user.username);
    }
  }

  setChatPosition(pos: ChatPosition[]) {
    if (!this.configService.isMobile && pos.find(p => p === 'bottom')) {
      // TODO: test on live test shop and fix
      const chatTab = this.document.getElementById('robin_tab_container');
      if (chatTab) {
        chatTab.style.position = 'static';
        chatTab.style.float = 'right';
        chatTab.style.margin = '10px';
        const navBarRight = this.document.getElementsByClassName('navbar-right')[0];
        navBarRight.append(chatTab);
      }
    }
  }

  showChat() {
    __robin.show();
  }
}
