import { EditorComponent } from '../editor.component';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ed-editor-mob',
  templateUrl: 'editor-mob.component.html',
  styleUrls: ['editor-mob.component.scss']
})
export class EditorMobComponent extends EditorComponent implements OnInit {
  showNewMobileMenu: boolean;

  ngOnInit() {
    super.ngOnInit();
    this.showNewMobileMenu = this.configService.showNewMobileMenu;
  }
}
