import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from '../../../models';
import {
  BrightColors,
  checkColorValue,
  createNewColor,
  hexToHsl,
  HSB,
  hsbToHex,
  hslToHsb
} from '../../../utils/color-utils';

@Component({
  selector: 'ed-color-picker',
  templateUrl: 'color-picker.component.html',
  styleUrls: ['color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit {
  @Input() previewColor: string;
  @Input() isMobile: boolean;

  @Output() currentCustomColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() selectedColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() width: number;
  @Input() disableInput = false;
  @Input() colorScheme = BrightColors;
  @Input() dataLayerIdPrefix: string;
  @Input() spotUv: boolean;

  markColorInputNotValid = false;
  hsbColor: HSB;
  inputColor: HSB;

  ngOnInit(): void {
    const currentColor = this.previewColor || '#000000';
    this.setHsbColor(currentColor);
  }

  setColor(color: HSB) {
    this.hsbColor = color;
    const newColor = hsbToHex(this.hsbColor);
    this.changeColor(newColor);
  }

  setHsbColor(color: string) {
    const hslColor = hexToHsl(color);
    if (color === '#ffffff' || color === '#000000') {
      hslColor.s = 0;
    }
    this.hsbColor = hslToHsb(hslColor);
  }

  setInputColor(color: string) {
    const newHslColor = hexToHsl(color);
    this.inputColor = hslToHsb(newHslColor);
  }

  setHue(hue: number) {
    this.hsbColor = { ...this.hsbColor, h: hue };
    const newColor = hsbToHex(this.hsbColor);
    this.changeColor(newColor);
  }

  changeColor(newColorName: string) {
    const newColor = createNewColor(newColorName, this.spotUv);
    this.selectedColor.emit(newColor);
    this.currentCustomColor.emit(newColor);
  }

  onInput(newColorName: string) {
    if (checkColorValue(newColorName)) {
      this.markColorInputNotValid = false;
      this.changeColor(newColorName);
      this.setInputColor(newColorName);
    }
  }

  onChange(newColorName: string) {
    this.markColorInputNotValid = !checkColorValue(newColorName);
    if (!this.markColorInputNotValid) {
      this.changeColor(newColorName);
      this.setInputColor(newColorName);
    } else {
      this.markColorInputNotValid = true;
    }
  }

  onSelect(color: Color): void {
    this.selectedColor.emit(color);
  }

  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }
}
