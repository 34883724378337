import { fabric } from 'fabric';
import { calculatePosition } from './object-event.utils';

export function getAngle(rotation: number): number {
  return (rotation + 360) % 360;
}

export function isSmallObject(object: fabric.Object) {
  const zoom = object.canvas?.getZoom() ?? 1;
  return object.getScaledHeight() * zoom < 50 || object.getScaledWidth() * zoom < 50;
}

export function isTinyObject(object: fabric.Object) {
  const zoom = object.canvas?.getZoom() ?? 1;
  return object.getScaledHeight() * zoom < 25 || object.getScaledWidth() * zoom < 25;
}

export function setPositionAfterZooming(target: fabric.Object, childObject: fabric.Object, zoomScale: number) {
  let {
    left: childLeft,
    top: childTop,
    angle: childAngle,
    centerPoint: childCenterPoint,
    width: childWidth,
    height: childHeight,
    scaleX: childScaleX,
    scaleY: childScaleY
  } = getOptions(childObject);

  let {
    left: targetLeft,
    top: targetTop,
    angle: targetAngle,
    centerPoint: targetCenterPoint,
    width: targetWidth,
    height: targetHeight,
    scaleX: targetScaleX,
    scaleY: targetScaleY
  } = getOptions(target);

  ({ x: childLeft, y: childTop } = calculatePosition(childLeft, childTop, -childAngle, targetCenterPoint));
  childCenterPoint = { x: childLeft + childWidth * childScaleX / 2, y: childTop + childHeight * childScaleY / 2 };

  ({ x: targetLeft, y: targetTop } = calculatePosition(targetLeft, targetTop, -targetAngle, targetCenterPoint));

  determinePositionAfterZooming()

  if (
    targetLeft < childLeft ||
    targetTop < childTop ||
    targetLeft + targetWidth * targetScaleX > childLeft + childObject.width * childScaleX ||
    targetTop + targetHeight * targetScaleY > childTop + childObject.height * childScaleY
  ) {
    fitChildWithinPhotoFrame()
  }

  ({ x: childLeft, y: childTop } = calculatePosition(childLeft, childTop, childAngle, targetCenterPoint));

  childObject.set({
    left: childLeft,
    top: childTop,
    scaleX: childScaleX,
    scaleY: childScaleY
  })

  function getOptions(object: fabric.Object) {
    return {
      left: object.left,
      top: object.top,
      angle: object.angle,
      centerPoint: { x: object.getCenterPoint().x, y: object.getCenterPoint().y },
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY
    }
  }

  function determinePositionAfterZooming() {
    let targetScaledWidth = targetWidth * targetScaleX;
    let targetScaledHeight = targetHeight * targetScaleY;

    let childScaledWidth = childWidth * childScaleX;
    let childScaledHeight = childHeight * childScaleY;

    let nextScaleX = childScaleX * zoomScale;
    let nextScaleY = childScaleY * zoomScale;
    let nextTop = childTop;
    let nextLeft = childLeft;

    if (childWidth * nextScaleX <= targetScaledWidth && childHeight * nextScaleY <= targetScaledHeight) {
      nextTop = targetTop;
      nextLeft = targetLeft;
      nextScaleX = targetScaledWidth / childWidth
      nextScaleY = targetScaledHeight / childHeight
    }
    else {
      let offset = zoomScale - 1;
      nextLeft = childLeft - (childScaledWidth / 2 + (targetCenterPoint.x - childCenterPoint.x)) * offset;
      nextTop = childTop - (childScaledHeight / 2 + (targetCenterPoint.y - childCenterPoint.y)) * offset;

      if (childWidth * nextScaleX <= targetScaledWidth) {
        nextScaleX = targetScaledWidth / childWidth;
        let actualZoomScale = nextScaleX / childScaleX;
        nextScaleY = childScaleY * actualZoomScale;

        offset = actualZoomScale - 1;
        nextLeft = targetLeft;
        nextTop = childTop - (childScaledHeight / 2 + (targetCenterPoint.y - childCenterPoint.y)) * offset;
      }

      if (childHeight * nextScaleY <= targetScaledHeight) {
        nextScaleY = targetScaledHeight / childHeight;
        let actualZoomScale = nextScaleY / childScaleY;
        nextScaleX = childScaleX * actualZoomScale;

        offset = actualZoomScale - 1
        nextLeft = childLeft - (childScaledWidth / 2 + (targetCenterPoint.x - childCenterPoint.x)) * offset;
        nextTop = targetTop;
      }

    }

    childLeft = nextLeft;
    childTop = nextTop;
    childScaleX = nextScaleX;
    childScaleY = nextScaleY;
  }

  function fitChildWithinPhotoFrame() {
    let left = childLeft;
    let top = childTop;

    if (targetLeft < childLeft) {
      left = targetLeft;
    }
    if (targetTop < childTop) {
      top = targetTop;
    }
    if (targetTop + targetHeight * targetScaleY > childTop + childHeight * childScaleY) {
      top = (targetTop + targetHeight * targetScaleY) - childHeight * childScaleY
    }
    if (targetLeft + targetWidth * targetScaleX > childLeft + childWidth * childScaleX) {
      left = (targetLeft + targetWidth * targetScaleX) - childWidth * childScaleX
    }

    childLeft = left;
    childTop = top;
  }
}