import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { merge } from 'lodash-es';

import { State, InitialState } from './reducer';
import { LoadConfig } from './actions';
import { SetConfig } from '../actions/permission-actions';
import { environment } from '../../environments/environment';
import { ErrorDialogComponent } from '../shared/dialogs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FunctionPermissions, SetElementPermissions } from '../models';
import { ParamsService } from '../services/params.service';
import { BrowserStorageService } from '../services/browser-storage.service';
import { storageClipboardElement } from '../services/copy.service';

declare function setGoogleTagManager(id: string): void;

declare let dataLayer: Array<Record<string, string>>;

@Injectable()
export class Configuration {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private titleService: Title,
    private store: Store<State>,
    public deviceService: DeviceDetectorService,
    private paramsService: ParamsService,
    private browserStorageService: BrowserStorageService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {}

  private get isMac() {
    return navigator.platform.toLowerCase().indexOf('mac') > -1;
  }

  private get isChrome() {
    return this.deviceService.browser === 'Chrome';
  }

  private get isIphone() {
    return navigator.appVersion.includes('iPhone OS');
  }

  private get isIpad() {
    const iOS_1to12 = navigator.platform.includes('iPad');

    const iOS13_iPad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 2;

    return iOS_1to12 || iOS13_iPad;
  }

  private get isIphoneOS12() {
    return navigator.appVersion.includes('iPhone OS 12');
  }

  private get isAndroid() {
    return this.deviceService.device === 'Android' || navigator.appVersion.includes('Android'); // testen op meer devices
  }

  private get isTouchDevice() {
    return navigator.maxTouchPoints > 0;
  }

  load(): Promise<State> {
    if (this.browserStorageService.getSession(storageClipboardElement)) {
      this.browserStorageService.removeSessionItem(storageClipboardElement);
    }

    return new Promise(resolve => {
      const appConfig = new InitialState();
      this.http.get(environment.configFile, { params: this.paramsService.httpParams }).subscribe(config => {
        merge(appConfig, config);
        if (environment.production) {
          dataLayer = dataLayer.concat(appConfig.googleTagManagerDataLayer);
          setGoogleTagManager(environment.googleTagManagerEditorID);
          if (appConfig.googleTagManagerID) {
            setGoogleTagManager(appConfig.googleTagManagerID);
          }
        }

        this.store.dispatch(
          new SetConfig(
            Object.assign(new FunctionPermissions(), appConfig.permissions.functionPermissions),
            Object.assign(new SetElementPermissions(), appConfig.permissions.setElementPermissions)
          )
        );
        appConfig.isMac = this.isMac;
        appConfig.isIphone = this.isIphone;
        appConfig.isIpad = this.isIpad;
        appConfig.isIphoneOS12 = this.isIphoneOS12;
        appConfig.isChrome = this.isChrome;
        appConfig.isAndroid = this.isAndroid;
        appConfig.isTouchDevice = this.isTouchDevice;
        this.titleService.setTitle(appConfig.text.title);
        this._document.getElementById('appFavicon').setAttribute('href', appConfig.faviconUrl);
        this._document.documentElement.setAttribute('lang', appConfig.locale.replace('_', '-'));
        const head = this._document.getElementsByTagName('head')[0];
        if (appConfig.styleSheetLink.length) {
          const bootstrapStyle = this._document.createElement('link');
          bootstrapStyle.rel = 'stylesheet';
          bootstrapStyle.href = appConfig.styleSheetLink;
          head.appendChild(bootstrapStyle);
        }

        this.store.dispatch(new LoadConfig(appConfig));
        resolve(config as State);
      });
    });
  }

  openDialog(message: string) {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: { message }
    });
  }
}

export function init(configService: Configuration): () => Promise<State> {
  return () => configService.load();
}
