import { Component, EventEmitter, Input, Output, HostListener, OnInit, OnDestroy } from '@angular/core';
import { round } from 'lodash-es';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { coerceNumber } from '../../utils/element.utils';

@Component({
  selector: 'ed-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  @Input() dataLayerIdPrefix: string;
  @Input() minCount: number;
  @Input() maxCount: number;
  @Input() symbol = '';
  @Input() showInput = true;
  @Input() step = 1;
  @Input() decimals = 0;

  _elementCount: number;

  maxWidthInput: number;

  @Input() set elementCount(count: number) {
    this.setMaxWidthInput(count);
    this._elementCount = count;
  }

  get elementCount() {
    return this._elementCount;
  }

  @Output() changeValue = new EventEmitter();
  @Output() slideEnd = new EventEmitter();
  isSliding = false;

  // previewCount is displayed in input as rounded value. The decimals count can be set by decimals input.
  get previewCount() {
    return round(this.elementCount, this.decimals);
  }

  setMaxWidthInput(value: number): void {
    this.maxWidthInput = 2 + 8 * value.toString().length;
  }

  boundCount(count: number): number {
    return Math.min(Math.max(count, this.minCount), this.maxCount) || 0;
  }

  changeCountByInput(count: string): void {
    const countValue = coerceNumber(count, this.decimals);
    const boundedValue = this.boundCount(countValue);
    this.changeValue.emit(boundedValue);
  }

  changeCountBySlider(count: string): void {
    const countValue = coerceNumber(count, this.decimals);
    this.changeValue.emit(countValue);
  }

  onMouseDown(event: MouseEvent): void {
    if (event.button !== undefined && event.button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }

    this.isSliding = true;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    if (this.isSliding) {
      this.slideEnd.emit();
      this.isSliding = false;
    }
  }
}
