import { createAction, props } from '@ngrx/store';
import { Font } from '../models';
import { fontLibraryStatus } from './reducer';

export const LoadFontLibrary = createAction('[FontLibrary] Loaded', props<{ fontLibrary: Font[] }>());

export const LoadFontLibraryFailure = createAction('[FontLibrary] Load error', props<{ payload: string }>());

export const LoadFont = createAction('[FontLibrary] Font Loaded', props<{ font: Font }>());

export const SetFontLibraryStatus = createAction('[FontLibrary] Set Status', props<{ status: fontLibraryStatus }>());
