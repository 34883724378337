import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ButtonFunctions, CounterButtonComponent } from '../../counter/counter-button.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'ed-slider-button',
  templateUrl: './slider-button.component.html',
  styleUrls: ['../../mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderButtonComponent extends CounterButtonComponent {
  @Input() range: Array<number>;

  @Input() minValue = Infinity;
  @Input() maxValue = Infinity;

  @Input() faIcon: IconProp;

  getNewCountFromRange(): number {
    let newCount: number;
    this.range.map((rangeValue, index) => {
      if (this.buttonFunction === ButtonFunctions.increase) {
        const nextRangeValue = this.range[index + 1];
        if (this.count >= rangeValue && this.count < nextRangeValue) {
          newCount = nextRangeValue;
        }
      } else {
        const previousRangeValue = this.range[index - 1];
        if (this.count <= rangeValue && this.count > previousRangeValue) {
          newCount = previousRangeValue;
        }
      }
    });
    return newCount;
  }

  setNewCount(delta: number) {
    if (this.range) {
      this.newCount = this.getNewCountFromRange();
      this.changeValue.emit(this.newCount);
    } else {
      // if there is no range the value will be increased or decreased by stepValue
      // then the button can be hold to speed up increasing or decreasing
      this.newCount = this.count + delta;
      this.changeValue.emit(this.newCount);
      this.startTimer(delta);
    }
  }

  onMouseDown(event: MouseEvent, delta: number) {
    if (event.button !== undefined && event.button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }

    this.setNewCount(delta);
  }

  onTouchStart(event: TouchEvent, delta: number) {
    event.preventDefault();

    this.setNewCount(delta);
  }
}
