import { EditorAction } from '../action';
import { REPLACE_IMAGE } from './canvas.action.types';

export class ReplaceImage implements EditorAction {
  readonly type = REPLACE_IMAGE;
  readonly undoable = true;
  readonly save = true;

  constructor(
    public route: Array<number>,
    public width: number,
    public height: number,
    public naturalWidth: number,
    public naturalHeight: number,
    public sid?: string,
    public url?: string,
    public isFoilable?: boolean
  ) {}
}
