import { Block, Document, Mark, MarkJSON, Point, Text, TextJSON, Value, BlockJSON, ValueJSON } from 'slate';
import { HorizontalAlignment, Word, TEXTWIDTH_CACHE } from '../models/text-editor.model';
import {
  FALLBACK_FONT_DEFAULT,
  FALLBACK_FONT_MONOSPACE,
  FoilTypes,
  MarkTypes,
  Paragraph,
  SpanPart
} from '../../src/app/models';

/**
 * return all parts of spans in a paragraph as Word[]
 */
export function flattenParagraph(paragraph: Block): Word[] {
  return paragraph.nodes
    .toArray()
    .filter((line: Block) => line.type === 'line')
    .flatMap((line: Block) => flattenLine(line.nodes.toJS() as TextJSON[]));
}

/**
 * return all parts of a given set of spans as Word[]
 * @param splitIntoCharacters: return array of separate characters including whitespaces
 */
export function flattenLine(nodes: Array<TextJSON>, splitIntoCharacters = false): Word[] {
  let parts: Word[] = [];

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  svg.appendChild(text);
  document.body.appendChild(svg);

  nodes.forEach((span: TextJSON) => {
    const spanParts = splitSpan(span, splitIntoCharacters);
    const style = convertMarks(span);
    const styleJson = JSON.stringify(style);
    spanParts.forEach((word: Word) => {
      word.marks = span.marks;
      word.width = TEXTWIDTH_CACHE[word.text + styleJson];
      if (!word.width) {
        Object.assign(text.style, style);
        text.textContent = word.text;
        word.width = text.getComputedTextLength();
        TEXTWIDTH_CACHE[word.text + styleJson] = word.width;
      }
    });
    parts = parts.concat(spanParts);
  });
  document.body.removeChild(svg);
  return parts;
}

/**
 * merge spanParts into words
 */
export function mergeWords(spanParts: Word[]): Word[] {
  const words: Word[] = [];
  spanParts.forEach(curWord => {
    if (
      words.length &&
      ((words[words.length - 1].isWhiteSpace && curWord.isWhiteSpace) ||
        (!words[words.length - 1].isWhiteSpace && !curWord.isWhiteSpace))
    ) {
      words[words.length - 1].text += curWord.text;
      words[words.length - 1].width += curWord.width;
      words[words.length - 1].parts = [...words[words.length - 1].parts, curWord];
    } else {
      words.push({
        text: curWord.text,
        width: curWord.width,
        key: curWord.key,
        isWhiteSpace: curWord.isWhiteSpace,
        x: curWord.x,
        parts: [curWord]
      });
    }
  });
  return words;
}

/**
 * split span into word parts
 * @param span: TextJson
 * @param singleCharacters (boolean): separate into characters
 */
function splitSpan(span: TextJSON, singleCharacters: boolean = false): Word[] {
  const words: Word[] = [];
  const whiteSpace = ' '; // else width is not calculated right
  for (let char of span.text) {
    const curWord = words[words.length - 1];
    const charIsWhiteSpace = char === ' ' || char === '\u00A0';
    char = charIsWhiteSpace ? whiteSpace : char;
    const curWordisWhiteSpace = curWord && curWord.text[0] === whiteSpace;
    if (
      !singleCharacters &&
      curWord &&
      ((charIsWhiteSpace && curWordisWhiteSpace) || (!charIsWhiteSpace && !curWordisWhiteSpace))
    ) {
      curWord.text += char;
    } else {
      words.push({
        text: char,
        isWhiteSpace: charIsWhiteSpace,
        key: span.key
      });
    }
  }
  return words;
}

/**
 * return stylemarks of a node as object (note: only marks that influence the width of the node)
 */
function convertMarks(node: TextJSON) {
  // convert mark to css styles
  const jsMarks = {};
  let isMonospace = false;
  node.marks.forEach((jsMark: MarkJSON) => {
    const value = jsMark.data[jsMark.type];
    jsMarks[jsMark.type] = value ? value : true;
    if (jsMark.type === MarkTypes.Font) {
      isMonospace = jsMark.data['isMonospace'];
    }
  });

  const fallback = isMonospace ? FALLBACK_FONT_MONOSPACE : FALLBACK_FONT_DEFAULT;
  /**
   * Inter Explorer gets problems with calculating word width when text is italic:
   * CalculateTextLength gives wrong text-width (see http://www.positioniseverything.net/explorer/italicbug-ie.html)
   * This causes incorrectly placed caret
   */
  return {
    'white-space': 'pre',
    'font-size': jsMarks[MarkTypes.Fontsize] + 'px',
    'font-family': `'${jsMarks[MarkTypes.Font]}', '${fallback}'`,
    'font-weight': jsMarks[MarkTypes.Bold] ? 700 : 400,
    'font-style': jsMarks[MarkTypes.Italic] ? MarkTypes.Italic : 'normal'
  };
}

/**
 * return the offset of start of text on a line
 */
export function getLineOffset(line: Block, totalWidth: number, padding: number): number {
  const alignment = line.data.get('textAlign');
  const lineWidth = getLineWidth(line);

  switch (alignment) {
    case HorizontalAlignment.start:
      return padding;
    case HorizontalAlignment.middle:
      return (totalWidth - lineWidth) / 2 + padding;
    case HorizontalAlignment.end:
      return totalWidth - lineWidth + padding;
    default:
      return padding;
  }
}

/**
 * return width of a text in a line
 */
export function getLineWidth(line: Block) {
  const spacing = line.data.get('wordSpacing') || 0;
  const parts = flattenLine(line.nodes.toJS() as TextJSON[]);
  return customLineWidth(parts, spacing);
}

function customLineWidth(parts: Word[], wordSpacing = 0) {
  const words = mergeWords(parts);
  const spaces = words.filter(word => !word.isWhiteSpace).length - 1;
  return totalWordWidth(words) + Math.max(spaces, 0) * wordSpacing;
}

/**
 * return combined width of Word array
 */
export function totalWordWidth(line: Word[]): number {
  return line.map(word => word.width).reduce((a, b) => a + b, 0);
}

/**
 * calculate the line width from start of line until point in line
 */
export function calculateLineWidthUntilPoint(line: Block, point: Point) {
  const spanIndex = line.nodes.findIndex((span: Text) => span.key === point.key);
  const spans = line.nodes.toJS().slice(0, spanIndex) as TextJSON[];
  const spanAtPoint = line.nodes.get(spanIndex).toJS() as TextJSON;
  const spacing = line.data.get('wordSpacing') || 0;

  spanAtPoint.text = spanAtPoint.text.slice(0, point.offset);

  const parts = flattenLine([...spans, spanAtPoint]);
  const lineParts = flattenLine(line.nodes.toJS() as TextJSON[]);

  let totalWidth = customLineWidth(parts, spacing);

  if (parts.length && parts[parts.length - 1].isWhiteSpace && parts.length !== lineParts.length) {
    totalWidth += spacing;
  }

  return totalWidth;
}

/**
 * return coordinates to topleft corner of a point in the text
 */
export function getPointX(point: Point, document: Document, totalWidth: number): number {
  const line = document.getParent(point.key) as Block;
  const padding = document.data.get('padding');
  const deltaXLine = calculateLineWidthUntilPoint(line, point);
  return deltaXLine + getLineOffset(line, totalWidth, padding);
}

// -------- queries into the editor document ---------

/**
 * returns lines from all paragraphs as Block[]
 * @param document: Document (slate)
 */
export function getAllLines(document: Document): Block[] {
  return document.nodes
    .flatMap((paragraph: Block) => paragraph.nodes.filter((line: Block) => line.type === 'line'))
    .toArray() as Block[];
}

/**
 * return index of line in all lines of the document
 */
export function getLineIndex(line: Block, document: Document) {
  const allLines = getAllLines(document);
  return allLines.findIndex((node: Block) => node.key === line.key);
}

/**
 * get all values of a specific mark within the current selection
 * @param type: MarkType
 * @param value: Value (slate)
 */
export function getSelectionMarkValues(type: string, value: Value) {
  return value.marks
    .filter(mark => mark.type === type)
    .map(mark => mark.data.get(type))
    .toArray();
}

/**
 * return mark value of a node
 * @param node: Text (slate)
 * @param type: MarkType
 */
export function getMarkValueFromNode(node: Text, type: string) {
  const mark = node.marks.find((item: Mark) => item.type === type);
  return mark ? mark.data.get(type) : undefined;
}

/**
 * return flattened paragraph as lines of text
 */
export function getTextLines(words: Word[], textWidth: number): Array<{ words: Word[]; text: string }> {
  let totalWidth = 0; // width so far since start of new line
  const textLines: Array<{ words: Word[]; text: string }> = [];
  let currentLine: { words: Word[]; text: string } = { text: '', words: [] };

  words.forEach(word => {
    while (word.width > textWidth && word.text.length > 1 && !word.isWhiteSpace) {
      if (totalWidth > 0) {
        textLines.push(currentLine);
        currentLine = { text: '', words: [] };
      }

      const [firstWord, remainingWord] = breakWord(word, textWidth);

      textLines.push({
        text: firstWord.text,
        words: [firstWord]
      });

      word = remainingWord;
      totalWidth = 0;
    }

    if (word.width + totalWidth > textWidth && !word.isWhiteSpace && totalWidth > 0) {
      // if word is not whitespace and word exceeds line length and not first word of line
      // add breakpoint to breaks array
      textLines.push(currentLine);
      currentLine = {
        text: word.text,
        words: [word]
      };
      totalWidth = word.width;
    } else {
      totalWidth += word.width;
      currentLine.text += word.text;
      currentLine.words.push(word);
    }
  });

  textLines.push(currentLine);

  return textLines;
}

function breakWord(word: Word, textWidth: number) {
  let totalWordPartWidth = 0;
  const splitWordPartsAt = word.parts.findIndex(part => {
    if (part.width + totalWordPartWidth > textWidth) {
      return true;
    }
    totalWordPartWidth += part.width;
  });

  const wordPartAtBreak = word.parts[splitWordPartsAt];
  let splitWordPartAtChar = wordPartAtBreak.text.length;
  for (; splitWordPartAtChar >= 0; splitWordPartAtChar--) {
    const slicedWord = flattenLine([
      {
        ...wordPartAtBreak,
        text: wordPartAtBreak.text.slice(0, splitWordPartAtChar)
      }
    ]);
    if (splitWordPartAtChar === 0 || slicedWord[0].width + totalWordPartWidth < textWidth) {
      break;
    }
  }

  if (splitWordPartsAt === 0 && splitWordPartAtChar === 0) {
    // if the breakpoint is in the first character of a line (meaning textwidth is less than one letter width), keep breaking off into
    // single characters
    splitWordPartAtChar = 1;
  }

  const firstWordPart = flattenLine([
    {
      ...wordPartAtBreak,
      text: wordPartAtBreak.text.slice(0, splitWordPartAtChar)
    }
  ]);

  const remainingWordPart = flattenLine([
    {
      ...wordPartAtBreak,
      text: wordPartAtBreak.text.slice(splitWordPartAtChar)
    }
  ]);

  const firstPartWordParts = [...word.parts.slice(0, splitWordPartsAt), ...firstWordPart];
  const firstWord = {
    ...word,
    text: firstPartWordParts.reduce((prev, next) => prev + next.text, ''),
    width: firstPartWordParts.reduce((prev, next) => prev + next.width, 0),
    parts: firstPartWordParts
  };

  const remainingWordParts = [...remainingWordPart, ...word.parts.slice(splitWordPartsAt + 1)];
  const remainingWord = {
    ...word,
    text: remainingWordParts.reduce((prev, next) => prev + next.text, ''),
    width: remainingWordParts.reduce((prev, next) => prev + next.width, 0),
    parts: remainingWordParts
  };

  return [firstWord, remainingWord];
}

/**
 * return word spacing of line
 */
export function getWordSpacing(line: BlockJSON, textWidth: number): number {
  const lineAsSpanParts = flattenLine(line.nodes as TextJSON[]);
  const lineAsWordArray = mergeWords(lineAsSpanParts);
  const lineWidth = totalWordWidth(lineAsWordArray);
  const spacing = (textWidth - lineWidth) / (lineAsWordArray.filter(word => !word.isWhiteSpace).length - 1);
  return Math.max(0, Number(spacing.toFixed(2)));
}

export function assignSpanParts(val: ValueJSON, outputValue: Paragraph[], padding: number) {
  val.document.nodes.forEach((paragraph: BlockJSON, parIndex) => {
    paragraph.nodes
      .filter((line: BlockJSON) => line.type === 'line' && paragraph.data.isJustified)
      .flatMap((line: BlockJSON, lineIndex) => {
        let widthSoFar = padding;
        const wordSpacing = line.data.wordSpacing;

        line.nodes.forEach((node: TextJSON, nodeIndex) => {
          const parts = flattenLine([node]);
          parts.forEach(part => {
            part.x = widthSoFar;
            widthSoFar += part.width;
            if (part.isWhiteSpace) {
              widthSoFar += wordSpacing;
            }
          });

          outputValue[parIndex].lines[lineIndex].textSpans[nodeIndex].spanParts = parts
            .filter(part => !part.isWhiteSpace)
            .map(part => new SpanPart(part.text, part.x));
        });
      });
  });
}

export function textAsParagraphs(text: string, defaultMarks: Mark[], lineData: {}): BlockJSON[] {
  const delimiter = '\n';
  return text
    .replace(/\r\n/g, delimiter)
    .split(delimiter)
    .map(line => {
      return {
        type: 'paragraph',
        object: 'block',
        data: {},
        nodes: [
          {
            type: 'line',
            object: 'block',
            data: lineData,
            nodes: [
              {
                object: 'text',
                text: line,
                marks: defaultMarks.map(Mark.createProperties)
              }
            ]
          }
        ]
      };
    });
}

export function filterNonPrintableCharacters(text: string): string {
  return text
    .split('\n')
    .map((p: string) => p.replace(/[\x00-\x1F]/g, ''))
    .join('\n');
}

export function getFoilType(document: Document): FoilTypes {
  const mark = document.getMarksByType(MarkTypes.Foil).first();
  return mark ? mark.data.get(MarkTypes.Foil) : undefined;
}

export function getSpotUv(document: Document): boolean {
  const mark = document.getMarksByType(MarkTypes.SpotUv).first();
  return !!mark;
}

export function getColor(document: Document): string {
  const mark = document.getMarksByType(MarkTypes.Color).first();
  return mark ? mark.data.get(MarkTypes.Color) : undefined;
}
