import { Color } from '../models';
import { isEqual } from 'lodash-es';

export const LIGHT_SCHEME_PERCENTAGE = 90;
export const DARK_SCHEME_PERCENTAGE = 30;

export class RGB {
  constructor(public r: number, public g: number, public b: number, public opacity?: number) {}
}

export interface HSL {
  h: number; // hue
  s: number; // saturation
  l: number; // lightness
}

// HSB is the same as HSV
export interface HSB {
  h: number; // hue
  s: number; // saturation
  b: number; // brightness
}

export class ColorScheme {
  whiteGradientLeftOpacity: number;
  whiteGradientRightOpacity: number;
  blackGradientTopOpacity: number;
  blackGradientBottomOpacity: number;
}

export const HueColors = {
  red: `rgba(255, 0, 0, 1)`,
  yellow: `rgba(255, 255, 0, 1)`,
  green: `rgba(0, 255, 0, 1)`,
  cyan: `rgba(0, 255, 255, 1)`,
  blue: `rgba(0, 0, 255, 1)`,
  pink: `rgba(255, 0, 255, 1)`
};

export const BrightColors = setColorScheme(1, 0, 0, 1);

// To get dark and light color schemes: change the opacity of white and black gradient
// Black gradient changes brightness and White gradient changes saturation in HSB color model
export const LightColors = setColorScheme(1, LIGHT_SCHEME_PERCENTAGE / 100, 0, 1 - LIGHT_SCHEME_PERCENTAGE / 100);

export const DarkColors = setColorScheme(1 - DARK_SCHEME_PERCENTAGE / 100, 0, DARK_SCHEME_PERCENTAGE / 100, 1);

export const validColorPattern = /^#?([a-fA-F0-9]{6})$/; // the hash is optional

export function setColorScheme(
  whiteLeftOpacity: number,
  whiteRightOpacity: number,
  blackTopOpacity: number,
  blackBottomOpacity: number
): ColorScheme {
  return {
    whiteGradientLeftOpacity: whiteLeftOpacity,
    whiteGradientRightOpacity: whiteRightOpacity,
    blackGradientTopOpacity: blackTopOpacity,
    blackGradientBottomOpacity: blackBottomOpacity
  };
}

export function colorExistsInArray(color: Color, colorArray: Color[]): boolean {
  return !!colorArray.find(customColor => isEqual(customColor, color));
}

export function checkColorValue(colorValue: string) {
  return validColorPattern.test(colorValue);
}

export function createNewColor(colorValue: string, spotUv = false) {
  colorValue = !colorValue.startsWith('#') ? `#${colorValue}` : colorValue;
  return new Color('', colorValue, undefined, spotUv);
}

export function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number): string {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function rgbStringToHex(rgb: string): string {
  const values = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')')).split(',');
  const r = parseInt(values[0], 0);
  const g = parseInt(values[1], 0);
  const b = parseInt(values[2], 0);
  return rgbToHex(r, g, b);
}

export function getColorAtPosition(ctx: CanvasRenderingContext2D, x: number, y: number) {
  const imageData = ctx.getImageData(x, y, 1, 1).data;
  const r = imageData[0];
  const g = imageData[1];
  const b = imageData[2];
  return rgbToHex(r, g, b);
}

export function hexToRgb(hex: string): RGB {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
  return rgb;
}

export function rgbToHsl(rgb: RGB): HSL {
  // color conversion calculation
  // https://www.niwa.nu/2013/05/math-behind-colorspace-conversions-rgb-hsl/

  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  let lum = (min + max) / 2;

  let sat: number;
  if (lum < 0.5) {
    sat = (max - min) / (max + min);
  } else {
    sat = (max - min) / (2 - max - min);
  }

  let hue: number;
  if (r >= b && r >= g) {
    hue = (g - b) / (max - min);
  } else if (g >= r && g >= b) {
    hue = 2.0 + (b - r) / (max - min);
  } else if (b >= r && b >= g) {
    hue = 4.0 + (r - g) / (max - min);
  }
  hue *= 60;
  if (hue < 0) {
    hue += 360;
  }
  hue = hue / 360;
  lum = Math.min(1, Math.max(0, lum));
  sat = Math.min(1, Math.max(0, sat));
  hue = Math.min(1, Math.max(0, hue));

  return { h: Math.round(hue * 360), s: Math.round(sat * 100), l: Math.round(lum * 100) };
}

export function colorIsGrey(color: string): boolean {
  const rgbColor = hexToRgb(color);
  return rgbColor.r === rgbColor.g && rgbColor.r === rgbColor.b;
}

export function hslToRgb(hsl: HSL) {
  const lum = hsl.l / 100;
  const sat = hsl.s / 100;
  const hue = hsl.h / 360;

  let r = 0;
  let g = 0;
  let b = 0;

  if (hsl.h === 0 && hsl.s === 0) {
    r = Math.round(255 * lum);
    g = Math.round(255 * lum);
    b = Math.round(255 * lum);
  } else {
    r = getSingleValueForRgb('r', hue, sat, lum);
    g = getSingleValueForRgb('g', hue, sat, lum);
    b = getSingleValueForRgb('b', hue, sat, lum);
  }

  return new RGB(Math.min(Math.max(0, r), 255), Math.min(Math.max(0, g), 255), Math.min(Math.max(0, b), 255));
}

export function getSingleValueForRgb(singleValue: 'r' | 'g' | 'b', hue: number, sat: number, lum: number) {
  let singleValueForRgb = 0;

  let temp1: number;

  if (lum < 0.5) {
    temp1 = lum * (1.0 + sat);
  } else {
    temp1 = lum + sat - lum * sat;
  }

  const temp2 = 2 * lum - temp1;

  let tempSingleValue: number;

  if (singleValue === 'r') {
    tempSingleValue = hue + 0.333;
  } else if (singleValue === 'g') {
    tempSingleValue = hue;
  } else {
    tempSingleValue = hue - 0.333;
  }

  if (tempSingleValue < 0) {
    tempSingleValue += 1;
  } else if (tempSingleValue > 1) {
    tempSingleValue -= 1;
  }

  if (tempSingleValue * 6 < 1) {
    singleValueForRgb = temp2 + (temp1 - temp2) * 6 * tempSingleValue;
  } else if (tempSingleValue * 2 < 1) {
    singleValueForRgb = temp1;
  } else if (tempSingleValue * 3 < 2) {
    singleValueForRgb = temp2 + (temp1 - temp2) * (0.666 - tempSingleValue) * 6;
  } else {
    singleValueForRgb = temp2;
  }

  return Math.round(singleValueForRgb * 255);
}

export function hslToHsb(hsl: HSL) {
  const t = (hsl.s * (hsl.l < 50 ? hsl.l : 100 - hsl.l)) / 100; // Temp value
  const newS = Math.round((200 * t) / (hsl.l + t)) || 0;
  const newB = Math.round(t + hsl.l);

  return { h: hsl.h, s: newS, b: newB };
}

export function hsbToHsl(hsb: HSB) {
  const newL = ((2 - hsb.s / 100) * hsb.b) / 2;
  const newS = (hsb.s * hsb.b) / (newL < 50 ? newL * 2 : 200 - newL * 2) || 0;

  return { h: hsb.h, s: Math.round(newS), l: Math.round(newL) };
}

export function hexToHsl(color: string) {
  const colorRgb = hexToRgb(color);
  return rgbToHsl(colorRgb);
}

export function hsbToHex(hsb: HSB) {
  const colorHsl = hsbToHsl(hsb);
  const colorRgb = hslToRgb(colorHsl);
  return rgbToHex(colorRgb.r, colorRgb.g, colorRgb.b);
}

export function colorShade(color: string, percent: number) {
  const rgb = hexToRgb(color);

  let r = Math.round((rgb.r * (100 + percent)) / 100);
  let g = Math.round((rgb.g * (100 + percent)) / 100);
  let b = Math.round((rgb.b * (100 + percent)) / 100);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const rr = r.toString(16).length === 1 ? '0' + r.toString(16) : r.toString(16);
  const gg = g.toString(16).length === 1 ? '0' + g.toString(16) : g.toString(16);
  const bb = b.toString(16).length === 1 ? '0' + b.toString(16) : b.toString(16);

  return '#' + rr + gg + bb;
}

export function newColorShade(color: string, percent: number) {
  const hsl = hexToHsl(color);
  const newHsl = { h: hsl.h, s: hsl.s, l: hsl.l + percent };
  const newRgb = hslToRgb(newHsl);
  return rgbToHex(newRgb.r, newRgb.g, newRgb.b);
}

export function colorLuminance(hex: string, lum?: number) {
  lum = lum || 0;

  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let c;
  let i;
  let newHex = '#';
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    newHex += ('00' + c).substr(c.length);
  }
  return newHex;
}
