import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

const directions = {
  top: 0,
  'top-right': 45,
  right: 90,
  'bottom-right': 135,
  bottom: 180,
  'bottom-left': 225,
  left: 270,
  'top-left': 315
};

@Injectable()
export class ResizeCursorService {
  public resizeCursor$ = new BehaviorSubject(null);
  public resizeCursorHover$ = new BehaviorSubject(null);
  public resizeCursors = {};

  rotation: number;

  constructor(private sanitizer: DomSanitizer) {}

  // create the actual image of the cursor
  createResizeCursor(correction: number, rotation: number) {
    const angle = Math.round(rotation + correction);
    return this.sanitizer.bypassSecurityTrustStyle(
      `url("data:image/svg+xml;utf8,
          <svg height='23' viewBox='0 0 23 23' width='23' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none' fill-rule='evenodd' transform='translate(3 2) rotate(${angle} 8.5 9.5)'>
              <path d='m5 14v-9h3l-4-5-4 5h3v9h-3l4 5 4-5z' fill='%23fff' fill-rule='evenodd'/>
              <path d='m5.5 13.5h3.54031242l-5.04031242 6.3003905-5.04031242-6.3003905h3.54031242v-8h-3.54031242l5.04031242-6.30039053
                5.04031242 6.30039053h-3.54031242z' stroke='%23363b3e'/>
            </g>
          </svg>") 11.5 11.5, default`.replace(/\s\s+/g, ' ')
    );
  }

  // set the possible cursors for an element
  setResizeCursors(resizeDirections: string[], rotation: number) {
    this.rotation = rotation;

    const resizeCursorsDefault = {};
    const resizeCursors = {};

    // hack for safari else cursor is not updated until overlay is completely rendered again
    resizeDirections.map(direction => (resizeCursorsDefault[direction] = 'default'));
    this.resizeCursorHover$.next(resizeCursorsDefault);

    resizeDirections.map(
      direction => (resizeCursors[direction] = this.createResizeCursor(directions[direction], this.rotation))
    );
    this.resizeCursorHover$.next(resizeCursors);
    this.resizeCursors = resizeCursors;
  }

  // set cursor after mousedown in overlay component, this cursor does not change during mousedown
  setCursorResizing(direction: string) {
    this.resizeCursor$.next(this.resizeCursors[direction]);
  }

  resetCursor() {
    this.resizeCursor$.next(null);
  }
}
