import { fabric } from "fabric";
import { isSmallObject } from "../../../utils/object.utils";

const size = 24;

const horizontalImg = document.createElement('img');
horizontalImg.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="170" height="300" viewBox="0 0 17 30" fill="none">
    <rect x="5" y="5" width="7" height="20" rx="3.5" fill="#FFF" shape-rendering="geometricPrecision" stroke="#0008" stroke-width="1" stroke-opacity="0.7" />
  </svg>
`);

const verticalImg = document.createElement('img');
verticalImg.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="340" height="170" viewBox="0 0 34 17" fill="none" stroke="#0008" stroke-width="1" stroke-opacity="0.7">
    <rect x="5" y="5" width="24" height="7" rx="3.5" fill="#FFF" />
  </svg>
`);

const ml: Partial<fabric.Control> = {
  sizeX: size / 2,
  sizeY: size,
  getVisibility,
  render: renderHorizontal
};

Object.assign(fabric.Object.prototype.controls.ml, ml);
Object.assign(fabric.Textbox.prototype.controls.ml, ml);

const mt: Partial<fabric.Control> = {
  sizeX: size,
  sizeY: size / 2,
  getVisibility,
  render: renderVertical
};

Object.assign(fabric.Object.prototype.controls.mt, mt);
Object.assign(fabric.Textbox.prototype.controls.mt, mt);

const mr: Partial<fabric.Control> = {
  sizeX: size / 2,
  sizeY: size,
  getVisibility,
  render: renderHorizontal
};

Object.assign(fabric.Object.prototype.controls.mr, mr);
Object.assign(fabric.Textbox.prototype.controls.mr, mr);

const mb: Partial<fabric.Control> = {
  sizeX: size,
  sizeY: size / 2,
  getVisibility,
  render: renderVertical
};

Object.assign(fabric.Object.prototype.controls.mb, mb);
Object.assign(fabric.Textbox.prototype.controls.mb, mb);

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return (fabricObject instanceof fabric.Text || !isSmallObject(fabricObject)) && fabricObject._controlsVisibility[controlKey];
}

function renderHorizontal(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {

  // Do not render horizontal crop controls if object is small text
  if (fabricObject instanceof fabric.Text && isSmallObject(fabricObject)) {
    return;
  }

  // Render height dinamically based on object height if object is text
  const objectHeight = fabricObject.getScaledHeight() * (fabricObject.canvas?.getZoom() ?? 1)
  const height = fabricObject instanceof fabric.Text ? Math.min(size, objectHeight / 4) : size;

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(horizontalImg, -size / 2, -height, size, height * 2);
  ctx.restore();
}

function renderVertical(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {

  // Do not render horizontal crop controls if object is small text
  if (fabricObject instanceof fabric.Text && isSmallObject(fabricObject)) {
    return;
  }

  // Render width dinamically based on object width  if object is text
  const objectWidth = fabricObject.getScaledWidth() * (fabricObject.canvas?.getZoom() ?? 1)
  const width = fabricObject instanceof fabric.Text ? Math.min(size, objectWidth / 4) : size;

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(verticalImg, -width, -size / 2, width * 2, size);
  ctx.restore();
}
