import { ConfigActions, ConfigActionsTypes } from './actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BasketInterface,
  ChangeFormatPreset,
  Color,
  CustomFormField,
  Design,
  FunctionPermissions,
  Material,
  MenuItem,
  SetElementPermissions,
  Trim,
  TrimTypes
} from '../models';
import { TextState } from '../models/text';
import { CssVariables } from '../data/theme.data';
import { selectDesign } from '../selectors';
import { ChatConfig, ChatPosition } from '../models/chat';

export interface State {
  isMac: boolean;
  isIphone: boolean;
  isIpad: boolean;
  isIphoneOS12: boolean;
  isChrome: boolean;
  isAndroid: boolean;
  isTouchDevice: boolean;
  googleTagManagerID: string;
  googleTagManagerDataLayer: object[];
  styleSheetLink: string;
  renderAsSvg: boolean;
  locale: string;
  permissions: {
    functionPermissions: FunctionPermissions;
    setElementPermissions: SetElementPermissions;
  };
  changeFormatPresets: Array<ChangeFormatPreset>;
  useAbsoluteFontSize: boolean;
  imgBase: string;
  logoUrl: string;
  faviconUrl: string;
  designUrl: string;
  designOtherUserUrl: string;
  updateExistingDesignUrl: string;
  designToReviewUrl: string;
  isAuthenticatedUrl: string;
  loginUrl: string;
  logoutUrl: string;
  registerUrl: string;
  recoverPasswordUrl: string;
  imageLibraryUrl: string;
  imageLibrarySearchUrl: string;
  imageSearchLimit: number;
  fontsUrl: string;
  collectionUrl: string;
  continueUrl: string;
  closeUrl: string;
  foilInfoUrl: string;
  spotUvInfoUrl: string;
  convertDesignUrl: string;
  createPdfUrl: string;

  toolBarColorStart: string;
  toolBarColorEnd: string;
  toolBarTextColor: string;
  toolBarColorOrientation: string;
  rainbowBackgroundUrl: string;
  rainbowColorChipUrl: string;
  gridSize: number;
  showNewMobileMenu: boolean;

  text: TextState;

  helpMenuItems: MenuItem[];
  userMenuItems: MenuItem[];
  registrationFormFields: CustomFormField[];

  matchPasswordFields: string[];

  basketInterface: BasketInterface;

  tooltipShowDelay: number;

  colorData: Color[];
  spotUvColorData: Color[];
  foilColorData: Color[];
  trimData: Trim[];

  tags: string[];

  materials: Material[];

  themeData: CssVariables;

  chat: ChatConfig;
}

export class InitialState implements State {
  isMac = false;
  isIphone = false;
  isIpad = false;
  isIphoneOS12 = false;
  isChrome = false;
  isAndroid = false;
  isTouchDevice = false;
  googleTagManagerID: string = null;
  googleTagManagerDataLayer: Array<Record<string, string>> = [];
  styleSheetLink = '';
  renderAsSvg = true;
  locale = 'nl_NL';
  permissions = {
    setElementPermissions: new SetElementPermissions(),
    functionPermissions: new FunctionPermissions()
  };
  changeFormatPresets: Array<ChangeFormatPreset> = [];
  useAbsoluteFontSize = false;
  imgBase = 'https://img.kaart-o-matic.nl';
  logoUrl = '';
  faviconUrl = '/favicon.ico';
  designUrl = '/design';
  designOtherUserUrl = '/designotheruser';
  updateExistingDesignUrl = '/titledescription';
  designToReviewUrl = '/designtoreview';
  isAuthenticatedUrl = '/authenticate';
  loginUrl = '/logout';
  logoutUrl = '/authenticate';
  registerUrl = '/register';
  recoverPasswordUrl = '/recoverpassword';
  imageLibraryUrl = '/imagelibrary';
  imageLibrarySearchUrl = '/imagelibrarysearch';
  imageSearchLimit = 100;
  fontsUrl = '/fonts';
  collectionUrl = '/collectie';
  continueUrl = '/continue-to-url';
  closeUrl = '/';
  foilInfoUrl = '';
  spotUvInfoUrl = '';
  convertDesignUrl = 'https://img.kaart-o-matic.nl/convert_design_pages';
  createPdfUrl = '/drukpdf';
  toolBarColorStart = '#444c5f';
  toolBarColorEnd = '#444c5f';
  toolBarTextColor = '#ffffff';
  toolBarColorOrientation = '90deg';
  rainbowBackgroundUrl = '/assets/rainbow-pattern.png';
  rainbowColorChipUrl = '/assets/rainbow.jpg';
  gridSize = 20;
  showNewMobileMenu = false;

  text = {
    title: 'Editor Demo App',
    topMenu: {
      chatTitle: 'Chat',
      menuTitle: 'File',
      headerTitle: 'Editor Demo App',
      helpMenuTitle: 'Help',
      loginMenuTitle: 'Login',
      logoutTitle: 'Log out'
    },
    mainMenu: {
      makeCopyLinkTitle: 'Make a copy...',
      changeNameLinkTitle: 'Change name...',
      personalCollectionLinkTitle: `Go to 'My collection'`,
      updateExistingDesignTitle: 'Overwrite existing design',
      newDesignTitle: 'New design',
      saveInCollectionTitle: 'Save in collection',
      changePageFormatTitle: 'Change card format',
      importExportTitle: 'JSON',
      createPdfTitle: 'Create Pdf',
      grid: {
        on: 'Grid on',
        off: 'Grid off'
      },
      closeEditorLinkTitle: 'Close editor',
      demoCanvasButtonText: 'Load Demo Canvas'
    },
    toolBar: {
      undoRedo: {
        tooltip: {
          undo: 'Undo',
          redo: 'Redo'
        }
      },
      save: {
        tooltip: 'Save',
        snackbar: {
          titleOrDescriptionSaved: 'title/description saved',
          designSavedForProposal: 'Design saved for proposal',
          designSavedInCustomerCollection: "Design saved in customer's collection"
        }
      },
      autoSave: {
        tooltip: 'Every change will be automatically saved to your collection.',
        saveDesignText: 'Save design',
        savePendingText: 'Saving...',
        saveSuccessText: 'Saved'
      },
      copy: {
        copyButtonText: 'Copy',
        tooltip: 'Copy'
      }
    },
    design: {
      instantReplaceable: {
        tooltip: 'Replace'
      },
      pageOverlay: {
        bleed: 'Bleed',
        safetyMargin: 'Safety Margin'
      },
      defaultLabels: {
        pages: {
          1: [['Front']],
          2: [['Front'], ['Back']],
          4: [['Front'], ['Inside left'], ['Inside right'], ['Back']]
        },
        userSpreads: {
          2: [['Front'], ['Back']],
          4: [['Back', 'Front'], ['Inside']],
          6: [
            ['', '', 'Front'],
            ['', '', 'Back']
          ]
        },
        card: [['Front'], ['Inside'], ['Back']]
      },
      zoomMenu: {
        zoomToFit: 'Zoom to fit'
      },
      pageViewMenu: {
        pageView: 'Page view',
        spreadView: 'Spread view',
        cardView: 'Card view',
        productionView: 'Product view'
      },
      toolsMenu: {
        grid: {
          tooltip: 'Grid on/off'
        },
        untargetableElementsTargetable: {
          tooltip: 'untargetable elements are targetable'
        },
        delete: {
          tooltip: 'Delete'
        },
        copyPaste: {
          tooltip: {
            cut: 'Cut',
            copy: 'Copy',
            paste: 'Paste'
          }
        },
        order: {
          tooltip: 'Order'
        }
      }
    },
    edit: {
      menu: {
        panel: {
          title: {
            text: 'Text',
            image: 'Images and decorations',
            background: 'Background'
          },
          subTitle: {
            chooseImage: 'Choose an image or pattern',
            chooseColor: 'Choose a color',
            imageCropReady: 'Image cropping finished?',
            imageCropText: "Click 'Done' or click outside the image."
          },
          tooltip: {
            backgroundDisabled: "Background can't be edited"
          },
          button: {
            addText: 'Add new text',
            addTextInline: 'Add new inline text',
            uploadImage: 'Upload your photo',
            openImageLibrary: 'Search within our images',
            startImageCropping: 'Crop',
            stopImageCropping: 'Done',
            replaceImage: 'Replace',
            addNewFrame: 'Add new frame',
            chooseImageOrPattern: 'Choose image or pattern',
            removeBackgroundImage: 'Delete',
            showMoreOptions: 'More options',
            showLessOptions: 'Less options',
            tooltip: {
              startImageCropping: 'Crop',
              uploadImage: 'Upload your photo',
              removeImage: 'Delete'
            }
          }
        },
        mobile: {
          navigation: {
            text: 'Text',
            image: 'Image',
            background: 'Edit background',
            backgroundDisabledText: "Background can't be edited"
          },
          navigationMenu: {
            goBack: 'Close',
            add: 'Add',
            pages: 'Pages',
            save: 'Save',
            goForward: 'Example'
          },
          addMenu: {
            title: 'Add',
            text: 'Text',
            image: 'Image',
            upload: 'Upload',
            background: 'Background'
          },
          subTitle: {
            chooseFont: 'Choose a font',
            chooseImage: 'Choose an image',
            chooseImageOrPattern: 'Choose an image or pattern',
            background: 'Background'
          },
          button: {
            confirmText: 'Done',
            uploadImage: 'Upload your image',
            uploadImageShort: 'Upload image',
            startImageCropping: 'Crop',
            stopImageCropping: 'Done',
            replaceImage: 'Replace',
            backgroundImageOrPattern: 'Image/Pattern',
            backgroundColor: 'Color',
            replaceBackground: 'Replace',
            showMore: 'More',
            borderTrim: 'Border trim'
          }
        }
      },
      mobile: {
        addButtonText: 'Add',
        uploadButtonText: 'Upload',
        removeButtonText: 'Remove',
        duplicateButtonText: 'Duplicate',
        replaceButtonText: 'Replace',
        cropImageButtonText: 'Crop',
        edit: {
          title: 'Edit'
        },
        color: {
          title: 'Color',
          tabs: {
            design: 'Design colors',
            foil: 'Foil',
            default: 'Default',
            custom: 'Custom'
          }
        },
        order: {
          title: 'Order',
          options: {
            moveStepForward: 'Up',
            moveStepBackward: 'Down',
            moveToFront: 'Above',
            moveToBack: 'Below'
          }
        },
        fill: {
          title: 'Fill',
          options: {
            horizontal: 'Horizontal',
            vertical: 'Vertical'
          }
        },
        flip: {
          title: 'Flip',
          options: {
            horizontal: 'Horizontal',
            vertical: 'Vertical'
          }
        },
        rotate: {
          title: 'Rotate'
        },
        transparency: {
          title: 'Transparency'
        },
        zoom: {
          title: "Zoomen"
        },
        fontStyle: {
          title: 'Font style',
          options: {
            bold: 'Bold',
            italic: 'Italic',
            underline: 'Underline'
          }
        },
        alignment: {
          title: 'Alignment',
          options: {
            start: 'align left',
            middle: 'align center',
            end: 'align right',
            justify: 'justify'
          }
        },
        borderTrim: {
          title: 'Trim'
        },
        crop: {
          title: 'Crop',
          options: {
            cancel: 'Cancel',
            done: 'Done'
          }
        }
      },
      option: {
        title: {
          alignment: 'Alignment',
          backgroundColor: 'Background',
          borderTrim: 'Border trim',
          changeMaterial: 'Material',
          color: 'Color',
          dimensions: 'Dimensions',
          fill: 'Fill',
          flip: 'Flip',
          font: 'Font',
          fontSize: 'Font size',
          fontStyle: 'Font style',
          lineHeight: 'Line height',
          order: 'Order',
          position: 'Position',
          positionAndDimensions: 'Position and size',
          rotation: 'Rotation',
          thumb: 'Image',
          transparency: 'Transparency',
          zoom: 'Zoomen'
        },
        mobile: {
          title: {
            alignment: 'Alignment',
            backgroundColor: 'Background',
            borderTrim: 'Border trim',
            color: 'Color',
            fill: 'Fill',
            flip: 'Flip',
            font: 'Font',
            fontSize: 'Font size',
            fontStyle: 'Font style',
            lineHeight: 'Line height',
            order: 'Order',
            rotation: 'Rotation',
            text: 'Edit',
            textColor: 'Text color',
            transparency: 'Transparency',
            zoom: 'Zoomen',
            settings: 'Settings',
            delete: 'Delete',
            cut: 'Cut',
            copy: 'Copy',
            paste: 'Paste'
          }
        },
        effect: {
          title: 'Effect',
          options: {
            default: 'Default',
            spotUv: 'High gloss'
          }
        }
      },
      setting: {
        openElementSettingsTooltip: 'Edit settings',
        selectElementText: 'Select an element to edit its settings',
        menu: {
          title: 'Edit settings',
          operationsTitle: 'Operations',
          propertiesTitle: 'Properties',
          pagePropertiesTitle: 'Page properties',
          designPropertiesTitle: 'Design properties',
          tagsTitle: 'Tags',
          layerTypeTitle: 'Afdruklaag',
          characterLimit: 'Character limit'
        },
        checkbox: {
          adjustTransparency: 'Adjust transparency',
          croppable: 'Crop',
          duplicatable: 'Duplicate',
          flippable: 'Flip',
          foilable: 'Foil available',
          replaceable: 'Replaceable',
          instantReplaceable: 'Instant replaceable',
          instantReplaceablePlaceholder: 'Always visible',
          isTopLayer: 'Always on top',
          lock: 'Lock element',
          lockPage: 'Lock page',
          hidePage: 'Hide page',
          movable: 'Move',
          printable: 'Print',
          recolorable: 'Recolor',
          removable: 'Remove',
          resizable: 'Resize',
          rotatable: 'Rotate',
          untargetable: 'Click through',
          coating: 'White coating',
          spotUv: 'Spot UV',
          addChildElements: 'Add elements',
          adjustTrim: 'Adjust trim',
          changeMaterial: 'Material',
          isOrderable: 'Order',
          useRichText: 'Use Rich Text',
          shrinkToFit: 'Shrink to Fit',
          excludeFromPreviews: 'Exclude from previews',
          isVisibleOutsideCropArea: 'Show outside the crop',
          isSpotUvable: 'Spot UV available',
          isExternallyEditable: 'Editable in the PDP',
          fontChangeable: 'Font changeable in Editor and PDP',
          fontResizable: 'Font resizeable in Editor and PDP',
          textEditable: 'Text editable in Editor and PDP'
        },
        tooltip: {
          adjustTransparency: 'Users can adjust the element transparency using the slider in the panel.',
          croppable: 'The image can be cropped with the crop buttons on the sides of the element.',
          duplicatable: `The element can be copied and pasted with the shortcuts or buttons in the toolbar.`,
          flippable: `The element can be flipped horizontally and/or vertically with the buttons in the panel.`,
          foilable: `The option for adding a foil color to the element is available in the color menu.`,
          replaceable: `The element can by replaced with the button in the panel or drag-and-drop.`,
          instantReplaceable: `The selected element has a camera icon in the middle. When a user clicks this icon,
            the image can be instantly replaced.`,
          instantReplaceablePlaceholder: `The upload icon is always visible until the user replaces the image.`,
          isTopLayer: `The element will be placed in the top layer. A user can not put elements on top of it.`,
          lock: 'The element can not be selected or edited. All edit options will be turned off. ',
          lockPage: 'The page cannot be edited. All edit options will be turned off.',
          hidePage: 'The page is not visible for users',
          movable: `An element can be moved by dragging or using arrow buttons or shortcuts.`,
          printable: `The element is visible in the design and will be printed.`,
          recolorable: `The color of an image can be changed by choosing another color from the color menu in the panel.`,
          removable: `The element can be removed with the delete button on the element (cross icon) or the 'delete' or 'backspace' keys.`,
          resizable: `The element can be scaled with the buttons on the corners of the element.`,
          rotatable: `The element can be rotated with the rotation button at the bottom of the element, the slider in
            the panel and the shortcuts.`,
          untargetable: `The element is locked (so it can not be selected), but underlying elements can be selected.`,
          coating: `The element has a white coating. This results in brighter colors in kraft designs.`,
          spotUv: `The element has spot UV, which results in a glow effect.`,
          addChildElements: `Users can add elements to the selected page.`,
          adjustTrim: `Users can adjust the trim of the design.`,
          changeMaterial: `Users can change the material of the design`,
          isOrderable: `Users are able to move element up/down inside layer (foil, toplayer, background etc)`,
          useRichText: 'Users are able to style parts of a text seperately within the same element.',
          shrinkToFit: 'The text shrinks to fit the textbox during typing.',
          excludeFromPreviews: 'The element is not shown in any generated previews.',
          isVisibleOutsideCropArea: 'The element remains visible outside the design.',
          isSpotUvable: 'Users can choose Spot UV for the element.',
          isExternallyEditable: 'Editable in the PDP',
          fontChangeable: 'The font is changeable in the Editor and PDP',
          fontResizable: 'The font size is resizeable in the Editor and PDP',
          textEditable: 'The text content is editable in the Editor and PDP'
        },
        tags: {
          selectPlaceholder: 'Choose a tag',
          selectDeleteTag: '- Delete Tag -'
        },
        layerTypes: {
          cutThrough: {
            title: 'Cut Through',
            tooltip: 'The element is cut out of the design. The card gets a hole in the shape of the element.'
          },
          cutThroughInverted: {
            title: 'Cut Through Inverted',
            tooltip: 'Everything except the element is cut out of the design.'
          },
          crease: {
            title: 'Crease',
            tooltip: 'The element is creased.'
          },
          perforation: {
            title: 'Perforation',
            tooltip: 'The element is perforated.'
          },
          kissCut: {
            title: 'Kiss Cut',
            tooltip: 'The element is cut through the vinyl layer only. The paper backing stays intact.'
          },
          standardRgb: {
            title: 'RGB (standard)',
            tooltip: 'The element is printed with is original colors.'
          }
        }
      },
      coordinates: {
        x: 'X',
        y: 'Y'
      },
      colorMenu: {
        headerText: 'Colors',
        backHeaderText: 'Back',
        title: 'Choose a color',
        resetColorButtonText: 'Reset color',
        subTitleCustom: 'Used colors',
        subTitleFoil: 'Foil colors',
        subTitleFoilToggle: 'Replace all foil colors',
        subTitleSpotUvToggle: 'Replace all high gloss colors',
        subTitleDesign: 'Design colors',
        subTitleSpotUv: 'spot Uv colors',
        subTitleColorPalette: 'Color palette',
        foilMessage: 'Foil gives a nice shiny effect.',
        specialColorInfoLink: 'More information',
        hasNoFoil: 'Found no elements with foil colors.',
        spotUvMessage: 'High gloss is a luxurious finish for your design.',
        hasNoSpotUv: 'Found no high gloss elements.'
      },
      dimensions: {
        width: 'W',
        height: 'H'
      },
      fontList: {
        usedFonts: 'Used fonts',
        allFonts: 'All fonts'
      },
      order: {
        tooltip: {
          moveToBack: 'To the back',
          moveStepBackward: 'Backward',
          moveStepForward: 'Forward',
          moveToFront: 'To the front'
        }
      },
      borderTrim: {
        dialog: {
          title: 'Choose a trim'
        }
      },
      textInput: {
        header: 'Type a text',
        doneButton: 'Done',
        placeholder: 'Type a text'
      },
      imageLibrary: {
        designImagesTitle: 'Design Images',
        searchInput: {
          placeholder: 'Zoeken in alle afbeeldingen'
        }
      }
    },
    navBar: {
      button: {
        back: 'Back',
        continue: 'Preview'
      },
      tooltip: {
        pageHidden: '(not visible)'
      },
      mobile: {
        button: {
          continue: 'Preview'
        }
      }
    },
    dialog: {
      button: {
        cancel: 'Cancel',
        delete: 'Delete',
        login: 'Login',
        no: 'No',
        ok: 'Ok',
        register: 'Register',
        save: 'Save',
        send: 'Send',
        yes: 'Yes',
        previous: 'Previous',
        saveChanges: 'Save changes'
      },
      update: {
        title: 'Attention',
        message: 'There is an update available, do you want to reload the page?'
      },
      authenticate: {
        loginTabTitle: 'Login',
        loginTabSubtitle: `You've got an account`,
        registerTabTitle: 'Register',
        registerTabSubtitle: 'For new customers'
      },
      loginForm: {
        loginText: 'Log in of',
        registerText: 'maak een account',
        userNameInputTitle: 'E-mail address',
        passwordInputTitle: 'Password',
        passwordForgotten: 'Password forgotten?',
        saveText: 'For editing and saving your design you should login on your account.'
      },
      changeFormat: {
        title: 'Change card format',
        text: `When changing the card format it is important to change the pages to the right pages of the new format.`,
        replaceTitle: 'Change to:',
        presetTitle: 'select preset:',
        pageLabel: 'Page',
        emptyPageOption: 'Let empty',
        oldPageOption: 'old page',
        button: 'Change',
        widthLabel: 'width',
        heightLabel: 'height',
        scaleLabel: 'scale',
        bleedLabel: 'bleed',
        scaleOptions: {
          auto: 'auto',
          width: 'width',
          height: 'height'
        }
      },
      confirm: {
        title: 'Attention!',
        message: 'Are you sure you want to delete the element?'
      },
      dropFoilError: {
        title: 'Oops',
        message: 'This image is unfortunately not suitable for foil. Choose another image.'
      },
      doubleBackgroundImage: {
        message:
          'This action will also remove the current backgroundimage from one or more other pages. Do you want to continue?'
      },
      error: {
        title: 'Oops',
        defaultMessage: 'Something went wrong'
      },
      differentSpotUvTypes: {
        spotUvOnJpgElement:
          'Attention! Do you want a glossy photo? This will remove the high gloss on the other elements.',
        spotUvOnOtherElement:
          'Attention! Do you want high gloss on this element? With this the high gloss on photo elements disappears.'
      },
      specialColor: {
        updatePositionFoilSafety: 'Foil elements can not be placed outside the safety margin.',
        setFoilSafety: `Foil elements can not be placed outside the safety margin.
          Move the element to the inside and try to select the foil color again.`,
        multipleSpecialColor: `Only one foil or spot uv color can be used. Do you want to set all foil elements to {{ colorName }}?`,
        replaceColorText: 'this color'
      },
      loginSaveContinue: {
        saveTitle: 'Save changes',
        loginTitle: 'Save design',
        saveText: 'Do you want to save the last changes?',
        loginText: 'Save the design so you will be able to edit it in future.',
        continueWithoutSaving: 'Continue without saving',
        continueWithoutLogin: 'Continue without logging in',
        noAccountTitle: 'No account yet?'
      },
      recoverPassword: {
        title: 'Password forgotten',
        message: 'Enter your e-mail address and you will receive a link to reset your password.',
        notForgottenTitle: 'Password not forgotten?',
        emailPlaceholderText: 'E-mail address',
        emailRequiredErrorText: 'E-mail address is required',
        invalidEmailErrorText: 'invalid e-mail address'
      },
      recoverPasswordSuccess: {
        title: 'Recover e-mail send',
        message: 'A recover e-mail has been send to:'
      },
      registerForm: {
        registerText: 'Get an account or',
        loginText: 'login',
        advantagesOfAccountText: '',
        mismatchPasswordsMessage: "Oops! Your passwords don't match"
      },
      registerSuccess: {
        title: 'Registration successfully',
        message: "Thanks for registration. Let's start!",
        buttonText: "Let's go!"
      },
      save: {
        formDesignNameTitle: 'Design name',
        warningText: "The design will not be saved to your account because you haven't logged in",
        saveAndContinueTitle: 'Save and continue',
        saveDesignTitle: 'Save design',
        changeDesignNameTitle: 'Change name',
        saveAndContinueButtonText: 'Save and continue',
        saveDesignButtonText: 'Save',
        completedButtonText: 'Done',
        submitErrorNameText: 'Add a name to the design',
        submitErrorLengthText: 'Maximum length exceeded'
      },
      copy: {
        formTitle: 'Make a copy',
        formDesignNameTitle: 'Design name',
        makeCopyButtonText: 'Make a copy',
        submitErrorNameText: 'Add a name to the design',
        submitErrorLengthText: 'Maximum length exceeded'
      },
      updateExistingDesignDialogText: {
        title: 'Overwrite existing design',
        titleInputTitle: 'Title',
        descriptionInputTitle: 'Description',
        saveChangesButtonText: 'Save changes'
      },
      newDesignDialogText: {
        title: 'New design',
        changeCurrentProposedDesignCheckboxText: 'Overwrite current proposed design',
        titleInputTitle: 'Title',
        descriptionInputTitle: 'Description',
        requestButtonText: 'Save for proposal'
      },
      saveDesignInCollectionDialogText: {
        title: 'Save in collection',
        nameInputTitle: 'Name design',
        customerEmailInputTitle: 'E-mail address customer',
        saveButtonText: 'Save'
      },
      importExportJsonDialogText: {
        title: 'JSON',
        importButtonText: 'Import',
        exportButtonText: 'Export',
        jsonImportPlaceholderText: 'Paste your JSON here',
        jsonImportButtonText: 'Start import'
      },
      saveErrorText: {
        titleRequired: 'Title is required',
        maximumLengthExceeded: 'Maximum length exceeded',
        descriptionRequired: 'Description is required',
        nameRequired: 'Name is required',
        emailRequired: 'Email address is required'
      },
      upload: {
        error: {
          title: 'Invalid file format',
          text: 'Only png, jpg, svg, pdf and gif files are supported'
        }
      },
      warning: {
        title: 'Attention!'
      },
      customFormFields: {
        requiredText: 'is required',
        minLengthTextBeforeFigure: 'needs to have minimal',
        minLengthTextAfterFigure: 'characters',
        invalidEmailText: 'Please enter a valid e-mail address'
      },
      contextMenuText: {
        cutText: 'Cut',
        copyText: 'Copy',
        pasteText: 'Paste',
        deleteText: 'Delete'
      }
    },
    untitledText: 'Untitled',
    noImagesFoundText: 'No images were found.',
    forcePortrait: {
      text: 'Rotate your device for a better edit experience',
      buttonText: 'Continue'
    }
  };

  helpMenuItems = [{ text: 'Help', url: '/help' }];

  userMenuItems = [{ text: 'My collection', url: '/collection' }];

  registrationFormFields: CustomFormField[] = [];

  matchPasswordFields: string[] = [];

  basketInterface = { textSingular: 'item', textPlural: 'items', url: '/basket' };

  tooltipShowDelay = 500;

  colorData: Color[] = [];
  spotUvColorData: Color[] = [];
  foilColorData: Color[] = [];
  trimData: Trim[] = [];
  tags: string[] = [];
  materials: Material[] = [];
  themeData = {};

  chat = {
    key: '',
    settings: {},
    position: [] as ChatPosition[]
  };
}

export function reducer(state = new InitialState(), action: ConfigActions): State {
  switch (action.type) {
    case ConfigActionsTypes.LoadConfig: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}

export const selectConfigState = createFeatureSelector<State>('config');

export const getImageBase = createSelector(selectConfigState, (state: State) => state.imgBase);

export const getDesignUrl = createSelector(selectConfigState, (state: State) => state.designUrl);

export const getUpdateExistingDesignUrl = createSelector(
  selectConfigState,
  (state: State) => state.updateExistingDesignUrl
);

export const getImageLibraryUrl = createSelector(selectConfigState, (state: State) => state.imageLibraryUrl);

export const getImageLibrarySearchUrl = createSelector(
  selectConfigState,
  (state: State) => state.imageLibrarySearchUrl
);

export const getImageSearchLimit = createSelector(selectConfigState, (state: State) => state.imageSearchLimit);

export const getFontsUrl = createSelector(selectConfigState, (state: State) => state.fontsUrl);

export const getDesignOtherUserUrl = createSelector(selectConfigState, (state: State) => state.designOtherUserUrl);

export const getDesignToReviewUrl = createSelector(selectConfigState, (state: State) => state.designToReviewUrl);

export const getShowMaterialOption = createSelector(selectConfigState, (config: State) => !!config.materials.length);

export const getChatConfig = createSelector(selectConfigState, (config: State) => config.chat);

export const getShowTrimOption = createSelector(selectConfigState, selectDesign, (config: State, design: Design) => {
  return (
    design.permissions.adjustTrim &&
    !!config.trimData.length &&
    (design.numberOfPages <= 4 ||
      // allow wrongly trimmed designs to be changed back
      design.trimType !== TrimTypes.borderStraight) &&
    // designs that contain cut through elements may not have trim option
    !design.hasCutThroughElements()
  );
});

export const getRegistrationFormFields = createSelector(
  selectConfigState,
  (config: State) => config.registrationFormFields
);
