import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DesignSet } from 'src/app/models/design-set';
import { CanvasElement, ElementType, PageElement } from '../../models';

@Component({
  selector: 'ed-page-thumb',
  templateUrl: 'page-thumb.component.html',
  styleUrls: ['page-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageThumbComponent {
  /**
   * reference to the corresponding store element
   * height should be relative to width to keep ratio of elements on every screen
   */

  elementType = ElementType;

  @Input() thumbSize: number;
  @Input() isThumb = false;

  @Input() canvasId: string;
  @Input() designSet: DesignSet;
  @Input() page: PageElement;
  @Input() showPageBehind = false;

  itemTrackBy(index: number, item: CanvasElement) {
    return item.route.join('');
  }
}
