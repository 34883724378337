import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { LoadImageLibrary, LoadImageLibraryFailure } from './actions';
import { ConfigActionsTypes } from '../config/actions';
import { ImageLibraryService } from './image-library.service';
import { ImageLibrary } from './image-library';

@Injectable()
export class ImageLibraryEffects {
  @Effect()
  loadImageLibrary$: Observable<Action> = this.actions$.pipe(
    ofType(ConfigActionsTypes.LoadConfig),
    switchMap(() =>
      this.libraryService.load().pipe(
        map((imagelibrary: ImageLibrary) => new LoadImageLibrary(imagelibrary)),
        catchError(error => of(new LoadImageLibraryFailure(error)))
      )
    )
  );

  constructor(private libraryService: ImageLibraryService, private actions$: Actions) {}
}
