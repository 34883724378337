import { fabric } from "fabric";
import { BaseElement, PhotoFrameElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/page-element" {
  interface PageElement { }
}

PhotoFrameElement.prototype.createObjectAsync = async function (object?: fabric.Object): Promise<fabric.Object> {
  const element: PhotoFrameElement = this;

  if (!(element instanceof PhotoFrameElement)) {
    return null;
  }

  object ??= new fabric.Rect();

  if (!(object instanceof fabric.Rect)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

PhotoFrameElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: PhotoFrameElement = this;

  if (!(element instanceof PhotoFrameElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    opacity: 1e-323,
    lockMovementX: !element.permissions.isMovable,
    lockMovementY: !element.permissions.isMovable,
  };
}

PhotoFrameElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: PhotoFrameElement = this;

  if (!(element instanceof PhotoFrameElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  const isSmallImage = element.width <= 480 || element.height <= 480;

  const tolerance = 1 + 5e-3;
  const canZoomOut = element.firstChild.zoomLevel / tolerance > element.firstChild.minZoom;

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    "tl": !!element.permissions.isResizable,
    "tr": !!element.permissions.isResizable,
    "br": !!element.permissions.isResizable,
    "bl": !!element.permissions.isResizable,
    "ml": !!element.permissions.isCroppable,
    "mr": !!element.permissions.isCroppable,
    "mt": !!element.permissions.isCroppable,
    "mb": !!element.permissions.isCroppable,
    "mtr": !!element.permissions.isRotatable,
    "ml_imageReplaceControl": !element.firstChild.isVectorImage && !!element.permissions.isInstantReplaceable,
    "remove": !!element.permissions.isRemovable,
    "move": !!element.permissions.isMovable && (element.firstChild.isVectorImage || isSmallImage),
    "moveInner": !!element.firstChild.permissions.isMovable && !!element.permissions.isCroppable && element.firstChild.isCropped,
    "zoomPanel": !!element.permissions.isResizable && !!element.permissions.isCroppable,
    "zoomInnerMinus": !!element.permissions.isResizable && canZoomOut && !!element.permissions.isCroppable,
    "zoomInnerMinusDisabled": !!element.permissions.isResizable && !canZoomOut && !!element.permissions.isCroppable,
    "zoomInnerPlus": !!element.permissions.isResizable && !!element.permissions.isCroppable,
  };
}
