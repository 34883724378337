import { CanvasElement, SpecialColorElement } from './canvas';
import { PageElement } from './page-element';
import { TextElement } from './text-element';
import { ImageElement } from './image-element';
import { Spread } from './spread';
import { Font } from './font';
import { countBy, entries, flow, head, last, maxBy, partialRight, uniq } from 'lodash-es';
import { PhotoFrameElement } from './photo-frame-element';
import { BoxElement } from './box-element';
import { InlineTextElement } from './inline-text-element';
import { EditorPermissions, ElementPermissions } from './permissions';
import { TrimTypes } from './trim';
import { CategoryImage } from './category-image';
import { Color, FoilTypes } from './color';
import { getFlatChildren } from '../utils/element.utils';
import { setBackSidePage } from '../utils/spreads.utils';

export enum View {
  pages = 'pages',
  productionSpreads = 'productionSpreads',
  userSpreads = 'userSpreads',
  card = 'card'
}

export enum Fold {
  none = 'none',
  horizontal = 'horizontal',
  vertical = 'vertical',
  zigzag = 'zigzag'
}

export type Labels = {
  [name in View]?: string[][];
};

export class KeyLabels {
  [key: number]: string[][];
}

export const defaultMaterialType = 'default';

export class Material {
  type = defaultMaterialType;
  needsCoating = false;
  isFoilable = true;
  isSpotUvable = true;
  isFoldable = true;
  image?: string;
  name?: string;
  disabledColors?: string[] = [];
}

/**
 * This class represents the Design.
 */
export class Design {
  _version = 1;
  id: string;
  setId = -1; // TODO: should not have a default
  active = true; // TODO: should not default be true

  // deprecated:
  title: string;
  description: string;
  isProposedDesign = false;
  user_design_title: string;
  user_design_id: number;

  useAbsoluteFontSize = false;
  spreads: Array<Spread> = [];
  foldType: Fold;
  view: View;
  trimType: TrimTypes;
  labels: Labels = { pages: [], userSpreads: [], card: [] };
  material: Material;
  specs: string;
  adjustTrimPermissionWasChanged = false;

  // these are the setElement/functionPermissions for the design not the elementPermissions for the design
  editorPermissions: EditorPermissions;

  permissions: ElementPermissions = {
    adjustTrim: true,
    changeMaterial: true,
    useRichText: false
  };

  // TODO not sure if it's part of design or designSet
  designFonts: Font[] = [];
  customColors: Color[] = [];
  designImages: CategoryImage[] = [];
  designColors: Color[] = [];

  get route(): number[] {
    return [this.setId];
  }

  private _pages: Array<PageElement> = [];

  // TODO: deprecated use spreads instead
  set pages(pages: Array<PageElement>) {
    this._pages = pages;
  }

  get pages(): Array<PageElement> {
    return this._pages;
  }

  /**
   * number of pages regardless of the view
   */
  get numberOfPages(): number {
    return this.spreads.reduce((acc, spread) => acc + spread.pages.length, 0);
  }

  // TODO: deprecated use spreads instead
  addPage(page: PageElement, id?: number) {
    page.id = id ? id : this.newId;
    page.parent = this;
    this._pages.push(page);
  }

  get selectedElement(): CanvasElement {
    const selectedChild = this.pages.find(element => !!element.selectedElement);
    return selectedChild ? (selectedChild.selectedElement as CanvasElement) : undefined;
  }

  getDesignFonts(): Font[] {
    return uniq(this.getFontProperty('font', 'fonts'))
      .sort()
      .map(font => new Font(font));
  }

  getFontProperty(textProp: keyof TextElement, inlineTextProp: keyof InlineTextElement) {
    // not compatible with box elements/ nested text elements
    const inlineTextPropValues = this.pages
      .flatMap(page => page.children.filter(el => el.isInlineText()))
      .flatMap((el: InlineTextElement) => el[inlineTextProp]);
    const textPropValues = this.pages.flatMap(page => page.children.filter(el => el.isText())).map(el => el[textProp]);
    return [...textPropValues, ...inlineTextPropValues];
  }

  getMostUsedProperty(textProp: keyof TextElement, inlineTextProp: keyof InlineTextElement) {
    return flow(countBy, entries, partialRight(maxBy, last), head)(this.getFontProperty(textProp, inlineTextProp));
  }

  get visiblePage(): PageElement {
    return this.pages.find(page => page.visible) as PageElement;
  }

  get newId() {
    return this._pages.reduce((maxId, nextPage) => Math.max(maxId, nextPage.id), 0) + 1;
  }

  get spreadView() {
    return this.view !== View.pages;
  }

  get hasRoundedCorners() {
    return this.trimType === TrimTypes.borderRound;
  }

  /**
   * true if caret is placed or text is (partially) selected for typing
   */
  get editingInlineText() {
    return (
      this.selectedElement &&
      this.selectedElement.isInlineText() &&
      !(this.selectedElement as InlineTextElement).editFullRange
    );
  }

  updateView(view: View) {
    this.view = view;
    if (this.spreadView) {
      this.spreads.forEach(spread => spread.pagesToSpread());
      this._pages = this.spreads.map(spread => spread.spreadPage);
    } else {
      this.spreads.forEach(spread => spread.spreadToPages());
      this._pages = [].concat(...this.spreads.map(spread => spread.pages)).sort((a, b) => a.order - b.order);
    }
    setBackSidePage(this._pages);

    this.pages.filter(page => !page.permissions.isHidden).forEach((page, index) => (page.visible = index === 0));
  }

  updatePages() {
    if (this.spreadView) {
      this._pages = this.spreads.map(spread => spread.spreadPage);
    } else {
      this._pages = [].concat(...this.spreads.map(spread => spread.pages)).sort((a, b) => a.order - b.order);
    }
  }

  select(route: Array<number>): CanvasElement {
    const element = this.getElement(route);

    // only clean up design if a new element is selected
    if (this.selectedElement && route.join('') !== this.selectedElement.route.join('')) {
      this.cleanUpAndDeselectElements();
    }

    element.select();

    return element;
  }

  removeElement(route: Array<number>): void {
    const siblings = this.getElement(route).parent.children;
    const index = this.getIndex(siblings, route[0]);
    siblings.splice(index, 1);
  }

  addElement(parentRoute: Array<number>, element: CanvasElement) {
    this.cleanUpAndDeselectElements();
    const parent = this.getElement(parentRoute) as PageElement | PhotoFrameElement | BoxElement;
    parent.addElement(element);
  }

  get foilElements(): SpecialColorElement[] {
    return getFlatChildren(this.pages).filter(
      el => !!(el as SpecialColorElement).foilType && !el.isPhotoFrameChild
    ) as SpecialColorElement[];
  }

  get spotUvElements(): SpecialColorElement[] {
    return getFlatChildren(this.pages).filter(
      el => (el as SpecialColorElement).spotUv && !el.isPhotoFrameChild
    ) as SpecialColorElement[];
  }

  get hasSpotUv(): boolean {
    return getFlatChildren(this.pages).filter(el => !!(el as SpecialColorElement).spotUv).length > 0;
  }

  get foilType(): FoilTypes {
    const foilElements = this.foilElements;
    return foilElements && foilElements.length > 0 ? foilElements[0].foilType : undefined;
  }

  addSpread(spread: Spread) {
    this.spreads.push(spread);
  }

  getElement(route: Array<number>): CanvasElement {
    route = route.slice(0, -1);
    const pageId = route[route.length - 1];
    const page = this.pages.find(_page => _page.id === pageId);
    if (page) {
      return page.getElement(route) as CanvasElement;
    }
  }

  getPageElement(id: number): PageElement {
    const element = this.getElement([id, this.setId]);
    if (!element.isPage()) {
      throw new Error('element is not a page element');
    }
    return element as PageElement;
  }

  getTextElement(route: Array<number>): TextElement {
    const element = this.getElement(route);
    if (!element.isText()) {
      throw new Error('element is not a text element');
    }
    return element as TextElement;
  }

  getImageElement(route: Array<number>): ImageElement {
    const element = this.getElement(route);
    if (element.isImage() || element.isBackgroundImage()) {
      return element as ImageElement;
    }
    throw new Error('element is not a image element');
  }

  getBaseSiblings(route: Array<number>): Array<CanvasElement> {
    return this.getElement(route).parent.children;
  }

  getIndex(location: CanvasElement[], elementId: number) {
    return location.findIndex(c => c.id === elementId);
  }

  cleanUpAndDeselectElements() {
    this.pages.map(page => {
      page.cleanUpElements();
      page.deselectAll();
    });
  }

  hasCutThroughElements() {
    return getFlatChildren(this.pages).some(elem => elem.isCutThrough || elem.isCutThroughInverted);
  }
}
