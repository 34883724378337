import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { View } from '../../../models';
import { CanvasActions } from '../../../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { DesignSet } from '../../../models/design-set';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-page-view',
  templateUrl: 'page-view.component.html',
  styleUrls: ['page-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageViewComponent {
  init = false;
  menuOpened = false;
  views = View;
  view: View;

  @Input() set designSet(designSet: DesignSet) {
    if (designSet.activeDesign) {
      const design = designSet.activeDesign;
      if (!this.init && designSet.init && design.visiblePage) {
        // enable change detection for this component only after initialization of design,
        // because initialisation of design is fast after loading and this causes ExpressionChangedAfterItHasBeenCheckedError
        this.init = true;
        this.changeDetectorRef.reattach();
      }
      this.view = design.view;
    }
  }

  get buttonText() {
    switch (this.view) {
      case View.card:
        return this.getTextService.text.design.pageViewMenu.cardView;
      case View.pages:
        return this.getTextService.text.design.pageViewMenu.pageView;
      case View.userSpreads:
        return this.getTextService.text.design.pageViewMenu.spreadView;
      case View.productionSpreads:
        return this.getTextService.text.design.pageViewMenu.productionView;
    }
  }

  constructor(
    private store: Store<AppState>,
    public getTextService: GetTextService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.changeDetectorRef.detach();
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  toggleView(view: View) {
    this.store.dispatch(new CanvasActions.ToggleView(view));
  }
}
