import { EditorAction } from '../action';
import { ADD_TEXT_INLINE } from './canvas.action.types';

export class AddTextInline implements EditorAction {
  readonly type = ADD_TEXT_INLINE;
  readonly undoable = true;
  readonly save = true;

  constructor(public spanText: string) {}
}
