import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, zip } from 'rxjs';
import { first, tap, map } from 'rxjs/operators';
import * as Continue from './actions';
import * as fromContinue from './reducer';
import * as fromPermissions from '../reducers/permissions.reducer';
import { ClientError } from '../models/client-error';
import { DialogButtonText, LoginSaveContinueDialogText } from '../models/text';
import * as fromAuth from '../auth/reducer';

@Component({
  selector: 'ed-login-save-continue-dialog',
  templateUrl: 'login-save-continue-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginSaveContinueDialogComponent implements OnInit, OnDestroy {
  title: string;
  text: string;
  warning: string;
  warningText: string;
  configSubscription: Subscription;
  continueButtonText$: Observable<string>;
  isLoggedIn$: Observable<boolean>;
  pending$: Observable<boolean>;
  disable: boolean;
  errors$: Observable<ClientError[]>;
  dataLayerIdPrefixLeave = 'popup-leave-';
  dataLayerIdPrefixSave = 'popup-save-change-';

  constructor(
    public dialogRef: MatDialogRef<LoginSaveContinueDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { text: { dialogText: LoginSaveContinueDialogText; buttonText: DialogButtonText } },
    public store$: Store<fromContinue.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());
    this.isLoggedIn$ = this.store$.pipe(select(fromAuth.isLoggedIn));
    this.pending$ = this.store$.pipe(
      select(fromContinue.getSaveContinuePending),
      tap(pending => (this.disable = pending))
    );
    this.errors$ = this.store$.pipe(select(fromContinue.getError));

    this.configSubscription = zip(this.store$.pipe(select(s => s.config)), this.isLoggedIn$).subscribe(
      ([config, isLoggedIn]) => {
        this.title = isLoggedIn ? this.data.text.dialogText.saveTitle : this.data.text.dialogText.loginTitle;
        this.text = isLoggedIn ? this.data.text.dialogText.saveText : this.data.text.dialogText.loginText;
      }
    );

    this.continueButtonText$ = this.isLoggedIn$.pipe(
      map(isLoggedIn =>
        isLoggedIn ? this.data.text.dialogText.continueWithoutSaving : this.data.text.dialogText.continueWithoutLogin
      )
    );
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
  }

  onNoClick(): void {
    this.store$.dispatch(new Continue.CloseLoginContinueDialog());
  }

  close(): void {
    this.dialogRef.close();
  }

  continue(): void {
    this.store$.dispatch(new Continue.ContinueWithoutSave());
  }

  login() {
    this.store$.dispatch(new Continue.Login());
  }

  save() {
    this.store$.dispatch(new Continue.Save());
  }

  openRegisterDialog() {
    this.store$.dispatch(new Continue.Register());
  }
}
