import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { fabric } from 'fabric';
import { cloneDeep, debounce } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { CanvasActions } from 'src/app/actions';
import { ImageLibraryType } from 'src/app/image-library/image-library';
import { BaseElement, CanvasElement, CoatingType, Design, EditorSelection, ElementType, Font, PageElement, View } from 'src/app/models';
import { DesignSet } from 'src/app/models/design-set';
import { AppState } from 'src/app/reducers';
import { getAddImageAsPhotoFrame } from 'src/app/reducers/permissions.reducer';
import { ConfigService, GetTextService, ImageUploadService } from 'src/app/services';
import { ImageData, UPLOAD_URL } from 'src/app/services/image-upload.service';
import { ContextMenuService } from 'src/app/shared/context-menu/context-menu.service';
import { ErrorDialogComponent } from 'src/app/shared/dialogs';
import { NAVBAR_HEIGHT, TOOLBAR_HEIGHT_GT_SM } from 'src/app/shared/layout-constants';
import { fileExtensions } from 'src/app/utils/element.utils';
import { GradientData, gradients } from 'src/app/utils/gradient.utils';
import { AlignGuidelines } from '../alignment-guidelines/aligning';
import * as FabricCanvasActions from "./actions";
import { X_BOUND_BOX_ROUTE, X_COAT_ROUTE, X_CROP_ROUTE, X_CUT_THROUGH_ROUTE, X_FOIL_IMAGE_ROUTE, X_FOIL_ROUTE, X_REPLACE_CONTROL_ROUTE, X_ROUTE, X_SPOT_UV_ROUTE } from './fabric/constants/object-keys';
import { IMAGE_REPLACE_SVG } from './fabric/controls/image-replace.control';
import { ICanvasOptions } from './models/canvas-options';
import { ViewportBoundaries } from './models/viewport-boundaries';
import * as CanvasElementUtils from './utils/canvas-element.utils';
import { FontUtils } from './utils/font.utils';
import * as GradientUtils from './utils/gradient.utils';
import { InlineTextElementUtils } from './utils/inline-text-element.utils';
import { calculatePosition, clearObjectTempKeys, onObjectTransformContainObject, onObjectTransformContainWithinObject, onObjectTransform_TransformObject, setTransformMatrix } from './utils/object-event.utils';
import { getAngle, setPositionAfterZooming } from './utils/object.utils';
import { TextboxUtils } from './utils/textbox.utils';

@Component({
  selector: 'ed-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  providers: [ImageUploadService]
})
export class CanvasComponent implements AfterViewInit, OnDestroy {
  @ViewChild('downLg', { static: false })
  downLg: ElementRef;

  @ViewChild('canvasContainer', { static: false })
  canvasContainer: ElementRef;

  @Input()
  canvasId: string;

  canvas: fabric.Canvas;

  init: boolean;

  private renderAbortController: AbortController;

  private addImageAsPhotoFrame: boolean;

  private fontLibrary: Font[] = [];

  protected readonly unsubscribe$ = new Subject<void>();

  cutThroughBehindPages: PageElement[] = [];

  private _options: ICanvasOptions;

  private keysPressed: [] = [];

  private alignGuidelines: AlignGuidelines;

  private keydownListener: (event: KeyboardEvent) => void;
  private keyupListener: (event: KeyboardEvent) => void;

  @Input()
  set options(options: ICanvasOptions) {
    this._options = options;

    if (this.canvas) {
      this.canvas.setDimensions({
        width: this.options.width,
        height: this.options.height,
      });
      this.zoomCanvasToFit();
    }
  }

  get options(): ICanvasOptions {
    return this._options;
  }

  @Input()
  designSet: DesignSet;

  @Input()
  design: Design;

  private _page: PageElement;

  @Input()
  set page(page: PageElement) {
    let isViewChanged = this.page?.parent?.view !== page?.parent?.view;
    let isRouteChanged = this.page?.route?.toString() !== page?.route?.toString();

    this._page = page;
    this.cutThroughBehindPages = this.getCutThroughBehindPages(page);

    if (this.canvas) {
      if (isViewChanged || isRouteChanged) {
        this.canvas.clear();
        this.zoomCanvasToFit();
      }
      if (this.options.keepViewport) {
        this.zoomCanvasToFit();
      }
      this.debounceRenderPageAsync(page);
    }
  }

  get page(): PageElement {
    return this._page;
  }

  @Input()
  set zoom(zoom: number) {
    this.zoomCanvasToCenter(zoom);
  }

  get zoom(): number {
    return this.canvas.getZoom();
  }

  get zoomToFit() {
    return Math.min(
      (this.canvas.width - (this.options.marginLeft ?? 0) * 2) / this.page.width,
      (this.canvas.height - (this.options.marginTop ?? 0) * 2) / this.page.height,
      this.designSet.pixelsPerMm * 1.5
    );
  }

  @Output()
  zoomChange = new EventEmitter<number>();

  @Input()
  set viewportTransform(viewportTransform: number[]) {
    if (this.canvas && viewportTransform?.length) {
      this.canvas.viewportTransform = viewportTransform;
      this.viewportTransformChange.emit(viewportTransform);

      let viewportBoundaries = this.canvas.calcViewportBoundaries();
      this.viewportBoundariesChange.emit(viewportBoundaries);

      this.canvas.requestRenderAll();
    }
  }

  @Input()
  isPageThumb = false;

  preserveViewportTransform: boolean = true;

  get viewportTransform() {
    return this.canvas.viewportTransform;
  }

  @Output()
  viewportTransformChange = new EventEmitter<number[]>();

  get viewportBoundaries(): ViewportBoundaries {
    return this.canvas.calcViewportBoundaries();
  }

  @Output()
  viewportBoundariesChange = new EventEmitter<ViewportBoundaries>();

  get relativeWidth(): number {
    return this.canvas.width * this.zoom;
  }

  get relativeHeight(): number {
    return this.canvas.height * this.zoom;
  }

  get pageRelativeWidth(): number {
    return this.page.width * this.zoom;
  }

  get pageRelativeHeight(): number {
    return this.page.height * this.zoom;
  }

  get pageRelativeLeft(): number {
    return ((this.viewportBoundaries?.tl?.x ?? 0) * this.zoom * - 1) + (this.relativeWidth - this.pageRelativeWidth) / 2;
  }

  get pageRelativeTop(): number {
    return ((this.viewportBoundaries?.tl?.y ?? 0) * this.zoom * - 1) + (this.relativeHeight - this.pageRelativeHeight) / 2;
  }

  get pageRelativeRight(): number {
    return this.pageRelativeLeft + this.pageRelativeWidth;
  }

  get pageRelativeBottom(): number {
    return this.pageRelativeTop + this.pageRelativeHeight;
  }

  get pageRelativeCenterX(): number {
    return this.pageRelativeLeft + this.pageRelativeWidth / 2;
  }

  get pageRelativeCenterY(): number {
    return this.pageRelativeTop + this.pageRelativeHeight / 2;
  }

  get pageInvertedRelativeRight(): number {
    return ((this.relativeWidth - (this.viewportBoundaries?.br?.x ?? 0) * this.zoom) * -1) + (this.relativeWidth - this.pageRelativeWidth) / 2;
  }

  get pageInvertedRelativeBottom(): number {
    return ((this.relativeHeight - (this.viewportBoundaries?.br?.y ?? 0) * this.zoom) * -1) + (this.relativeHeight - this.pageRelativeHeight) / 2;
  }

  get gridCellSize(): number {
    return this.configService.gridSize;
  }

  @Output()
  objectModifying = new EventEmitter<fabric.Object>();

  @Output()
  objectModified = new EventEmitter<fabric.Object>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly contextMenuService: ContextMenuService,
    private readonly imageUploadService: ImageUploadService,
    private readonly getTextService: GetTextService,
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly configService: ConfigService
  ) {
    this.setupPermissionSubscriptions();
    this.setupFontLibrarySubscriptions();
    this.setupFabricCanvasActionSubscriptions();

    this.keydownListener = (event: KeyboardEvent) => this.onCanvasKeyDown(event);
    this.keyupListener = (event: KeyboardEvent) => this.onCanvasKeyUp(event);

    this.objectModified.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.clearCanvasObjectsTempKeys();
    });

    // Prevent default context menu when right click on object
    const body = this.document.getElementsByTagName('body')?.[0];
    body?.addEventListener('contextmenu', (e: any) => e.srcElement.className.includes("context-menu-dialog") && e.preventDefault());
  }

  ngAfterViewInit(): void {
    // Setup canvas
    let canvasElement = document.createElement('canvas');
    canvasElement.id = this.canvasId;

    let nativeCanvasContainer = this.canvasContainer.nativeElement;
    nativeCanvasContainer.append(canvasElement);

    let canvas = new fabric.Canvas(canvasElement.id, {
      renderOnAddRemove: false,
      imageSmoothingEnabled: false,
      altActionKey: "none",
      uniScaleKey: "none",
      selection: false,
      preserveObjectStacking: true,
      stopContextMenu: true,
      fireRightClick: true,
    });

    // Canvas mouse events
    canvas.on('mouse:wheel', (event: fabric.IEvent<WheelEvent>) => this.onCanvasMouseWheel(event));

    // Canvas text events
    canvas.on('text:editing:entered', (event: fabric.IEvent<MouseEvent>) => this.onCanvasTextEditingEntered(event));
    canvas.on('text:editing:exited', (event: fabric.IEvent<MouseEvent>) => this.onCanvasTextEditingExited(event));
    canvas.on('text:changed', (event: fabric.IEvent<KeyboardEvent>) => this.onCanvasTextChanged(event));

    // Canvas object events
    canvas.on('object:mouseup', (event: fabric.IEvent<MouseEvent>) => this.onObjectMouseUp(event));
    canvas.on('object:moving', (event: fabric.IEvent<MouseEvent>) => this.onObjectMoving(event));
    canvas.on('object:rotating', (event: fabric.IEvent<MouseEvent>) => this.onObjectRotating(event));
    canvas.on('object:scaling', (event: fabric.IEvent<MouseEvent>) => this.onObjectScaling(event));
    canvas.on('object:resizing', (event: fabric.IEvent<MouseEvent>) => this.onObjectScaling(event));
    canvas.on('object:removed', (event: fabric.IEvent<MouseEvent>) => this.onCanvasObjectRemoved(event));
    canvas.on('object:modified', (event: fabric.IEvent<MouseEvent>) => {
      this.onObjectModified(event);
      this.clearCanvasObjectsTempKeys();
    });

    // Canvas object (custom) events
    canvas.on('object:remove', (event: fabric.IEvent<MouseEvent>) => this.onObjectRemove(event));
    canvas.on('object:zoomInnerMinus', (event: fabric.IEvent<MouseEvent>) => this.onObjectZoomInner(event));
    canvas.on('object:zoomInnerPlus', (event: fabric.IEvent<MouseEvent>) => this.onObjectZoomInner(event));

    this.setup_canvasSelection(canvas);
    this.setup_onCanvasMouse_handleTouchEvents(canvas);
    this.setup_onCanvasMouseOver_renderAdjacentObjects(canvas);
    this.setup_onCanvasDragover_renderAdjacentObjects(canvas);
    this.setup_onCanvasDrop_upsertImage(canvas);

    // Custom events
    canvas.on('object:before:imageReplace', (event: fabric.IEvent<MouseEvent>) => this.onObjectBeforeImageReplace(event));
    canvas.on('object:imageReplace', (event: fabric.IEvent<MouseEvent>) => this.onObjectImageReplace(event));

    document.addEventListener('keydown', this.keydownListener);
    document.addEventListener('keyup', this.keyupListener);

    // Setup canvas align guidelines
    this.alignGuidelines = new AlignGuidelines({
      canvas,
      highlightObjectOptions: {
        strokeColor: '#8B3DFF',
      },
      aligningOptions: {
        strokeColor: '#D53AC5',
      },
      ignoreCanvasObjTypes: [
        { key: 'elementType', value: ElementType.image },
        { key: 'elementType', value: ElementType.background },
        { key: 'elementType', value: ElementType.backgroundImage },
      ],
      ignoreSelectedObjTypes: [
        { key: 'elementType', value: ElementType.image }
      ]
    });
    this.alignGuidelines.init();

    // Init canvas
    this.canvas = canvas;
    this.init = true;

    // Trigger canvas update
    this.options = this.options;
    this.page = this.page;
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.keydownListener);
    document.removeEventListener('keyup', this.keyupListener);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  trackById(index: number, item: CanvasElement) {
    return item.id;
  }

  //#region Setup subscriptions

  private setupPermissionSubscriptions() {
    this.store.pipe(
      select(getAddImageAsPhotoFrame),
      takeUntil(this.unsubscribe$),
    ).subscribe((addImageAsPhotoFrame) => {
      this.addImageAsPhotoFrame = addImageAsPhotoFrame;
    });
  }

  private setupFontLibrarySubscriptions() {
    this.store.pipe(
      select(s => s.fontlibrary.fontlibrary),
      takeUntil(this.unsubscribe$),
    ).subscribe((fontLibrary) => {
      this.fontLibrary = fontLibrary;

      if (this.page) {
        this.debounceRenderPageOnFontLoadAsync(this.page)
      }
    });
  }

  private setupFabricCanvasActionSubscriptions() {
    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_FAMILY),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontFamily }: FabricCanvasActions.ChangeTextboxFontFamily) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      let element = this.findElement(object[X_ROUTE]);
      if (!(element.isInlineText())) {
        return;
      }

      this.removeFoilObject(object[X_ROUTE]);

      object.data.element.text[0].lines[0].textSpans[0].font = fontFamily;

      object.set({ fontFamily: FontUtils.getFontFamily(object.data.fontLibrary, fontFamily) });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_SIZE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontSize }: FabricCanvasActions.ChangeTextboxFontSize) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontSize });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_STYLE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontStyle }: FabricCanvasActions.ChangeTextboxFontStyle) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontStyle });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_WEIGHT),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontWeight }: FabricCanvasActions.ChangeTextboxFontWeight) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontWeight });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_LINE_HEIGHT),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, lineHeight }: FabricCanvasActions.ChangeTextboxLineHeight) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ lineHeight });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_TEXT_ALIGN),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, textAlign }: FabricCanvasActions.ChangeTextboxTextAlign) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ textAlign });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_UNDERLINE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, underline }: FabricCanvasActions.ChangeTextboxUnderline) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ underline });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_PHOTOFRAME_ZOOM),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, zoomLevel }: FabricCanvasActions.ChangePhotoFrameZoom) => {
      let zoomScale: number;

      let object = this.findObject(route);

      if (!object)
        return;

      let element = this.findElement(route);

      if (!element.isPhotoFrame())
        return;

      let childElement = element.firstChild;

      zoomLevel = Math.min(Math.max(zoomLevel, childElement.minZoom), childElement.maxZoom)

      if (childElement.isImage())
        zoomScale = zoomLevel / childElement.zoomLevel

      if (!zoomScale || zoomScale === 1)
        return;

      //@ts-expect-error
      this.onObjectZoomInner({ transform: { target: object, 'zoomScale': zoomScale } });
    });
  }

  //#endregion

  //#region Setup (canvas)

  private setup_canvasSelection(canvas: fabric.Canvas) {
    let mouseDownActiveObject: fabric.Object;
    let mouseDownTargetCorner: boolean;
    let mouseUpDeselectActiveObject: boolean;

    canvas.on("mouse:down:before", (event: fabric.IEvent<MouseEvent>) => {
      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      console.log('element clicked, mouse down:before: ',target)
      if (!route) return;

      mouseDownActiveObject = canvas.getActiveObject();
      mouseDownTargetCorner = !!target?._findTargetCorner(event.pointer);

      mouseUpDeselectActiveObject = true;
      setTimeout(() => {
        mouseUpDeselectActiveObject = false;
      }, 150);
    });

    canvas.on('mouse:up', (event: fabric.IEvent<MouseEvent>) => {
      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      console.log('element clicked, mouse up: ',target)
      const element = this.findElement(route);
      if (!element || !element.selected || element.isText() || element.isInlineText()) return;

      switch (event.button) {
        case 1: {
          if (!mouseDownTargetCorner && target === mouseDownActiveObject && mouseUpDeselectActiveObject) {
            this.onObjectDeselected(Object.assign(event, { target: event.target }));
            canvas.discardActiveObject(event.e);
          }
          break;
        }
      }
    });

    canvas.on('selection:created', (event: fabric.IEvent<MouseEvent>) => {
      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:updated', (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
        canvas.discardActiveObject(event.e);
      }

      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        const route: number[] = selectedTarget?.[X_ROUTE];
        if (!route) return;

        const element = this.findElement(route);
        if (!element || element.isBackground()) return;

        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:cleared', async (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
        canvas.discardActiveObject(event.e);
      }
    });
  }

  private setup_onCanvasMouse_handleTouchEvents(canvas: fabric.Canvas) {
    if (!this.downLg) {
      return;
    }

    const that = this;

    const touchPoints: fabric.Point[] = [];
    const objectsOptions: { object: fabric.Object, options: fabric.IObjectOptions }[] = [];

    canvas.on('mouse:move', (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ('TouchEvent' in window && event.e instanceof TouchEvent) {
        switch (event.e.touches.length) {
          case 1: {
            if (canvas.getActiveObjects().includes(event.target)) {
              break;
            }

            disablePreserveViewport();
            disableActiveObjectEvents();

            const prevTouchPoint = touchPoints[0];
            touchPoints[0] = new fabric.Point(event.e.touches[0].clientX, event.e.touches[0].clientY);

            if (!prevTouchPoint) {
              break;
            }

            // Drag (pan)
            const dPoint = touchPoints[0].subtract(prevTouchPoint);
            this.setCanvasViewportTransform(this.canvas.getZoom(), dPoint.x, dPoint.y);

            break;
          }

          case 2: {
            disablePreserveViewport();
            disableActiveObjectEvents();

            const prevTouchPoint0 = touchPoints[0];
            touchPoints[0] = new fabric.Point(event.e.touches[0].clientX, event.e.touches[0].clientY);

            const prevTouchPoint1 = touchPoints[1];
            touchPoints[1] = new fabric.Point(event.e.touches[1].clientX, event.e.touches[1].clientY);

            if (!prevTouchPoint0 || !prevTouchPoint1) {
              break;
            }

            const prevMidPoint = prevTouchPoint0.midPointFrom(prevTouchPoint1);
            const midPoint = touchPoints[0].midPointFrom(touchPoints[1]);

            // Zoom (pinch)
            const prevDistance = prevTouchPoint0.distanceFrom(prevTouchPoint1);
            const distance = touchPoints[0].distanceFrom(touchPoints[1]);

            const zoom = this.canvas.getZoom() * .999 ** ((prevDistance - distance) / this.canvas.getZoom());
            this.setCanvasZoom(midPoint, zoom);

            // Drag (pan)
            const dPoint = midPoint.subtract(prevMidPoint);
            this.setCanvasViewportTransform(zoom, dPoint.x, dPoint.y);

            break;
          }
        }
      }
    });

    canvas.on("mouse:up", (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ('TouchEvent' in window && event.e instanceof TouchEvent) {
        enablePreserveViewport();
        enableActiveObjectEvents();

        // Clear temp variables
        touchPoints.splice(0, touchPoints.length);
        objectsOptions.splice(0, objectsOptions.length);

        // Zoom to viewport
        const zoom = this.canvas.getZoom();
        if (zoom < this.zoomToFit) {
          this.zoomCanvasToFit();
        }
      }
    });

    function enableActiveObjectEvents() {
      for (const { object, options } of objectsOptions) {
        object.set(options);
      }
    }

    function disableActiveObjectEvents() {
      for (const object of canvas.getActiveObjects()) {
        objectsOptions.push({
          object,
          options: {
            lockMovementX: object.lockMovementX,
            lockMovementY: object.lockMovementY,
          },
        });

        // TODO: Investigate. Setting `activeObject.evented = false` continues to trigger 'moving' event.
        object.set({
          lockMovementX: true,
          lockMovementY: true,
        });
      }
    }

    function enablePreserveViewport() {
      that.preserveViewportTransform = true;
    }

    function disablePreserveViewport() {
      that.preserveViewportTransform = false;
    }
  }

  private setup_onCanvasMouseOver_renderAdjacentObjects(canvas: fabric.Canvas) {
    canvas.on('mouse:over', (e: fabric.IEvent<MouseEvent>) => {
      let target = e.target;
      if (target) {
        this.createBoundBoxObject(target);
        this.createReplaceControlObject(target);
      }
    });

    canvas.on('mouse:out', (e: fabric.IEvent<MouseEvent>) => {
      // @ts-expect-error
      let nextTarget = e.nextTarget;
      let target = e.target;

      if (
        !target ||
        !target[X_ROUTE] ||
        !nextTarget ||
        !nextTarget[X_REPLACE_CONTROL_ROUTE] ||
        !(nextTarget[X_REPLACE_CONTROL_ROUTE].toString() === target[X_ROUTE].toString())
      ) {
        this.deleteBoundBoxObjects();
        this.deleteReplaceControlObjects();
      }
    });

    canvas.on("before:render", (e: fabric.IEvent<MouseEvent>) => {
      canvas.getObjects()
        .filter((o) => o[X_REPLACE_CONTROL_ROUTE])
        .forEach((o) => {
          let size = 36 / this.canvas.getZoom();
          let { nLeft, nTop, angle, width, height, centerPoint } = o.data;
          let rPos = calculatePosition(nLeft - size / 2, nTop - size / 2, angle, centerPoint);

          o.set({
            left: rPos.x,
            top: rPos.y,
            scaleX: size / width,
            scaleY: size / height,
            angle: angle,
          });
          o.setCoords();
        });
    });
  }

  private setup_onCanvasDragover_renderAdjacentObjects(canvas: fabric.Canvas) {
    let prevDragoverTarget: fabric.Object;

    canvas.on('dragover', (e: fabric.IEvent<MouseEvent>) => {
      let target = e.target;
      if (!target || target !== prevDragoverTarget) {
        this.deleteBoundBoxObjects();
      }

      if (target) {
        let element = this.findElement(target[X_ROUTE]);
        if (element && element.isPhotoFrame()) {
          this.createBoundBoxObject(target);
        }
      }

      prevDragoverTarget = target;
    });

    canvas.on('dragleave', (e: fabric.IEvent<MouseEvent>) => {
      this.deleteBoundBoxObjects();
    });
  }

  private setup_onCanvasDrop_upsertImage(canvas: fabric.Canvas) {
    canvas.on('drop', (e: fabric.IEvent<MouseEvent>) => {
      // @ts-expect-error
      let dataTransfer = e.e?.dataTransfer;
      let serializedData = dataTransfer?.getData('text');
      if (!serializedData) {
        return;
      }

      let data = JSON.parse(serializedData);
      let { sid, url, height, width, isFoilable, isSvg, libraryType, droppable } = data;

      if (droppable !== 'droppable') {
        return;
      }

      e.e.preventDefault();

      switch (libraryType) {
        // Upsert: background image
        case ImageLibraryType.background: {
          let backgroundElement = this.page.children.find((el) => el.isBackground());
          let hasBackgroundImageElement = backgroundElement?.children.some((el) => el.isBackgroundImage());

          if (hasBackgroundImageElement) {
            this.store.dispatch(new CanvasActions.ChangeBackgroundImage(width, height, sid, url));
          } else {
            this.store.dispatch(new CanvasActions.AddBackgroundImage(width, height, sid, url));
          }
          break;
        }

        // Upsert: photoFrame image, image
        case ImageLibraryType.image: {
          let hoverObject = canvas.findTarget(e.e, true);
          let hoverElement = this.findElement(hoverObject?.[X_ROUTE]);

          if (!hoverElement || !(hoverElement.isImage() || hoverElement.isPhotoFrame()) || hoverElement.parent?.isBackground()) {
            let x = this.pageRelativeLeft ? (e.e.clientX - this.pageRelativeLeft) / this.zoom : null;
            let y = this.pageRelativeTop ? (e.e.clientY - (this.pageRelativeTop + NAVBAR_HEIGHT + TOOLBAR_HEIGHT_GT_SM)) / this.zoom : null;

            if (this.addImageAsPhotoFrame) {
              this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(width, height, sid, url, x, y, isFoilable));
            } else {
              this.store.dispatch(new CanvasActions.AddImage(width, height, sid, url, x, y, isFoilable));
            }
          } else {
            if (!isSvg && !!hoverElement.foilType) {
              this.openFoilErrorDialog();
            } else {
              let canBeFoilable = isFoilable || hoverElement.permissions.isFoilable;
              this.store.dispatch(new CanvasActions.ReplaceImage(hoverElement.route, width, height, width, height, sid, url, canBeFoilable));
            }
          }
          break;
        }
      }
    });
  }

  //#endregion

  //#region Bound box (object)

  private findBoundBoxObject(route: number[]) {
    if (!route) return null;
    return this.canvas.getObjects().find((o) => o[X_BOUND_BOX_ROUTE]?.toString() === route.toString());
  }

  private createBoundBoxObject(object: fabric.Object) {
    if (this.options.keepViewport) return;

    let route: number[] = object?.[X_ROUTE];
    if (!route) return;

    let element = this.findElement(route);
    if (!element || element.selected || element.isBackground() || element.isBackgroundChild) return;

    let existingBoundBoxObject = this.findBoundBoxObject(element.route);
    let boundBoxObject = existingBoundBoxObject ?? new fabric.Rect({
      fill: object.selectionBackgroundColor,
      stroke: object.borderColor,
      strokeWidth: 1 / this.zoomToFit,
      strokeUniform: true,
      evented: false,
      // @ts-expect-error
      [X_BOUND_BOX_ROUTE]: element.route,
    });

    boundBoxObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      angle: object.angle,
    });
    boundBoxObject.setCoords();

    if (!existingBoundBoxObject) {
      let index = this.canvas.getObjects().length;
      this.canvas.insertAt(boundBoxObject, index, true);
    } else {
      let index = this.canvas.getObjects().length - 1;
      this.canvas.moveTo(boundBoxObject, index);
    }

    this.canvas.requestRenderAll();

    if (element.isBoxChild) {
      let parentObject = this.findObject(element.parent.route);
      this.createBoundBoxObject(parentObject);
    }
  };

  private deleteBoundBoxObjects() {
    let boundBoxObjects = this.canvas.getObjects().filter((o) => o[X_BOUND_BOX_ROUTE]);
    if (boundBoxObjects.length) {
      this.canvas.remove(...boundBoxObjects);
      this.canvas.requestRenderAll();
    }
  }

  //#endregion

  //#region Replace control (object)

  private findReplaceControlObject(route: number[]) {
    if (!route) return null;
    return this.canvas.getObjects().find((o) => o[X_REPLACE_CONTROL_ROUTE]?.toString() === route.toString());
  }

  private async createReplaceControlObject(object: fabric.Object) {
    if (this.options.keepViewport) return;

    let route: number[] = object?.[X_ROUTE];
    if (!route) return;

    let element = this.findElement(route);
    if (
      !element ||
      element.selected ||
      !element.isPhotoFrame() ||
      !element.firstChild ||
      element.firstChild.isVectorImage ||
      !element.permissions.isInstantReplaceable
    ) return;

    let existingReplaceControlObject = this.findReplaceControlObject(element.route);
    let replaceControlObject = existingReplaceControlObject ?? await new Promise((resolve: (result: fabric.Object) => void) => {
      fabric.loadSVGFromString(IMAGE_REPLACE_SVG, (results) => {
        let result = new fabric.Group(results, {
          hasBorders: false,
          hasControls: false,
          hoverCursor: "pointer",
          // @ts-expect-error
          [X_REPLACE_CONTROL_ROUTE]: element.route,
        });

        let objectCenterPoint = object.getCenterPoint();
        let nPos = calculatePosition(objectCenterPoint.x, objectCenterPoint.y, -object.angle, objectCenterPoint);

        result.set({
          data: {
            nLeft: nPos.x,
            nTop: nPos.y,
            width: result.width,
            height: result.height,
            angle: object.angle,
            centerPoint: objectCenterPoint,
          },
        });

        result.on("selected", (event: fabric.IEvent<MouseEvent>) => {
          let nextEvent = Object.assign(cloneDeep(event), { target: object });
          this.onObjectSelected(nextEvent);
          this.onObjectBeforeImageReplace(nextEvent);
        });

        resolve(result);
      });
    });

    let size = 36 / this.canvas.getZoom();
    let { nLeft, nTop, angle, width, height, centerPoint } = replaceControlObject.data;
    let rPos = calculatePosition(nLeft - size / 2, nTop - size / 2, angle, centerPoint);

    replaceControlObject.set({
      left: rPos.x,
      top: rPos.y,
      scaleX: size / width,
      scaleY: size / height,
      angle: angle,
    });
    replaceControlObject.setCoords();

    if (!existingReplaceControlObject) {
      let index = this.canvas.getObjects().length;
      this.canvas.insertAt(replaceControlObject, index, true);
    } else {
      let index = this.canvas.getObjects().length - 1;
      this.canvas.moveTo(replaceControlObject, index);
    }

    this.canvas.requestRenderAll();
  };

  private deleteReplaceControlObjects() {
    let replaceControlObjects = this.canvas.getObjects()
      .filter((o) => {
        let route = o[X_REPLACE_CONTROL_ROUTE];
        if (!route) return false;

        let element = this.findElement(o[X_REPLACE_CONTROL_ROUTE]);
        return !element || !element.permissions.hasInstantReplaceablePlaceholder;
      });

    if (replaceControlObjects.length) {
      this.canvas.remove(...replaceControlObjects);
      this.canvas.requestRenderAll();
    }
  }

  //#endregion

  protected upsertGroupObjects(group: fabric.Group, objects: fabric.Object[]) {
    let existingObjects = group.getObjects();
    for (let object of existingObjects) {
      group.remove(object);
    }

    for (let object of objects) {
      group.addWithUpdate(object);
    }
  }

  private findElement(route: number[]) {
    if (!route) return null;
    return ((this.page?.route?.toString() === route.toString() && this.page) || this.page?.getElement(route.slice(0, -1))) as CanvasElement;
  }

  private findObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_ROUTE]?.toString() === route.toString());
  }

  private findObjectIndex(object: fabric.Object) {
    if (!object) return -1;
    return this.canvas?.getObjects()?.indexOf(object) ?? -1;
  }

  private findCropObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_CROP_ROUTE]?.toString() === route.toString());
  }

  private findCoatObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_COAT_ROUTE]?.toString() === route.toString());
  }

  private findFoilObject(route: number[]) {
    if (!route) {
      return null;
    }

    let rectRoute = route.slice(route.length - 2);
    let rect = this.canvas.getObjects().find((o) => o[X_FOIL_ROUTE]?.toString() === rectRoute.toString());

    switch (route.length) {
      case 2: {
        return rect;
      }

      case 3:
      case 4: {
        if (rect && rect.clipPath instanceof fabric.Group) {
          let objects = rect.clipPath.getObjects();
          return objects.find((o) => o[X_FOIL_ROUTE]?.toString() === route.toString());
        } else {
          return null;
        }
      }

      default: {
        return null;
      }
    }
  }

  private findFoilImage(route: number[]) {
    if (!route) {
      return null;
    }

    return this.canvas.getObjects().find(o => o[X_FOIL_IMAGE_ROUTE]?.toString() === route.toString());
  }

  private findSpotUvObject(route: number[]) {
    if (!route) {
      return null;
    }

    let rectRoute = route.slice(route.length - 2);
    let rect = this.canvas.getObjects().find((o) => o[X_SPOT_UV_ROUTE]?.toString() === rectRoute.toString());

    switch (route.length) {
      case 2: {
        return rect;
      }

      case 3:
      case 4: {
        if (rect && rect.clipPath instanceof fabric.Group) {
          let objects = rect.clipPath.getObjects();
          return objects.find((o) => o[X_SPOT_UV_ROUTE]?.toString() === route.toString());
        } else {
          return null;
        }
      }

      default: {
        return null;
      }
    }
  }

  private findCutThroughObject(route: number[]): fabric.Object {
    if (route?.length > 2) {
      const pageRoute = [...route].slice(route.length - 2);
      const pageCutThroughObject = this.findCutThroughObject(pageRoute);

      // pageCutThroughObject.clipPath and it's objects are not added to canvas
      // therefore can only be retrived from the pageCutThroughObject.clipPath
      const clipPathObjects: fabric.Object[] = [];
      pageCutThroughObject instanceof fabric.Group && clipPathObjects.push(...(pageCutThroughObject.getObjects()));
      pageCutThroughObject?.clipPath instanceof fabric.Group && clipPathObjects.push(...(pageCutThroughObject.clipPath.getObjects()));

      if (route.length === 4) {
        const clipPathObject = clipPathObjects.find((object) => object[X_CUT_THROUGH_ROUTE]?.toString() === route.toString());
        return clipPathObject;
      }

      if (route.length === 3) {
        const clipPathObject = clipPathObjects.find((object) => object[X_CUT_THROUGH_ROUTE]?.toString() === route.toString());
        if (clipPathObject) {
          return clipPathObject;
        }

        const parentClipPathObject = clipPathObjects.find((object) => object[X_CUT_THROUGH_ROUTE]?.slice(1)?.toString() === route.toString());
        if (parentClipPathObject?.clipPath?.[X_CUT_THROUGH_ROUTE]?.toString() === route.toString()) {
          return parentClipPathObject.clipPath;
        }
      }
    }
    return this.canvas?.getObjects()?.find(o => o[X_CUT_THROUGH_ROUTE]?.toString() === route?.toString());
  }

  private removeCropObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findCropObject(route);
    if (object) {
      this.canvas.remove(object);
    }
  }

  private removeCoatObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findCoatObject(route);
    if (object) {
      this.canvas.remove(object);
    }
  }

  private removeCutThroughObject(route: number[]) {
    const that = this;

    if (route.length <= 2 || !this.findCutThroughObject(route)) {
      return;
    }

    const pageRoute = route.slice(route.length - 2);
    const pageObject = this.findObject(pageRoute);

    removeCutThroughGroupObjects(pageObject);
    pageObject.set({ clipPath: undefined });

    function removeCutThroughGroupObjects(root: fabric.Object) {
      if (root.clipPath instanceof fabric.Group) {
        removeCutThroughGroupObjects(root.clipPath);

        for (const cutThroughObject of root.clipPath.getObjects()) {
          const cutThroughRoute = cutThroughObject[X_CUT_THROUGH_ROUTE];
          const element = that.findElement(cutThroughRoute);
          const object = that.findObject(cutThroughRoute);

          if (element && object) {
            object.set({ opacity: element.opacity });
            root.clipPath.remove(cutThroughObject)
          }
        }
      }
    }
  };

  private removeFoilObject(route: number[]) {
    if (!route) {
      return;
    }

    let rectRoute = route.slice(route.length - 2);
    let rect = this.findFoilObject(rectRoute);

    switch (route.length) {
      case 2: {
        if (rect) {
          this.canvas.remove(rect);
        }
        break;
      }

      case 3:
      case 4: {
        if (rect && rect.clipPath instanceof fabric.Group) {
          let objects = rect.clipPath.getObjects();
          let objectsToRemove = objects.filter((o) => o[X_FOIL_ROUTE]?.toString() === route.toString());
          if (objectsToRemove.length) {
            rect.clipPath.remove(...objectsToRemove);
          }
        }
        break;
      }
    }
  }

  private removeSpotUvObject(route: number[]) {
    if (!route) {
      return;
    }

    let rectRoute = route.slice(route.length - 2);
    let rect = this.findSpotUvObject(rectRoute);

    switch (route.length) {
      case 2: {
        if (rect) {
          this.canvas.remove(rect);
        }
        break;
      }

      case 3:
      case 4: {
        if (rect && rect.clipPath instanceof fabric.Group) {
          let objects = rect.clipPath.getObjects();
          let objectsToRemove = objects.filter((o) => o[X_SPOT_UV_ROUTE]?.toString() === route.toString());
          if (objectsToRemove.length) {
            rect.clipPath.remove(...objectsToRemove);
          }
        }
        break;
      }
    }
  }

  //#region Render

  private debounceRenderPageAsync = debounce(this.renderPageAsync, 100);
  private debounceRenderPageOnFontLoadAsync = debounce((page: PageElement) => {
    fabric.util.clearFabricFontCache();
    this.renderPageAsync(page);
  }, 200);

  private async renderPageAsync(page: PageElement) {
    this.renderAbortController?.abort();
    this.renderAbortController = new AbortController();

    let abortSignal = this.renderAbortController.signal;

    this.removeElementsFromCanvas();
    if (abortSignal.aborted) return;

    let pageIndex = this.findObjectIndex(this.findObject(page.route));
    pageIndex === -1 && (pageIndex = 0);

    await this.addElementToCanvasAsync(page, abortSignal, pageIndex);
    if (abortSignal.aborted) return;

    await this.addPageFoilToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    await this.addPageSpotUvToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    this.addPageViewSeparatorToCanvas(page);

    if (!this.options.keepViewport) {
      this.addPageGridToCanvas(page);
    }

    // Add replace control object for instant replaceable placeholder elements
    for (let element of page.children.filter((el) => !!el.permissions.hasInstantReplaceablePlaceholder)) {
      let object = this.findObject(element.route);
      await this.createReplaceControlObject(object);
    }

    if (!this.isPageThumb && page.selectedElement && page.selectedElement.selected) {
      this.setSelectedElementToCanvas(page.selectedElement);
    }
    else {
      if (this.canvas.getActiveObject()) {
        this.canvas.discardActiveObject()
      }
    }

    this.canvas.requestRenderAll();
  }

  private getCutThroughBehindPages(page: PageElement): PageElement[] {
    let cutThroughElements = page?.children.filter(p => p.isCutThrough || p.isCutThroughInverted);
    if (!cutThroughElements?.length || !page?.pageBehindId) {
      return [];
    }

    let design = page.parent;
    let designPages = design.pages;

    let behindPage = designPages.find(p => p.id == page.pageBehindId);
    return [...this.getCutThroughBehindPages(behindPage), behindPage];
  }

  private setSelectedElementToCanvas(selectedElement: BaseElement) {
    const selectedObject = this.findObject(selectedElement?.route);

    if (selectedObject) {
      //@ts-expect-error
      this.canvas.setActiveObject(selectedObject, { transform: { target: selectedObject } });
    } else {
      this.canvas.discardActiveObject();
    }
  }

  //#region Foil object

  private getFoilElements(element: CanvasElement): CanvasElement[] {
    const childrenFullRange = CanvasElementUtils.getChildrenFullRange(element);
    return childrenFullRange.filter((element) => element.isSpecialColorElement() && element.foilType && element.type !== ElementType.photoFrame);
  }

  private getSpotUvElements(element: CanvasElement): CanvasElement[] {
    const childrenFullRange = CanvasElementUtils.getChildrenFullRange(element);
    return childrenFullRange.filter((element) => element.isSpecialColorElement() && element.spotUv && element.type !== ElementType.photoFrame);
  }

  //#endregion

  private async addElementToCanvasAsync(element: CanvasElement, abortSignal?: AbortSignal, index: number = 0) {
    if (!element || abortSignal?.aborted) return -1;

    const existingObject = this.findObject(element.route);
    const object = await this.createObjectAsync(element, existingObject);

    if (!object || abortSignal?.aborted) return -1;

    // Set options
    object.set({
      evented: (
        element.isClickable &&
        (!element.permissions.isUntargetable || this.designSet.untargetableElementsTargetable) &&
        !element.isPhotoFrameChild
      ),
      // @ts-expect-error
      activeOn: this.downLg ? "up" : "down",
      [X_ROUTE]: element.route,
    });

    // Set clip path
    if (element.isPage()) {
      await this.setPageObjectClipPathAsync(element, object, abortSignal);

      // Check index position
      const clipPathIndex = this.findObjectIndex(object.clipPath);
      clipPathIndex !== -1 && (index = clipPathIndex + 1);
    } else if (!!element.permissions.isVisibleOutsideCropArea) {
      object.set({ clipPath: undefined })
    } else {
      const parentObject = this.findObject(element.parent?.route);
      object.set({
        clipPath: parentObject?.set({
          absolutePositioned: true,
        }),
      });
    }

    // Set transform matrix
    if (element.isPhotoFrameChild || element.isBoxChild) {
      const parentObject = this.findObject(element.parent.route);
      setTransformMatrix(object, parentObject);
    }

    if (!existingObject) {
      // Set object control options
      if (object instanceof fabric.Object) {
        object.set({
          cornerSize: 12,
          transparentCorners: false,
          borderColor: '#8B3DFF',
          borderScaleFactor: 1,
          borderOpacityWhenMoving: 1,
          cornerColor: '#FFF',
          cornerStrokeColor: '#0008',
          cornerStyle: 'circle',
          hasBorders: !element.isBackground(),
        });
      }

      if (object instanceof fabric.Textbox) {
        object.set({
          editingBorderColor: '#8B3DFF',
        });
      }

      this.canvas.insertAt(object, index++, false);
    } else {
      this.canvas.moveTo(object, index++);
    }

    // Create coat object
    this.createCoatObject(element, object);

    // Add child elements to canvas
    for (const childElement of element.children) {
      const nextIndex = await this.addElementToCanvasAsync(childElement, abortSignal, index);
      nextIndex !== -1 && (index = nextIndex);
    }

    return index;
  }

  private async createObjectAsync(element: CanvasElement, object?: fabric.Object) {
    const data = {
      element,
      fontLibrary: this.fontLibrary,
    };

    if (!this.isPageThumb && element.isInlineText() && !element.selected) {
      return await element.createImageAsync(object, data);
    }
    return await element.createObjectAsync(object, data);
  }

  private async createFoilObjectAsync(element: CanvasElement) {
    if (!element || element.type === ElementType.page) {
      return null;
    }

    let existingObject = this.findFoilObject(element.route);
    let object = await this.createObjectAsync(element, existingObject);
    if (!object) {
      return null;
    }

    let clipPath = await this.createFoilObjectAsync(element.parent);
    object.set({
      clipPath: clipPath?.set({
        absolutePositioned: true,
      }),
      // @ts-expect-error
      [X_FOIL_ROUTE]: element.route,
    });

    object.setCoords();

    return object;
  }

  private async createSpotUvObjectAsync(element: CanvasElement) {
    if (!element || element.type === ElementType.page) {
      return null;
    }

    let existingObject = this.findSpotUvObject(element.route);
    let object = await this.createObjectAsync(element, existingObject);
    if (!object) {
      return null;
    }

    let clipPath = await this.createSpotUvObjectAsync(element.parent);
    object.set({
      clipPath: clipPath?.set({
        absolutePositioned: true,
      }),
      // @ts-expect-error
      [X_SPOT_UV_ROUTE]: element.route,
    });

    object.setCoords();

    return object;
  }

  //#endregion

  //#region Clip path

  private async setPageObjectClipPathAsync(pageElement: PageElement, pageObject: fabric.Object, abortSignal?: AbortSignal) {
    const that = this;

    let cutThroughObjects: fabric.Object[] = [];
    let invertedCutThroughObjects: fabric.Object[] = [];

    // Map cut through elements to objects
    for (let cutThroughElement of CanvasElementUtils.getCutThroughElements(pageElement)) {
      if (abortSignal?.aborted) return;

      let cutThroughObject = await createCutThroughObjectAsync(cutThroughElement, false);
      if (cutThroughObject) {
        cutThroughObjects.push(cutThroughObject);
      }
    }

    for (let cutThroughElement of CanvasElementUtils.getBackSideCutThroughElements(pageElement)) {
      if (abortSignal?.aborted) return;

      let cutThroughObject = await createCutThroughObjectAsync(cutThroughElement, true);
      if (cutThroughObject) {
        cutThroughObjects.push(cutThroughObject);
      }
    }

    // Map inverted cut through elements to objects
    for (let invertedCutThroughElement of CanvasElementUtils.getInvertedCutThroughElements(pageElement)) {
      if (abortSignal?.aborted) return;

      let invertedCutThroughObject = await createCutThroughObjectAsync(invertedCutThroughElement, false);
      if (invertedCutThroughObject) {
        invertedCutThroughObjects.push(invertedCutThroughObject);
      }
    }

    for (let invertedCutThroughElement of CanvasElementUtils.getBackSideInvertedCutThroughElements(pageElement)) {
      if (abortSignal?.aborted) return;

      let invertedCutThroughObject = await createCutThroughObjectAsync(invertedCutThroughElement, true);
      if (invertedCutThroughObject) {
        invertedCutThroughObjects.push(invertedCutThroughObject);
      }
    }

    // Upsert (inverted) cut through clip path
    let cutThroughClipPath: fabric.Group;
    let invertedCutThroughClipPath: fabric.Group;

    if (cutThroughObjects.length) {
      let existingCutThroughClipPath = this.findCutThroughObject(pageElement.route);
      cutThroughClipPath = existingCutThroughClipPath instanceof fabric.Group && existingCutThroughClipPath.inverted
        ? existingCutThroughClipPath
        : new fabric.Group([], {
          opacity: 1e-323,
          inverted: true,
          evented: false,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: pageElement.route,
        });

      this.upsertGroupObjects(cutThroughClipPath, cutThroughObjects);
    } else {
      cutThroughClipPath = null;
    }

    if (abortSignal?.aborted) return;

    if (invertedCutThroughObjects.length) {
      let existingInvertedCutThroughClipPath = this.findCutThroughObject(pageElement.route);
      invertedCutThroughClipPath = existingInvertedCutThroughClipPath instanceof fabric.Group && !existingInvertedCutThroughClipPath.inverted
        ? existingInvertedCutThroughClipPath
        : new fabric.Group([], {
          opacity: 1e-323,
          evented: false,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: pageElement.route,
        });

      this.upsertGroupObjects(invertedCutThroughClipPath, invertedCutThroughObjects);
    } else {
      invertedCutThroughClipPath = null;
    }

    if (abortSignal?.aborted) return;

    let clipPath = invertedCutThroughClipPath?.set({
      clipPath: cutThroughClipPath?.set({
        absolutePositioned: true,
      }),
    }) ?? cutThroughClipPath;

    if (clipPath && this.findObjectIndex(clipPath) === -1) {
      this.canvas.insertAt(clipPath, 0, false);
    }

    pageObject.set({
      clipPath: clipPath?.set({
        absolutePositioned: true,
      }),
    });

    async function createCutThroughObjectAsync(element: CanvasElement, isBackSidePageElement: boolean): Promise<fabric.Object> {
      if (!element || element.isPage()) {
        return null;
      }

      let existingObject = that.findCutThroughObject(element.route);
      let object = await that.createObjectAsync(element, existingObject);
      if (!object) {
        return null;
      }

      if (isBackSidePageElement) {
        let backSidePageOffsetLeft = 0;
        if (element.page.width !== element.backSidePage.width) {
          backSidePageOffsetLeft = Math.min(element.page.width, element.backSidePage.width) * .5;
        }

        mirrorBackSideObject(element, object, backSidePageOffsetLeft);
      }

      object.set({
        opacity: element.opacity,
        clipPath: (await createCutThroughObjectAsync(element.parent, isBackSidePageElement))?.set({
          absolutePositioned: true,
        }),
        // @ts-expect-error
        [X_CUT_THROUGH_ROUTE]: element.route,
      });

      if (that.findObject(element.route)) {
        setTransformMatrix(object, that.findObject(element.route))
      }

      return object;

      function mirrorBackSideObject(element: CanvasElement, object: fabric.Object, backSidePageOffsetLeft: number = 0) {
        let left = (element.designX * -1) + (element.pageX * 2) - element.width + element.pageWidth - backSidePageOffsetLeft;
        let top = element.designY;
        let degrees = (360 - getAngle(element.screenRotation)) % 360;

        let originLeft = left + (element.width / 2) - backSidePageOffsetLeft;
        let originTop = top + (element.height / 2);

        if (element.isBoxChild || element.isPhotoFrameChild) {
          originLeft = (element.parent.designX * -1) + (element.pageX * 2) - element.parent.width + element.pageWidth + (element.parent.width / 2) - backSidePageOffsetLeft;
          originTop = element.parent.designY + (element.parent.height / 2);
        }

        let { x, y } = fabric.util.rotatePoint(
          new fabric.Point(left, top),
          new fabric.Point(originLeft, originTop),
          fabric.util.degreesToRadians(degrees)
        );

        object.set({
          left: x,
          top: y,
          angle: degrees,
          flipX: !object.flipX,
        });

        object.setCoords();
      }
    }
  }

  //#endregion

  //#region Canvas events

  private onCanvasMouseWheel(event: fabric.IEvent<WheelEvent>) {
    let delta = event.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom *= 0.999 ** delta;

    let zoomPoint = new fabric.Point(event.e.offsetX, event.e.offsetY);
    this.zoomCanvasToPoint(zoomPoint, zoom);

    event.e?.preventDefault();
    event.e?.stopPropagation();
  }

  private onCanvasTextEditingEntered(event: fabric.IEvent<MouseEvent>) {
    this.canvas.preserveObjectStacking = false;

    const target: fabric.Object = event?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isInlineText() && element.editFullRange) {
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(route, false));
    }
  }

  private onCanvasTextEditingExited(event: fabric.IEvent<MouseEvent>) {
    this.canvas.preserveObjectStacking = true;

    const target: fabric.Object = event?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isInlineText() && !element.editFullRange) {
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(route, true));
    }
  }

  private onCanvasTextChanged(event: fabric.IEvent<KeyboardEvent> | { target: fabric.Object }) {
    const target: fabric.Object = event?.target;
    if (!(target instanceof fabric.Textbox)) return;

    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    // Set shrink to fit
    if (element.permissions.shrinkToFit && !target.text.match(/[\r\n]/)) {
      while (target._textLines.length > 1) {
        target.set({
          fontSize: target.fontSize - 1,
        });
      }
    }

    let foilObject = this.findFoilObject(element.route);
    if (foilObject instanceof fabric.Textbox) {
      foilObject.set({ text: target.text });
      this.canvas.requestRenderAll();
    }

    if (element.isText()) {
      this.store.dispatch(new CanvasActions.ChangeFontsize(route, target.fontSize));
      this.store.dispatch(new CanvasActions.ChangeText(route, target.text));

      const { x, y, width, height } = this.getObjectTransform(event.target);
      this.store.dispatch(new CanvasActions.Translate(route, width, height, x, y));
    }

    if (element.isInlineText()) {
      const { x, y, width, height } = this.getObjectTransform(event.target);
      const text = TextboxUtils.getInlineTextElementText(target);
      this.store.dispatch(new CanvasActions.ChangeTextInline(route, text, width, height, x, y));
    }
  }

  private clearCanvasObjectsTempKeys() {
    this.canvas.getObjects().forEach((object) => {
      clearObjectTempKeys(object);
    });
  }

  private onCanvasObjectRemoved(event: fabric.IEvent<MouseEvent>) {
    let target = event.target;
    let route: number[] = target[X_ROUTE];
    if (!route) return;

    this.removeCoatObject(route);
    this.removeCropObject(route);
    this.removeFoilObject(route);
    this.removeSpotUvObject(route);
    this.removeCutThroughObject(route);
  }

  private onObjectBeforeImageReplace(e: fabric.IEvent<MouseEvent>) {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = function (event: any) {
      let file = event.target.files[0];
      let fileExtension = file.name.split('.').slice(-1)[0].toLowerCase();

      let isValidFileExtension = fileExtensions[fileExtension];
      if (isValidFileExtension) {
        Object.assign(e, { file });
      }

      e.target.canvas.fire('object:imageReplace', e);
      e.target.fire('imageReplace', e);
    };
    input.click();
  }

  private onObjectImageReplace(event: fabric.IEvent<MouseEvent>) {
    let route: number[] = event.target[X_ROUTE];
    if (!route) return;

    // @ts-expect-error
    let file: File = event.file;
    if (file) {
      this.uploadImage(route, file);
    } else {
      this.uploadImageError();
    }
  }

  private uploadImage(route: number[], file: File) {
    this.imageUploadService.uploadUrl = UPLOAD_URL;
    this.imageUploadService.uploadImage(file, (imageData: ImageData) => {
      this.store.dispatch(new CanvasActions.ReplaceImage(
        route,
        imageData.width,
        imageData.height,
        imageData.width,
        imageData.height,
        imageData.sid,
        '',
        false
      ));
    });
  }

  private uploadImageError() {
    this.dialog.open(ErrorDialogComponent, {
      width: '250px',
      data: {
        title: this.getTextService.text.dialog.upload.error.title,
        message: this.getTextService.text.dialog.upload.error.text,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  //#region Object events

  private async onObjectSelected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    console.log('element selected: ', element)

    if (element.isInlineText()) {
      this.onCanvasTextChanged({ target })
    }

    // this.deleteBoundBoxObjects();
    // this.deleteReplaceControlObjects();

    this.store.dispatch(new CanvasActions.Select(route));
  }

  private async onObjectDeselected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.store.dispatch(new CanvasActions.Deselect());
  }

  private onObjectMoving(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    target.set({
      hasControls: false
    })

    if (element.isBox()) {
      for (const childElement of element.children) {
        onObjectTransform_TransformObject(event, this.findObject(childElement.route));
        onObjectTransform_TransformObject(event, this.findCropObject(childElement.route));
        onObjectTransform_TransformObject(event, this.findCutThroughObject(childElement.route));
        this.removeFoilObject(childElement.route);
        this.removeSpotUvObject(childElement.route);
        this.removeCutThroughObject(childElement.route);
      }
    }

    if (element.isPhotoFrame()) {
      onObjectTransform_TransformObject(event, this.findObject(element.firstChild.route));
      onObjectTransform_TransformObject(event, this.findCropObject(element.firstChild.route));
      onObjectTransform_TransformObject(event, this.findCutThroughObject(element.firstChild.route));
      this.removeFoilObject(element.firstChild.route);
      this.removeSpotUvObject(element.firstChild.route);
      this.removeCutThroughObject(element.firstChild.route);
    }

    if (element.isPhotoFrameChild) {
      onObjectTransformContainObject(event, this.findObject(element.parent.route));
      this.createCropObjectAsync(element, this.findObject(element.route));
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));
    onObjectTransform_TransformObject(event, this.findCutThroughObject(element.route));
    this.removeFoilObject(element.route);
    this.removeSpotUvObject(element.route);
    this.removeCutThroughObject(element.route);

    if (!(event instanceof KeyboardEvent) || event.repeat)
      this.objectModifying.emit(target);
  }

  private onObjectRotating(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isBox() || element.isPhotoFrame()) {
      for (const childElement of element.children) {
        onObjectTransform_TransformObject(event, this.findObject(childElement.route));
        onObjectTransform_TransformObject(event, this.findCropObject(childElement.route));
        onObjectTransform_TransformObject(event, this.findCutThroughObject(childElement.route));
        this.removeFoilObject(childElement.route);
        this.removeSpotUvObject(childElement.route);
        this.removeCutThroughObject(childElement.route);
      }
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));
    onObjectTransform_TransformObject(event, this.findCutThroughObject(element.route));
    this.removeFoilObject(element.route);
    this.removeSpotUvObject(element.route);
    this.removeCutThroughObject(element.route);

    this.objectModifying.emit(target);
  }

  private async onObjectScaling(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isPhotoFrame()) {
      switch (event.transform.action) {
        case 'scale': {
          for (const childElement of element.children) {
            onObjectTransform_TransformObject(event, this.findObject(childElement.route));
            onObjectTransform_TransformObject(event, this.findCropObject(childElement.route));
            onObjectTransform_TransformObject(event, this.findCutThroughObject(childElement.route));
            this.removeFoilObject(childElement.route);
            this.removeSpotUvObject(childElement.route);
            this.removeCutThroughObject(childElement.route);
          }
          break;
        }

        case 'scaleX':
        case 'scaleY': {
          for (const childElement of element.children) {
            onObjectTransformContainWithinObject(event, this.findObject(childElement.route));
            await this.createCropObjectAsync(childElement, this.findObject(childElement.route));
          }
          break;
        }
      }
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));
    onObjectTransform_TransformObject(event, this.findCutThroughObject(element.route));
    this.removeFoilObject(element.route);
    this.removeSpotUvObject(element.route);
    this.removeCutThroughObject(element.route);

    this.objectModifying.emit(target);
  }

  private onObjectModified(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    switch (event.action) {
      case 'drag':
        this.onObjectMove(event);
        break;
      case 'rotate':
        this.onObjectRotate(event);
        break;
      case 'resizing':
      case 'scale':
        if (element.isBox()) {
          this.onObjectCrop(event);
          break;
        }

        this.onObjectScale(event);
        break;
      case 'scaleX':
      case 'scaleY':
        this.onObjectCrop(event);
        break;
    }

    if (element.isPhotoFrame()) {
      for (const childElement of element.children) {
        this.removeCropObject(childElement.route);
      }
    }
  }

  private onObjectMove(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;

    target.set({
      hasControls: true
    })

    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Translate(route, width, height, x, y));
    this.objectModified.emit(target);
  }

  private onObjectRotate(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height, rotation } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Rotate(route, width, height, x, y, rotation));
    this.objectModified.emit(target);
  }

  private onObjectCrop(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Crop(route, width, height, x, y));
    this.objectModified.emit(target);
  }

  private onObjectScale(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (target instanceof fabric.Textbox) {
      if (element.isInlineText()) {

        for (let paragraph of element.text) {
          for (let line of paragraph.lines) {
            for (let textSpan of line.textSpans) {
              textSpan.fontSize *= target.scaleX;
            }
          }
        }

        target.set({
          fontSize: Number.parseInt((target.fontSize * target.scaleX).toFixed(0), 10),
          width: target.width * target.scaleX,
          height: target.height * target.scaleY,
          scaleX: 1,
          scaleY: 1,
        });
      }
    }

    const { x, y, width, height } = this.getObjectTransform(target);
    this.store.dispatch(new CanvasActions.Resize(route, width, height, x, y));

    if (target instanceof fabric.Textbox) {
      if (element.isInlineText()) {
        const text = TextboxUtils.getInlineTextElementText(target);
        this.store.dispatch(new CanvasActions.ResizeTextInline(element.route, text, width, height, x, y, new EditorSelection()));
      }
    }

    this.objectModified.emit(target);
  }

  private getObjectTransform(object: fabric.Object) {
    const route: number[] = object?.[X_ROUTE];
    if (!route) return null;

    const element = this.findElement(route);
    if (!element) return null;

    const left = object.left;
    const top = object.top;

    const width = object.width * object.scaleX;
    const height = object.height * object.scaleY;

    if (element.isBoxChild || element.isPhotoFrameChild) {
      const parentElement = element.parent;
      const parentObject = this.findObject(parentElement.route);

      const rotation = CanvasElementUtils.getRotation((object.angle - parentObject.angle) % 360);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(
          parentElement.designX + (parentObject.width * parentObject.scaleX) / 2,
          parentElement.designY + (parentObject.height * parentObject.scaleY) / 2
        ),
        fabric.util.degreesToRadians(-parentObject.angle - ((object.angle - parentObject.angle) % 360))
      );

      let correctedWidth = Math.max(width, element.parent.width);
      let correctedHeight = Math.max(height, element.parent.height);

      return { route, x: x - element.parent.designX, y: y - element.parent.designY, width: correctedWidth, height: correctedHeight, rotation };
    } else {
      const rotation = CanvasElementUtils.getRotation(object.angle);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(left - (width) / 2, top - height / 2),
        fabric.util.degreesToRadians(object.angle)
      );
      return { route, x: x - element.pageX, y: y - element.pageY, width, height: height, rotation };
    }
  }

  private onObjectRemove(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    this.store.dispatch(new CanvasActions.RemoveElement(route));
  }

  private onObjectZoomInner(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    const zoomScale = event.transform['zoomScale'] ?? 0;
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    target.setCoords();

    for (const childElement of element.children) {
      const childObject = this.findObject(childElement.route);
      if (childObject) {
        setPositionAfterZooming(target, childObject, zoomScale);

        if (childElement.isPhotoFrameChild) {
          let childOptions = {
            left: childObject.left,
            top: childObject.top,
            scaleX: childObject.scaleX,
            scaleY: childObject.scaleY,
          }

          this.findCropObject(childElement.route)?.set(childOptions)?.setCoords();
          this.findCoatObject(childElement.route)?.set(childOptions)?.setCoords();
          this.findFoilObject(childElement.route)?.set(childOptions)?.setCoords();
          this.findSpotUvObject(childElement.route)?.set(childOptions)?.setCoords();
          this.findCutThroughObject(childElement.route)?.set(childOptions)?.setCoords();
        }

        // @ts-expect-error
        this.onObjectScale({ transform: { target: childObject } });
      }
    }
    this.canvas.requestRenderAll();
  }

  private onObjectMouseUp(event: fabric.IEvent<MouseEvent>) {
    if (event?.button === 3) {
      const route: number[] = event.target?.[X_ROUTE];
      if (!route) return;

      const element = this.findElement(route);
      if (!element) return;

      this.onObjectSelected(event);

      this.contextMenuService.openContextMenu(event.pointer.x, event.pointer.y, element);
    }
  }

  //#endregion

  //#region Crop object

  private async createCropObjectAsync(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    if (!element.isPhotoFrameChild || !element.parent.selected) return;

    let existingCropObject = this.findCropObject(element.route);
    let cropObject = await this.createObjectAsync(element, existingCropObject);
    if (!cropObject || abortSignal?.aborted) return;

    cropObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      flipX: object.flipX,
      flipY: object.flipY,
      angle: object.angle,
      opacity: .5,
      evented: false,
      // @ts-expect-error
      [X_CROP_ROUTE]: element.route,
    });

    cropObject.setCoords();
    setTransformMatrix(cropObject, object);

    let isAddedToCanvas = !!this.findCropObject(element.route);
    let index = this.findObjectIndex(object);

    if (!isAddedToCanvas) {
      this.canvas.insertAt(cropObject, index, false);
    } else {
      cropObject.moveTo(index);
    }
  }

  //#endregion

  //#region Coat object

  private createCoatObject(element: CanvasElement, object: fabric.Object) {
    if (!CanvasElementUtils.hasCoating(element)) return;

    let backgroundElement = this.page.children.find((e) => e.isBackgroundElement());
    if (!backgroundElement || backgroundElement.children.length) return;

    let backgroundObject = this.findObject(backgroundElement.route);
    let backgroundObjectIndex = this.findObjectIndex(backgroundObject);
    if (backgroundObjectIndex === -1) return;

    let existingCoatObject = this.findCoatObject(element.route);
    let coatObject = existingCoatObject ?? new fabric.Rect();
    if (!coatObject) return;

    coatObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      angle: object.angle,
      fill: CoatingType,
      opacity: 1,
      clipPath: this.findObject(element.parent.route)?.set({
        absolutePositioned: true,
      }),
      evented: false,
      // @ts-expect-error
      [X_COAT_ROUTE]: element.route,
    });

    coatObject.setCoords();
    setTransformMatrix(coatObject, object);

    let isAddedToCanvas = !!this.findCoatObject(element.route);
    let index = backgroundObjectIndex + 1;

    if (!isAddedToCanvas) {
      this.canvas.insertAt(coatObject, index, false);
    } else {
      coatObject.moveTo(index);
    }
  }

  //#endregion

  //#region Zoom

  private zoomCanvasToFit() {
    if (!this.canvas) {
      return;
    }

    let zoomToFit = this.zoomToFit;
    this.zoomCanvasToCenter(zoomToFit);
  }

  private zoomCanvasToCenter(zoom: number) {
    if (!this.canvas) {
      return;
    }

    let center = this.canvas.getCenter();
    let centerPoint = new fabric.Point(center.left, center.top);
    this.zoomCanvasToPoint(centerPoint, zoom);
  }

  private zoomCanvasToPoint(point: fabric.Point, zoom: number) {
    if (!this.canvas) {
      return;
    }

    this.setCanvasZoom(point, zoom);
    this.setCanvasViewportTransform(zoom);
  }

  private setCanvasZoom(point: fabric.Point, zoom: number) {
    let minZoom = this.zoomToFit * this.page.minZoom;
    if (zoom < minZoom) zoom = minZoom;

    let maxZoom = this.zoomToFit * this.page.maxZoom;
    if (zoom > maxZoom) zoom = maxZoom;

    this.canvas.zoomToPoint(point, zoom);
    this.zoomChange.emit(zoom);

    this.canvas.requestRenderAll();
  }

  private setCanvasViewportTransform(zoom: number, dx?: number, dy?: number) {
    let viewportTransform = [...this.canvas.viewportTransform];
    if (this.preserveViewportTransform) {
      if (zoom <= this.zoomToFit) {
        viewportTransform[4] = this.canvas.width / 2 - (this.page.designX + this.page.width / 2) * zoom;
        viewportTransform[5] = this.canvas.height / 2 - (this.page.designY + this.page.height / 2) * zoom;
      }
    } else {
      viewportTransform[4] += dx ?? 0;
      viewportTransform[5] += dy ?? 0;
    }

    if (zoom >= this.zoomToFit && !this.options.keepViewport) {
      const minTranslateX = this.canvas.width - this.canvas.width * zoom * 2;
      const maxTranslateX = this.canvas.width * zoom;
      viewportTransform[4] = Math.max(Math.min(viewportTransform[4], maxTranslateX), minTranslateX);

      const minTranslateY = this.canvas.height - this.canvas.height * zoom * 2;
      const maxTranslateY = this.canvas.height * zoom;
      viewportTransform[5] = Math.max(Math.min(viewportTransform[5], maxTranslateY), minTranslateY);
    }

    this.canvas.viewportTransform = viewportTransform;
    this.viewportTransformChange.emit(viewportTransform);

    let viewportBoundaries = this.canvas.calcViewportBoundaries();
    this.viewportBoundariesChange.emit(viewportBoundaries);

    this.canvas.requestRenderAll();
  }

  //#endregion

  //#region Canvas container

  private onCanvasKeyDown(event: KeyboardEvent) {
    this.keysPressed[event.key] = true;

    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;

    const element = this.findElement(activeObject?.[X_ROUTE]);
    if (!element) return;

    const STEP = event.shiftKey ? 10 : 1;
    let arrowMovement = false;

    if (activeObject instanceof fabric.Textbox && (event.ctrlKey || event.metaKey)) {
      switch (event.key) {
        case "b":
          event.preventDefault()
          const nextFontWeight = InlineTextElementUtils.getTextboxFontWeight(!TextboxUtils.getInlineTextElementBold(activeObject));
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxFontWeight(element.route, nextFontWeight));
          break;
        case "u":
          event.preventDefault()
          const nextUnderline = !activeObject.underline
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxUnderline(element.route, nextUnderline));
          break;
        case "i":
          event.preventDefault()
          const nextFontStyle = InlineTextElementUtils.getTextboxFontStyle(!TextboxUtils.getInlineTextElementItalic(activeObject));
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxFontStyle(element.route, nextFontStyle));
          break;
      }
    }

    function addPositionOffset(axis: 'left' | 'top', value: number) {
      activeObject.set({ [axis]: activeObject[axis] + value });
      activeObject.setCoords();
      arrowMovement = true;
    };

    if (!event.ctrlKey && !event.metaKey && !event.altKey) {
      switch (event.key) {
        case 'ArrowLeft':
          addPositionOffset('left', -STEP);
          break;
        case 'ArrowUp':
          addPositionOffset('top', -STEP);
          break;
        case 'ArrowRight':
          addPositionOffset('left', STEP);
          break;
        case 'ArrowDown':
          addPositionOffset('top', STEP);
          break;
      }
    }

    if (arrowMovement) {
      this.alignGuidelines.disableMagnetism();

      //@ts-expect-error
      this.onObjectMoving(Object.assign(event, { transform: { target: activeObject } }))
      this.alignGuidelines.onObjectMoving(activeObject);
      this.canvas.requestRenderAll();
    }
  }

  private onCanvasKeyUp(event: KeyboardEvent) {
    if ((this.keysPressed['ArrowLeft'] || this.keysPressed['ArrowUp'] || this.keysPressed['ArrowRight'] || this.keysPressed['ArrowDown'])) {
      this.alignGuidelines.enableMagnetism();
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        //@ts-expect-error
        this.onObjectMove({ transform: { target: activeObject } })
      }
    }

    delete this.keysPressed[event.key];
  }

  private openFoilErrorDialog() {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: this.getTextService.text.dialog.dropFoilError.title,
        message: this.getTextService.text.dialog.dropFoilError.message,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  private addPageGridToCanvas(page: PageElement) {
    if (!this.designSet.showGrid) {
      return;
    }

    const that = this;

    let gridLines: fabric.Line[] = [];
    let cellSize = Math.round(page.width / this.gridCellSize);

    gridLines.push(...getGridLines());
    gridLines.push(...getMedianLines());

    this.canvas.add(...gridLines);

    function getGridLines() {
      let gridLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(67, 191, 254, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let x = page.x;
      while (x <= page.x + page.width) {
        let horizontalLine = new fabric.Line([
          x, page.y,
          x, page.y + page.height
        ],
          lineOptions);
        //@ts-expect-error
        horizontalLine.elementType = ElementType.background;
        gridLines.push(horizontalLine);
        x += cellSize;
      }

      let y = page.y;
      while (y <= page.y + page.height) {
        let verticalLine = new fabric.Line([
          page.x, y,
          page.x + page.width, y
        ], lineOptions);
        //@ts-expect-error
        verticalLine.elementType = ElementType.background;
        gridLines.push(verticalLine);
        y += cellSize;
      }

      return gridLines;
    }

    function getMedianLines() {
      let medianLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(255, 28, 65, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let horizontalLine = new fabric.Line([
        page.x, page.y + page.height / 2,
        page.x + page.width, page.y + page.height / 2
      ], lineOptions);

      //@ts-expect-error
      horizontalLine.elementType = ElementType.background;

      medianLines.push(horizontalLine);

      let verticalLine = new fabric.Line([
        page.x + page.width / 2, page.y,
        page.x + page.width / 2, page.y + page.height
      ], lineOptions);

      //@ts-expect-error
      verticalLine.elementType = ElementType.background;

      medianLines.push(verticalLine);

      return medianLines;
    }
  }

  private addPageViewSeparatorToCanvas(page: PageElement) {
    let design = page?.parent;
    if (!design || design.view !== View.userSpreads && design.view !== View.card) {
      return;
    }

    let spread = design.spreads.find(spread => spread.spreadPage.route.toString() == page.route.toString());
    let spreadPages = spread?.pages;
    spreadPages = spreadPages.slice(0, spreadPages.length - 1);

    let separatorLines: fabric.Line[] = [];
    let width: number;

    for (let spreadPage of spreadPages) {
      width ??= spreadPage.width;

      let separatorLine = new fabric.Line([
        page.x + width, page.y,
        page.x + width, page.y + page.height
      ], {
        stroke: 'rgba(128, 128, 128, 0.5)',
        strokeWidth: 1 / this.zoomToFit,
        strokeUniform: true,
        evented: false
      });
      //@ts-expect-error
      separatorLine.elementType = ElementType.background;
      separatorLines.push(separatorLine);
      width += spreadPage.width;
    }

    this.canvas.add(...separatorLines);
  }

  private async addPageFoilToCanvasAsync(page: PageElement, abortSignal: AbortSignal) {
    let pageRect = this.findObject(page.route);
    if (!pageRect) {
      return;
    }

    // Create foil objects

    let foilObjects: fabric.Object[] = [];
    let gradient: GradientData;

    let foilElements = this.getFoilElements(page);
    for (let foilElement of foilElements) {
      if (abortSignal.aborted) {
        return;
      }

      let foilObject = await this.createFoilObjectAsync(foilElement);
      if (foilObject) {
        foilObjects.push(foilObject);

        if (!gradient && foilElement.isSpecialColorElement()) {
          gradient = gradients[foilElement.foilType];
        }
      }
    }

    if (!foilObjects.length || !gradient || abortSignal.aborted) {
      return;
    }

    // Create foil rect

    let existingFoilRect = this.findFoilObject(page.route);
    let foilRect = existingFoilRect ?? new fabric.Rect({
      evented: false,
    });

    foilRect.set({
      left: pageRect.left,
      top: pageRect.top,
      width: pageRect.width,
      height: pageRect.height,
    });

    foilRect.setCoords();

    let existingFoilClipPath = foilRect.clipPath instanceof fabric.Group ? foilRect.clipPath : null;
    let foilClipPath = existingFoilClipPath ?? new fabric.Group([], {
      evented: false,
    });

    this.upsertGroupObjects(foilClipPath, foilObjects);

    foilRect.set({
      fill: GradientUtils.createGradient(foilRect.width * 1.25, foilRect.height * 1.25, gradient),
      clipPath: foilClipPath?.set({
        absolutePositioned: true,
        clipPath: pageRect?.set({
          absolutePositioned: true,
        }),
      }),
      // @ts-expect-error
      [X_FOIL_ROUTE]: page.route,
    });

    foilRect.setCoords();

    // Create foil (rainbow) image

    let patternImage: fabric.Image;
    if (gradient.id === gradients.rainbow.id) {
      let existingPatternImage = this.findFoilImage(page.route);
      patternImage = existingPatternImage instanceof fabric.Image
        ? existingPatternImage
        : await new Promise((resolve: (object: fabric.Image) => void) => {
          fabric.Image.fromURL("/assets/rainbow-pattern.png", (object: fabric.Image) => {
            object.set({
              evented: false,
              // @ts-expect-error
              [X_FOIL_IMAGE_ROUTE]: page.route,
            });
            resolve(object);
          }, {
            crossOrigin: "anonymous",
          });
        });

      patternImage.set({
        left: pageRect.left,
        top: pageRect.top,
        scaleX: pageRect.width / patternImage.width,
        scaleY: pageRect.height / patternImage.height,
        clipPath: foilClipPath?.set({
          absolutePositioned: true,
          clipPath: pageRect?.set({
            absolutePositioned: true,
          }),
        }),
      });
    }

    // Upsert foil rect to canvas

    let isAddedToCanvas = !!this.findFoilObject(page.route);
    let index = this.canvas.getObjects().length;

    if (!isAddedToCanvas) {
      patternImage && this.canvas.insertAt(patternImage, index++, false);
      this.canvas.insertAt(foilRect, index, false);
    } else {
      patternImage && this.canvas.insertAt(patternImage, index++, false);
      foilRect.moveTo(index);
    }
  }

  private async addPageSpotUvToCanvasAsync(page: PageElement, abortSignal: AbortSignal) {
    let pageRect = this.findObject(page.route);
    if (!pageRect) {
      return;
    }

    // Create foil objects

    let spotUvObjects: fabric.Object[] = [];
    let gradient: GradientData;

    let spotUvElements = this.getSpotUvElements(page);
    for (let spotUvElement of spotUvElements) {
      if (abortSignal.aborted) {
        return;
      }

      let foilObject = await this.createSpotUvObjectAsync(spotUvElement);
      if (foilObject) {
        spotUvObjects.push(foilObject);

        if (!gradient && spotUvElement.isSpecialColorElement()) {
          gradient = gradients.spotUv;
        }

      }
    }

    if (!spotUvObjects.length || !gradient || abortSignal.aborted) {
      return;
    }

    // Create foil rect

    let existingSpotUvRect = this.findSpotUvObject(page.route);
    let spotUvRect = existingSpotUvRect ?? new fabric.Rect({
      evented: false,
    });

    spotUvRect.set({
      left: pageRect.left,
      top: pageRect.top,
      width: pageRect.width,
      height: pageRect.height,
    });

    spotUvRect.setCoords();

    let existingSpotUvClipPath = spotUvRect.clipPath instanceof fabric.Group ? spotUvRect.clipPath : null;
    let spotUvClipPath = existingSpotUvClipPath ?? new fabric.Group([], {
      evented: false,
    });

    this.upsertGroupObjects(spotUvClipPath, spotUvObjects);

    spotUvRect.set({
      fill: GradientUtils.createGradient(spotUvRect.width * 1.25, spotUvRect.height * 1.25, gradient),
      clipPath: spotUvClipPath?.set({
        absolutePositioned: true,
        clipPath: pageRect?.set({
          absolutePositioned: true,
        }),
      }),
      // @ts-expect-error
      [X_SPOT_UV_ROUTE]: page.route,
    });

    spotUvRect.setCoords();

    // Upsert foil rect to canvas

    let isAddedToCanvas = !!this.findSpotUvObject(page.route);
    let index = this.canvas.getObjects().length;

    if (!isAddedToCanvas) {
      this.canvas.insertAt(spotUvRect, index, false);
    } else {
      spotUvRect.moveTo(index);
    }
  }

  private removeElementsFromCanvas() {
    let canvasObjects = this.canvas.getObjects();
    let objectsToRemove = canvasObjects
      .filter((object) => {
        let route: number[] = object[X_ROUTE] || object[X_CROP_ROUTE] || object[X_COAT_ROUTE] || object[X_FOIL_ROUTE] || object[X_FOIL_IMAGE_ROUTE] || object[X_SPOT_UV_ROUTE] || object[X_CUT_THROUGH_ROUTE];
        if (!route) return true;

        let element = this.findElement(route);
        if (!element) return true;

        let removeText = !this.isPageThumb && element.isInlineText() && ((object instanceof fabric.Textbox && !element.selected) || (object instanceof fabric.Image && element.selected));
        if (removeText) return true;

        let hasNoCrop = object[X_CROP_ROUTE] && (!element.parent.selected || element.imgSource !== (object as fabric.Image).getSrc());
        if (hasNoCrop) return true;

        let hasNoCoat = object[X_COAT_ROUTE] && !CanvasElementUtils.hasCoating(element);
        if (hasNoCoat) return true;

        let hasNoFoil = object[X_FOIL_ROUTE] && !this.getFoilElements(element).length;
        if (hasNoFoil) return true;

        let hasNoFoilImage = object[X_FOIL_IMAGE_ROUTE] && !this.getFoilElements(element).length;
        if (hasNoFoilImage) return true;

        let hasSpotUv = object[X_SPOT_UV_ROUTE] && !this.getSpotUvElements(element).length;
        if (hasSpotUv) return true;

        let hasNoCutThrough = (
          object[X_CUT_THROUGH_ROUTE] &&
          element.isPage() && (
            object.inverted
              ? !CanvasElementUtils.getCutThroughElements(element).length && !CanvasElementUtils.getBackSideCutThroughElements(element).length
              : !CanvasElementUtils.getInvertedCutThroughElements(element).length && !CanvasElementUtils.getBackSideInvertedCutThroughElements(element).length
          ));
        if (hasNoCutThrough) return true;

        return false;
      });

    for (let objectToRemove of objectsToRemove) {
      this.canvas.remove(objectToRemove);
    }
  }
}
