import { EditorAction } from '../action';
import { SET_USER_TITLE_AND_ID } from './canvas.action.types';

export class SetUserTitleAndId implements EditorAction {
  readonly type = SET_USER_TITLE_AND_ID;
  readonly undoable = false;
  readonly save = false;

  constructor(public user_design_title: string, public user_design_id: number) {}
}
