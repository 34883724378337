import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SliderComponent } from '../slider.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonFunctions } from '../../counter/counter-button.component';
import { newColorShade } from '../../../utils/color-utils';

@Component({
  selector: 'ed-slider-mob',
  templateUrl: 'slider-mob.component.html',
  styleUrls: ['./slider-mob.component.scss', '../../mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderMobComponent extends SliderComponent implements OnInit {
  @Input() range: Array<number>;
  @Input() increaseButtonIcon: IconProp = 'plus';
  @Input() decreaseButtonIcon: IconProp = 'minus';

  increaseButtonDisabled: boolean;
  decreaseButtonDisabled: boolean;

  buttonFunctions = ButtonFunctions;

  ngOnInit() {
    this.setButtonsDisabled(this.elementCount);
  }

  setButtonsDisabled(currentValue: number) {
    if (this.range) {
      const rangeLastIndex = this.range.length - 1;
      this.decreaseButtonDisabled = currentValue <= this.range[0];
      this.increaseButtonDisabled = currentValue >= this.range[rangeLastIndex];
    } else {
      this.decreaseButtonDisabled = currentValue <= this.minCount;
      this.increaseButtonDisabled = currentValue >= this.maxCount;
    }
  }

  changeBySliderButton(value: number) {
    const newValue = this.boundCount(value);
    this.setButtonsDisabled(newValue);
    this.changeValue.emit(newValue);
  }
}
