import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as Recover from './actions';
import * as Auth from '../auth/actions';
import * as fromRecover from './reducer';
import { RecoverUser } from '../models/user';

@Component({
  selector: 'ed-recover-success-dialog',
  templateUrl: 'recover-success-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss', 'recover-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoverSuccessDialogComponent implements OnInit {
  recoverUser$: Observable<RecoverUser>;

  constructor(
    public dialogRef: MatDialogRef<RecoverSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; loginButtonText: string },
    private store: Store<fromRecover.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());
    this.recoverUser$ = this.store.pipe(select(fromRecover.getUser));
  }

  onNoClick(): void {
    this.store.dispatch(new Recover.CloseRecoverSuccessDialog());
  }

  close(): void {
    this.dialogRef.close();
  }

  openLoginDialogAfterRecover() {
    this.store.dispatch(new Auth.OpenLoginDialogAfterRecover());
  }
}
