import { FOIL_GRADIENT_ID_PREFIX, FoilTypes, SPOT_UV_GRADIENT_ID, SpotUvType } from '../models';

export interface GradientStop {
  offset: string;
  opacity: string;
  color: string;
}

export interface GradientData {
  id: string;
  animated: boolean;
  stops: GradientStop[];
}

export const gradients = {
  gold: {
    id: FOIL_GRADIENT_ID_PREFIX + 'gold',
    animated: false,
    stops: [
      {
        offset: '0%',
        opacity: '1',
        color: '#dbaf00'
      },
      {
        offset: '10%',
        opacity: '1',
        color: '#dbaf00'
      },
      {
        offset: '90%',
        opacity: '1',
        color: '#f7f39e'
      },
      {
        offset: '100%',
        opacity: '1',
        color: '#f7f39e'
      }
    ]
  },
  silver: {
    id: FOIL_GRADIENT_ID_PREFIX + 'silver',
    animated: false,
    stops: [
      {
        offset: '0%',
        opacity: '1',
        color: '#70706e'
      },
      {
        offset: '10%',
        opacity: '1',
        color: '#70706e'
      },
      {
        offset: '90%',
        opacity: '1',
        color: '#ececea'
      },
      {
        offset: '100%',
        opacity: '1',
        color: '#ececea'
      }
    ]
  },
  copper: {
    id: FOIL_GRADIENT_ID_PREFIX + 'copper',
    animated: false,
    stops: [
      {
        offset: '0%',
        opacity: '1',
        color: '#d17036'
      },
      {
        offset: '10%',
        opacity: '1',
        color: '#d17036'
      },
      {
        offset: '90%',
        opacity: '1',
        color: '#f6be82'
      },
      {
        offset: '100%',
        opacity: '1',
        color: '#f6be82'
      }
    ]
  },
  rose: {
    id: FOIL_GRADIENT_ID_PREFIX + 'rose',
    animated: false,
    stops: [
      {
        offset: '0%',
        opacity: '1',
        color: '#e5a481'
      },
      {
        offset: '10%',
        opacity: '1',
        color: '#e5a481'
      },
      {
        offset: '90%',
        opacity: '1',
        color: '#ffeadb'
      },
      {
        offset: '100%',
        opacity: '1',
        color: '#ffeadb'
      }
    ]
  },
  black: {
    id: FOIL_GRADIENT_ID_PREFIX + 'black',
    animated: false,
    stops: [
      {
        offset: '0%',
        opacity: '1',
        color: '#000000'
      },
      {
        offset: '10%',
        opacity: '1',
        color: '#000000'
      },
      {
        offset: '90%',
        opacity: '1',
        color: '#666666'
      },
      {
        offset: '100%',
        opacity: '1',
        color: '#666666'
      }
    ]
  },
  rainbow: {
    id: FOIL_GRADIENT_ID_PREFIX + 'rainbow',
    animated: true,
    stops: [
      {
        offset: '0%',
        opacity: '.6',
        color: '#ffffff'
      },
      {
        offset: '10%',
        opacity: '.6',
        color: '#ffffff'
      },
      {
        offset: '90%',
        opacity: '0',
        color: '#ffffff'
      },
      {
        offset: '100%',
        opacity: '0',
        color: '#ffffff'
      }
    ]
  },
  spotUv: {
    id: SPOT_UV_GRADIENT_ID,
    animated: true,
    stops: [
      {
        offset: '0%',
        opacity: '.6',
        color: '#ffffff'
      },
      {
        offset: '10%',
        opacity: '.6',
        color: '#ffffff'
      },
      {
        offset: '90%',
        opacity: '0',
        color: '#ffffff'
      },
      {
        offset: '100%',
        opacity: '0',
        color: '#ffffff'
      }
    ]
  }
};

export function getGradientData(gradientType: FoilTypes | SpotUvType): GradientData {
  if (gradientType) {
    return gradients[gradientType];
  }
}
