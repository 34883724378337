import { DEFAULT_INLINE_TEXT_DESCENDER, DEFAULT_INLINE_TEXT_ASCENDER } from './inline-text-element';

export enum FontStatus {
  active = 'active',
  inactive = 'inactive',
  loading = 'loading'
}

export const FALLBACK_FONT_DEFAULT = 'Arial';
export const FALLBACK_FONT_MONOSPACE = 'Courier New';
export const DEFAULT_FONTS = [FALLBACK_FONT_DEFAULT, FALLBACK_FONT_MONOSPACE];

export class FontFace {
  family: string;
  url: string;
  status: FontStatus = FontStatus.loading;
  styles: string[];
}

export class Font {
  imageSampleSource = '';
  constructor(
    public name: string,
    public screenTitle: string = '',
    public status: FontStatus = FontStatus.loading,
    public ascender = DEFAULT_INLINE_TEXT_ASCENDER,
    public descender = DEFAULT_INLINE_TEXT_DESCENDER,
    public isMonospace = false,
    public oldNames: Array<string> = [],
    public fontFaces: Array<FontFace> = []
  ) {
    this.imageSampleSource = '/fontsample/' + name + '?no_shrink=1&s=16';
    this.screenTitle = screenTitle || name;
  }
}

export class FontResponse {
  name: string;
  screen_title: string;
}
