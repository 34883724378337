import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { ImageCategory } from '../models';
import * as fromConfig from '../config/reducer';
import { ParamsService } from '../services/params.service';
import * as fromImageLibrary from './reducer';
import { mergeCategories } from './image-library.utils';
import {
  ImageLibrary,
  ImageLibraryJSON,
  ImageLibrarySearchJSON,
  ImageLibraryType,
  ImageLibraryWithSearchTerm
} from './image-library';

declare let dataLayer: Array<any>;

@Injectable()
export class ImageLibraryService {
  imageLibraryUrl$: Observable<string>;
  imageLibrarySearchUrl$: Observable<string>;
  imageLibraryOpen$: Observable<boolean>;
  imageSearchLimit$: Observable<number>;
  limit: number;
  savedSearchTerm$: Observable<string>;
  savedImagelibrary$: Observable<ImageLibrary>;
  public requestPending: boolean;

  constructor(private http: HttpClient, private store$: Store<AppState>, private paramsService: ParamsService) {
    this.imageLibraryUrl$ = this.store$.pipe(select(fromConfig.getImageLibraryUrl));
    this.imageLibrarySearchUrl$ = this.store$.pipe(select(fromConfig.getImageLibrarySearchUrl));
    this.imageLibraryOpen$ = this.store$.pipe(select(fromImageLibrary.getImageLibraryOpen));
    this.imageSearchLimit$ = this.store$.pipe(select(fromConfig.getImageSearchLimit));
    this.savedSearchTerm$ = this.store$.pipe(select(fromImageLibrary.getSavedSearchTerm));
    this.savedImagelibrary$ = this.store$.pipe(select(fromImageLibrary.getSavedImagelibrary));
  }

  load(): Observable<ImageLibrary> {
    // division of main categories here means that imagelibrary is not mutated beyond this point except via store ('update')
    return this.imageLibraryUrl$.pipe(
      mergeMap(imageLibraryUrl => this.http.get(imageLibraryUrl, { params: this.paramsService.httpParams })),
      map(
        (imageLibrary: ImageLibraryJSON) =>
          new ImageLibrary([
            new ImageCategory(
              {
                imageType: ImageLibraryType.image,
                subcategories: imageLibrary.categories.filter(cat => cat.imageType === ImageLibraryType.image)
              },
              [0]
            ),
            new ImageCategory(
              {
                imageType: ImageLibraryType.background,
                subcategories: mergeCategories(imageLibrary.categories)
              },
              [1]
            )
          ])
      )
    );
  }

  search(searchTerm: string, dataLayerId: string): Observable<ImageLibraryWithSearchTerm> {
    this.requestPending = true;
    return this.imageLibrarySearchUrl$.pipe(
      withLatestFrom(this.imageSearchLimit$),
      mergeMap(([imageLibrarySearchUrl, limit]) =>
        this.http.get(imageLibrarySearchUrl, {
          params: this.paramsService.httpParams.append('search_term', searchTerm).append('limit', limit)
        })
      ),
      map((imageLibrarySearch: ImageLibrarySearchJSON) => {
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            Edclicks: dataLayerId,
            event: 'EditorImageSearch',
            searchTerm: searchTerm,
            searchResult: imageLibrarySearch.images.length
          });
        }
        this.requestPending = false;
        return new ImageLibraryWithSearchTerm(
          new ImageLibrary([
            new ImageCategory(
              {
                imageType: ImageLibraryType.image,
                images: imageLibrarySearch.images
              },
              [0]
            )
          ]),
          searchTerm
        );
      }),
      catchError(() => {
        this.requestPending = false;

        return of(
          new ImageLibraryWithSearchTerm(
            new ImageLibrary([
              new ImageCategory(
                {
                  imageType: ImageLibraryType.image,
                  images: []
                },
                [0]
              )
            ]),
            searchTerm
          )
        );
      })
    );
  }
}
