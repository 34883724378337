import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import {
  tap,
  map,
  switchMap,
  catchError,
  withLatestFrom,
  filter,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import * as fromPermissions from '../reducers/permissions.reducer';
import * as fromSave from './reducer';
import * as fromRegister from '../register/reducer';
import * as fromRecover from '../recover/reducer';
import * as fromAuth from '../auth/reducer';
import {
  SaveActionTypes,
  Save,
  SubmitSave,
  SubmitFailure,
  SubmitSuccess,
  OpenSaveDialog,
  CancelLogin,
  LoginSuccess,
  RegisterSuccess,
  InitSuccess,
  ResetLastSavedDesign,
  Copy
} from './actions';
import { SaveDialogComponent } from './save-dialog.component';
import { SaveService } from './save.service';
import { CanvasActions, CanvasActionTypes, UndoActions, PermissionActionTypes } from '../actions';
import { AppState } from '../reducers';
import { AuthActionTypes, OpenLoginDialog, OpenLoginDialogFromSave } from '../auth/actions';
import { RegisterActionTypes } from '../register/actions';
import * as UndoRedoActionTypes from '../actions/undo-actions/undo.action.types';
import { HttpErrorResponse } from '@angular/common/http';
import { GetTextService } from '../services';
import { getDesignSet } from '../selectors';
import { CopyDialogComponent } from './copy-dialog.component';
import * as PermissionActions from '../actions/permission-actions';
import { isDesignSetSubmit } from '../models/design-submit';
import { DesignSet } from '../models/design-set';

@Injectable()
export class SaveEffects {
  /* Init */
  @Effect()
  saveInit$: Observable<Action> = this.actions$.pipe(
    ofType(PermissionActionTypes.INIT_USER),
    switchMap(() => this.saveService.init().pipe(map(response => new PermissionActions.SetDesign(response))))
  );

  @Effect()
  convertDesign$: Observable<Action> = this.actions$.pipe(
    ofType(PermissionActionTypes.SET_DESIGN),
    switchMap((action: PermissionActions.SetDesign) =>
      this.saveService.transformDesignAndAdjustText(action.designOrSetSubmit)
    ),
    map(designSet => new InitSuccess(designSet))
  );

  /* Save */
  @Effect()
  save$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.Save),
    withLatestFrom(this.store$.pipe(select(fromAuth.isLoggedIn))),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    map(([[action, loggedIn], designSet]) => {
      if (!loggedIn) {
        return new OpenLoginDialogFromSave();
      } else if (designSet.userCollectionId) {
        return new SubmitSave(designSet.userCollectionTitle, loggedIn);
      } else {
        return new OpenSaveDialog();
      }
    })
  );

  @Effect()
  copy$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.Copy),
    map((action: Copy) => action),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    map(([action, designSet]) => {
      const designSubmit = this.saveService.transformToKc(designSet);
      isDesignSetSubmit(designSubmit)
        ? (designSubmit.user_collection_title = action.name)
        : (designSubmit.user_design_title = action.name);
      return designSubmit;
    }),
    switchMap(designSubmit =>
      this.saveService.makeCopy(designSubmit).pipe(
        map(design => new SubmitSuccess(design, true)),
        catchError(error => this.handleError(error))
      )
    )
  );

  /* SubmitSave, SubmitSuccess and SubmitFailure */
  @Effect()
  submitSave$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.SubmitSave),
    switchMap((action: SubmitSave) =>
      this.saveService.saveDesign(action.title, action.save).pipe(
        map(design => new SubmitSuccess(design, action.save)),
        catchError(error => this.handleError(error))
      )
    )
  );

  @Effect()
  submitSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.SubmitSuccess),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    // only dispatch setUserTitleAndDesign when they did change
    filter(([action, designSet]: [SubmitSuccess, DesignSet]) => {
      return (
        action.designSet.userCollectionTitle !== designSet.userCollectionTitle ||
        action.designSet.userCollectionId !== designSet.userCollectionId
      );
    }),
    map(([action, designSet]: [SubmitSuccess, DesignSet]) => {
      return new CanvasActions.SetUserTitleAndId(
        action.designSet.userCollectionTitle,
        action.designSet.userCollectionId
      );
    })
  );

  @Effect()
  submitFailure$ = this.actions$.pipe(
    ofType(SaveActionTypes.SubmitFailure),
    withLatestFrom(
      this.store$.pipe(select(fromSave.getSaveDialogOpen)),
      this.store$.pipe(select(fromSave.getCopyDialogOpen))
    ),
    map(([action, saveDialogOpen, copyDialogOpen]) => {
      if (copyDialogOpen) {
        return [action, copyDialogOpen];
      }
      return [action, saveDialogOpen];
    }),
    filter(([action, dialogOpen]) => !dialogOpen),
    map(() => new OpenSaveDialog())
  );

  /* Open/Close saveDialog */
  @Effect({ dispatch: false })
  openSaveModal$ = this.actions$.pipe(
    ofType(SaveActionTypes.OpenSaveDialog),
    tap(
      () =>
        (this.saveDialog = this.dialog.open(SaveDialogComponent, {
          width: '400px',
          data: {
            text: {
              dialogText: this.getTextService.text.dialog.save,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  @Effect({ dispatch: false })
  closeSaveModal$ = this.actions$.pipe(
    ofType(SaveActionTypes.CloseSaveDialog, SaveActionTypes.SubmitSuccess),
    filter(() => !!this.saveDialog),
    tap(() => this.saveDialog.close())
  );

  @Effect({ dispatch: false })
  openCopyModal$ = this.actions$.pipe(
    ofType(SaveActionTypes.OpenCopyDialog),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    map(
      ([action, designSet]) =>
        (this.copyDialog = this.dialog.open(CopyDialogComponent, {
          width: '400px',
          data: {
            text: {
              proposedDesignTitle: designSet.userCollectionTitle,
              dialogText: this.getTextService.text.dialog.copy,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  // by default, an effect dispatches an action: it is a stream of actions to be dispatched. In this case we don't want to update the state
  // after the effect, so we don't want to dispatch an action. With { dispatch: false } the observable does not complete
  // and does not dispatch an action.
  @Effect()
  automaticSave$ = this.actions$.pipe(
    ofType(...Object.values(CanvasActionTypes), ...Object.values(UndoRedoActionTypes)),
    filter((action: CanvasActions.All | UndoActions.All) => action.save),
    withLatestFrom(this.store$.pipe(select(getDesignSet))),
    debounceTime(350),
    map(([action, designSet]) => designSet),
    distinctUntilChanged(
      (oldDesignSet, newDesignSet) => !this.saveService.designHasChanges(newDesignSet, oldDesignSet)
    ),
    withLatestFrom(this.store$.pipe(select(fromAuth.isLoggedIn))),
    withLatestFrom(this.store$.pipe(select(fromPermissions.getAutoSave))),
    map(([[designSubmit, isLoggedIn], autoSave]) => {
      const save = isLoggedIn && autoSave;
      return new SubmitSave(undefined, save);
    })
  );

  /* Login */
  @Effect()
  loginOpenLoginDialog$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.Login),
    map(() => new OpenLoginDialogFromSave())
  );

  @Effect()
  closeLoginModal$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.CloseLoginDialog),
    withLatestFrom(this.store$.pipe(select(fromSave.getLoginOrRegisterPending))),
    withLatestFrom(this.store$.pipe(select(fromRegister.getDialogOpen))), // login can also be called from register
    withLatestFrom(this.store$.pipe(select(fromRecover.getDialogOpen))), // login can also be called from recover
    filter(
      ([[[action, loginOrRegisterPending], registerDialogOpen], recoverDialogOpen]) =>
        loginOrRegisterPending && !registerDialogOpen && !recoverDialogOpen
    ),
    map(() => new CancelLogin())
  );

  @Effect()
  cancelLogin$ = this.actions$.pipe(
    ofType(SaveActionTypes.CancelLogin),
    map(() => new OpenLoginDialog())
  );

  @Effect()
  loginSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    withLatestFrom(this.store$.pipe(select(fromSave.getLoginOrRegisterPending))),
    withLatestFrom(this.store$.pipe(select(fromRegister.getSuccessDialogOpen))), // register success also does login success
    filter(([[action, loginOrRegisterPending], successDialogOpen]) => loginOrRegisterPending && !successDialogOpen),
    map(() => new LoginSuccess())
  );

  @Effect()
  loginSuccessSave$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.LoginSuccess),
    map(() => new Save())
  );

  @Effect()
  registerSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(RegisterActionTypes.CloseRegisterSuccessDialog),
    withLatestFrom(this.store$.pipe(select(fromSave.getLoginOrRegisterPending))), // register can also be called from login
    filter(([action, loginOrRegisterPending]) => loginOrRegisterPending),
    map(() => new RegisterSuccess())
  );

  @Effect()
  registerSuccessSave$: Observable<Action> = this.actions$.pipe(
    ofType(SaveActionTypes.RegisterSuccess),
    map(() => new Save())
  );

  @Effect()
  resetLastSavedDesign: Observable<Action> = this.actions$.pipe(
    ofType(CanvasActionTypes.INIT),
    // if init design does not have an user design id, reset it (import json, adjust pages etc)
    filter((action: CanvasActions.Init) => !action.designSet.userCollectionId),
    map(() => new ResetLastSavedDesign())
  );

  saveDialog: MatDialogRef<SaveDialogComponent>;
  copyDialog: MatDialogRef<CopyDialogComponent>;

  handleError(error: HttpErrorResponse) {
    // only dispatch SubmitFailure when clientside error occurs
    if (error.status >= 400 && error.status < 500) {
      return of(new SubmitFailure(error.error));
    } else {
      throw error;
    }
  }

  constructor(
    private saveService: SaveService,
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<AppState>,
    private getTextService: GetTextService
  ) {}
}
