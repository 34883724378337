import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Save from './actions';
import { select, Store } from '@ngrx/store';
import * as fromSave from './reducer';
import * as fromContinue from '../continue/reducer';
import { Observable } from 'rxjs';
import { first, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ClientError } from '../models/client-error';
import { DialogButtonText, SaveDialogText } from '../models/text';
import { DESIGN_NAME_COLLECTION_MAX_LENGTH } from '../data/designs.data';
import * as fromAuth from '../auth/reducer';
import { getDesignSet } from '../selectors';

@Component({
  selector: 'ed-save-dialog',
  templateUrl: 'save-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveDialogComponent implements OnInit {
  pending$: Observable<boolean>;
  errors$: Observable<ClientError[]>;

  isLoggedIn$: Observable<boolean>;
  title$: Observable<string>;
  submitButtonText$: Observable<string>;

  form = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(DESIGN_NAME_COLLECTION_MAX_LENGTH)])
  });
  dataLayerIdPrefix = 'popup-change-name-';

  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: { dialogText: SaveDialogText; buttonText: DialogButtonText } },
    public store$: Store<fromSave.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());

    this.pending$ = this.store$.pipe(
      select(fromSave.getSavePending),
      tap(pending => (pending ? this.form.disable() : this.form.enable()))
    );

    const designSet$ = this.store$.pipe(select(getDesignSet));
    this.isLoggedIn$ = this.store$.pipe(select(fromAuth.isLoggedIn));

    const save$ = designSet$.pipe(
      withLatestFrom(this.isLoggedIn$),
      map(([designSet, loggedIn]) => loggedIn && !designSet.userCollectionId)
    );

    this.errors$ = this.store$.pipe(select(fromSave.getError));
    designSet$.pipe(take(1)).subscribe(design => this.form.controls['title'].setValue(design.userCollectionTitle));

    const continue$ = this.store$.pipe(select(fromContinue.getSavePending));

    this.title$ = save$.pipe(
      withLatestFrom(continue$),
      map(([save, go]) =>
        go
          ? this.data.text.dialogText.saveAndContinueTitle
          : save
          ? this.data.text.dialogText.saveDesignTitle
          : this.data.text.dialogText.changeDesignNameTitle
      )
    );

    this.submitButtonText$ = save$.pipe(
      withLatestFrom(continue$),
      map(([save, go]) =>
        go
          ? this.data.text.dialogText.saveAndContinueButtonText
          : save
          ? this.data.text.dialogText.saveDesignButtonText
          : this.data.text.dialogText.completedButtonText
      )
    );
  }

  get title(): AbstractControl {
    return this.form.get('title');
  }

  onNoClick(): void {
    this.store$.dispatch(new Save.CloseSaveDialog());
    this.resetErrors();
  }

  close(): void {
    this.dialogRef.close();
  }

  resetErrors(): void {
    this.store$.dispatch(new Save.ResetValidationErrors());
  }

  submit() {
    const errors = [];
    if (this.form.valid) {
      this.dispatch(this.form.value.title);
    } else if (this.title.errors.required) {
      errors.push({ message: this.data.text.dialogText.submitErrorNameText, code: '' });
    } else if (this.title.errors.maxlength) {
      errors.push({ message: this.data.text.dialogText.submitErrorLengthText, code: '' });
    }
    if (errors.length) {
      this.store$.dispatch(new Save.SubmitFailure(errors));
    }
  }

  dispatch(title: string) {
    this.isLoggedIn$.pipe(first()).subscribe(isLoggedIn => {
      this.store$.dispatch(new Save.SubmitSave(title, isLoggedIn));
    });
  }
}
