import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ed-toolbar-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['../toolbar.scss', './chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent {
  @Input() chatTitle: string;
  @Output() showChat = new EventEmitter();

  onClick() {
    this.showChat.emit();
  }
}
