import { ClientError } from '../models/client-error';
import { Action } from '@ngrx/store';
import { DesignTitleResponse } from '../models/design-submit';

export enum AdminDesignerActionTypes {
  SaveTitleDescription = '[Design] Save Title/Description',
  SaveTitleDescriptionFailure = '[Design] Save Title/Description Failure',
  SaveTitleDescriptionSuccess = '[Design] Save Title/Description Success',
  SubmitSaveDesignForReview = '[Design] Submit Save Design For Review',
  SubmitDesignForReviewSuccess = '[Design] Submit Design For Review Success',
  SubmitDesignForReviewFailure = '[Design] Submit Design For Review Failure',
  SubmitSaveDesignOtherUser = '[Design] Submit Save Design Other User',
  SubmitDesignOtherUserSuccess = '[Design] Submit Design Other User Success',
  SubmitDesignOtherUserFailure = '[Design] Submit Design Other User Failure',
  ResetValidationErrors = '[Design] Reset Validation Errors'
}

export class SaveTitleDescription implements Action {
  readonly type = AdminDesignerActionTypes.SaveTitleDescription;

  constructor(public title: string, public description: string, public overwrite: boolean) {}
}

export class SaveTitleDescriptionFailure implements Action {
  readonly type = AdminDesignerActionTypes.SaveTitleDescriptionFailure;

  constructor(public payload: ClientError[]) {}
}

export class SaveTitleDescriptionSuccess implements Action {
  readonly type = AdminDesignerActionTypes.SaveTitleDescriptionSuccess;

  constructor(public data: DesignTitleResponse) {}
}

export class SubmitSaveDesignForReview implements Action {
  readonly type = AdminDesignerActionTypes.SubmitSaveDesignForReview;

  constructor(public title: string, public description: string, public requestOverwrite: boolean) {}
}

export class SubmitDesignForReviewSuccess implements Action {
  readonly type = AdminDesignerActionTypes.SubmitDesignForReviewSuccess;

  constructor(public data: DesignTitleResponse) {}
}

export class SubmitSaveDesignForReviewFailure implements Action {
  readonly type = AdminDesignerActionTypes.SubmitDesignForReviewFailure;

  constructor(public payload: ClientError[]) {}
}

export class SubmitSaveDesignOtherUser implements Action {
  readonly type = AdminDesignerActionTypes.SubmitSaveDesignOtherUser;

  constructor(public payload: TitleAndEmail) {}
}

export class SubmitDesignOtherUserSuccess implements Action {
  readonly type = AdminDesignerActionTypes.SubmitDesignOtherUserSuccess;
}

export class SubmitDesignOtherUserFailure implements Action {
  readonly type = AdminDesignerActionTypes.SubmitDesignOtherUserFailure;

  constructor(public payload: ClientError[]) {}
}

export class ResetValidationErrors implements Action {
  readonly type = AdminDesignerActionTypes.ResetValidationErrors;
}

export type AdminDesignerActions =
  | SaveTitleDescription
  | SaveTitleDescriptionFailure
  | SaveTitleDescriptionSuccess
  | SubmitSaveDesignOtherUser
  | SubmitDesignOtherUserSuccess
  | SubmitDesignOtherUserFailure
  | SubmitSaveDesignForReview
  | SubmitDesignForReviewSuccess
  | SubmitSaveDesignForReviewFailure
  | ResetValidationErrors;

export class TitleAndEmail {
  userTitle: string;
  emailAddress: string;
}
