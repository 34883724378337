import { uniq } from 'lodash-es';
import { BaseElement } from './base-element';
import { BackgroundElement } from './box-element';
import { CanvasCoordinate, CanvasElement, ElementType } from './canvas';
import { Design } from './design';
import { Font } from './font';
import { BackgroundImageElement, ImageElement } from './image-element';
import { InlineTextElement } from './inline-text-element';
import { ElementPermissions } from './permissions';
import { ROUNDED_CORNER_RADIUS } from './trim';

export enum BackgroundTypes {
  image = 'image',
  color = 'color'
}

export interface BorderRadius {
  leftTop: number;
  rightTop: number;
  rightBottom: number;
  leftBottom: number;
}

export class PageElement extends BaseElement {
  readonly type = ElementType.page;
  name = 'page';
  order: number; // used to set order of pages when toggling spreads/pages;
  label: Array<string>;
  parent: Design;
  bleed = 10; // bleed of old editor in kc, does not corespondent with bleed in production pdf's
  safety = 10;
  rotation = 0;
  fonts: Font[] = [];
  position: number;
  init = false;

  borderRadius: BorderRadius = {
    leftTop: ROUNDED_CORNER_RADIUS,
    rightTop: ROUNDED_CORNER_RADIUS,
    rightBottom: ROUNDED_CORNER_RADIUS,
    leftBottom: ROUNDED_CORNER_RADIUS
  };

  get pageSvgUseHref() {
    return `svg-page-${this.route}`;
  }

  // shown behind this page when cut through
  pageBehindId: number;
  // show inverted cut through elements of this page
  backSidePageId: number;

  minRelativeZoom = 25;
  relativeZoomSteps = [50, 75, 100, 150, 200, 400];

  permissions: ElementPermissions = {
    addChildElements: true,
    isLocked: false,
    isHidden: false,
    isFoilable: true,
    isSpotUvable: true
  };

  get center(): CanvasCoordinate {
    return { x: this.width / 2, y: this.height / 2 };
  }

  get leftTop() {
    return { x: 0, y: 0 };
  }

  get rightTop() {
    return { x: this.width, y: 0 };
  }

  get leftBottom() {
    return { x: 0, y: this.height };
  }

  get rightBottom() {
    return { x: this.width, y: this.height };
  }

  get pageWidth() {
    return this.width;
  }

  get designX() {
    return this.screenX;
  }

  get designY() {
    return this.screenY;
  }

  get overlayPosX() {
    return this.screenX;
  }

  get overlayPosY() {
    return this.screenY;
  }

  get pageX() {
    return this.screenX;
  }

  get pageY() {
    return this.screenY;
  }

  get screenRotation() {
    return this.rotation;
  }

  get visiblePageBoundingBox() {
    const allPoints = this.children
      .filter(elem => elem.permissions.isVisibleOutsideCropArea)
      .flatMap(elem => elem.polygon);

    const x = Math.min(0, ...allPoints.map(point => point.x));
    const y = Math.min(0, ...allPoints.map(point => point.y));
    const width = Math.max(this.width, ...allPoints.map(point => point.x)) - x;
    const height = Math.max(this.height, ...allPoints.map(point => point.y)) - y;

    return { x, y, width, height };
  }

  get visiblePageBoundingBoxCenter() {
    const bb = this.visiblePageBoundingBox;
    return {
      x: bb.width / 2 + bb.x,
      y: bb.height / 2 + bb.y
    };
  }

  get backgroundElements() {
    return this.children.filter(element => element.type === ElementType.background) as Array<BackgroundElement>;
  }

  // returns undefined if multiple backgroundElements and nothing selected
  get background(): BackgroundElement | ImageElement | BackgroundImageElement {
    // there is a backgroundImage selected => return selectedElement
    if (this.selectedElement && this.selectedElement.isBackgroundImage()) {
      return this.selectedElement as ImageElement | BackgroundImageElement;
    }

    // there is a backgroundElement selected => return backgroundElement or image inside of backgroundElement
    if (this.selectedElement && this.selectedElement.isBackgroundElement()) {
      return this.selectedElement.firstChild
        ? (this.selectedElement.firstChild as ImageElement)
        : (this.selectedElement as BackgroundElement);
    }

    // in spreadview and nothing selected => return spreadBackgroundImage
    if (this.hasMultipleBackgroundElements) {
      return this.spreadBackgroundImage;
    }

    // in pageview and nothing selected => return pageBackgroundImage or SpreadImage or BackroundElement
    if (!this.hasMultipleBackgroundElements) {
      const backgroundElement = this.children.find(element => element.isBackgroundElement());
      return backgroundElement.firstChild
        ? (backgroundElement.firstChild as ImageElement)
        : this.spreadBackgroundImage
          ? (this.spreadBackgroundImage as BackgroundImageElement)
          : (backgroundElement as BackgroundElement);
    }
  }

  get backgroundIsLocked(): boolean {
    if (!this.background) {
      return this.backgroundElements.filter(el => el.permissions.isLocked).length > 0;
    }
    return this.background.combinedPermissions.isLocked;
  }

  get hasMultipleBackgroundElements() {
    return this.backgroundElements.length > 1;
  }

  /**
   * returns topmost backgroundImageElement
   */
  get spreadBackgroundImage(): BackgroundImageElement {
    return this.children
      .slice()
      .reverse()
      .find(element => element.isSpreadBackgroundImage()) as BackgroundImageElement;
  }

  get backgroundColor() {
    return this.backgroundElements[0].color;
  }

  get backSidePage() {
    return this.backSidePageId ? this.parent.getPageElement(this.backSidePageId) : undefined;
  }

  get hasCutThroughElements(): boolean {
    const pageHasCutThrough = this.children.filter(elem => elem.isCutThrough || elem.isCutThroughInverted).length > 0;
    const backSidePageHasCutThrough = this.backSidePage
      ? this.backSidePage.children.filter(elem => elem.isCutThrough || elem.isCutThroughInverted).length > 0
      : false;
    return pageHasCutThrough || backSidePageHasCutThrough;
  }

  get minZoom() {
    return this.minRelativeZoom / 100;
  }

  get maxZoom() {
    return this.page.relativeZoomSteps[this.page.relativeZoomSteps.length - 1] / 100;
  }

  /**
   * @param element to be added to the page
   * @param id: existing id if added from json (should always be unique)
   */
  addElement(element: CanvasElement, id?: number, order?: number) {
    element.parent = this;
    element.id = id !== undefined ? id : this.newId;
    element.order = order !== undefined ? order : this.newOrder;
    this.children.push(element);
    this.sortChildren();
  }

  getInlineFonts(): Font[] {
    const inlineTextFonts = (this.children.filter(el => el.isInlineText()) as InlineTextElement[]).flatMap(
      el => el.fonts
    );
    return uniq(inlineTextFonts)
      .sort()
      .map(font => new Font(font));
  }

  constructor(id: number, { width = 10, height = 8, order = 0 } = {}) {
    super(id);
    this.width = width;
    this.height = height;
    this.order = order;
  }
}
