import { EditorAction } from '../action';
import { ADD_TAG } from './canvas.action.types';

export class AddTag implements EditorAction {
  readonly type = ADD_TAG;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public tag: string) {}
}
