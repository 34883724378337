import { EditorAction } from '../action';
import { ENLARGE_TEXTAREA } from './canvas.action.types';

export class EnlargeTextarea implements EditorAction {
  readonly type = ENLARGE_TEXTAREA;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>, public enlargeTextarea: boolean) {}
}
