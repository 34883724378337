import { FOIL_GRADIENT_ID_PREFIX, SPOT_UV_GRADIENT_ID } from './canvas';

export class Color {
  constructor(
    public name: string,
    public colorValue: string,
    public foil?: FoilTypes,
    public spotUv?: boolean,
    public multiColor?: boolean,
    public swatchColors?: string[]
  ) {}
}

export enum FoilTypes {
  gold = 'gold',
  silver = 'silver',
  copper = 'copper',
  rose = 'rose',
  black = 'black',
  rainbow = 'rainbow'
}

export type SpotUvType = 'spotUv';

export const CoatingType = 'white';

export const CUT_THROUGH_BASE_COLOR = '#000000';
export const CUT_THROUGH_INVERTED_BASE_COLOR = '#ffffff';
// Spot uv black color is used for converting foil black to spot uv
export const SPOT_UV_BLACK_COLOR = '#000000';
