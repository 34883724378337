import { EditorAction } from '../action';
import { CROP } from './canvas.action.types';

export class Crop implements EditorAction {
  readonly type = CROP;
  readonly undoable = true;
  readonly save = true;

  constructor(
    public route: Array<number>,
    public width: number,
    public height: number,
    public x: number,
    public y: number
  ) {}
}
