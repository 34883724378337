import { EditorAction } from '../action';
import { SET_MATERIAL } from './canvas.action.types';
import { Material } from '../../models';

export class SetMaterial implements EditorAction {
  readonly type = SET_MATERIAL;
  readonly undoable = true;
  readonly save = true;

  constructor(public material: Material) {}
}
