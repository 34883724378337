import { Component, Input } from '@angular/core';

@Component({
  selector: 'ed-edit-option',
  styleUrls: ['edit-option.component.scss'],
  templateUrl: 'edit-option.component.html'
})
export class EditOptionComponent {
  @Input() label = '';
}
