import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, tap, catchError } from 'rxjs/operators';
import { merge } from 'lodash-es';

import { DesignSetSubmit, DesignSubmit } from '../models/design-submit';
import { imageLibrary, imageLibrary2, emptyImageLibrary } from '../data/image-library.data';
import { fontData } from '../data';
import { HttpHeaders } from '@angular/common/http';
import { ClientError } from '../models/client-error';
import {
  emptyBusinessCard,
  design2,
  design3,
  design4,
  luikSquare,
  luikPortrait,
  luikLandscape,
  brochure,
  inline,
  designSet,
  allTrue
} from '../data/designs.data';

import { CollectionOtherUser } from '../models/collection-other-user';
import { FakeUser, fakeUsers, fakeUser, notLoggedInUser } from '../data/fake-users';
import { ResponseUser } from '../models/user';
import ConfigJson from '../../config/config.json';
import { EnvConfig } from '../../environments/config/config';
import { FunctionPermissions, SetElementPermissions } from '../models';
import { FakeDesign, FakeDesignOrSet, FakeDesignSet } from '../models/fake-design';
import { ImageLibrarySearch, ImageLibrarySearchJSON } from '../image-library/image-library';

export class CustomHttpErrorResponse extends HttpErrorResponse {
  readonly error: ClientError[];

  constructor(init: {
    error?: ClientError[];
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
  }) {
    super(init);
  }
}

declare type DesignOrSetSubmit = DesignSetSubmit | DesignSubmit;

@Injectable()
class FakeBackendInterceptor implements HttpInterceptor {
  constructor(@Inject(new InjectionToken<() => number>('delay')) private _delay: () => number) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only handle urls which startwith mock_backend
    if (!request.url.startsWith('/mock_backend')) {
      return next.handle(request);
    }

    console.log(request);

    // localStorage.clear();

    // array in local storage for registered users
    const registeredUsers: FakeUser[] = JSON.parse(localStorage.getItem('registered_users')) || fakeUsers;

    const loggedInUser: FakeUser = JSON.parse(localStorage.getItem('logged_in_user'));

    const currentDesign: FakeDesignOrSet = JSON.parse(localStorage.getItem('design'));

    const collection: FakeDesignOrSet[] = JSON.parse(localStorage.getItem('collection')) || [
      design2,
      design3,
      design4,
      emptyBusinessCard,
      luikSquare,
      luikPortrait,
      luikLandscape,
      brochure,
      inline,
      designSet
    ];

    const collectionOtherUser: CollectionOtherUser = new CollectionOtherUser(fakeUser.email, [
      design2,
      design3,
      design4,
      emptyBusinessCard
    ]);

    const designsForReviewAdmin: FakeDesignOrSet[] = JSON.parse(localStorage.getItem('designs_for_review')) || [];

    const designsAdmin: FakeDesignOrSet[] = JSON.parse(localStorage.getItem('designs_admin')) || [
      design2,
      design3,
      design4,
      emptyBusinessCard,
      luikSquare,
      luikPortrait,
      luikLandscape,
      brochure,
      inline
    ];

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(
      mergeMap(() => {
        if (request.url.startsWith('/mock_backend/config') && request.method === 'GET') {
          return of(new HttpResponse({ status: 200, body: merge(ConfigJson, EnvConfig) }));
        }

        // initial check if user is logged in
        if (request.url.endsWith('/authenticate') && request.method === 'GET') {
          let body: ResponseUser = {
            IsAuthenticated: false,
            Username: 'Niet Ingelogd',
            Shop: 'mooze',
            Email: null,
            BasketItems: 99,
            FunctionPermissions: notLoggedInUser.functionPermissions,
            SetElementPermissions: notLoggedInUser.setElementPermissions
          };
          if (loggedInUser) {
            body = {
              IsAuthenticated: true,
              Username: loggedInUser.username,
              Shop: 'mooze',
              Email: loggedInUser.email,
              BasketItems: loggedInUser.basketItems,
              FunctionPermissions: loggedInUser.functionPermissions,
              SetElementPermissions: loggedInUser.setElementPermissions
            };
          }
          return of(new HttpResponse({ status: 200, body }));
        }

        // authenticate
        if (request.url.endsWith('/authenticate') && request.method === 'POST') {
          // find if any user matches login credentials
          const filteredUser = registeredUsers.find(user => {
            return user.username === request.body.username && user.password === request.body.password;
          });

          if (filteredUser) {
            // if login details are valid return 200 OK with user details
            const body: ResponseUser = {
              IsAuthenticated: true,
              Username: filteredUser.username,
              Shop: 'mooze',
              Email: filteredUser.email,
              BasketItems: filteredUser.basketItems,
              FunctionPermissions: filteredUser.functionPermissions,
              SetElementPermissions: filteredUser.setElementPermissions
            };

            localStorage.setItem('logged_in_user', JSON.stringify(filteredUser));

            return of(new HttpResponse({ status: 200, body }));
          } else {
            const error = {
              message: 'E-mail or password is incorrect',
              code: '001'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }
        }

        if (request.url.endsWith('/logout')) {
          localStorage.removeItem('logged_in_user');
          localStorage.removeItem('design');

          // for debugging now, remove own collection from local storage
          // localStorage.removeItem('collection');

          const body: ResponseUser = {
            IsAuthenticated: false,
            Username: 'Niet Ingelogd',
            Shop: 'mooze',
            Email: null,
            BasketItems: 99,
            FunctionPermissions: notLoggedInUser.functionPermissions,
            SetElementPermissions: notLoggedInUser.setElementPermissions
          };
          return of(new HttpResponse({ status: 200, body }));
        }

        // register
        if (request.url.endsWith('/register') && request.method === 'POST') {
          const errors: ClientError[] = [];
          if (!request.body.email) {
            errors.push({
              code: '002',
              message: 'Please fill in Email',
              field: 'email'
            });
          }

          if (!request.body.password) {
            errors.push({
              code: '003',
              message: 'Please fill in Password',
              field: 'password'
            });
          }

          if (errors.length) {
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: errors }));
          }

          // find if any user matches register credentials
          const registeredUser = registeredUsers.find(user => {
            return user.username === request.body.email;
          });

          if (!registeredUser) {
            // if register details are valid return 200 OK with registered user details
            const shopName = 'Editor Demo App';
            const newUser: FakeUser = new FakeUser(request.body.email, shopName, request.body.email, 88);
            newUser.password = request.body.password;
            return of(new HttpResponse({ status: 200, body: this.registerFakeUser(newUser, registeredUsers) }));
          } else {
            const error = {
              message: 'User already exists',
              code: '004'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }
        }

        // register KC
        if (request.url.endsWith('/registerKC') && request.method === 'POST') {
          if (request.body.password_register_1 !== request.body.password_register_2) {
            const error = {
              message: 'Passwords do not match',
              code: '005'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }

          // find if any user matches register credentials
          const registeredUser = registeredUsers.find(user => {
            return user.username === request.body.email_register;
          });

          if (!registeredUser) {
            // if register details are valid return 200 OK with registered user details
            const shopName = 'Hippe-Geboortekaartjes';
            const newUser: FakeUser = new FakeUser(request.body.email_register, shopName, request.body.email_register);
            newUser.password = request.body.password;
            return of(new HttpResponse({ status: 200, body: this.registerFakeUser(newUser, registeredUsers) }));
          } else {
            const error = {
              message: 'User already exists',
              code: '004'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }
        }

        // register Mooze
        if (request.url.endsWith('/registerMooze') && request.method === 'POST') {
          // find if any user matches register credentials
          const registeredUser = registeredUsers.find(user => {
            return user.username === request.body.RegisterEmail;
          });

          if (!registeredUser) {
            // if register details are valid return 200 OK with registered user details
            const shopName = 'Mooze';
            const newUser: FakeUser = new FakeUser(request.body.RegisterEmail, shopName, request.body.RegisterEmail);
            newUser.password = request.body.RegisterPassword;
            return of(new HttpResponse({ status: 200, body: this.registerFakeUser(newUser, registeredUsers) }));
          } else {
            const error = {
              message: 'User already exists',
              code: '004'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }
        }

        /*
         * return design by designid or from session, else empty card for now
         */
        if (request.url.endsWith('/design') && request.method === 'GET') {
          let design: FakeDesignOrSet = designSet;
          const designId = request.params.has('designid') ? request.params.get('designid') : null;
          if (designId) {
            const existingDesign = collection.find(d => d.design_id === designId);
            design = existingDesign ? existingDesign : emptyBusinessCard;
          } else if (currentDesign) {
            design = currentDesign;
          }

          return of(new HttpResponse({ status: 200, body: createSubmit(design) }));
        }

        /*
         * if designid in query parameter, update existing design, else return error
         *
         * if user not logged in save to session (design key in local storage)
         *
         * (the autoSave function will call this function many times, with save is true)
         * if 'logged_in' in request check if design exists based on user_design_id
         * check if name exists in other designs
         * if 'make_copy' in request and design exists, get a new (unique) title and reset design/name exists
         * if name exists, get a new (unique) title
         * if design exists, update design in collection of user
         * else add new design in collection of user
         *
         */
        if (request.url.endsWith('/design') && request.method === 'POST') {
          const fakeDesignOrSet = createFakeDesign(request.body);

          const designId = request.params.has('designid') ? request.params.get('designid') : null;
          if (designId) {
            const existingDesignIndex = collection.findIndex(d => d.design_id === designId);
            if (existingDesignIndex > -1) {
              collection[existingDesignIndex] = fakeDesignOrSet;
              collection[existingDesignIndex].design_id = designId; // overwrite design in collection if json import is used
            } else {
              const error = {
                message: `Er is geen ontwerp met id: ${designId}`,
                code: '006'
              };
              return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
            }
          } else if (!request.body.save || !loggedInUser) {
            localStorage.setItem('design', JSON.stringify(fakeDesignOrSet));
          } else {
            let existingDesign = collection.find(
              designInCollection =>
                fakeDesignOrSet.user_design_id && designInCollection.user_design_id === fakeDesignOrSet.user_design_id
            );
            let otherDesignWithSameNameAlreadyExists =
              collection.findIndex(
                designInCollection =>
                  designInCollection.user_design_title === fakeDesignOrSet.user_design_title &&
                  designInCollection.user_design_id !== fakeDesignOrSet.user_design_id
              ) > -1;

            if (request.body.make_copy && existingDesign) {
              fakeDesignOrSet.user_design_title = this.getNewTitle(fakeDesignOrSet.user_design_title, collection);
              otherDesignWithSameNameAlreadyExists = false;
              existingDesign = null;
            }

            if (otherDesignWithSameNameAlreadyExists) {
              fakeDesignOrSet.user_design_title = this.getNewTitle(fakeDesignOrSet.user_design_title, collection);
            }

            if (existingDesign) {
              const index = collection.indexOf(existingDesign);
              collection[index] = fakeDesignOrSet;
            } else {
              fakeDesignOrSet.user_design_id =
                collection.reduce((maxId, nextDesign) => Math.max(maxId, nextDesign.user_design_id), 0) + 1;
              collection.push(fakeDesignOrSet);
            }
          }

          localStorage.setItem('design', JSON.stringify(fakeDesignOrSet));
          localStorage.setItem('collection', JSON.stringify(collection));

          return of(new HttpResponse({ status: 200, body: createSubmit(fakeDesignOrSet) }));
        }

        // save design in collection other user
        if (request.url.endsWith('/designotheruser') && request.method === 'POST') {
          const existingUser = registeredUsers.find(registeredUser => registeredUser.email === request.body.email_user);
          const userDoesNotExist = registeredUsers.findIndex(
            registeredUser => registeredUser.email === request.body.email_user
          );
          const nameAlreadyExists = collectionOtherUser.collection.findIndex(
            designInCollection => designInCollection.user_design_title === request.body.user_design_title
          );

          // if user does not exist, throw an error
          if (userDoesNotExist === -1) {
            const error = {
              message: 'Emailadres bestaat niet.',
              code: '007',
              field: 'user_email'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));

            // if title of design already exists, throw an error
          } else if (nameAlreadyExists > -1) {
            const error = {
              message: 'Naam bestaat al, kies een andere naam',
              code: '008',
              field: 'user_design_title'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));

            // if user exists and no design with entered title exists, save design in collection other user
          } else if (existingUser && nameAlreadyExists === -1) {
            request.body.user_design_id =
              collectionOtherUser.collection.reduce(
                (maxId, nextDesign) => Math.max(maxId, nextDesign.user_design_id),
                0
              ) + 1;
            collectionOtherUser.collection.push(request.body);
          }

          return of(new HttpResponse({ status: 200, body: request.body }));
        }

        function saveDesignAdminDesigner(
          requestObject: HttpRequest<any>,
          designCollection: FakeDesignOrSet[],
          session: string
        ) {
          const designid = requestObject.params.has('designid')
            ? requestObject.params.get('designid')
            : requestObject.body.design_id;
          const overwriteDesign = requestObject.body.overwrite_existing_design;
          const existingDesign = designCollection.findIndex(
            designInCollection => designInCollection.design_id === designid
          );
          let nameAlreadyExists = designCollection.findIndex(
            designInCollection =>
              designInCollection.design_title === requestObject.body.design_title &&
              designInCollection.design_id !== requestObject.body.design_id
          );

          const errors = {
            noDesignId: {
              message: `Er is geen ontwerp met id: ${designid}`,
              code: '006',
              field: 'overwrite'
            },
            nameExists: {
              message: 'Naam bestaat al, kies een andere naam',
              code: '008',
              field: 'title'
            }
          };

          if (overwriteDesign) {
            // overwrite design when designid is present
            if (existingDesign > -1) {
              if (nameAlreadyExists === -1) {
                designCollection[existingDesign] = requestObject.body;
              } else {
                return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [errors.nameExists] }));
              }
            } else {
              return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [errors.noDesignId] }));
            }

            // if overwriteDesign = false, generate new design_id for design
          } else {
            nameAlreadyExists = designCollection.findIndex(
              designInCollection => designInCollection.design_title === requestObject.body.design_title
            );
            if (nameAlreadyExists > -1) {
              return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [errors.nameExists] }));
            } else {
              request.body.design_id = (
                designCollection.reduce(
                  (maxId, nextDesign) => Math.max(maxId, parseInt(nextDesign.design_id, 0) || 0),
                  0
                ) + 1
              ).toString();

              designCollection.push(request.body);
            }
          }

          requestObject.body.is_proposed_design = true;
          localStorage.setItem('design', JSON.stringify(requestObject.body));
          localStorage.setItem(session, JSON.stringify(designCollection));

          const body = {
            design_id: designid,
            design_title: requestObject.body.design_title,
            design_description: requestObject.body.design_description
          };

          return of(new HttpResponse({ status: 200, body }));
        }

        // overwrite title/description of design
        if (request.url.endsWith('/titledescription') && request.method === 'POST') {
          return saveDesignAdminDesigner(request, designsAdmin, 'designs_admin');
        }

        // save design for admin to review
        if (request.url.endsWith('/designtoreview') && request.method === 'POST') {
          return saveDesignAdminDesigner(request, designsForReviewAdmin, 'designs_for_review');
        }

        // initial load imageLibraryUrl
        if (request.url.endsWith('/imagelibrary') && request.method === 'GET') {
          const body = request.params.has('designid')
            ? imageLibrary2
            : request.params.has('emptyImageLibrary')
            ? emptyImageLibrary
            : imageLibrary;
          return of(new HttpResponse({ status: 200, body })).pipe(delay(0));
        }

        // search imageLibrary
        if (request.url.endsWith('/imagelibrarysearch') && request.method === 'GET') {
          const search_term = request.params.get('search_term').toLowerCase();
          const limit = request.params.has('limit') ? Number(request.params.get('limit')) : -1;
          const body: ImageLibrarySearchJSON = new ImageLibrarySearch([]);

          if (search_term === 'error') {
            const error = {
              message: "Geen afbeelding gevonden met zoekterm 'error'",
              code: '010',
              field: 'search'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          }

          imageLibrary.categories.forEach(c => {
            c.images?.forEach(i => {
              return i.name.toLowerCase().indexOf(search_term) !== -1 && body.images.push(i);
            });
          });

          if (limit !== -1) {
            body.images = body.images.slice(0, limit);
          }

          return of(new HttpResponse({ status: 200, body })).pipe(delay(0));
        }

        // initial load fonts
        if (request.url.endsWith('/fonts') && request.method === 'GET') {
          return of(new HttpResponse({ status: 200, body: fontData }));
        }

        // short list of fonts, for e2e tests
        if (request.url.endsWith('/fonts_short') && request.method === 'GET') {
          return of(new HttpResponse({ status: 200, body: fontData.slice(0, 10) }));
        }

        // try to recover password
        if (request.url.endsWith('/recoverpassword') && request.method === 'POST') {
          // find if any user matches recover credentials
          const registeredUser = registeredUsers.find(user => {
            return user.email === request.body.recoverEmail;
          });

          if (!registeredUser) {
            const error = {
              message: 'Er is geen account gekoppeld aan dit e-mailadres.',
              code: '007'
            };
            return throwError(() => new CustomHttpErrorResponse({ status: 400, error: [error] }));
          } else {
            // if user exists, return 200 OK with registered user details
            const body = { email: registeredUser.email };
            return of(new HttpResponse({ status: 200, body }));
          }
        }

        // pass through any requests not handled above
        return next.handle(request);
      }),
      // call materialize and dematerialize to ensure delay even if an error is
      // thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      materialize(),
      delay(this._delay()),
      dematerialize(),
      tap(response => console.log(response)),
      catchError(error => {
        console.warn(error);
        return throwError(() => error);
      })
    );
  }

  registerFakeUser(newUser: FakeUser, registeredUsers: FakeUser[]) {
    localStorage.setItem('logged_in_user', JSON.stringify(newUser));
    registeredUsers.push(newUser);
    localStorage.setItem('registered_users', JSON.stringify(registeredUsers));

    return {
      IsAuthenticated: true,
      Username: newUser.username,
      Shop: newUser.shop,
      Email: newUser.email,
      BasketItems: newUser.basketItems,
      FunctionPermissions: newUser.functionPermissions
    };
  }

  getNewTitle(user_design_title: string, collection: FakeDesignOrSet[]) {
    let copy_index = 1;
    let design_exists = true;
    let new_title = user_design_title;
    while (design_exists) {
      design_exists =
        collection.findIndex(designInCollection => designInCollection.user_design_title === new_title) > -1;
      if (design_exists) {
        new_title = `${user_design_title} (${copy_index})`;
      }
      copy_index++;
    }
    return new_title;
  }
}

export function fakeBackendFactory(maxDelayMs = 3000) {
  return () => new FakeBackendInterceptor(() => Math.random() * maxDelayMs);
}

// checks if backend response is old DesignSubmit or newDesignSetSubmit version 1
const isDesignSetSubmit = (setOrNot: DesignOrSetSubmit): setOrNot is DesignSetSubmit => {
  return (setOrNot as DesignSetSubmit).version !== undefined && (setOrNot as DesignSetSubmit).version >= 1;
};

const isDesignSet = (setOrNot: FakeDesignOrSet): setOrNot is FakeDesignSet => {
  return (setOrNot as FakeDesignSet).version !== undefined && (setOrNot as FakeDesignSet).version >= 1;
};

function createSubmit(design: FakeDesignOrSet) {
  // default all design permissions true
  const setElementPermissions = allTrue(new SetElementPermissions());
  const functionPermissions = allTrue(new FunctionPermissions());

  if (isDesignSet(design)) {
    const designSetSubmit = new DesignSetSubmit();
    designSetSubmit.designs = design.designs as unknown as DesignSubmit[];
    designSetSubmit.version = design.version;
    designSetSubmit.id = design.design_id;
    designSetSubmit.title = design.design_title;
    designSetSubmit.description = design.design_description;
    designSetSubmit.is_proposed = design.is_proposed_design;
    designSetSubmit.user_collection_id = design.user_design_id;
    designSetSubmit.user_collection_title = design.user_design_title;
    designSetSubmit.use_absolute_font_size = design.use_absolute_font_size;
    designSetSubmit.designs.forEach((d, i) => {
      if (!d.permissions) {
        if (i > 0) {
          // assume not first design is foilable for now
          setElementPermissions.isFoilable = false;
          functionPermissions.enableFoilableByDefault = false;
        }
        d.permissions = { setElementPermissions, functionPermissions };
      }
    });
    return designSetSubmit;
  } else {
    const designSubmit = new DesignSubmit();
    designSubmit.pages = design.pages;
    designSubmit.design_id = design.design_id;
    designSubmit.design_title = design.design_title;
    designSubmit.design_description = design.design_description;
    designSubmit.is_proposed_design = design.is_proposed_design;
    designSubmit.user_design_id = design.user_design_id;
    designSubmit.user_design_title = design.user_design_title;
    designSubmit.use_absolute_font_size = design.use_absolute_font_size;
    if (!design.permissions) {
      designSubmit.permissions = { setElementPermissions, functionPermissions };
    }
    return designSubmit;
  }
}

function createFakeDesign(design: DesignOrSetSubmit) {
  if (isDesignSetSubmit(design)) {
    return {
      designs: design.designs,
      version: design.version,
      design_id: design.id,
      design_title: design.title,
      design_description: design.description,
      is_proposed_design: design.is_proposed,
      user_design_id: design.user_collection_id,
      user_design_title: design.user_collection_title,
      use_absolute_font_size: design.use_absolute_font_size
    };
  } else {
    return design as FakeDesign;
  }
}
