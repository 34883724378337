import { fabric } from "fabric";
import { BaseElement, Font, TextElement } from "src/app/models";
import { FontUtils } from "../../utils/font.utils";
import { ControlsVisibility } from "../controls-visibility";
import { TextElementTextAlign } from "../text-element-text-align";
import { TextboxTextAlign } from "../textbox-text-align";

export type TextboxData = {
  element: TextElement;
  fontLibrary: Font[];
}

declare module "src/app/models/text-element" {
  interface TextElement {
    getTextboxTextAlign(): TextboxTextAlign;
    getTextboxLineHeight(): number;
  }
}

TextElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: TextElement = this;

  if (!(element instanceof TextElement)) {
    return null;
  }

  object ??= new fabric.Textbox("", { data });

  if (!(object instanceof fabric.Textbox)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);
  object.set({ data });

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

TextElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: TextElement = this;

  if (!(element instanceof TextElement) || !(object instanceof fabric.Textbox)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    text: element.text,
    textAlign: element.getTextboxTextAlign() || object.textAlign,
    lineHeight: element.getTextboxLineHeight(),
    fontFamily: FontUtils.getFontFamily(object.data.fontLibrary, element.font),
    fontSize: element.fontSize,
    fill: element.color,
    cursorColor: element.color,
    lockMovementX: !element.permissions.isMovable || object.isEditing,
    lockMovementY: !element.permissions.isMovable || object.isEditing,
    editable: false,
    splitByGrapheme: true
  };
}

TextElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: TextElement = this;

  if (!(element instanceof TextElement) || !(object instanceof fabric.Textbox)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    "ml": !!element.permissions.isResizable,
    "mr": !!element.permissions.isResizable,
    "mtr": !!element.permissions.isRotatable,
    "remove": !!element.permissions.isRemovable,
    "move": !!element.permissions.isMovable,
  };
}

//#region Util

const TextElementTextAlignMap = new Map<TextElementTextAlign, TextboxTextAlign>([
  ["L", "left"],
  ["C", "center"],
  ["R", "right"],
  ["justify", "justify-left"]
]);

TextElement.prototype.getTextboxTextAlign = function () {
  const element: TextElement = this;
  if (!(element instanceof TextElement)) {
    return null;
  }

  return TextElementTextAlignMap.get(((element.isJustify && "justify") || element.ha) as TextElementTextAlign);
}

TextElement.prototype.getTextboxLineHeight = function () {
  const element: TextElement = this;
  if (!(element instanceof TextElement)) {
    return null;
  }

  return 1 / fabric.Textbox.prototype._fontSizeMult;
}

//#endregion
