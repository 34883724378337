export const fontData = [
  {
    name: 'BlackJack',
    screen_title: 'testscreentitleBlackJack'
  },
  {
    name: 'Clementine',
    screen_title: 'testscreentitleClementine'
  },
  {
    name: 'Sugar Plums'
  },
  {
    name: 'Adios Script Pro'
  },
  {
    name: 'Advetime'
  },
  {
    name: 'Albemarle Swash'
  },
  {
    name: 'A Little Pot',
    screen_title: 'testscreentitleA Little Pot'
  },
  {
    name: 'Amatic SC Bold'
  },
  {
    name: 'Amatic SC Condensed'
  },
  {
    name: 'Ambar Italic'
  },
  {
    name: 'ANGELINA'
  },
  {
    name: 'Annie Use Your Telescope'
  },
  {
    name: 'Annie Use Your Telescope Bold'
  },
  {
    name: 'ArabicNaskhSSK'
  },
  {
    name: 'Architects Daughter'
  },
  {
    name: 'Arial',
    screen_title: 'testscreentitleArial'
  },
  {
    name: 'Arial Black,'
  },
  {
    name: 'a song for jennifer'
  },
  {
    name: 'Bentham'
  },
  {
    name: 'Bentham Bold'
  },
  {
    name: 'Blend'
  },
  {
    name: 'Blend Inline'
  },
  {
    name: 'BombshellPro'
  },
  {
    name: 'BombshellPro Bold'
  },
  {
    name: 'Boston Traffic'
  },
  {
    name: 'Brain Flower'
  },
  {
    name: 'Brain Flower Bold'
  },
  {
    name: 'Braveheart'
  },
  {
    name: 'Brawler'
  },
  {
    name: 'Buda'
  },
  {
    name: 'Buda Bold'
  },
  {
    name: 'Buda Italic'
  },
  {
    name: 'Cabin'
  },
  {
    name: 'Cabin Italic'
  },
  {
    name: 'CabinSketch'
  },
  {
    name: 'CabinSketch Bold'
  },
  {
    name: 'CaslonOpnface BT'
  },
  {
    name: 'Castellar'
  },
  {
    name: 'Castellia'
  },
  {
    name: 'Chalkduster'
  },
  {
    name: 'Chelsea Market'
  },
  {
    name: 'Chevalier Com'
  },
  {
    name: 'ChopinScript'
  },
  {
    name: 'CK Little Women'
  },
  {
    name: 'Club Type Script Pro'
  },
  {
    name: 'Cocktail Script Semi-Bold'
  },
  {
    name: 'Coolvetica'
  },
  {
    name: 'Crayon Crumble'
  },
  {
    name: 'Cul De Sac'
  },
  {
    name: 'Curlz MT'
  },
  {
    name: 'Damion'
  },
  {
    name: 'Dawning of a New Day'
  },
  {
    name: 'Dirty Headline'
  },
  {
    name: 'EcuyerDAX Thin'
  },
  {
    name: 'Emily Lime Standard'
  },
  {
    name: 'featherly handlettered'
  },
  {
    name: 'featherly handlettered swashes'
  },
  {
    name: 'Filena Bold'
  },
  {
    name: 'Fineliner Script'
  },
  {
    name: 'Freeland'
  },
  {
    name: 'Gentium Book Basic'
  },
  {
    name: 'Gentium Book Basic Bold'
  },
  {
    name: 'Gentium Book Basic Italic'
  },
  {
    name: 'Gravura'
  },
  {
    name: 'Harrington'
  },
  {
    name: 'hello goodbye'
  },
  {
    name: 'hello goodbye Bold'
  },
  {
    name: 'Hollyn'
  },
  {
    name: 'Hollyn Bold'
  },
  {
    name: 'Home Sweet Home'
  },
  {
    name: 'Impact'
  },
  {
    name: 'Inconsolata'
  },
  {
    name: 'Indie Flower'
  },
  {
    name: 'ITC American Typewriter Std'
  },
  {
    name: 'ITC American Typewriter Std Bold'
  },
  {
    name: 'ITC Avant Garde Gothic Std Light'
  },
  {
    name: 'Jenna Sue'
  },
  {
    name: 'Jenna Sue Bold'
  },
  {
    name: 'Josefin Sans'
  },
  {
    name: 'Josefin Sans Bold'
  },
  {
    name: 'Josefin Sans Italic'
  },
  {
    name: 'Josefin Slab'
  },
  {
    name: 'Josefin Slab Bold'
  },
  {
    name: 'Josefin Slab Italic'
  },
  {
    name: 'Jura'
  },
  {
    name: 'Just Another Hand'
  },
  {
    name: 'Lavanderia'
  },
  {
    name: 'Lemon Milk'
  },
  {
    name: 'Lobster Two'
  },
  {
    name: 'Love Ya Like A Sister'
  },
  {
    name: 'Magnalia'
  },
  {
    name: 'Maiden Orange'
  },
  {
    name: 'Miltonian'
  },
  {
    name: 'Minya Semi-Bold'
  },
  {
    name: 'Monotype Corsiva'
  },
  {
    name: 'Mountains of Christmas'
  },
  {
    name: 'Neucha'
  },
  {
    name: 'News Cycle'
  },
  {
    name: 'News Cycle Bold'
  },
  {
    name: 'Past Due'
  },
  {
    name: 'Peach Milk'
  },
  {
    name: 'Quaver Serif'
  },
  {
    name: 'Quaver Serif Bold'
  },
  {
    name: 'Quaver Serif Italic'
  },
  {
    name: 'Quicksand Regular'
  },
  {
    name: 'Script MT'
  },
  {
    name: 'Signature Collection'
  },
  {
    name: 'Special Elite'
  },
  {
    name: 'Spumoni LP Std'
  },
  {
    name: 'Stay Classy'
  },
  {
    name: 'Swanky and Moo Moo'
  },
  {
    name: 'Swanky and Moo Moo Bold'
  },
  {
    name: 'Tangerine'
  },
  {
    name: 'Thabit'
  },
  {
    name: 'TheMix Bold'
  },
  {
    name: 'Unkempt'
  },
  {
    name: 'VARSITY REGULAR'
  },
  {
    name: 'Veneer'
  },
  {
    name: 'Verdana'
  },
  {
    name: 'Wanderlust Letters'
  },
  {
    name: 'Wanderlust Letters Bold'
  },
  {
    name: 'Wanted M54'
  },
  {
    name: 'Wild Spirit'
  },
  {
    name: 'Yanone Kaffeesatz'
  },
  {
    name: 'Yanone Kaffeesatz Light'
  },
  {
    name: 'Young'
  },
  {
    name: 'Zipty Do'
  },
  {
    name: 'Zipty Do Bold'
  },
  {
    name: 'ae_Sindibad Bold'
  },
  {
    name: 'ITC Avant Garde Gothic Std Light Condensed'
  },
  {
    name: 'LMMonoCaps10'
  }
];
