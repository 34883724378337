import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomFormControlService } from '../services';
import { select, Store } from '@ngrx/store';
import * as fromRegister from './reducer';
import * as Register from '../register/actions';
import { CustomFormField, FormFieldType } from '../models';
import { CustomFormText, DialogButtonText, RegisterFormText } from '../models/text';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ClientError } from '../models/client-error';
import { first, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ed-register-form',
  templateUrl: 'register-form.component.html',
  styleUrls: ['../shared/dialogs/modals.scss', 'register-form.component.scss'],
  providers: [CustomFormControlService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @Input() registerText: RegisterFormText;
  @Input() buttonText: DialogButtonText;
  @Input() customFormText: CustomFormText;
  @Input() formFields: CustomFormField[];

  @Output() openLogin = new EventEmitter();

  regForm: FormGroup = new FormGroup({});
  pending$: Observable<boolean>;
  errors$: Observable<ClientError[]>;
  configSubscription: Subscription;
  submitted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private store: Store<fromRegister.State>,
    private fcs: CustomFormControlService,
    private dialogRef: MatDialogRef<RegisterFormComponent>
  ) {}

  ngOnInit() {
    this.pending$ = this.store.pipe(
      select(fromRegister.getPending),
      tap(pending => (pending ? this.regForm.disable() : this.regForm.enable()))
    );
    this.errors$ = this.store.pipe(select(fromRegister.getError));
    this.configSubscription = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.regForm = this.fcs.toFormGroup(config.registrationFormFields, config.matchPasswordFields);
    });
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.resetErrors());
  }

  openLoginForm(e: Event) {
    // there is an empty href attribute on the link, so we need to prevent the default action
    // the empty href is needed to get an underline on the link with boottrap
    e.preventDefault();
    this.openLogin.emit();
  }

  submit() {
    this.submitted.next(true);
    // mark controls as touched and dirty to show validation errors of each custom-form-field
    this.formFields
      .filter(field => field.type !== FormFieldType.Textbox)
      .forEach(field => {
        const control = this.regForm.get(field.name);
        // to prevent not required fields from being validated
        if (control.hasError('required')) {
          control.markAsTouched();
          control.markAsDirty();
        }

        // some fields are not required when another field has a specific value
        // e.g. 'companyname' is not required when 'BusinessCustomer' has value 'niet-zakelijk'
        if (field.hideIfValue) {
          const targetControl = this.regForm.get(field.hideIfValue.key);
          if (targetControl && targetControl.value === field.hideIfValue.value) {
            control.markAsUntouched();
            control.markAsPristine();
            control.setErrors(null);
          }
        }
      });

    if (this.regForm.valid) {
      this.store.dispatch(new Register.Register(this.regForm.value));
    }
  }

  get isValid() {
    return this.fcs.formGroupIsValid(this.formFields, this.regForm);
  }

  get showPasswordMismatch() {
    let controlsValid = true;

    this.formFields
      .filter(f => f.type === 'password')
      .forEach(field => {
        if (this.regForm.get(field.name).invalid) {
          controlsValid = false;
        }
      });

    return !this.regForm.pristine && controlsValid && this.regForm.hasError('password-mismatch');
  }

  resetErrors(): void {
    this.store.dispatch(new Register.ResetValidationErrors());
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
  }
}
