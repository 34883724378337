import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CopyPasteComponent } from './copy-paste.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CopyService, GetTextService } from '../../services';

@Component({
  selector: 'ed-copy-paste-mob',
  templateUrl: 'copy-paste-mob.component.html',
  styleUrls: ['../../editor/edit/edit-mob.component.scss', 'copy-paste-mob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyPasteMobComponent extends CopyPasteComponent implements OnInit {
  constructor(public store: Store<AppState>, public copyService: CopyService, public getTextService: GetTextService) {
    super(store, copyService);
  }
}
