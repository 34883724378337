import { Action } from '@ngrx/store';
import { CategoryImage } from '../../models';
import { UPDATE_DESIGN_IMAGES } from './canvas.action.types';

export class UpdateDesignImages implements Action {
  readonly type = UPDATE_DESIGN_IMAGES;
  undoable = false;
  readonly save = true;

  constructor(public setId: number, public images: CategoryImage[]) {}
}
