import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { DOCUMENT } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class ConfigService {
  imgBaseUrl: string;
  renderAsSvg: boolean;
  isIphoneOS12: boolean;
  isChrome: boolean;
  isAndroid: boolean;
  gridSize: number;
  isTouchDevice: boolean;
  showNewMobileMenu: boolean;

  get showAlternativeInputField() {
    return false; // not neccesary atm;
    // return this.isChrome && this.isIphoneOS12;
  }

  constructor(
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.imgBaseUrl = config.imgBase;
      this.renderAsSvg = config.renderAsSvg;
      this.isIphoneOS12 = config.isIphoneOS12;
      this.isChrome = config.isChrome;
      this.isAndroid = config.isAndroid;
      this.gridSize = config.gridSize;
      this.isTouchDevice = config.isTouchDevice;
      this.showNewMobileMenu = config.showNewMobileMenu;
    });
  }

  get imgBase() {
    if (!this.imgBaseUrl) {
      console.warn('empty imgBaseUrl');
    }
    return this.imgBaseUrl;
  }

  get isMobile() {
    // TODO: Replace hard coded media queries with media queries from config or css variables
    return this.breakpointObserver.isMatched('(max-width: 991.92px)');
  }

  get baseHref(): string {
    // remove hash, is used for url reference's in svg's, which breaks with html base element
    return `${this.document.location}`.replace(this.document.location.hash, '');
  }
}
