import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Output,
  ElementRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextMenuText, PositioningTooltipText } from '../../models/text';
import { ContextMenuShortcuts } from './context-menu.service';
import { CanvasElement, getLayerSiblings } from '../../models';

@Component({
  selector: 'ed-context-menu-dialog',
  templateUrl: 'context-menu-dialog.component.html',
  styleUrls: ['context-menu-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuDialogComponent {
  @Output() cutElement: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() copyElement: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pasteElement: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteElement: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stepForward: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stepBackward: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toFront: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  text: ContextMenuText;
  shortcut: ContextMenuShortcuts;
  showBackspaceShortcut: boolean;
  duplicatable: boolean;
  pastable: boolean;
  removable: boolean;
  orderable: boolean;
  element: CanvasElement;
  isOnBottomOfLayer = false;
  isOnTopOfLayer = false;
  positioningTooltipText: PositioningTooltipText;

  constructor(
    public dialogRef: MatDialogRef<ContextMenuDialogComponent>,
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contextMenuText: ContextMenuText;
      shortcut: ContextMenuShortcuts;
      showBackspaceShortcut: boolean;
      duplicatable: boolean;
      pastable: boolean;
      removable: boolean;
      orderable: boolean;
      element: CanvasElement;
      positioningTooltipText: PositioningTooltipText;
    }
  ) {
    this.text = data.contextMenuText;
    this.shortcut = data.shortcut;
    this.showBackspaceShortcut = data.showBackspaceShortcut;
    this.duplicatable = data.duplicatable;
    this.pastable = data.pastable;
    this.removable = data.removable;
    this.orderable = data.orderable;
    this.element = data.element;
    this.positioningTooltipText = data.positioningTooltipText;

    const siblings = getLayerSiblings(this.element);
    const index = siblings.indexOf(this.element);
    this.isOnBottomOfLayer = index === 0;
    this.isOnTopOfLayer = index === siblings.length - 1;
  }

  cut() {
    if (!this.removable || !this.duplicatable) {
      return;
    }
    this.cutElement.emit();
  }

  copy() {
    if (!this.duplicatable) {
      return;
    }
    this.copyElement.emit();
  }

  paste() {
    if (!this.pastable) {
      return;
    }
    this.pasteElement.emit();
  }

  delete() {
    if (!this.removable) {
      return;
    }
    this.deleteElement.emit();
  }

  moveStepForward() {
    if (!this.orderable) {
      return;
    }
    this.stepForward.emit();
  }

  moveStepBackward() {
    if (!this.orderable) {
      return;
    }
    this.stepBackward.emit();
  }

  moveToFront() {
    if (!this.orderable) {
      return;
    }
    this.toFront.emit();
  }

  moveToBack() {
    if (!this.orderable) {
      return;
    }
    this.toBack.emit();
  }

  @HostListener('document: click', ['$event'])
  onContextMenu(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dialogRef.close();
    }
  }
}
