import { NgModule } from '@angular/core';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarMobComponent } from './toolbar-mob.component';
import { SharedModule } from '../shared/shared.module';
import { SaveComponent } from './save/save.component';
import { MainMenuModule } from '../main-menu/main-menu.module';
import { BasketComponent } from './basket/basket.component';
import { ShopBrandingComponent } from './shop-branding/shop-branding.component';
import { ChatComponent } from './chat/chat.component';
import { AccountComponent } from './account/account.component';
import { UndoRedoComponent } from './undo-redo/undo-redo.component';

@NgModule({
  imports: [SharedModule, MainMenuModule],
  declarations: [
    ToolbarComponent,
    ToolbarMobComponent,
    SaveComponent,
    BasketComponent,
    ShopBrandingComponent,
    ChatComponent,
    AccountComponent,
    UndoRedoComponent
  ],
  providers: [],
  exports: [ToolbarComponent, ToolbarMobComponent]
})
export class ToolbarModule {}
