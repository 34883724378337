import { Injectable } from '@angular/core';
import { cloneDeep, uniqBy } from 'lodash-es';
import {
  BackgroundImageElement,
  BoxElement,
  CanvasCoordinate,
  CanvasElement,
  CoatingType,
  Design,
  flipType,
  ImageElement,
  InlineTextElement,
  KC_LAYER_TYPE_MAPPING,
  KcBackground,
  KcClipPathType,
  KcDesign,
  KcDesignData,
  KcElementType,
  KcImageElement,
  KcLayerType,
  KcPage,
  kcPermissionsMapping,
  KcRichTextElement,
  KcTextElement,
  LayerableElement,
  PageElement,
  PhotoFrameElement,
  TextElement,
  View
} from '../../models';
import { coerceNumber, getFlatChildren, fileExtensionIsJpg } from '../../utils/element.utils';
import { FormatSpec } from '../../models/format-spec';
import { select, Store } from '@ngrx/store';
import { getEnableFoilableByDefault, getEnableSpotUvByDefault } from '../../reducers/permissions.reducer';
import { AppState } from '../../reducers';

// service that transforms the design-format of the editor to the pages-format of the kc-shops
@Injectable()
export class OutputService {
  enableFoilableByDefault: boolean;
  spotUvPermissionForJpgByDefault: boolean;

  constructor(store$: Store<AppState>) {
    store$.pipe(select(getEnableFoilableByDefault)).subscribe(enable => (this.enableFoilableByDefault = enable));
    store$.pipe(select(getEnableSpotUvByDefault)).subscribe(enable => (this.spotUvPermissionForJpgByDefault = enable));
  }

  clippath(element: ImageElement | TextElement | BoxElement | PhotoFrameElement): CanvasCoordinate[] {
    return element.polygon.map(point => ({ x: coerceNumber(point.x), y: coerceNumber(point.y) }));
  }

  transformToKC(design: Design, updateDesignAssets = false): KcDesign {
    const kcPages: KcDesign = {};

    kcPages.design_data = this.getDesignData(design, updateDesignAssets);
    kcPages._version = design._version;

    // output still needs pages, so make a clone and toggle view to pages
    const designClone = cloneDeep(design);
    designClone.updateView(View.pages);
    designClone.pages.map((page: PageElement) => {
      const kcPage = new KcPage();

      kcPage.add_child_elements = page.permissions.addChildElements;
      kcPage.locked = page.permissions.isLocked;
      kcPage.is_hidden = page.permissions.isHidden;
      kcPage.foil_enabled = page.permissions.isFoilable;
      kcPage.spot_uv_enabled = page.permissions.isSpotUvable;
      kcPage.h = coerceNumber(page.height, 0);
      kcPage.w = coerceNumber(page.width, 0);
      kcPage.bleed = coerceNumber(page.bleed, 2);
      kcPage.safety = coerceNumber(page.safety, 2);
      kcPage.position = coerceNumber(page.position, 0);

      kcPage.bg = new KcBackground();
      kcPage.bg.h = coerceNumber(page.height, 0);
      kcPage.bg.bgc = page.backgroundElements[0].color;
      kcPage.bg.w = coerceNumber(page.width, 0);

      kcPage.bg.locked = page.backgroundIsLocked;

      // make sure page rotation is always 0 for calculation child element rotation (if in inline text editing mode)
      page.rotation = 0;

      // get page children and nested children
      kcPage.images = this.addChildren(page.children, []);

      if (updateDesignAssets && this.enableFoilableByDefault) {
        kcPage.images.forEach(image => {
          if (image.is_foilable === false) {
            // only false, if undefined nothing should happen
            image.is_foilable_permission_was_changed = true;
          }
        });
      }

      if (updateDesignAssets && !this.spotUvPermissionForJpgByDefault) {
        kcPage.images.forEach(image => {
          if (
            image.type === KcElementType.photo &&
            fileExtensionIsJpg(image.sid || image.url) &&
            image.is_spot_uvable === true
          ) {
            // only true, if undefined nothing should happen
            image.is_spotuvable_for_jpg_image_permission_was_changed = true;
          }
        });
      }

      kcPages['p' + page.id] = kcPage;
    });

    return kcPages;
  }

  getDesignData(design: Design, updateDesignAssets = false): KcDesignData {
    return {
      view: design.view,
      fold: design.foldType,
      design_images: updateDesignAssets
        ? this.getDesignImages(design)
        : design.designImages.map(i => ({ name: i.name, sid: i.sid, url: i.url })),
      design_colors: updateDesignAssets
        ? this.getDesignColors(design)
        : design.designColors.map(c => ({ name: c.name, color_value: c.colorValue })),
      labels: design.labels,
      material: {
        type: design.material.type,
        needsCoating: design.material.needsCoating,
        isFoilable: design.material.isFoilable,
        isSpotUvable: design.material.isSpotUvable,
        isFoldable: design.material.isFoldable,
        image: design.material.image
      },
      trim: design.trimType,
      permissions: design.permissions,
      adjust_trim_permission_was_changed: design.adjustTrimPermissionWasChanged,
      specs: design.specs ? (JSON.parse(design.specs) as FormatSpec) : undefined
    };
  }

  getDesignColors(design: Design): { name: string; color_value: string }[] {
    return uniqBy(
      getFlatChildren(design.pages)
        .filter(
          el =>
            el.isImage() ||
            el.isInlineText() ||
            (el.isText() && !(el as ImageElement | TextElement | InlineTextElement).foilType)
        )
        .flatMap(el => (el.isInlineText() ? [...(el as InlineTextElement).colors] : el.color))
        .filter(color => !!color)
        .map(color => ({ name: '', color_value: color })),
      c => c.color_value
    );
  }

  getDesignImages(design: Design): { name: string; sid: string; url: string }[] {
    return uniqBy(
      getFlatChildren(design.pages)
        .filter(el => (el.isImage() || el.isBackgroundImage()) && this.hasPermission(el))
        .map((el: ImageElement | BackgroundImageElement) => ({ name: el.name, sid: el.sid, url: el.url })),
      i => i.sid || i.url
    );
  }

  hasPermission(el: CanvasElement) {
    // for material images and shop logo's etc
    const permissions = el.isImage()
      ? el.isPhotoFrameChild
        ? el.parent.permissions
        : el.permissions
      : el.combinedPermissions;
    return permissions.isPrintable && permissions.isDuplicatable;
  }

  getKcImageElement(element: ImageElement | BackgroundImageElement) {
    const kcElement = new KcImageElement();
    kcElement.fg_color = element.color;
    kcElement.recolor = !!element.color;
    kcElement.aspect = coerceNumber(element.ratio);
    kcElement.sid = element.sid;
    kcElement.url = element.url;
    kcElement.is_bg_image = element.parent.isBackgroundElement();
    kcElement.is_bg_image_spread = element.isSpreadBackgroundImage();
    kcElement.foil_type = element.foilType;
    kcElement.is_foilable_permission_was_changed = element.parent.isFoilablePermissionWasChanged;

    if (element.isPhotoFrameChild) {
      kcElement.is_spotuvable_for_jpg_image_permission_was_changed = (
        element.parent as PhotoFrameElement
      ).isSpotUvForJpgImagePermissionWasChanged;
    }

    const elementForLayerType = element.isPhotoFrameChild ? (element.parent as PhotoFrameElement) : element;
    kcElement.layer_type = this.getLayerType(kcElement, elementForLayerType);
    this.setCommonProperties(kcElement, element);
    this.setSize(kcElement, element);
    this.setPermissions(kcElement, element);
    if (element.parent.isPhotoFrame()) {
      this.setPermissions(kcElement, element.parent as PhotoFrameElement);
    }
    return kcElement;
  }

  getLayerType(kcElement: KcRichTextElement | KcImageElement | KcTextElement, element: LayerableElement): KcLayerType {
    let layer_type = KcLayerType.standardRgb;
    if (element.foilType) {
      layer_type = KcLayerType.foil;
    } else if (element.spotUv) {
      layer_type =
        kcElement.type === KcElementType.photo && fileExtensionIsJpg(kcElement.sid || kcElement.url)
          ? KcLayerType.spotUvImage
          : KcLayerType.spotUv;
    } else if (element.layerType) {
      layer_type = KC_LAYER_TYPE_MAPPING[element.layerType];
    }
    return layer_type;
  }

  setPermissions(kcElement: KcRichTextElement | KcImageElement | KcTextElement, element: CanvasElement) {
    Object.keys(element.permissions).map(permission => {
      kcElement[kcPermissionsMapping[permission]] = kcPermissionsMapping[permission]
        ? element.permissions[permission]
        : undefined;
    });
    kcElement.locked = element.combinedPermissions.isLocked ? 1 : 0;
    kcElement.coating = element.permissions.hasCoating ? CoatingType : undefined;
  }

  setSize(kcElement: KcImageElement, element: CanvasElement) {
    if (element.ratio > 1) {
      kcElement.size = coerceNumber(element.width * element.ratio);
    } else {
      kcElement.size = coerceNumber(element.width);
    }
  }

  getKcTextElement(element: TextElement) {
    const kcElement = new KcTextElement();

    kcElement.va = element.va;
    kcElement.font = element.font;
    kcElement.color = element.color;
    kcElement.text = element.text;
    kcElement.markup = element.markup;
    kcElement.s = coerceNumber(element.fontSize);
    kcElement.ha = element.ha;
    kcElement.is_justify = element.isJustify;
    kcElement.w = coerceNumber(element.width);
    kcElement.h = coerceNumber(element.height);
    kcElement.use_absolute_font_size = element.useAbsoluteFontSize;
    kcElement.foil_type = element.foilType;
    kcElement.is_foilable_permission_was_changed = element.isFoilablePermissionWasChanged;

    kcElement.layer_type = this.getLayerType(kcElement, element);
    this.setCommonProperties(kcElement, element);
    this.setPermissions(kcElement, element);

    return kcElement;
  }

  getKcInlineTextElement(element: InlineTextElement) {
    const kcElement = new KcRichTextElement();

    kcElement.w = coerceNumber(element.width);
    kcElement.h = coerceNumber(element.height);
    kcElement.text = element.text;
    kcElement.padding = element.padding;
    kcElement.foil_type = element.foilType;
    kcElement.is_foilable_permission_was_changed = element.isFoilablePermissionWasChanged;
    kcElement.layer_type = this.getLayerType(kcElement, element);
    kcElement.character_limit = element.characterLimit;

    this.setCommonProperties(kcElement, element);
    this.setPermissions(kcElement, element);

    return kcElement;
  }

  setCommonProperties(kcElement: KcRichTextElement | KcTextElement | KcImageElement, element: CanvasElement) {
    kcElement.template_id = element.templateId;
    kcElement.r = coerceNumber(element.screenRotation, 0);
    kcElement.trans = coerceNumber(element.transparency, 0);
    kcElement.tag = element.tag;
    kcElement.y = coerceNumber(element.pagePositionY);
    kcElement.x = coerceNumber(element.pagePositionX);
    kcElement.route = element.route.slice(0, -1);
    kcElement.order =
      element.parent.isPhotoFrame() || element.parent.isBackgroundElement() || element.parent.isBox()
        ? element.parent.order
        : element.order;

    if (element.parent.isBox() || element.parent.isPhotoFrame()) {
      kcElement.clip_path_type = element.parent.isBox() ? KcClipPathType.box : KcClipPathType.photoFrame;
      kcElement.clip_path = this.clippath(element.parent);
    }
    if (element.flipHorizontal) {
      kcElement.flip = flipType.horizontal;
    }
    if (element.flipVertical) {
      kcElement.flip = flipType.vertical;
    }
    if (element.flipHorizontal && element.flipVertical) {
      kcElement.flip = flipType.both;
    }
  }

  addChildren(elements: CanvasElement[], kcImagesArray: Array<KcRichTextElement | KcImageElement | KcTextElement>) {
    elements.map(childElement => {
      if (childElement.isImage()) {
        kcImagesArray.push(this.getKcImageElement(childElement));
      } else if (childElement.isSpreadBackgroundImage()) {
        kcImagesArray.push(this.getKcImageElement(childElement));
      } else if (childElement.isText()) {
        childElement = childElement as TextElement;
        if (!childElement.textNotEdited) {
          kcImagesArray.push(this.getKcTextElement(childElement));
        }
      } else if (childElement.isInlineText()) {
        childElement = childElement as InlineTextElement;
        if (childElement.hasText) {
          kcImagesArray.push(this.getKcInlineTextElement(childElement));
        }
      } else if (childElement.isBox() || childElement.isPhotoFrame() || childElement.isBackgroundElement()) {
        kcImagesArray = this.addChildren(childElement.children, kcImagesArray);
      }
    });

    return kcImagesArray;
  }
}
