import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PageElement, ThumbSize } from '../../../models';
import { ConfigService } from '../../../services';
import { DesignSet } from '../../../models/design-set';

/**
 * scaled representation of design where cutout represents visible area of page when page is zoomed
 */

@Component({
  selector: 'ed-page-navigator',
  templateUrl: 'page-navigator.component.html',
  styleUrls: ['page-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNavigatorComponent {
  @Input() set designSet(designSet: DesignSet) {
    this._visiblePage = designSet.visiblePage;
    this._designSet = designSet;
    this.setScaleAndThumbSize();
  }

  get designSet(): DesignSet {
    return this._designSet;
  }

  @Input() zoom: number;

  @Input() viewportTransform: number[];

  @Output() viewportTransformChange = new EventEmitter<number[]>();

  @Input() leftSide: number;

  @Input() topSide: number;

  @Input() rightSide: number;

  @Input() bottomSide: number;

  get visiblePage(): PageElement {
    return this._visiblePage;
  }

  _visiblePage: PageElement;
  private _designSet: DesignSet;
  pageMargin = 15;
  scale: number;
  thumbSize = ThumbSize.large;
  dragging = false;
  jumping = false;
  lastViewportTransform: number[];
  lastEventX: number;
  lastEventY: number;
  cutoutClass = 'cutout';
  cutoutBorderClass = 'cutout-border';

  get factor(): number {
    return this.scale * this.zoom;
  }

  get cutoutWidth() {
    return this.borderLeft + this.borderRight;
  }

  get cutoutHeight() {
    return this.borderTop + this.borderBottom;
  }

  get borderLeft(): number {
    const scaledLeftSide = -this.leftSide / this.factor + this.pageMargin;
    return this.max(scaledLeftSide, this.maxBorderWidth);
  }

  get borderTop(): number {
    const scaledTopSide = -this.topSide / this.factor + this.pageMargin;
    return this.max(scaledTopSide, this.maxBorderHeight);
  }

  get borderRight(): number {
    const scaledRightSide = -this.rightSide / this.factor + this.pageMargin;
    return this.max(scaledRightSide, this.maxBorderWidth);
  }

  get borderBottom(): number {
    const scaledBottomSide = -this.bottomSide / this.factor + this.pageMargin;
    return this.max(scaledBottomSide, this.maxBorderHeight);
  }

  get maxBorderWidth(): number {
    return this.visiblePage.width / this.scale + this.pageMargin * 2;
  }

  get maxBorderHeight(): number {
    return this.visiblePage.height / this.scale + this.pageMargin * 2;
  }

  max(side: number, max: number): number {
    return Math.round(Math.min(Math.max(0, side), max));
  }

  constructor(public config: ConfigService) { }

  /**
   * sets thumb to appropriate size and calculates scale accordingly
   */
  setScaleAndThumbSize() {
    const maxLargestSide = 170;
    const minShortestSide = 70;
    const scaleAtDefaultThumbSize = this.visiblePage.height / ThumbSize.large;

    if (this.visiblePage.width / scaleAtDefaultThumbSize > maxLargestSide) {
      this.scale = this.visiblePage.width / maxLargestSide;
      this.thumbSize = this.visiblePage.height / this.scale;
    } else if (this.visiblePage.width / scaleAtDefaultThumbSize < minShortestSide) {
      this.scale = this.visiblePage.width / minShortestSide;
      this.thumbSize = this.visiblePage.height / this.scale;
    } else {
      this.thumbSize = ThumbSize.large;
      this.scale = this.visiblePage.height / this.thumbSize;
    }
  }

  @HostListener('mousedown', ['$event'])
  eventStart(event: any) {
    if (event.button !== undefined && event.button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }

    // preventDefault added to prevent that cursor changes to text-cursor when dragging on safari
    event.preventDefault();
    if (event.srcElement.classList.contains(this.cutoutClass)) {
      this.lastViewportTransform = [...this.viewportTransform];
      this.lastEventX = event.clientX;
      this.lastEventY = event.clientY;

      this.dragging = true;
    } else if (event.srcElement.className === this.cutoutBorderClass) {
      this.jumping = true;

      let { x, y, width, height } = event.srcElement.getBoundingClientRect();

      this.lastViewportTransform = [...this.viewportTransform];
      this.lastEventX = x + (width - this.borderLeft - this.borderRight) / 2 + this.borderLeft;
      this.lastEventY = y + (height - this.borderTop - this.borderBottom) / 2 + this.borderTop;
    }
  }

  /**
   * moves visible area of page by dragging cutout
   */
  @HostListener('mousemove', ['$event'])
  onDrag(event: MouseEvent) {
    event.preventDefault(); // prevent blue selections, drag symbols etc

    if (this.dragging) {
      this.dispatchViewportTransform(event);
    }
  }

  @HostListener('mouseup', ['$event'])
  dragStop(event: MouseEvent) {
    if (this.dragging) {
      this.dispatchViewportTransform(event);

      this.lastViewportTransform = null;
      this.lastEventX = null;
      this.lastEventY = null;

      this.dragging = false;
    }
  }

  /**
   * centers visible area of page around click location on navigator
   */
  @HostListener('click', ['$event'])
  onJump(event: MouseEvent) {
    if (this.jumping) {
      this.dispatchViewportTransform(event);

      this.lastViewportTransform = null;
      this.lastEventX = null;
      this.lastEventY = null;

      this.jumping = false;
    }
  }

  @HostListener('pan', ['$event'])
  onPan(event: HammerInput) {
    // prevent triggering pan on design area (panning the page)
    // stopPropegation on Mousemove is not enough, it does not stop pan event
    event.preventDefault();
    event.srcEvent.stopPropagation();
  }

  dispatchViewportTransform(event: MouseEvent) {
    let viewportTransform = [...this.lastViewportTransform];
    let deltaX = this.lastEventX - event.clientX;
    let deltaY = this.lastEventY - event.clientY;

    viewportTransform[4] = viewportTransform[4] + deltaX * this.factor;
    viewportTransform[5] = viewportTransform[5] + deltaY * this.factor;

    this.viewportTransformChange.emit(viewportTransform);
  }
}
