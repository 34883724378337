import { Action } from '@ngrx/store';
import { ClientError } from '../models/client-error';
import { DesignSet } from '../models/design-set';

export enum SaveActionTypes {
  Save = '[Save] Save',
  InitSuccess = '[Save] Init Success',
  Copy = '[Save] Copy',

  Login = '[Save] Login',
  LoginSuccess = '[Save] Login Success',
  CancelLogin = '[Save] Cancel Login',

  Register = '[Save] Register',
  RegisterSuccess = '[Save] Register Success',
  CancelRegister = '[Save] Cancel Register',

  OpenSaveDialog = '[Save] Open Save Dialog',
  OpenCopyDialog = '[Save] Open Copy Dialog',
  CloseSaveDialog = '[Save] Close Save Dialog',
  CloseCopyDialog = '[Save] Close Copy Dialog',
  SubmitSave = '[Save] Submit Save',
  SubmitFailure = '[Save] Submit Failure',
  SubmitSuccess = '[Save] Submit Success',

  ResetLastSavedDesign = '[Save] Reset Last Saved Design',
  ResetValidationErrors = '[Save] Reset Validation Errors'
}

export class Save implements Action {
  readonly type = SaveActionTypes.Save;
}

export class InitSuccess implements Action {
  readonly type = SaveActionTypes.InitSuccess;

  constructor(public designSet: DesignSet) {}
}

export class Login implements Action {
  readonly type = SaveActionTypes.Login;
}

export class LoginSuccess implements Action {
  readonly type = SaveActionTypes.LoginSuccess;
}

export class CancelLogin implements Action {
  readonly type = SaveActionTypes.CancelLogin;
}

export class Register implements Action {
  readonly type = SaveActionTypes.Register;
}

export class RegisterSuccess implements Action {
  readonly type = SaveActionTypes.RegisterSuccess;
}

export class CancelRegister implements Action {
  readonly type = SaveActionTypes.CancelRegister;
}

export class OpenSaveDialog implements Action {
  readonly type = SaveActionTypes.OpenSaveDialog;
}

export class CloseSaveDialog implements Action {
  readonly type = SaveActionTypes.CloseSaveDialog;
}

export class OpenCopyDialog implements Action {
  readonly type = SaveActionTypes.OpenCopyDialog;
}

export class CloseCopyDialog implements Action {
  readonly type = SaveActionTypes.CloseCopyDialog;
}

export class SubmitSave implements Action {
  readonly type = SaveActionTypes.SubmitSave;

  constructor(public title: string, public save = false) {}
}

export class SubmitFailure implements Action {
  readonly type = SaveActionTypes.SubmitFailure;

  constructor(public payload: ClientError[]) {}
}

export class SubmitSuccess implements Action {
  readonly type = SaveActionTypes.SubmitSuccess;

  constructor(public designSet: DesignSet, public save = false) {}
}

export class Copy implements Action {
  readonly type = SaveActionTypes.Copy;

  constructor(public name: string) {}
}

export class ResetLastSavedDesign implements Action {
  readonly type = SaveActionTypes.ResetLastSavedDesign;
}

export class ResetValidationErrors implements Action {
  readonly type = SaveActionTypes.ResetValidationErrors;
}

export type SaveActions =
  | InitSuccess
  | Save
  | Copy
  | Login
  | LoginSuccess
  | CancelLogin
  | Register
  | RegisterSuccess
  | CancelRegister
  | OpenSaveDialog
  | CloseSaveDialog
  | OpenCopyDialog
  | CloseCopyDialog
  | SubmitSave
  | SubmitFailure
  | SubmitSuccess
  | ResetLastSavedDesign
  | ResetValidationErrors;
