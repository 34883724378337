import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageCategory } from '../../../models';
import { AppState } from '../../../reducers';
import { select, Store } from '@ngrx/store';
import { getMainCategory } from '../../../image-library/reducer';
import { ImageLibraryType } from '../../../image-library/image-library';
import { ImageData } from '../../../services/image-upload.service';
import { CanvasActions } from '../../../actions';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-background-mob-new',
  templateUrl: 'background-mob-new.component.html'
})
export class BackgroundMobNewComponent implements OnInit {
  @Output() closeImageLibrary: EventEmitter<boolean> = new EventEmitter<boolean>();

  mainCategory$: Observable<ImageCategory>;
  imageLibrarySavedLocation$: Observable<number[]>;

  constructor(private store: Store<AppState>, public getTextService: GetTextService) {}

  ngOnInit() {
    this.mainCategory$ = this.store.pipe(select(getMainCategory(ImageLibraryType.background)));
    this.imageLibrarySavedLocation$ = this.store.pipe(select(s => s.imagelibrary.savedLocation));
  }

  uploadImageCallback() {
    return (imageData: ImageData) => {
      this.store.dispatch(
        new CanvasActions.CheckDoubleBackgroundImage(imageData.width, imageData.height, imageData.sid)
      );
    };
  }

  chooseImage(event: any) {
    this.store.dispatch(new CanvasActions.CheckDoubleBackgroundImage(event.width, event.height, event.sid, event.url));
  }
}
