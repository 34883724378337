import { EditorAction } from '../action';
import { PASTE } from './canvas.action.types';
import { CanvasElement } from '../../models';

export class Paste implements EditorAction {
  readonly type = PASTE;
  readonly undoable = true;
  readonly save = true;

  constructor(public newElement: CanvasElement, public moveElement: boolean) {}
}
