import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CanvasActions } from '../../../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ImageElement, PhotoFrameElement } from '../../../models';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-image-crop-mob',
  templateUrl: 'image-crop-mob.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropMobComponent {
  _element: ImageElement | PhotoFrameElement;

  @Input()
  set element(element: ImageElement | PhotoFrameElement) {
    this._element = element;
  }

  get element() {
    return this._element;
  }

  get imageElement(): ImageElement {
    return this.element.firstChild
      ? (this.element.firstChild as ImageElement)
      : this.element.isImage()
      ? (this.element as ImageElement)
      : undefined;
  }

  constructor(private store: Store<AppState>, public getTextService: GetTextService) {}

  toggleCroppingMode() {
    this.store.dispatch(new CanvasActions.ToggleCroppingMode(this.imageElement.parent.route));
  }
}
