import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import {
  ImageUploadService,
  UploadProgress,
  ImageData,
  UploadCallbackFunction,
  UPLOAD_URL
} from '../../../services/image-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../shared/dialogs';
import { GetTextService } from '../../../services';
import { fileExtensions } from '../../../utils/element.utils';

@Component({
  selector: 'ed-upload',
  templateUrl: 'upload.component.html',
  styleUrls: ['../../../shared/button/buttons.scss'],
  providers: [ImageUploadService]
})
export class UploadComponent implements OnInit, OnDestroy {
  uploadErrorSub: Subscription;
  uploadSubscription: Subscription;
  uploadProgress: Observable<UploadProgress>;
  isUploading = false;
  @Input() icon = '';
  @Input() text = '';
  @Input() color = '';
  @Input() buttonClass = '';
  @Input() dataLayerIdString: string;
  @Input() uploadUrl = UPLOAD_URL;
  @Input() callback: UploadCallbackFunction;
  @Output() uploadedImage: EventEmitter<ImageData> = new EventEmitter();
  @Output() uploading: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor(
    protected imageUploadService: ImageUploadService,
    public dialog: MatDialog,
    protected getTextService: GetTextService
  ) {}

  ngOnInit() {
    this.imageUploadService.uploadUrl = this.uploadUrl;
    this.uploadProgress = this.imageUploadService.uploadProgress;
    this.uploadSubscription = this.imageUploadService.uploadedImage.subscribe(imageData => {
      this.uploadedImage.emit(imageData);
      this.isUploading = false;
      this.uploading.emit(false);
    });

    this.uploadErrorSub = this.imageUploadService.error.subscribe(error => {
      this.isUploading = false;
      this.uploading.emit(false);
      this.openDialog();
    });
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const extension = file.name.split('.').slice(-1)[0].toLowerCase();
    const validExtension = !!fileExtensions[extension];

    if (validExtension) {
      this.isUploading = true;
      this.uploading.emit(true);
      this.imageUploadService.uploadImage(file, this.callback);
    } else {
      this.openDialog();
    }
  }

  ngOnDestroy() {
    this.uploadSubscription.unsubscribe();
    this.uploadErrorSub.unsubscribe();
  }

  openDialog(): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '250px',
      data: {
        title: this.getTextService.text.dialog.upload.error.title,
        message: this.getTextService.text.dialog.upload.error.text,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  clickFileInput() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }
}
