import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { CanvasElement, MenuItem } from '../../models';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../../auth/reducer';
import { GetTextService } from '../../services';
import * as Continue from '../../continue/actions';
import * as Auth from '../../auth/actions';
import { CanvasActions } from '../../actions';
import { selectDesign } from '../../selectors';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ed-account',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss', '../toolbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountComponent implements OnInit, OnDestroy {
  configSubscription: Subscription;
  userMenuItems: MenuItem[];
  isLoggedIn$: Observable<boolean>;
  user$: Observable<User>;
  loginPending$: Observable<boolean>;
  selectedElementSubscription: Subscription;
  selectedElement: CanvasElement;
  accountOpened = false;

  constructor(private store: Store<fromAuth.State>, public getTextService: GetTextService) {}

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(fromAuth.isLoggedIn));
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.loginPending$ = this.store.pipe(select(fromAuth.getPending));
    this.configSubscription = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.userMenuItems = config.userMenuItems;
    });
    this.selectedElementSubscription = this.store
      .pipe(
        select(selectDesign),
        filter(d => !!d),
        map(design => design.selectedElement)
      )
      .subscribe(selectedElement => (this.selectedElement = selectedElement));
  }

  toggleAccount() {
    this.accountOpened = !this.accountOpened;
  }

  openLoginDialog() {
    this.store.dispatch(new Auth.OpenLoginDialog());
  }

  logOut() {
    this.store.dispatch(new Auth.Logout());

    /*
      this if statement is added temporarily, to prevent that a locked element is still activated after logging out. For now, when a user is
      logged in he/she has lockElements rights, when he/she is logged out he does not have this rights. To prevent that a user has still
      lockElements rights on an element after logging out, the element is deselected.
     */
    if (this.selectedElement && this.selectedElement.permissions.isLocked) {
      this.store.dispatch(new CanvasActions.Deselect());
    }
  }

  goToUrl(url: string) {
    this.store.dispatch(new Continue.Navigate(url));
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
  }
}
