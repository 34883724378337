import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditOptionSelectorComponent } from './edit-option-selector.component';
import { PreviewType } from './edit-option-dialog.component';
import { formatImageSource } from '../../../../models';

@Component({
  selector: 'ed-edit-option-selector-mob',
  templateUrl: 'edit-option-selector-mob.component.html',
  styleUrls: ['edit-option-selector-mob.component.scss', '../../../../shared/button/preview-button-mob.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditOptionSelectorMobComponent extends EditOptionSelectorComponent {
  getPreviewBackgroundImage() {
    if (this.previewType === PreviewType.image) {
      return this.sanitizer.bypassSecurityTrustStyle(
        `url(${this.imageBase}${formatImageSource(this.itemImg)}) center/cover no-repeat`
      );
    } else {
      return 'transparent';
    }
  }
}
