import { EditorAction } from '../action';
import { FILL_HORIZONTAL } from './canvas.action.types';

export class FillHorizontal implements EditorAction {
  readonly type = FILL_HORIZONTAL;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>) {}
}
