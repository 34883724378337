import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../reducers';
import { Design, Fold, PageElement, Spread, Thumb, ThumbSize } from '../../models';
import { CanvasActions } from '../../actions';
import * as Continue from '../../continue/actions';
import { GetTextService } from '../../services';
import * as fromPermissions from '../../reducers/permissions.reducer';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { DesignSet } from 'src/app/models/design-set';

@Component({
  selector: 'ed-nav-thumbs',
  templateUrl: 'nav-thumbs.component.html',
  styleUrls: ['nav-thumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavThumbsComponent implements OnInit, OnDestroy {
  public tooltipPosition: TooltipPosition = 'above';
  tooltipShowDelay$: Observable<number>;
  pages: PageElement[];
  thumbs: Thumb[];
  foldType: Fold;
  thumbSize = ThumbSize.extraSmall;
  inSpreadView: boolean;
  spreads: Spread[];
  dataLayerIdPrefix = 'nav-';
  active: boolean;
  public canHidePage: boolean;
  public canHidePageSubscription$: Subscription;
  swiperConfig: SwiperOptions;
  isThumb = true;
  showButtons = false;
  visiblePageIndex = 0;

  @Input() designSet: DesignSet;

  @Input() set design(design: Design) {
    this.active = design.active;
    this.pages = design.pages;
    this.spreads = design.spreads;
    this.inSpreadView = design.spreadView;

    const foldType = design.spreadView || design.pages.length > 4 ? Fold.none : design.foldType;
    this.foldType = foldType;
    this.thumbs = this.createThumbs(design, foldType, this.thumbSize);

    this.visiblePageIndex = design.pages.findIndex(page => page.visible);
  }

  @ViewChild(SwiperComponent, { static: false }) swiper?: SwiperComponent;

  constructor(private store: Store<AppState>, public getTextService: GetTextService) {}

  ngOnInit() {
    this.tooltipShowDelay$ = this.store.pipe(
      select(s => s.config),
      map(config => config.tooltipShowDelay)
    );

    this.canHidePageSubscription$ = this.store
      .pipe(select(fromPermissions.getSetElementPermissionIsHidden))
      .subscribe(permission => {
        this.canHidePage = permission;
      });

    this.swiperConfig = {
      initialSlide: this.visiblePageIndex,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev'
      },
      mousewheel: true,
      centerInsufficientSlides: true,
      slideToClickedSlide: true,
      allowTouchMove: false,
      watchOverflow: true,
      updateOnWindowResize: true,
      slidesPerGroup: 2,
      on: {
        lock: () => {
          this.showButtons = false;
        },
        unlock: () => {
          this.showButtons = true;
        },
        click: e => {
          this.swiper.swiperRef.slideTo(e.clickedIndex);
        }
      }
    };
  }

  ngOnDestroy() {
    this.canHidePageSubscription$.unsubscribe();
  }

  createThumbs(design: Design, foldType: Fold, thumbSize: ThumbSize): Thumb[] {
    const designPages = design.pages;
    if (
      designPages.length === 4 &&
      (design.foldType === Fold.horizontal || design.foldType === Fold.vertical) &&
      !design.spreadView
    ) {
      const thumb1 = new Thumb(
        1,
        this.designSet,
        [designPages[2], designPages[0]],
        designPages[0].permissions.isHidden,
        foldType,
        thumbSize,
        design.active
      );
      const thumb2 = new Thumb(
        2,
        this.designSet,
        [designPages[1], designPages[2]],
        designPages[1].permissions.isHidden,
        foldType,
        thumbSize,
        design.active
      );
      const thumb3 = new Thumb(
        3,
        this.designSet,
        [designPages[1], designPages[2]],
        designPages[2].permissions.isHidden,
        foldType,
        thumbSize,
        design.active
      );
      const thumb4 = new Thumb(
        4,
        this.designSet,
        [designPages[1], designPages[3]],
        designPages[3].permissions.isHidden,
        foldType,
        thumbSize,
        design.active
      );
      return [thumb1, thumb2, thumb3, thumb4].filter(thumb => this.canHidePage || !thumb.blur);
    }
    return designPages
      .map(
        (page, index) =>
          new Thumb(page.id, this.designSet, [designPages[index]], page.permissions.isHidden, foldType, thumbSize, design.active)
      )
      .filter(thumb => this.canHidePage || !thumb.blur);
  }

  get designIsTriptych() {
    return this.spreads && this.spreads[0] && this.spreads[0].spreadPage.backgroundElements.length === 3;
  }

  selectPage(route: Array<number>) {
    this.store.dispatch(new CanvasActions.TogglePage(route));
  }

  itemTrackBy(index: number, item: PageElement | Thumb) {
    return item.id;
  }

  goToUrl(url: string, retainParams = false) {
    this.store.dispatch(new Continue.Navigate(url, retainParams));
  }
}
