export class UiState {
  showElementSettingsMenu = false;
  showStyleMenu = false;
}

interface DialogValues {
  dialogWidth: string;
  dialogMaxWidth: string;
  dialogPosition: Record<string, unknown>;
  dialogWidthValue?: number;
}

export const DIALOG_VALUES_MOB: DialogValues = {
  dialogWidth: '100vw',
  dialogMaxWidth: '100vw',
  dialogPosition: { bottom: '0px' }
};

export const DIALOG_VALUES_DT: DialogValues = {
  dialogWidthValue: 360,
  dialogWidth: '360px',
  dialogMaxWidth: '360px',
  dialogPosition: { right: '20px' }
};
