import { BaseElement } from './base-element';
import { ElementType, CanvasElement, LayerType } from './canvas';
import { ElementPermissions } from './permissions';
import { ImageElement } from './image-element';
import { PageElement } from './page-element';
import { FoilTypes } from './color';

export class PhotoFrameElement extends BaseElement {
  readonly type = ElementType.photoFrame;
  name = 'photoframe';
  parent: PageElement;
  layerType: LayerType = LayerType.standardRgb;
  isSpotUvForJpgImagePermissionWasChanged = false;
  permissions: ElementPermissions = {
    isLocked: false,
    isMovable: true,
    isRemovable: true,
    isReplaceable: true,
    isInstantReplaceable: false,
    hasInstantReplaceablePlaceholder: false,
    isRotatable: true,
    isResizable: true,
    isRecolorable: true,
    isDuplicatable: true,
    isFlippable: true,
    isFoilable: false,
    isCroppable: true,
    isPrintable: true,
    isTopLayer: false,
    isUntargetable: false,
    adjustTransparency: true,
    hasCoating: false,
    isSpotUvable: true,
    isFillable: true,
    isOrderable: true,
    excludeFromPreviews: false,
    isVisibleOutsideCropArea: false,
    isExternallyEditable: false
  };

  get foilType() {
    return this.firstChild.foilType;
  }

  set foilType(type: FoilTypes) {
    this.firstChild.foilType = type;
  }

  get spotUv() {
    return this.firstChild.spotUv;
  }

  set spotUv(hasSpotUv: boolean) {
    this.firstChild.spotUv = hasSpotUv;
  }

  get color() {
    return this.firstChild.color;
  }

  set color(color: string) {
    this.firstChild.color = color;
  }

  get inCroppingMode(): boolean {
    return this.firstChild && this.firstChild.selected && this.permissions.isCroppable;
  }

  get combinedPermissions() {
    return this.firstChild ? { ...this.firstChild.permissions, ...this.permissions } : this.permissions;
  }

  get firstChild() {
    return this.children[0] as ImageElement;
  }

  get tag() {
    return this.firstChild.tag;
  }

  set tag(tag: string) {
    this.firstChild.tag = tag;
  }

  addElement(element: CanvasElement, id?: number) {
    element.id = id !== undefined ? id : this.newId;
    element.parent = this;
    // these permissions should always be true for photoframechildren;
    element.permissions.isMovable = true;
    element.permissions.isResizable = true;
    this.children.push(element);
  }

  constructor(id = -1, { width = 10, height = 8, x = 0, y = 0, r = 0 } = {}) {
    super(id);
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.rotation = r;
  }
}
