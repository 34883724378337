import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmDialogComponent } from '../shared/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { GetTextService } from './get-text.service';
import { exhaustMap, filter, first, mergeMap } from 'rxjs/operators';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { getShowUpdateDialog } from '../reducers/permissions.reducer';
import { combineLatest, concat, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private dialog: MatDialog,
    private getTextService: GetTextService,
    private store: Store<AppState>,
    private http: HttpClient
  ) {}

  updateProtocolFile = 'update_protocol.json';

  checkForUpdates() {
    if (this.updates.isEnabled) {
      // check every 15 min if update is available
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const every15Min$ = interval(1000 * 60 * 15);
      const every15minOnceAppIsStable$ = concat(appIsStable$, every15Min$);
      every15minOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());

      // when force_update is true automatically reload page when update available
      this.updates.available
        .pipe(
          mergeMap(() => {
            return this.http.get(this.updateProtocolFile, { params: { cache_bust: new Date().getTime() } });
          }),
          filter((protocol: { force_update: boolean }) => protocol.force_update)
        )
        .subscribe(() => this.updates.activateUpdate().then(() => document.location.reload()));

      // when getShowUpdateDialog true, check for update once and show dialog with option to reload page and update
      const showDialog$ = this.store.select(getShowUpdateDialog);
      combineLatest([showDialog$, this.updates.available])
        .pipe(
          filter(([showDialog, event]) => showDialog),
          exhaustMap(([showDialog, event]) => this.openDialog().afterClosed()),
          filter((confirmed: boolean) => confirmed)
        )
        .subscribe(() => this.updates.activateUpdate().then(() => document.location.reload()));
    }
  }

  openDialog() {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.getTextService.text.dialog.update.title,
        message: this.getTextService.text.dialog.update.message,
        confirmButtonText: this.getTextService.text.dialog.button.ok,
        cancelButtonText: this.getTextService.text.dialog.button.cancel
      }
    });
  }
}
