import { EditorAction } from '../action';
import { FLIP_VERTICAL } from './canvas.action.types';

export class FlipVertical implements EditorAction {
  readonly type = FLIP_VERTICAL;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public flip: boolean) {}
}
