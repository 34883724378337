import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorScheme, HSB, hslToRgb, rgbToHex } from '../../../utils/color-utils';
import { ColorSliderComponent } from '../color-slider/color-slider.component';

@Component({
  selector: 'ed-color-hue-selector',
  templateUrl: '../color-slider/color-slider.component.html',
  styleUrls: ['./color-hue-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorHueSelectorComponent extends ColorSliderComponent implements AfterViewInit {
  @Input() set newColorByInput(newColorByInput: HSB) {
    if (newColorByInput && newColorByInput !== this.currentHueColor) {
      this.currentHueColor = newColorByInput;
      this.selectedPosition = this.getSelectedPosition(newColorByInput);
      this.redrawGradientAndSlider();
    }
  }

  get currentHueColor() {
    return this._currentHueColor;
  }

  @Input() set currentHueColor(currentHueColor: HSB) {
    if (currentHueColor && currentHueColor !== this.currentHueColor) {
      // if hue is NaN, hue is set to 0, to display a red gradient like Photoshop do
      this._currentHueColor = { ...currentHueColor, h: currentHueColor.h || 0 };
      this.redrawGradientAndSlider();
    }
  }

  _currentHueColor: HSB;

  @Input() colorScheme: ColorScheme;

  @Output() color: EventEmitter<HSB> = new EventEmitter();

  height = 80;

  satMin = 0;
  satMax = 0;
  satScale = 0;
  brightMin = 0;
  brightMax = 0;
  brightScale = 0;

  ngAfterViewInit() {
    this.satMin = this.colorScheme.blackGradientTopOpacity * 100;
    this.satMax = this.colorScheme.blackGradientBottomOpacity * 100;
    this.satScale = this.satMax - this.satMin;
    this.brightMin = this.colorScheme.whiteGradientRightOpacity * 100;
    this.brightMax = this.colorScheme.whiteGradientLeftOpacity * 100;
    this.brightScale = this.brightMax - this.brightMin;

    this.selectedPosition = this.getSelectedPosition(this.currentHueColor);

    this.sliderContext = this.drawElementRef(this.slider);
    this.gradientContext = this.drawElementRef(this.canvas);

    this.drawGradient();
    this.drawSlider();
  }

  drawGradient() {
    const fillColorRgb = hslToRgb({ h: this.currentHueColor.h, s: 100, l: 50 });

    this.gradientContext.fillStyle = rgbToHex(fillColorRgb.r, fillColorRgb.g, fillColorRgb.b);
    this.gradientContext.fillRect(0, 0, this.width, this.height);

    const whiteGradient = this.createGradient(this.gradientContext, this.width, 0, [
      `rgba(255, 255, 255, ${this.colorScheme.whiteGradientLeftOpacity})`,
      `rgba(255, 255, 255, ${this.colorScheme.whiteGradientRightOpacity})`
    ]);
    this.gradientContext.fillStyle = whiteGradient;
    this.gradientContext.fillRect(0, 0, this.width, this.height);

    const blackGradient = this.createGradient(this.gradientContext, 0, this.height, [
      `rgba(0, 0, 0, ${this.colorScheme.blackGradientTopOpacity})`,
      `rgba(0, 0, 0, ${this.colorScheme.blackGradientBottomOpacity})`
    ]);
    this.gradientContext.fillStyle = blackGradient;
    this.gradientContext.fillRect(0, 0, this.width, this.height);
  }

  drawSlider() {
    this.resetContext(this.sliderContext);
    if (this.selectedPosition) {
      this.sliderContext.strokeStyle = 'white';
      this.sliderContext.fillStyle = 'white';
      this.sliderContext.shadowOffsetX = 1;
      this.sliderContext.shadowOffsetY = 1;
      this.sliderContext.shadowColor = 'rgba(0,0,0,0.3)';
      this.sliderContext.beginPath();
      this.sliderContext.arc(this.selectedPosition.x, this.selectedPosition.y, 8, 0, 2 * Math.PI);
      this.sliderContext.lineWidth = 3;
      this.sliderContext.stroke();
    }
  }

  createGradient(ctx: CanvasRenderingContext2D, width: number, height: number, stops: string[]) {
    const gradient = ctx.createLinearGradient(0, 0, width, height);
    gradient.addColorStop(0, stops[0]);
    gradient.addColorStop(1, stops[1]);
    return gradient;
  }

  getSelectedPosition(color: HSB) {
    const selectedPositionX = this.width * ((color.s - this.satMin) / this.satScale);
    const selectedPositionY = this.height - this.height * ((color.b - this.brightMin) / this.brightScale);

    return { x: selectedPositionX, y: selectedPositionY };
  }

  changeColor(offsetX: number, offsetY: number) {
    this.selectedPosition = { x: offsetX, y: offsetY };
    this.drawSlider();

    // use hsb because it is a linear color space
    const h = this.currentHueColor.h;
    const s = Math.max(Math.round(this.satMin), Math.round(this.satMin + (offsetX / this.width) * this.satScale));
    const b = Math.max(
      Math.round(this.brightMin),
      Math.round(this.brightMax - (offsetY / this.height) * this.brightScale)
    );

    this.color.emit({ h, s, b });
  }
}
