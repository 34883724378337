import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as Auth from '../auth/actions';
import * as fromAuth from '../auth/reducer';
import { Observable, shareReplay, Subscription } from 'rxjs';
import { User } from '../models/user';
import { BasketInterface, CanvasElement } from '../models';
import { CanvasActions } from '../actions';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import * as Continue from '../continue/actions';
import { ConfigService, GetTextService } from '../services';
import { ChatService } from '../services/chat.service';
import { getDesignSet, selectDesign } from '../selectors';
import { cloneDeep } from 'lodash-es';
import { DesignSet } from '../models/design-set';
import { TooltipPosition } from '@angular/material/tooltip';
import * as fromPermissions from '../reducers/permissions.reducer';

/**
 * This class represents the toolbar component.
 */
@Component({
  selector: 'ed-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {
  logoUrl = '';
  homeUrl = '/';
  configSubscription: Subscription;
  output = '';
  basketInterface: BasketInterface;
  basketText$: Observable<string>;
  user$: Observable<User>;
  selectedElementSubscription: Subscription;
  selectedElement: CanvasElement;
  designSet$: Observable<DesignSet>;
  tooltipShowDelay$: Observable<number>;
  tooltipAutoSaveDisabled$: Observable<boolean>;
  tooltipPosition: TooltipPosition = 'below';
  isMac$: Observable<boolean>;

  undoRedoTooltipText = this.getTextService.text.toolBar.undoRedo.tooltip;

  @Output() showMainMenu = new EventEmitter<void>();
  @Output() closeMainMenu = new EventEmitter<void>();
  @Input() mainMenuOpened: boolean;

  toggleMainMenu() {
    this.mainMenuOpened ? this.closeMainMenu.emit() : this.showMainMenu.emit();
  }

  openLoginDialog() {
    this.store.dispatch(new Auth.OpenLoginDialog());
  }

  logOut() {
    this.store.dispatch(new Auth.Logout());

    /*
      this if statement is added temporarily, to prevent that a locked element is still activated after logging out. For now, when a user is
      logged in he/she has lockElements rights, when he/she is logged out he does not have this rights. To prevent that a user has still
      lockElements rights on an element after logging out, the element is deselected.
     */
    if (this.selectedElement && this.selectedElement.permissions.isLocked) {
      this.store.dispatch(new CanvasActions.Deselect());
    }
  }

  constructor(
    private store: Store<fromAuth.State>,
    public getTextService: GetTextService,
    public configService: ConfigService,
    public chatService: ChatService
  ) {}

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    this.isMac$ = config$.pipe(map(config => config.isMac));
    this.designSet$ = this.store.pipe(
      select(getDesignSet),
      map(set => cloneDeep(set)),
      shareReplay()
    );
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.configSubscription = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.logoUrl = config.logoUrl;
      this.basketInterface = config.basketInterface;
    });
    this.basketText$ = this.user$.pipe(
      withLatestFrom(this.store.pipe(select(s => s.config))),
      map(([user, config]) =>
        user.basketItems === 1 ? config.basketInterface.textSingular : config.basketInterface.textPlural
      )
    );
    this.selectedElementSubscription = this.store
      .pipe(
        select(selectDesign),
        filter(d => !!d),
        map(design => design.selectedElement)
      )
      .subscribe(selectedElement => (this.selectedElement = selectedElement));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    this.tooltipAutoSaveDisabled$ = this.store.pipe(
      select(fromPermissions.getAutoSave),
      map(autoSave => !autoSave)
    );
  }

  goToUrl(url: string) {
    this.store.dispatch(new Continue.Navigate(url));
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
    this.selectedElementSubscription.unsubscribe();
  }

  showChat() {
    this.chatService.showChat();
  }
}
