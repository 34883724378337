import { EditorAction } from '../action';
import { TOGGLE_VIEW } from './canvas.action.types';
import { View } from '../../models';

export class ToggleView implements EditorAction {
  readonly type = TOGGLE_VIEW;
  readonly undoable = true;
  readonly save = true;

  constructor(public view: View) {}
}
