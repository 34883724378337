import { Component, Input, HostListener, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { PageElement } from '../../models';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CanvasActions } from '../../actions';
import { Subscription } from 'rxjs';
import * as fromPermissions from '../../reducers/permissions.reducer';

export enum ButtonStyles {
  shadow = 'shadow'
}

@Component({
  selector: 'ed-navigation-buttons',
  styleUrls: ['navigation-buttons.component.scss'],
  templateUrl: 'navigation-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationButtonsComponent implements OnInit, OnDestroy {
  @Input() pageToggleButtonY: number;
  @Input() pageTogglePreviousButtonX: number;
  @Input() pageToggleNextButtonX: number;
  @Input() buttonStyle: string;

  @Input() set pages(pages: PageElement[]) {
    this._pages = pages.filter(page => this.canHidePage || !page.permissions.isHidden);
    this.visiblePageIndex = this._pages.findIndex(page => page.visible);
    this.visiblePage = pages.find(page => page.visible);
    this.lastPageIndex = this._pages.length - 1;
  }

  visiblePageIndex: number;
  visiblePage: PageElement;
  lastPageIndex: number;
  indexFirstItem = 0;
  buttonStyles = ButtonStyles;

  public _pages: PageElement[];

  public canHidePage: boolean;
  public canHidePageSubscription$: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.canHidePageSubscription$ = this.store
      .pipe(select(fromPermissions.getSetElementPermissionIsHidden))
      .subscribe(permission => {
        this.canHidePage = permission;
      });
  }

  ngOnDestroy() {
    this.canHidePageSubscription$.unsubscribe();
  }

  get onFirstPage() {
    return this.visiblePageIndex === this.indexFirstItem;
  }

  get onLastPage() {
    return this.visiblePageIndex === this.lastPageIndex;
  }

  togglePage(route: Array<number>) {
    this.store.dispatch(new CanvasActions.TogglePage(route));
  }

  /**
   * prevent page zooming on safari ios
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
  }

  previousPage() {
    if (!this.onFirstPage) {
      const newPage = this._pages[this.visiblePageIndex - 1];
      this.togglePage(newPage.route);
    }
  }

  nextPage() {
    if (!this.onLastPage) {
      const newPage = this._pages[this.visiblePageIndex + 1];
      this.togglePage(newPage.route);
    }
  }
}
