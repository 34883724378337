import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FunctionPermission, ImageCategory } from '../../../../models';
import { ConfigService, GetTextService } from '../../../../services';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import * as ImageLibraryActions from '../../../../image-library/actions';
import { categoryByPath } from '../../../../image-library/image-library.utils';
import { ImageLibraryService } from 'src/app/image-library/image-library.service';
import { debounceTime, distinctUntilChanged, EMPTY, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { UploadCallbackFunction } from '../../../../services/image-upload.service';
import { AiService } from 'src/app/services/';
import { CanvasActions } from 'src/app/actions';


@Component({
  selector: 'ed-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss', '../../../../shared/button/buttons.scss']
})
export class ImageLibraryComponent implements OnInit, OnDestroy {
  category: ImageCategory;
  form: FormGroup;  // Form group to handle the text input
  isLoading = false;  // Track loading state for the spinner


  inputValue = '';

  @Input() mainCategory: ImageCategory;
  @Input() savedLocation: number[];
  @Input() dataLayerIdPrefix: string;
  @Input() headerTitle: string;
  @Input() image: boolean;
  @Input() background: boolean;
  @Input() addImageAsPhotoFrame: boolean;
  @Input() uploadImageCallback: UploadCallbackFunction;
  @Output() closeImageLibrary = new EventEmitter();
  @Output() chooseImage: EventEmitter<object> = new EventEmitter();

  permissions = FunctionPermission;

  private searchImageLibrarySubscription: Subscription;
  private savedImageLibrarySubscription: Subscription;
  public searchText$ = new Subject<string>();
  searchTerm$: Observable<string>;

  constructor(
    // private imageGenerationService: ImageGenerationService,  // Hypothetical service
    public getTextService: GetTextService,
    public configService: ConfigService,
    public store: Store<AppState>,
    public libraryService: ImageLibraryService,
    private aiService: AiService  // Make sure AiService is correctly injected

  ) {}

  ngOnInit(): void {

    this.form = new FormGroup({
      inputValue: new FormControl('', Validators.required)  // Initialize the form control with validation
    });


    this.searchTerm$ = this.libraryService.savedSearchTerm$;

    this.savedImageLibrarySubscription = this.libraryService.savedImagelibrary$.subscribe(imageLibrary => {
      if (imageLibrary) {
        this.category = imageLibrary.categories[0];
      } else {
        this.category = categoryByPath(this.savedLocation, this.mainCategory);
      }
    });

    this.searchImageLibrarySubscription = this.searchText$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(text => (text ? this.libraryService.search(text, this.dataLayerIdPrefix + 'search') : EMPTY))
      )
      .subscribe(response => {
        this.store.dispatch(new ImageLibraryActions.SaveSearch(response.searchTerm, response.imageLibrary));
      });
  }

  generateImage() {
    const prompt = this.form.get('inputValue').value;
    if (!prompt) {
      console.error('Prompt is empty');
      return;
    }
    this.isLoading = true;  // Start loading
    this.aiService.sendPrompt(prompt).subscribe({
      next: collection_uuid => {
        this.pollImageStatus(collection_uuid);
      },
      error: error => {
        console.error("Error sending prompt to generate image: ", error);
        this.isLoading = false;  // Stop loading on error
      }
    });
  }
  

private pollImageStatus(collection_uuid: string) {
    const maxRetries = 18; // This allows for 1.5 minutes of retries at 5 second intervals.
    let retries = 0;

    const subscription = this.aiService.checkImageStatus(collection_uuid).subscribe({
        next: imageUrl => {

          this.isLoading = false;  // Stop loading on success

            const newImageData = {
                width: 480,  // Example width
                height: 480, // Example height
                sid: '', // Example session id or unique identifier
                url: imageUrl, // URL of the generated image
                x: 0, // Example x position
                y: 0, // Example y position
                isFoilable: false // Example property
            };

            // Dispatching action to add image to the canvas
            this.store.dispatch(new CanvasActions.AddImage(newImageData.width, newImageData.height, undefined, newImageData.url));
            console.log("Image generated and added to canvas: ", imageUrl);
            subscription.unsubscribe(); // Stop the subscription after the image is successfully retrieved
        },
        error: error => {
            if (retries < maxRetries) {
                console.log("Image not ready yet, retrying...");
                retries++;
                setTimeout(() => this.pollImageStatus(collection_uuid), 5000); // Wait 5 seconds before retrying
            } else {
                console.error("Image generation error after multiple retries: ", error);
                this.isLoading = false;  // Stop loading on final error
                subscription.unsubscribe(); // Ensure to clean up the subscription if we're stopping retries
            }
        }
    });
}

  

  getButtonSize() {
    return this.configService.isMobile ? 'xsmall' : 'small';
  }

  goBack(category: ImageCategory): void {
    const newCategory = categoryByPath(category.path.slice(0, -1), this.mainCategory);
    this.selectCategory(newCategory);
  }

  onCloseImageLibrary() {
    this.selectCategory(this.mainCategory);
    this.closeImageLibrary.emit();
  }

  selectCategory(category: ImageCategory) {
    this.saveLocation(category.path);
    this.category = category;
  }

  onChooseImage(event: any) {
    this.chooseImage.emit({
      sid: event.sid,
      url: event.url,
      imageType: event.imageType,
      width: event.width,
      height: event.height,
      isFoilable: event.isFoilable
    });
  }

  saveLocation(path: number[]): void {
    this.store.dispatch(new ImageLibraryActions.SaveLocation(path));
  }

  getValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  onSearch(value: string) {
    this.searchText$.next(value);

    if (!value) {
      this.selectCategory(this.mainCategory);
      this.store.dispatch(new ImageLibraryActions.SaveSearch('', null));
    }
  }

  ngOnDestroy() {
    this.searchImageLibrarySubscription.unsubscribe();
    this.savedImageLibrarySubscription.unsubscribe();
  }
}
