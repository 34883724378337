import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, filter, tap, catchError, share } from 'rxjs/operators';
import { ConfigService } from './config-service';

export interface ImageData {
  width: number;
  height: number;
  sid: string;
}

export interface UploadProgress {
  loaded: number;
  total: number;
  percentage: number;
}

export const UPLOAD_URL = '/upload_image/version_2';
export const OBJECT_DETECTION_UPLOAD_URL = '/upload_image/experimental';

export type UploadCallbackFunction = (imageData: ImageData) => void;

@Injectable()
export class ImageUploadService {
  public uploadedImage: Observable<ImageData>;
  public uploadProgress = new Subject<UploadProgress>();
  public error = new Subject<string>();

  public uploadUrl: string;
  imageData = new Subject<[File, UploadCallbackFunction]>();

  constructor(private _http: HttpClient, private config: ConfigService) {
    // Data from imageData Subject is used to post to image backend,
    // uploaded image data is assigned to new Observable.
    const upload = this.imageData.pipe(
      switchMap(([data, action]) => this.postImageData(data, action)),
      share() //  prevents multiple uploads with multiple subscriptions
    );

    this.uploadedImage = upload.pipe(map(({ imgData, action }) => imgData));

    upload.subscribe(({ imgData, action }) => {
      if (action) {
        action(imgData);
      }
    });
  }

  uploadImage(file: File, action?: UploadCallbackFunction) {
    this.imageData.next([file, action]);
  }

  private postImageData(
    data: File,
    action: UploadCallbackFunction
  ): Observable<{ imgData: ImageData; action: UploadCallbackFunction }> {
    const formData = new FormData();
    formData.append('data', data);

    // https://github.com/w3c/ServiceWorker/issues/1141
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });
    const url = this.config.imgBase + this.uploadUrl;
    return this._http.post<ImageData>(url, formData, { reportProgress: true, observe: 'events', headers }).pipe(
      tap(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress.next({
            loaded: event.loaded,
            total: event.total,
            percentage: Math.round((event.loaded / event.total) * 100)
          });
        }
      }),
      filter<HttpResponse<ImageData>>(event => event.type === HttpEventType.Response),
      map(event => event.body),
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 400 && error.status < 500) {
          this.error.next(error.statusText);
        }
        return of(null);
      }),
      filter(imgData => !!imgData),
      map((imgData: ImageData) => ({ imgData, action }))
    );
  }
}
