import { fabric } from "fabric";
import { SVG_VIEWBOX_SCALE } from "react-text-editor/models/text-editor.model";
import { DEFAULT_INLINE_TEXT_ASCENDER, DEFAULT_INLINE_TEXT_DESCENDER, InlineTextElement } from "src/app/models";
import { TextboxData as InlineTextTextboxData } from "../../../models/prototypes/inline-text-element.prototype";
import { FontUtils } from "../../../utils/font.utils";

fabric.Textbox.prototype._fontSizeMult = 1;
fabric.Textbox.prototype._fontSizeFraction = 0;

fabric.Textbox.prototype.getHeightOfLine = function (lineIndex: number) {
  if (this.__lineHeights[lineIndex]) {
    return this.__lineHeights[lineIndex];
  }

  var line = this._textLines[lineIndex],
    // char 0 is measured before the line cycle because it nneds to char
    // emptylines
    maxHeight = this.getHeightOfChar(lineIndex, 0);
  for (var i = 1, len = line.length; i < len; i++) {
    maxHeight = Math.max(this.getHeightOfChar(lineIndex, i), maxHeight);
  }

  if (this.data && this.data.element instanceof InlineTextElement) {
    let data: InlineTextTextboxData = this.data;
    let font = FontUtils.getFont(data.fontLibrary, this.fontFamily);

    if (font) {
      let fontSize = this.fontSize * SVG_VIEWBOX_SCALE || 10;
      let ascender = Math.max(fontSize * font.ascender, DEFAULT_INLINE_TEXT_ASCENDER);
      let descender = Math.max(fontSize * font.descender, DEFAULT_INLINE_TEXT_DESCENDER);

      return (this.__lineHeights[lineIndex] = ((
        + ascender * this.lineHeight + descender * this.lineHeight
      ) / SVG_VIEWBOX_SCALE));
    }
  }

  return this.__lineHeights[lineIndex] = maxHeight * this.lineHeight * this._fontSizeMult;
};

fabric.Textbox.prototype.renderSelection = function (boundaries, ctx) {
  var selectionStart = this.inCompositionMode ? this.hiddenTextarea.selectionStart : this.selectionStart,
    selectionEnd = this.inCompositionMode ? this.hiddenTextarea.selectionEnd : this.selectionEnd,
    isJustify = this.textAlign.indexOf('justify') !== -1,
    start = this.get2DCursorLocation(selectionStart),
    end = this.get2DCursorLocation(selectionEnd),
    startLine = start.lineIndex,
    endLine = end.lineIndex,
    startChar = start.charIndex < 0 ? 0 : start.charIndex,
    endChar = end.charIndex < 0 ? 0 : end.charIndex;

  for (var i = startLine; i <= endLine; i++) {
    var lineOffset = this._getLineLeftOffset(i) || 0,
      lineHeight = this.getHeightOfLine(i),
      boxStart = 0, boxEnd = 0;
    if (i === startLine) {
      boxStart = this.__charBounds[startLine][startChar].left;
    }
    if (i >= startLine && i < endLine) {
      boxEnd = isJustify && !this.isEndOfWrapping(i) ? this.width : this.getLineWidth(i) || 5;
    }
    else if (i === endLine) {
      if (endChar === 0) {
        boxEnd = this.__charBounds[endLine][endChar].left;
      }
      else {
        var charSpacing = this._getWidthOfCharSpacing();
        boxEnd = this.__charBounds[endLine][endChar - 1].left
          + this.__charBounds[endLine][endChar - 1].width - charSpacing;
      }
    }

    var drawStart = boundaries.left + lineOffset + boxStart,
      drawWidth = boxEnd - boxStart,
      drawHeight = lineHeight, extraTop = 0;
    if (this.inCompositionMode) {
      ctx.fillStyle = this.compositionColor || 'black';
      drawHeight = 1;
      extraTop = lineHeight;
    }
    else {
      ctx.fillStyle = this.selectionColor;
    }
    if (this.direction === 'rtl') {
      drawStart = this.width - drawStart - drawWidth;
    }

    let topOffset = 0;

    if (this.data && this.data.element instanceof InlineTextElement) {
      let data: InlineTextTextboxData = this.data;
      let font = FontUtils.getFont(data.fontLibrary, this.fontFamily);

      if (font) {
        let fontSize = this.fontSize * SVG_VIEWBOX_SCALE || 10;
        let ascender = Math.max(fontSize * font.ascender, DEFAULT_INLINE_TEXT_ASCENDER);
        let descender = Math.max(fontSize * font.descender, DEFAULT_INLINE_TEXT_DESCENDER);

        topOffset -= (
          - descender * this.lineHeight
          + (ascender * this.lineHeight + descender * this.lineHeight) * (this.lineHeight - 1) / this.lineHeight
        ) / SVG_VIEWBOX_SCALE;
      }
    }

    ctx.fillRect(
      drawStart,
      boundaries.top + boundaries.topOffset + topOffset + extraTop,
      drawWidth,
      drawHeight);
    boundaries.topOffset += lineHeight;
  }
}

fabric.Textbox.prototype.isEndOfWrapping = function (lineIndex) {
  if (!this._styleMap[lineIndex + 1]) {
    // is last line, return true only if it is not word wrapped
    if (lineIndex > 0 && this._styleMap[lineIndex - 1].line === this._styleMap[lineIndex].line) {
      // this line is word wrapped
      return false;
    }
    return true;
  }

  if (this._styleMap[lineIndex + 1].line !== this._styleMap[lineIndex].line) {
    // this is last line before a line break, return true;
    return true;
  }

  if (lineIndex > 0 && this._styleMap[lineIndex - 1].line === this._styleMap[lineIndex].line) {
    return false;
  }

  return false;
}

//@ts-expect-error
fabric.Textbox.prototype.missingNewlineOffset = function (lineIndex) {
  if (this.splitByGrapheme || this.breakWords) {
    return this.isEndOfWrapping(lineIndex) ? 1 : 0;
  }
  return 1;
}

fabric.Textbox.prototype.get2DCursorLocation = function (selectionStart, skipWrapping) {
  if (typeof selectionStart === 'undefined') {
    selectionStart = this.selectionStart;
  }
  var lines = skipWrapping ? this._unwrappedTextLines : this._textLines,
    len = lines.length;

  for (var i = 0; i < len; i++) {
    if (selectionStart <= lines[i].length) {
      return {
        lineIndex: i,
        charIndex: selectionStart
      };
    }

    selectionStart -= lines[i].length + this.missingNewlineOffset(i);
  }
  return {
    lineIndex: i - 1,
    charIndex: lines[i - 1].length < selectionStart ? lines[i - 1].length : selectionStart
  };
}

fabric.Textbox.prototype.renderCursor = function (boundaries, ctx) {
  var cursorLocation = this.get2DCursorLocation(),
    lineIndex = cursorLocation.lineIndex,
    charIndex = cursorLocation.charIndex > 0 ? cursorLocation.charIndex - 1 : 0,
    charHeight = this.getValueOfPropertyAt(lineIndex, charIndex, 'fontSize'),
    multiplier = this.scaleX * this.canvas.getZoom(),
    cursorWidth = this.cursorWidth / multiplier,
    topOffset = boundaries.topOffset,
    dy = this.getValueOfPropertyAt(lineIndex, charIndex, 'deltaY');

  if (this.data && this.data.element instanceof InlineTextElement) {
    let data: InlineTextTextboxData = this.data;
    let font = FontUtils.getFont(data.fontLibrary, this.fontFamily);

    if (font) {
      let fontSize = this.fontSize * SVG_VIEWBOX_SCALE || 10;
      let ascender = Math.max(fontSize * font.ascender, DEFAULT_INLINE_TEXT_ASCENDER);
      let descender = Math.max(fontSize * font.descender, DEFAULT_INLINE_TEXT_DESCENDER);

      topOffset -= (
        - descender * this.lineHeight
        + (ascender * this.lineHeight + descender * this.lineHeight) * (this.lineHeight - 1) / this.lineHeight
      ) / SVG_VIEWBOX_SCALE;
    }
    else {
      topOffset += (1 - this._fontSizeFraction) * this.getHeightOfLine(lineIndex) / this.lineHeight
        - charHeight * (1 - this._fontSizeFraction);
    }
  }

  if (this.inCompositionMode) {
    this.renderSelection(boundaries, ctx);
  }
  ctx.fillStyle = this.cursorColor || this.getValueOfPropertyAt(lineIndex, charIndex, 'fill');
  ctx.globalAlpha = this.__isMousedown ? 1 : this._currentCursorOpacity;

  ctx.fillRect(
    boundaries.left + boundaries.leftOffset - cursorWidth / 2,
    topOffset + boundaries.top + dy,
    cursorWidth,
    this.getHeightOfLine(lineIndex));
}

fabric.Textbox.prototype.calcTextHeight = function () {
  var lineHeight, height = 0;
  for (var i = 0, len = this._textLines.length; i < len; i++) {
    lineHeight = this.getHeightOfLine(i);
    height += (i === len - 1 ? lineHeight / this.lineHeight : lineHeight);
  }

  if (this.data && this.data.element instanceof InlineTextElement) {
    let data: InlineTextTextboxData = this.data;
    let font = FontUtils.getFont(data.fontLibrary, this.fontFamily);

    if (font) {
      let fontSize = this.fontSize * SVG_VIEWBOX_SCALE || 10;
      let ascender = Math.max(fontSize * font.ascender, DEFAULT_INLINE_TEXT_ASCENDER);
      let descender = Math.max(fontSize * font.descender, DEFAULT_INLINE_TEXT_DESCENDER);

      height = this.__lineHeights.reduce((height: number, lineHeight: number, lineIndex: number) => {
        let padding = lineIndex > 0 ? 0 : data.element.padding;
        let prevLineDescender = lineIndex > 0 ? descender : 0;
        let prevLineHeight = lineIndex > 0 ? this.lineHeight : 1;

        height += padding + ascender * this.lineHeight + prevLineDescender * prevLineHeight;
        return height;
      }, data.element.padding + descender * this.lineHeight) / SVG_VIEWBOX_SCALE;
    }
  }

  return height;
};

// @ts-expect-error
fabric.Textbox.prototype._getTopOffset = function () {
  let topOffset = -this.height / 2;

  if (this.data && this.data.element instanceof InlineTextElement) {
    let data: InlineTextTextboxData = this.data;
    let font = FontUtils.getFont(data.fontLibrary, this.fontFamily);

    if (font) {
      let fontSize = this.fontSize * SVG_VIEWBOX_SCALE || 10;
      let ascender = Math.max(fontSize * font.ascender, DEFAULT_INLINE_TEXT_ASCENDER);
      let descender = Math.max(fontSize * font.descender, DEFAULT_INLINE_TEXT_DESCENDER);

      let height = this.__lineHeights.reduce((height: number, lineHeight: number, lineIndex: number) => {
        let padding = lineIndex > 0 ? 0 : data.element.padding;
        let prevLineDescender = lineIndex > 0 ? descender : 0;
        let prevLineHeight = lineIndex > 0 ? this.lineHeight : 1;

        height += padding + ascender * this.lineHeight + prevLineDescender * prevLineHeight;
        return height;
      }, data.element.padding + descender * this.lineHeight) / SVG_VIEWBOX_SCALE;

      topOffset = -height / 2;
      topOffset += (
        + data.element.padding
        - descender * this.lineHeight
        + (ascender * this.lineHeight + descender * this.lineHeight) * (this.lineHeight - 1) / this.lineHeight
      ) / SVG_VIEWBOX_SCALE;
    }
  }

  return topOffset;
};

// @ts-expect-error
fabric.Textbox.prototype._wrapLine = function (_line: string, lineIndex: number, desiredWidth: number, reservedSpace: number) {
  var lineWidth = 0,
    splitByGrapheme = this.splitByGrapheme,
    graphemeLines = [],
    line: string[] = [],
    // spaces in different languages?
    words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
    word = '',
    infix = splitByGrapheme ? '' : ' ',
    wordWidth = 0,
    largestCharWidth = 0,
    largestWordWidth = 0,
    lineJustStarted = true,
    additionalSpace = this._getWidthOfCharSpacing(),
    reservedSpace = reservedSpace || 0;
  // fix a difference between split and graphemeSplit
  if (words.length === 0) {
    // @ts-expect-error
    words.push([]);
  }
  desiredWidth -= reservedSpace;

  // add padding
  desiredWidth -= 2 * this.data.element.padding / SVG_VIEWBOX_SCALE;

  // keep space chars on current line
  let wordsIndex = 0;
  words = words.reduce((acc, word) => {
    if (word == "") {
      // multiple spaces between words
      if (wordsIndex > 0) {
        acc[--wordsIndex] += infix; wordsIndex++;
      }
      // line begins with space
      else {
        acc[wordsIndex++] = ""
      }
    }
    else {
      acc[wordsIndex++] = word;
    }

    // add a space between words
    if (wordsIndex > 1 && word !== "") {
      acc[wordsIndex - 2] += infix
    }
    return acc;
  }, []);

  if (words.length === 1 && words[0] === "") {
    lineJustStarted = false
  }
  else {
    for (var i = 0; i < words.length; i++) {
      // if using splitByGrapheme words are already in graphemes.
      // @ts-expect-error
      word = splitByGrapheme ? words[i] : fabric.util.string.graphemeSplit(words[i]);

      // word width without trailing spaces
      // @ts-ignore
      let strippedWord = (Array.isArray(word) ? word.join("") : word).trimEnd().split("");
      wordWidth = this._measureWord(strippedWord, lineIndex);

      // word width with trailing spaces
      let wordWidthOffset = this._measureWord(word, lineIndex) - wordWidth;

      if (this.breakWords && wordWidth >= desiredWidth) {
        for (var w = 0; w < word.length; w++) {
          var letter = word[w];
          var skipLeft = true;
          var prevLetter = w > 0 ? word[w - 1] : "";
          var letterWidth = this._getGraphemeBox(word[w], lineIndex, w, prevLetter, skipLeft).kernedWidth as number;

          largestCharWidth = Math.max(largestCharWidth, letterWidth);

          if (i > 0 && w == 0 || lineWidth + letterWidth > desiredWidth && letter != " ") {
            if (line.length > 0)
              graphemeLines.push(line);
            line = [];
            lineWidth = 0;
          }

          line.push(letter);
          lineWidth += letterWidth;
          lineJustStarted = false;
        }
        // @ts-expect-error
        word = [];
      } else {
        lineWidth += wordWidth - additionalSpace;
      }

      if (lineWidth > desiredWidth && !lineJustStarted) {
        if (line.length > 0) {
          graphemeLines.push(line);
        }
        line = [];
        lineWidth = wordWidth + wordWidthOffset;
        lineJustStarted = true;
      }
      else {
        // add trailing whitespaces if not final word
        lineWidth += additionalSpace + wordWidthOffset;
      }

      if (word.length > 0) {
        line = line.concat(word);
        lineJustStarted = false;
      }
      // keep track of largest word
      if (wordWidth > largestWordWidth && !this.breakWords) {
        largestWordWidth = wordWidth;
      }
    }
  }

  if (!lineJustStarted)
    graphemeLines.push(line);

  if (this.breakWords) {
    // set dynamic min width to largest char width
    this.dynamicMinWidth = largestCharWidth;
  } else if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
    this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
  }
  return graphemeLines;
};
