import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { StyleMenuComponent } from './style-menu.component';
import { StyleEditOptionComponent } from './style-edit-option.component';
import { StyleEditHeaderComponent } from './style-edit-header.component';

@NgModule({
  imports: [MatSidenavModule, CommonModule, SharedModule],
  declarations: [StyleMenuComponent, StyleEditOptionComponent, StyleEditHeaderComponent],
  exports: [StyleMenuComponent, StyleEditOptionComponent, StyleEditHeaderComponent],
  providers: []
})
export class StyleMenuModule {}
