import { SaveActions, SaveActionTypes } from './actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash-es';
import { AppState } from '../reducers';
import { ClientError } from '../models/client-error';
import { DesignSet } from '../models/design-set';

export interface SaveState {
  error: ClientError[];
  savePending: boolean; // true when save property of the action is either true or false --> save in collection or session
  savePendingSave: boolean; // true only when save property of the action is true --> save in collection
  saveDialogOpen: boolean;
  copyDialogOpen: boolean;
  loginSaveDialogOpen: boolean;
  loginOrRegisterPending: boolean;
  lastSavedDesign: DesignSet;
  lastNotSavedDesign: DesignSet;
  hasChangedSinceInit: boolean;
}

export interface State extends AppState {
  save: SaveState;
}

export const initialState: SaveState = {
  error: [],
  savePending: false,
  savePendingSave: false,
  saveDialogOpen: false,
  copyDialogOpen: false,
  loginSaveDialogOpen: false,
  loginOrRegisterPending: false,
  lastSavedDesign: undefined,
  lastNotSavedDesign: undefined,
  hasChangedSinceInit: false
};

export function reducer(state = initialState, action: SaveActions): SaveState {
  switch (action.type) {
    case SaveActionTypes.Login: {
      return {
        ...state,
        loginOrRegisterPending: true
      };
    }

    case SaveActionTypes.LoginSuccess: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case SaveActionTypes.CancelLogin: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case SaveActionTypes.Register: {
      return {
        ...state,
        loginOrRegisterPending: true
      };
    }

    case SaveActionTypes.RegisterSuccess: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case SaveActionTypes.CancelRegister: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case SaveActionTypes.OpenSaveDialog: {
      return {
        ...state,
        saveDialogOpen: true
      };
    }

    case SaveActionTypes.CloseSaveDialog: {
      return {
        ...state,
        saveDialogOpen: false
      };
    }

    case SaveActionTypes.OpenCopyDialog: {
      return {
        ...state,
        copyDialogOpen: true
      };
    }

    case SaveActionTypes.CloseCopyDialog: {
      return {
        ...state,
        copyDialogOpen: false
      };
    }

    case SaveActionTypes.Copy: {
      return {
        ...state,
        error: [],
        savePending: true
      };
    }

    case SaveActionTypes.SubmitSave: {
      return {
        ...state,
        error: [],
        savePending: true,
        savePendingSave: action.save
      };
    }

    case SaveActionTypes.SubmitFailure: {
      return {
        ...state,
        error: action.payload,
        savePending: false,
        savePendingSave: false
      };
    }

    case SaveActionTypes.SubmitSuccess: {
      return {
        ...state,
        error: [],
        savePending: false,
        savePendingSave: false,
        saveDialogOpen: false,
        lastSavedDesign: action.save ? cloneDeep(action.designSet) : undefined, // only when save is true
        lastNotSavedDesign: cloneDeep(action.designSet), // always, when save is either true or false
        hasChangedSinceInit: true
      };
    }

    case SaveActionTypes.InitSuccess: {
      // to make sure save button is not shown when design is already saved
      const design = action.designSet.userCollectionId ? cloneDeep(action.designSet) : undefined;
      return {
        ...state,
        error: [],
        savePending: false,
        savePendingSave: false,
        saveDialogOpen: false,
        lastNotSavedDesign: design
      };
    }

    case SaveActionTypes.ResetLastSavedDesign: {
      return {
        ...state,
        lastSavedDesign: undefined,
        lastNotSavedDesign: undefined,
        hasChangedSinceInit: false
      };
    }

    case SaveActionTypes.ResetValidationErrors: {
      return {
        ...state,
        error: []
      };
    }

    default: {
      return state;
    }
  }
}

export const selectSaveState = createFeatureSelector<SaveState>('save');

export const getError = createSelector(selectSaveState, (state: SaveState) => state.error);

export const getSavePending = createSelector(selectSaveState, (state: SaveState) => state.savePending);

export const getSavePendingSave = createSelector(selectSaveState, (state: SaveState) => state.savePendingSave);

export const getLoginOrRegisterPending = createSelector(
  selectSaveState,
  (state: SaveState) => state.loginOrRegisterPending
);

export const getSaveDialogOpen = createSelector(selectSaveState, (state: SaveState) => state.saveDialogOpen);

export const getCopyDialogOpen = createSelector(selectSaveState, (state: SaveState) => state.copyDialogOpen);

export const getLastSavedDesign = createSelector(selectSaveState, (state: SaveState) => state.lastSavedDesign);

export const getLastChangesAreSaved = createSelector(
  selectSaveState,
  (state: SaveState) => !!state.lastSavedDesign && isEqual(state.lastSavedDesign, state.lastNotSavedDesign)
);

export const getDesignHasChangedSinceInit = createSelector(
  selectSaveState,
  (state: SaveState) => state.hasChangedSinceInit
);
