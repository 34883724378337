export const ROUNDED_CORNER_RADIUS = 25;

export enum TrimTypes {
  borderRound = 'borderRound',
  borderStraight = 'borderStraight'
}

export class Trim {
  type: TrimTypes = TrimTypes.borderStraight;
  name = '';
  shortName = '';
  image?: string;
}
