import { ImgBaseFunc } from './canvas';

export interface CatImgJson {
  name: string;
  sid?: string;
  url?: string;
  isFoilable?: boolean;
  width?: number;
  height?: number;
}

export class CategoryImage {
  constructor(
    public name = '',
    public sid?: string,
    public url?: string,
    public isFoilable?: boolean,
    public width?: number,
    public height?: number
  ) {}

  get imageSource() {
    return formatImageSource(this.sid, this.url);
  }
}

export function formatImageSource(sid: string, url?: string) {
  let imgParams = null;
  if (sid) {
    imgParams = '?sid=' + sid;
  } else if (url) {
    imgParams = '?url=' + url;
  }
  if (imgParams) {
    return ImgBaseFunc.img + imgParams + '&maxsize=200';
  }
}
