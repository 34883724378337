import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { Font } from '../../models';
import { ConfigService } from '../../services';

@Component({
  selector: 'ed-font-select',
  templateUrl: 'font-select.component.html',
  styleUrls: ['font-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * fontselect shows on desktop the selected font; when clicked fontlist opens
 */
export class FontSelectComponent {
  selectContent = false;
  dataLayerIdPrefix = 't-text-font-select-';

  @Input() selectedItemName: string;
  @Input() selectedItemScreenTitle: string;
  @Input() useSampleImages = true;
  @Input() fontItemHeight = 33;
  @Output() selectContentClosed = new EventEmitter<boolean>();

  @HostBinding('style.height.px') selectorHeight = this.fontItemHeight + 2;
  @HostBinding('style.width.px') selectorWidth = 240;

  constructor(private elementRef: ElementRef, private config: ConfigService) {}

  showSelectContent(event: Event) {
    event.stopPropagation();
    this.selectContent = true;
  }

  closeSelectContent(event: Event) {
    event.stopPropagation();
    this.selectContent = false;
    this.selectContentClosed.emit();
  }

  @HostListener('window:click')
  hideSelectContent() {
    if (this.selectContent) {
      this.selectContentClosed.emit();
    }

    this.selectContent = false;
  }

  get imageSrc() {
    return this.config.imgBase + new Font(this.selectedItemName).imageSampleSource;
  }

  @HostListener('window:keydown.arrowUp', ['$event'])
  @HostListener('window:keydown.arrowDown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.selectContent) {
      event.preventDefault();
    }
  }
}
