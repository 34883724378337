import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CanvasCoordinate, CanvasElement, Design } from '../models';
import { AppState } from '../reducers';
import { doPolygonsIntersect, pointInPolygon } from '../utils/intersect.utils';
import { selectDesign } from '../selectors';

@Injectable()
export class ElementsIntersectService {
  design: Design;

  constructor(private store: Store<AppState>) {
    store.pipe(select(selectDesign)).subscribe(design => (this.design = design));
  }

  intersectsWithParent(route: Array<number>): boolean {
    const childElement = this.design.getElement(route);
    if (!childElement) {
      return true;
    }
    const parentElement = childElement.parent as CanvasElement;

    return this.doElementsIntersect(childElement, parentElement);
  }

  doElementsIntersect(childElement: CanvasElement, parentElement: CanvasElement) {
    return doPolygonsIntersect(childElement.polygon, parentElement.polygon);
  }

  // check if element is inside safetyPolygon of visiblePage
  isInsideSafetyPolygon(route: Array<number>): boolean {
    const element = this.design.getElement(route);
    const safetyPolygon = this.design.visiblePage.safetyPolygon;
    return this.allPointsInsidePolygon(element.polygon, safetyPolygon);
  }

  // check if all points of one polygon are inside other polygon
  allPointsInsidePolygon(innerPolygon: Array<CanvasCoordinate>, outerPolygon: Array<CanvasCoordinate>) {
    const allPointsInsideOuterPolygon = innerPolygon.map(point => pointInPolygon(point, outerPolygon));
    return !allPointsInsideOuterPolygon.includes(false);
  }
}
