import { Directive, HostListener } from '@angular/core';
import { environment } from '../../environments/environment';

@Directive({ selector: '[disableContextMenu]' })
export class DisableContextMenuDirective {
  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: Event) {
    if (environment.production) {
      event.preventDefault();
    }
  }
}
