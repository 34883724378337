import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { SharedModule } from '../shared/shared.module';
import { AuthDialogComponent } from './auth-dialog.component';
import { AuthEffects } from './auth.effects';
import { AuthService } from './auth.service';
import { LoginFormComponent } from './login-form.component';
import { RegisterModule } from '../register/register.module';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('auth', reducer),
    EffectsModule.forFeature([AuthEffects]),
    RegisterModule
  ],
  declarations: [AuthDialogComponent, LoginFormComponent],
  providers: [AuthService]
})
export class AuthModule {}
