import { fabric } from 'fabric';
import { isSmallObject } from '../../utils/object.utils';
import { X_ROUTE } from '../constants/object-keys';

const size = 40;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 50 50" fill="none">
    <rect x="4.5" y="2.5" width="41" height="41" rx="20" fill="white" fill-opacity="0.7" shape-rendering="geometricPrecision" stroke="#0008" stroke-width="1" stroke-opacity="0.7" />
    <path
      d="M25.8594 13.3906L28.3594 15.8906C28.8672 16.3594 28.8672 17.1797 28.3594 17.6484C27.8906 18.1562 27.0703 18.1562 26.6016 17.6484L26.25 17.2969V21.7891H30.7031L30.3516 21.3984C29.8438 20.9297 29.8438 20.1094 30.3516 19.6406C30.8203 19.1328 31.6406 19.1328 32.1094 19.6406L34.6094 22.1406C35.1172 22.6094 35.1172 23.4297 34.6094 23.8984L32.1094 26.3984C31.6406 26.9062 30.8203 26.9062 30.3516 26.3984C29.8438 25.9297 29.8438 25.1094 30.3516 24.6406L30.7031 24.25H26.2109V28.7422L26.6016 28.3906C27.0703 27.8828 27.8906 27.8828 28.3594 28.3906C28.8672 28.8594 28.8672 29.6797 28.3594 30.1484L25.8594 32.6484C25.3906 33.1562 24.5703 33.1562 24.1016 32.6484L21.6016 30.1484C21.0938 29.6797 21.0938 28.8594 21.6016 28.3906C22.0703 27.8828 22.8906 27.8828 23.3594 28.3906L23.75 28.7422V24.25H19.2578L19.6094 24.6406C20.1172 25.1094 20.1172 25.9297 19.6094 26.3984C19.1406 26.9062 18.3203 26.9062 17.8516 26.3984L15.3516 23.8984C14.8438 23.4297 14.8438 22.6094 15.3516 22.1406L17.8516 19.6406C18.3203 19.1328 19.1406 19.1328 19.6094 19.6406C20.1172 20.1094 20.1172 20.9297 19.6094 21.3984L19.2578 21.75H23.75V17.2969L23.3594 17.6484C22.8906 18.1562 22.0703 18.1562 21.6016 17.6484C21.0938 17.1797 21.0938 16.3594 21.6016 15.8906L24.1016 13.3906C24.5703 12.8828 25.3906 12.8828 25.8594 13.3906Z"
      fill="#7A7A7A" />
  </svg>
`);

const moveInner = new fabric.Control({
  actionName: 'moveInner',
  x: 0,
  y: 0,
  offsetX: 0,
  offsetY: 0,
  sizeX: 34,
  sizeY: 34,
  cursorStyle: 'pointer',
  visible: false,
  getVisibility,
  render,
  actionHandler,
  mouseDownHandler,
  mouseUpHandler,
});

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isSmallObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function actionHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  for (const object of getRelatedObjects(transform.target)) {
    const originalPointer = getOriginalPointerPosition(transform.target);
    const options = getTransformOptions(e, transform, x, y, object);

    object.left = options.transform.original.left + (x - originalPointer.x);
    object.top = options.transform.original.top + (y - originalPointer.y);

    object.canvas.fire('object:moving', options);
    object.fire('moving', options);
  }

  return true;
}

function mouseDownHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  setOriginalPointerPosition(transform.target, new fabric.Point(x, y));
  return true;
}

function mouseUpHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  for (const object of getRelatedObjects(transform.target)) {
    const options = getTransformOptions(e, transform, x, y, object);
    options.action = "drag";

    object.canvas.fire('object:modified', options);
    object.fire('modified', options);

    object['_original'] = undefined;
  }

  setOriginalPointerPosition(transform.target, undefined);
  return true;
}

function getTransformOptions(e: MouseEvent, transform: fabric.Transform, x: number, y: number, object: fabric.Object) {
  const original = (object['_original'] ??= { ...object, });

  return {
    e,
    transform: {
      ...transform,
      target: object,
      original,
    },
    target: object,
    pointer: new fabric.Point(x, y),
    // @ts-ignore
    action: undefined,
  };
}

function getOriginalPointerPosition(fabricObject: fabric.Object) {
  return fabricObject.controls.moveInner['_originalPointerPosition'];
}

function setOriginalPointerPosition(fabricObject: fabric.Object, point: fabric.Point) {
  fabricObject.controls.moveInner['_originalPointerPosition'] = point;
}

function getRelatedObjects(fabricObject: fabric.Object) {
  return fabricObject.canvas?.getObjects().filter((object) => isObjectRelated(object)) ?? [];

  function isObjectRelated(object: fabric.Object): unknown {
    return object !== fabricObject && object[X_ROUTE]?.slice(1).toString() == fabricObject[X_ROUTE];
  }
}

fabric.Object.prototype.controls.moveInner = moveInner;
