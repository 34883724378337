import { Action } from '@ngrx/store';
import { TOGGLE_ELEMENT_PRESET } from './canvas.action.types';
import { ElementPermission } from '../../models';

export class ToggleElementPreset implements Action {
  readonly type = TOGGLE_ELEMENT_PRESET;
  undoable = false;
  readonly save = true;

  constructor(public route: Array<number>, public preset: ElementPermission) {}
}
