import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as Recover from '../recover/actions';
import * as Continue from '../continue/actions';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map, switchMap, catchError, filter, withLatestFrom } from 'rxjs/operators';

import { Login, LoginSuccess, LoginFailure, AuthActionTypes, AuthInitSuccess } from './actions';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Authenticate } from '../models/user';
import { AuthDialogComponent } from './auth-dialog.component';
import { AuthService } from './auth.service';
import { ConfigActionsTypes } from '../config/actions';
import { PermissionActions, PermissionActionTypes } from '../actions';
import { AppState } from '../reducers';
import { GetTextService } from '../services';
import { FunctionPermissions, SetElementPermissions } from '../models';
import { ChatService } from '../services/chat.service';
import { getUseChat } from '../reducers/permissions.reducer';
import { getChatConfig, getRegistrationFormFields } from '../config/reducer';
import { getUser } from './reducer';
import { sha256 } from 'js-sha256';

@Injectable()
export class AuthEffects {
  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    map((action: Login) => action.payload),
    switchMap((auth: Authenticate) =>
      this.authService.login(auth).pipe(
        map(user => new LoginSuccess(user)),
        catchError(error => of(new LoginFailure(error.error)))
      )
    )
  );

  @Effect({ dispatch: true })
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    switchMap(() =>
      this.authService.logout().pipe(
        map(user => {
          const functionPermissions = user.functionPermissions || new FunctionPermissions();
          const setElementPermissions = user.setElementPermissions || new SetElementPermissions();
          return new PermissionActions.SetUser(functionPermissions, setElementPermissions);
        }),
        catchError(error => of(new PermissionActions.SetUser(new FunctionPermissions(), new SetElementPermissions())))
      )
    )
  );

  @Effect()
  authInit$ = this.actions$.pipe(
    ofType(ConfigActionsTypes.LoadConfig),
    switchMap(() =>
      this.authService.init().pipe(
        map(user => new AuthInitSuccess(user.isAuthenticated, user)),
        catchError(error => of(new LoginFailure(error.error)))
      )
    )
  );

  @Effect({ dispatch: false })
  openLoginModal$ = this.actions$.pipe(
    ofType(AuthActionTypes.OpenLoginDialog),
    withLatestFrom(this.store$.pipe(select(getRegistrationFormFields))),
    map(
      ([action, registerFormFields]) =>
        (this.loginDialog = this.dialog.open(AuthDialogComponent, {
          id: 'auth-dialog',
          data: {
            loginForSave: false,
            formFields: registerFormFields,
            text: {
              dialogText: this.getTextService.text.dialog.authenticate,
              loginText: this.getTextService.text.dialog.loginForm,
              registerText: this.getTextService.text.dialog.registerForm,
              customFormText: this.getTextService.text.dialog.customFormFields,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  @Effect()
  openLoginModalFromRecoverModal$ = this.actions$.pipe(
    ofType(AuthActionTypes.OpenLoginDialogFromRecoverDialog),
    withLatestFrom(this.store$.pipe(select(getRegistrationFormFields))),
    map(
      ([action, registerFormFields]) =>
        (this.loginDialog = this.dialog.open(AuthDialogComponent, {
          id: 'auth-dialog-from-recover-dialog',
          data: {
            loginForSave: false,
            formFields: registerFormFields,
            text: {
              dialogText: this.getTextService.text.dialog.authenticate,
              loginText: this.getTextService.text.dialog.loginForm,
              registerText: this.getTextService.text.dialog.registerForm,
              customFormText: this.getTextService.text.dialog.customFormFields,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    ),
    map(() => new Recover.CloseRecoverDialog())
  );

  @Effect()
  openLoginModalAfterRecover$ = this.actions$.pipe(
    ofType(AuthActionTypes.OpenLoginDialogAfterRecover),
    withLatestFrom(this.store$.pipe(select(getRegistrationFormFields))),
    map(
      ([action, registerFormFields]) =>
        (this.loginDialog = this.dialog.open(AuthDialogComponent, {
          id: 'auth-dialog-after-recover',
          data: {
            loginForSave: false,
            formFields: registerFormFields,
            text: {
              dialogText: this.getTextService.text.dialog.authenticate,
              loginText: this.getTextService.text.dialog.loginForm,
              registerText: this.getTextService.text.dialog.registerForm,
              customFormText: this.getTextService.text.dialog.customFormFields,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    ),
    map(() => new Recover.CloseRecoverSuccessDialog())
  );

  @Effect({ dispatch: false })
  openLoginModalFromSaveModal$ = this.actions$.pipe(
    ofType(AuthActionTypes.OpenLoginDialogFromSave),
    withLatestFrom(this.store$.pipe(select(getRegistrationFormFields))),
    map(
      ([action, registerFormFields]) =>
        (this.loginDialog = this.dialog.open(AuthDialogComponent, {
          id: 'auth-dialog-from-save-dialog',
          data: {
            loginForSave: true,
            formFields: registerFormFields,
            text: {
              dialogText: this.getTextService.text.dialog.authenticate,
              loginText: this.getTextService.text.dialog.loginForm,
              registerText: this.getTextService.text.dialog.registerForm,
              customFormText: this.getTextService.text.dialog.customFormFields,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    )
  );

  @Effect()
  openLoginModalFromContinueModal$ = this.actions$.pipe(
    ofType(AuthActionTypes.OpenLoginDialogFromContinue),
    withLatestFrom(this.store$.pipe(select(getRegistrationFormFields))),
    map(
      ([action, registerFormFields]) =>
        (this.loginDialog = this.dialog.open(AuthDialogComponent, {
          id: 'auth-dialog-from-continue-dialog',
          data: {
            loginForSave: true,
            formFields: registerFormFields,
            text: {
              dialogText: this.getTextService.text.dialog.authenticate,
              loginText: this.getTextService.text.dialog.loginForm,
              registerText: this.getTextService.text.dialog.registerForm,
              customFormText: this.getTextService.text.dialog.customFormFields,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    ),
    map(() => new Continue.CloseLoginContinueDialog())
  );

  @Effect({ dispatch: false })
  closeLoginModal$ = this.actions$.pipe(
    ofType(AuthActionTypes.CloseLoginDialog, AuthActionTypes.LoginSuccess),
    filter(() => !!this.loginDialog),
    tap(() => this.loginDialog.close())
  );

  @Effect()
  setPermissions$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    map(
      (action: LoginSuccess) =>
        new PermissionActions.SetUser(action.user.functionPermissions, action.user.setElementPermissions)
    )
  );

  @Effect({ dispatch: false })
  pushLoginEventToDataLayer$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    tap((action: LoginSuccess) => {
      const dataLayer = (window as any).dataLayer || [];

      dataLayer.push({
        event: 'loginEvent',
        email_hash: sha256(action.user.email),
        method: 'editor'
      });
    })
  );

  @Effect()
  authInitSuccesPermissions$ = this.actions$.pipe(
    ofType(AuthActionTypes.AuthInitSuccess),
    map(
      (action: AuthInitSuccess) =>
        new PermissionActions.InitUser(action.user.functionPermissions, action.user.setElementPermissions)
    )
  );

  @Effect({ dispatch: false })
  activateChat$ = this.actions$.pipe(
    ofType(PermissionActionTypes.SET_DESIGN),
    withLatestFrom(this.store$.select(getUseChat)),
    withLatestFrom(this.store$.select(getChatConfig)),
    withLatestFrom(this.store$.select(getUser)),
    map(([[[action, _useChat], chatConfig], user]) => {
      if (_useChat && chatConfig && chatConfig.key) {
        this.chatService.initChat(user, chatConfig);
      }
    })
  );

  loginDialog: MatDialogRef<AuthDialogComponent>;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<AppState>,
    private getTextService: GetTextService,
    private chatService: ChatService
  ) {}
}
