import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export function doNotSerializeParent(key: string, value: string): string {
  if (key === 'parent' || key === '_backSpread') {
    return ' prevent circular parent references in devtools';
  }
  return value;
}

export const storeDevToolsModule = StoreDevtoolsModule.instrument({
  maxAge: 100,
  logOnly: false,
  name: 'e-force editor',
  serialize: { replacer: doNotSerializeParent }
});
