import { EditorAction } from '../action';
import { HIGHLIGHT_TEXTAREA } from './canvas.action.types';

export class HighlightTextarea implements EditorAction {
  readonly type = HIGHLIGHT_TEXTAREA;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>, public highlightTextarea: boolean) {}
}
