import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromPermissions from '../../reducers/permissions.reducer';
import { AppState } from '../../reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import * as Save from '../../save/actions';
import { DesignSet } from '../../models/design-set';
import { ConfigService, GetTextService } from '../../services';
import { TooltipPosition } from '@angular/material/tooltip';
import { map, tap } from 'rxjs/operators';
import * as fromSave from '../../save/reducer';
import { isLoggedIn } from 'src/app/auth/reducer';
import { S } from '@angular/cdk/keycodes';

@Component({
  selector: 'ed-save',
  templateUrl: 'save.component.html',
  styleUrls: ['save.component.scss', '../toolbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveComponent implements OnInit, OnDestroy {
  public init = false;
  public canSave: boolean;
  public canSaveSubscription$: Subscription;
  public canCopyDesign: boolean;
  public designTitle: string;
  public existingUserDesign: boolean;
  private isLoggedIn = false;
  public tooltipShowDelay$: Observable<number>;
  public tooltipAutoSaveDisabled$: Observable<boolean>;
  public tooltipPosition: TooltipPosition = 'below';
  public shortcutSave$: Observable<string>;
  public isMac$: Observable<boolean>;
  public showSavePending$: Observable<boolean>;
  public showSaveButton$: Observable<boolean>;
  public showSaveSuccess$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    public getTextService: GetTextService,
    public configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.changeDetectorRef.detach();
  }

  @Input() set designSet(designSet: DesignSet) {
    if (designSet.activeDesign) {
      const design = designSet.activeDesign;
      if (!this.init && designSet.init && design.visiblePage) {
        // enable change detection for this component only after initialization of design,
        // because initialisation of design is fast after loading and this causes ExpressionChangedAfterItHasBeenCheckedError
        this.init = true;
        this.changeDetectorRef.reattach();
      }

      this.designTitle = designSet.userCollectionTitle || designSet.title || this.getTextService.text.untitledText;

      this.existingUserDesign = !!designSet.userCollectionId;
      this.canCopyDesign = this.existingUserDesign && this.isLoggedIn;
    }
  }

  ngOnInit() {
    const isLoggedIn$ = this.store.pipe(
      select(isLoggedIn),
      tap(loggedIn => {
        this.isLoggedIn = loggedIn;
        this.canCopyDesign = this.existingUserDesign && loggedIn;
      })
    );

    const saveState$ = combineLatest([
      isLoggedIn$,
      this.store.pipe(select(fromSave.getSavePendingSave)),
      this.store.pipe(select(fromSave.getLastChangesAreSaved))
    ]);

    this.showSavePending$ = saveState$.pipe(map(([loggedIn, savePending, isSaved]) => loggedIn && savePending));

    this.showSaveButton$ = saveState$.pipe(
      map(([loggedIn, savePending, isSaved]) => !loggedIn || (!savePending && !isSaved))
    );

    this.showSaveSuccess$ = saveState$.pipe(
      map(([loggedIn, savePending, isSaved]) => loggedIn && !savePending && isSaved)
    );

    const config$ = this.store.pipe(select(s => s.config));
    this.isMac$ = config$.pipe(map(config => config.isMac));
    this.shortcutSave$ = this.isMac$.pipe(map(isMac => this.setTooltipShortcuts(isMac)));

    this.canSaveSubscription$ = this.store.pipe(select(fromPermissions.getCanSave)).subscribe(permission => {
      this.canSave = permission;
    });
  }

  setTooltipShortcuts(isMac: boolean) {
    return `${this.getTextService.text.toolBar.save.tooltip} (${isMac ? 'Cmd' : 'Ctrl'} + S)`;
  }

  openSaveModal() {
    this.store.dispatch(new Save.OpenSaveDialog());
  }

  save() {
    if (this.canSave) {
      this.store.dispatch(new Save.Save());
    }
  }

  openCopyModal() {
    this.store.dispatch(new Save.OpenCopyDialog());
  }

  @HostListener('window:keydown', ['$event'])
  _onKeydown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === S) {
      this.save();
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.canSaveSubscription$.unsubscribe();
  }
}
