import { fabric } from "fabric";
import { GradientData, GradientStop } from "src/app/utils/gradient.utils";

export function createGradient(width: number, height: number, gradient: GradientData) {
  let colorStops = mapGradientStopsToColorStops(gradient.stops);
  colorStops = mapColorStopsToReflectColorStops(colorStops);
  colorStops = multiplyColorStops(colorStops, 3);

  let angle = 25;
  let coords = getCoordsForAngle(width, height, angle);

  return new fabric.Gradient({
    type: 'linear',
    offsetX: width * .75,
    coords,
    colorStops,
  });
}

function mapGradientStopsToColorStops(gradientStops: GradientStop[]) {
  return gradientStops.map(({ offset, opacity, color }) => ({
    color: color + mapOpacityToHexString(opacity),
    offset: Number.parseInt(offset.substring(0, offset.length - 1), 10) / 100,
  }));
}

function mapColorStopsToReflectColorStops(colorStops: fabric.IGradientOptionsColorStops) {
  return colorStops.reduce((colorStops: fabric.IGradientOptionsColorStops, colorStop) => [
    ...colorStops,
    { ...colorStop, offset: colorStop.offset / 2 },
    { ...colorStop, offset: 1 - colorStop.offset / 2 },
  ], []);
}

function multiplyColorStops(colorStops: fabric.IGradientOptionsColorStops, value: number) {
  for (let i = 1; i < value; i++) {
    colorStops = colorStops.reduce((values, value) => [
      ...values,
      { ...value, offset: (value.offset / 2) },
      { ...value, offset: (value.offset / 2) + (1 / 2) },
    ], []);
  }
  return colorStops;
}

function mapOpacityToHexString(opacity: string | number) {
  let numberValue = typeof opacity === "string" ? Number.parseFloat(opacity) : opacity;
  let stringValue = Math.floor(numberValue * 255).toString(16);

  return (stringValue.length === 1 && "0" + stringValue) || stringValue;
}

function getCoordsForAngle(width: number, height: number, angle: number) {
  let { x, y } = getPointForAngle(width, height, angle);

  return {
    x1: x - width * .5,
    y1: y - height * .5,
    x2: width - x - width * .5,
    y2: height - y - height * .5,
  };
}

function getPointForAngle(width: number, height: number, angle: number) {
  let a = height, b = a + width, c = b + height,          // First 3 corners
    p = (width + height) * 2,                             // Perimeter
    rp = p * 0.00277,                                     // Ratio between perimeter and 360
    pp = Math.round(((angle * rp) + (height * .5)) % p);  // Angle position on perimeter

  if (pp <= a) return { x: 0, y: height - pp };
  if (pp <= b) return { x: pp - a, y: 0 };
  if (pp <= c) return { x: width, y: pp - b };
  return { y: height, x: width - (pp - c) };
}
