import { EditorAction } from '../action';
import { TOGGLE_CROPPING_MODE } from './canvas.action.types';

export class ToggleCroppingMode implements EditorAction {
  readonly type = TOGGLE_CROPPING_MODE;
  readonly undoable = true;
  readonly save = false;

  constructor(public route: Array<number>) {}
}
