import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { SharedModule } from '../shared/shared.module';
import { RegisterSuccessDialogComponent } from './reg-success-dialog.component';
import { RegisterEffects } from './register.effects';
import { RegisterService } from './register.service';
import { RegisterFormComponent } from './register-form.component';

@NgModule({
  imports: [SharedModule, StoreModule.forFeature('register', reducer), EffectsModule.forFeature([RegisterEffects])],
  declarations: [RegisterSuccessDialogComponent, RegisterFormComponent],
  providers: [RegisterService],
  exports: [RegisterFormComponent]
})
export class RegisterModule {}
