import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  BoxElement,
  Color,
  Design,
  SpecialColorElement,
  InlineTextElement,
  KcElementType,
  KcImageElement,
  KcRichTextElement,
  KcTextElement,
  PhotoFrameElement
} from '../models';
import { CanvasActions } from '../actions';
import { TextEditorService } from '../text-editor/text-editor.service';
import { getAllowCrossSessionElementCopying, getFunctionPermissions } from '../reducers/permissions.reducer';
import { BrowserStorageService } from './browser-storage.service';
import { OutputService } from './output-service/output.service';
import { cloneDeep } from 'lodash-es';
import { selectDesign } from '../selectors';
import {
  addElementPermissions,
  createCanvasElement,
  createParentElementFromClipPath,
  setImageDimensions
} from '../utils/transform.utils';
import { combineLatestWith, filter } from 'rxjs/operators';

export const storageClipboardElement = 'clipboardElement';
export const storagePageDetails = 'pageDetails';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
  design: Design;
  allowCrossSessionCopying: boolean;
  addImageAsPhotoFrame: boolean;
  enableFoilableByDefault: boolean;
  spotUvPermissionForJpgByDefault: boolean;
  useAbsoluteFontSizeConfig: boolean;
  canAddSpotUv: boolean;
  foilColors: Color[];
  originalElementPageId: Array<number> = [];

  constructor(
    private store: Store<AppState>,
    private textEditorService: TextEditorService,
    private storageService: BrowserStorageService,
    private outputService: OutputService
  ) {
    this.store
      .pipe(select(getAllowCrossSessionElementCopying))
      .subscribe(allow => (this.allowCrossSessionCopying = allow));
    this.store
      .pipe(
        select(getFunctionPermissions),
        combineLatestWith(this.store.pipe(select(selectDesign))),
        filter(([perm, design]) => !!design)
      )
      .subscribe(([perm, design]) => {
        this.design = design;
        this.addImageAsPhotoFrame = perm.addImageAsPhotoFrame;
        this.enableFoilableByDefault = perm.enableFoilableByDefault && design.visiblePage.permissions.isFoilable;
        this.spotUvPermissionForJpgByDefault =
          perm.spotUvPermissionForJpgByDefault && design.visiblePage.permissions.isSpotUvable;
        this.canAddSpotUv = perm.canAddSpotUv;
      });
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.foilColors = config.foilColorData;
      this.useAbsoluteFontSizeConfig = config.useAbsoluteFontSize;
    });
  }

  get selectedElement() {
    return this.design.selectedElement;
  }

  get allowDuplicating(): boolean {
    return (
      this.selectedElement &&
      this.selectedElement.permissions.isDuplicatable &&
      !this.selectedElement.inCroppingMode &&
      !this.design.editingInlineText
    );
  }

  get allowPasting(): boolean {
    return (
      this.design.visiblePage.permissions.addChildElements && (!this.selectedElement || !this.design.editingInlineText)
    );
  }

  get allowCutting(): boolean {
    return (
      this.selectedElement &&
      this.selectedElement.permissions.isRemovable &&
      this.selectedElement.permissions.isMovable &&
      !this.design.editingInlineText
    );
  }

  getClipboardElement(): string {
    if (this.allowCrossSessionCopying) {
      return this.storageService.getLocal(storageClipboardElement);
    } else {
      return this.storageService.getSession(storageClipboardElement);
    }
  }

  copy(cutElement = false) {
    if (!this.allowDuplicating) {
      return;
    }

    const clonedElement = cloneDeep(this.selectedElement);
    const asOutputElementArray = this.outputService.addChildren([clonedElement], []);
    const elementsJSON = JSON.stringify(asOutputElementArray);

    const page = this.design.visiblePage;

    this.originalElementPageId = [];
    if (!cutElement) {
      this.originalElementPageId.push(page.id);
    }

    if (this.allowCrossSessionCopying) {
      this.storageService.setLocal(storageClipboardElement, elementsJSON);
      this.storageService.setLocal(storagePageDetails, JSON.stringify({ width: page.width, height: page.height }));
    } else {
      this.storageService.setSession(storageClipboardElement, elementsJSON);
    }
  }

  paste() {
    if (!this.allowPasting) {
      return;
    }

    const elementsJSON = this.getClipboardElement() || '{}';
    const inputElements: Array<KcImageElement | KcTextElement | KcRichTextElement> = JSON.parse(elementsJSON);

    if (!inputElements.length) {
      return;
    }

    const page = cloneDeep(this.design.visiblePage);
    let parent: PhotoFrameElement | BoxElement;

    if (inputElements[0].clip_path) {
      parent = createParentElementFromClipPath(inputElements[0].clip_path, inputElements[0].clip_path_type);
      addElementPermissions(
        parent,
        inputElements[0],
        this.design.material,
        this.enableFoilableByDefault,
        this.spotUvPermissionForJpgByDefault,
        this.canAddSpotUv
      );
    } else if (this.addImageAsPhotoFrame && inputElements[0].type === KcElementType.photo) {
      parent = new PhotoFrameElement(-1, { x: inputElements[0].x, y: inputElements[0].y, r: inputElements[0].r });
      setImageDimensions(parent, inputElements[0]);
      inputElements[0].x = 0;
      inputElements[0].y = 0;
      addElementPermissions(
        parent,
        inputElements[0],
        this.design.material,
        this.enableFoilableByDefault,
        this.spotUvPermissionForJpgByDefault,
        this.canAddSpotUv
      );
    }

    const child = createCanvasElement(
      inputElements[0],
      parent || page,
      this.design.useAbsoluteFontSize,
      this.design.material,
      this.foilColors,
      this.addImageAsPhotoFrame,
      this.enableFoilableByDefault,
      this.spotUvPermissionForJpgByDefault,
      this.useAbsoluteFontSizeConfig,
      this.canAddSpotUv
    );
    child.foilType = this.enableFoilableByDefault ? child.foilType : undefined;
    child.spotUv = this.canAddSpotUv && child.spotUv;
    if (parent) {
      parent.addElement(child);
      inputElements.slice(1).forEach(el => {
        const nextChild = createCanvasElement(
          el,
          parent,
          this.design.useAbsoluteFontSize,
          this.design.material,
          this.foilColors,
          this.addImageAsPhotoFrame,
          this.enableFoilableByDefault,
          this.spotUvPermissionForJpgByDefault,
          this.useAbsoluteFontSizeConfig,
          this.canAddSpotUv
        );
        nextChild.foilType = this.enableFoilableByDefault ? nextChild.foilType : undefined;
        nextChild.spotUv = this.canAddSpotUv && nextChild.spotUv;
        parent.addElement(nextChild);
      });
    }

    let newElement = parent || child;
    const pageDetailsJSON = this.storageService.getLocal(storagePageDetails);

    if (pageDetailsJSON && this.allowCrossSessionCopying) {
      const pageDetails = JSON.parse(pageDetailsJSON);
      const widthFactor = newElement.x / pageDetails.width;
      const heightFactor = newElement.y / pageDetails.height;
      newElement.x = this.design.visiblePage.width * widthFactor;
      newElement.y = this.design.visiblePage.height * heightFactor;
    }

    let moveElement: boolean;

    if (this.originalElementPageId.indexOf(page.id) === -1) {
      moveElement = false;
      this.originalElementPageId.push(page.id);
    } else {
      moveElement = true;
    }

    // validations

    const material = this.design.material;
    newElement.permissions.hasCoating =
      newElement.permissions.hasCoating !== undefined && material && material.needsCoating;

    if (newElement.isSpecialColorElement() && newElement.foilType) {
      newElement = newElement as SpecialColorElement;

      if (newElement.permissions.isFoilable && material && !material.isFoilable) {
        newElement.permissions.isFoilable = false;
        newElement.foilType = undefined;
        newElement.firstChild.color = '';
      }

      const designFoilType = this.design.foilElements[0]?.foilType;
      if (!!designFoilType && !!newElement.foilType && designFoilType !== newElement.foilType) {
        newElement.foilType = designFoilType;
        const designFoilElement = this.design.foilElements[0];
        newElement.color = designFoilElement.isInlineText()
          ? (designFoilElement as InlineTextElement).colors[0]
          : designFoilElement.color;
      }
    }

    this.store.dispatch(new CanvasActions.Paste(newElement, moveElement));
  }

  cut() {
    if (!this.allowCutting) {
      return;
    }

    this.copy(true);
    this.store.dispatch(new CanvasActions.RemoveElement(this.selectedElement.route));
  }
}
