import { fabric } from "fabric";
import { BaseElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/base-element" {
  interface BaseElement {
    createObjectAsync(object?: fabric.Object, data?: any): Promise<fabric.Object>;
    getObjectOptions(object: fabric.Object): fabric.IObjectOptions;
    getObjectControlsVisibility(object: fabric.Object): ControlsVisibility;
  }
}

BaseElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement)) {
    return null;
  }

  if (!(object instanceof fabric.Object)) {
    return null;
  }

  return object;
}

BaseElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement) || !(object instanceof fabric.Object)) {
    return null;
  }

  const { left, top, angle } = getObjectPosition(element);

  return {
    left,
    top,
    angle,
    flipX: element.flipHorizontal,
    flipY: element.flipVertical,
    opacity: (!element.parent.isCutThrough && !element.parent.isCutThroughInverted) ? element.opacity : 1e-323,
    strokeWidth: 0,
    lockScalingFlip: true,
    dirty: true,
    // @ts-expect-error
    elementType: element.type,
  };
}

BaseElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement) || !(object instanceof fabric.Object)) {
    return null;
  }

  return {
    tl: false,
    tr: false,
    br: false,
    bl: false,
    ml: false,
    mr: false,
    mt: false,
    mb: false,
    mtr: false,
    ml_imageReplaceControl: false,
    remove: false,
    move: false,
    moveInner: false,
    zoomPanel: false,
    zoomInnerMinus: false,
    zoomInnerMinusDisabled: false,
    zoomInnerPlus: false
  };
}

function getObjectPosition(element: BaseElement): { left: number; top: number; angle: number; } {
  if (!(element instanceof BaseElement)) {
    return null;
  }

  const angle = (element.screenRotation + 360) % 360;
  const { x: left, y: top } = fabric.util.rotatePoint(
    new fabric.Point(
      element.designX,
      element.designY
    ),
    (element.isBoxChild || element.isPhotoFrameChild)
      ? new fabric.Point(
        element.parent.designX + (element.parent.width / 2),
        element.parent.designY + (element.parent.height / 2)
      )
      : new fabric.Point(
        element.designX + (element.width / 2),
        element.designY + (element.height / 2)
      ),
    fabric.util.degreesToRadians(angle)
  );

  return { left, top, angle };
}
