import { BaseElement } from './base-element';
import { CanvasElement, ElementType, LayerType } from './canvas';
import { round } from 'lodash-es';
import { FoilTypes } from './color';
import { ElementPermissions } from './permissions';
import { OUTPUT_DECIMALS } from '../utils/element.utils';

export class TextElement extends BaseElement {
  readonly type = ElementType.text;
  name = 'text';
  text: string;
  font: string;
  markup: string;
  fontSize: number;
  ha: string; // horizontal alignment
  va: string; // vertical alignment
  isJustify: boolean;
  highlightTextarea: boolean;
  enlargeTextarea: boolean;
  imgSource: string;
  parent: CanvasElement;
  // TextNotEdited is set to true when creating a new text-element
  // then in edit-text-component the textarea is cleared and focused
  textNotEdited: boolean;
  useAbsoluteFontSize: boolean;
  foilType: FoilTypes;
  spotUv: boolean;
  layerType: LayerType = LayerType.standardRgb;

  permissions: ElementPermissions = {
    isLocked: false,
    isMovable: true,
    isRemovable: true,
    isRotatable: true,
    isResizable: true,
    isDuplicatable: true,
    isFoilable: false,
    isFlippable: true,
    isPrintable: true,
    isTopLayer: false,
    isUntargetable: false,
    adjustTransparency: true,
    hasCoating: false,
    isSpotUvable: true,
    isOrderable: true,
    excludeFromPreviews: false,
    isVisibleOutsideCropArea: false,
    isExternallyEditable: false,
    fontChangeable: true,
    fontResizable: true,
    isRecolorable: true,
    textEditable: true
  };

  // because really small text images get malformed in backend, make image 10 times bigger
  // is svg so doesn't matter for file size
  textScale = 10;
  isSvg = true;

  _scaleWidth(scaleWidth: number, parentX: number) {
    // because a child text element's size changes with parent rezise, font size needs to change
    // as well to keep screenfontsize the same
    this.fontSize = this.fontSize * scaleWidth;
    this.screenX = this.screenX * scaleWidth;
    this.screenWidth = this.screenWidth * scaleWidth;
  }

  select(): void {
    this.selected = true;
    this.highlightTextarea = true;
  }

  deselectAll() {
    this.selected = false;
    this.highlightTextarea = false;
  }

  get imgParams() {
    return {
      text: this.text,
      w: round(this.width, OUTPUT_DECIMALS),
      h: 1, // height is calculated in backend, prevents multiple requests when height changes
      font: this.font,
      markup: this.markup,
      s: this.fontSize,
      trans: this.transparency,
      color: this.color,
      ha: this.ha,
      va: this.va,
      is_justify: this.isJustify,
      scale: this.textScale,
      use_absolute_font_size: this.useAbsoluteFontSize,
      foilType: this.foilType
    };
  }

  constructor(
    id: number,
    {
      width = 300,
      height = 44,
      x = 0,
      y = 0,
      text = '',
      font = 'Arial',
      markup = '',
      fontSize = 35,
      trans = 0,
      color = '#000000',
      ha = 'C',
      va = 'N',
      isJustify = false,
      useAbsoluteFontSize = false
    } = {}
  ) {
    super(id);
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.text = text;
    this.font = font;
    this.markup = markup;
    this.fontSize = fontSize;
    this.transparency = trans;
    this.color = color;
    this.ha = ha;
    this.va = va;
    this.isJustify = isJustify;
    this.highlightTextarea = false;
    this.enlargeTextarea = false;
    this.textNotEdited = false;
    this.tag = '';
    this.useAbsoluteFontSize = useAbsoluteFontSize;
  }
}
