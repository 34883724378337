import { ContinueActions, ContinueActionTypes } from './actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { ClientError } from '../models/client-error';

export interface ContinueState {
  error: ClientError[];
  loginSaveContinueDialogOpen: boolean;
  loginOrRegisterPending: boolean;
  savePending: boolean;
  saveContinuePending: boolean;
  redirectUrl: string;
  retainParams: boolean;
  navigationHandled: boolean;
}

export interface State extends AppState {
  continue: ContinueState;
}

export const initialState: ContinueState = {
  error: [],
  loginSaveContinueDialogOpen: false,
  loginOrRegisterPending: false,
  savePending: false,
  saveContinuePending: false,
  redirectUrl: '',
  retainParams: false,
  navigationHandled: false
};

export function reducer(state = initialState, action: ContinueActions): ContinueState {
  switch (action.type) {
    case ContinueActionTypes.Navigate: {
      return {
        ...state,
        redirectUrl: action.url,
        retainParams: action.retainParams
      };
    }

    case ContinueActionTypes.Continue: {
      return {
        ...state
      };
    }

    case ContinueActionTypes.OpenLoginContinueDialog: {
      return {
        ...state,
        loginSaveContinueDialogOpen: true
      };
    }

    case ContinueActionTypes.CloseLoginContinueDialog: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false
      };
    }

    case ContinueActionTypes.Login: {
      return {
        ...state,
        loginOrRegisterPending: true
      };
    }

    case ContinueActionTypes.LoginSuccess: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case ContinueActionTypes.CancelLogin: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case ContinueActionTypes.Register: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false,
        loginOrRegisterPending: true
      };
    }

    case ContinueActionTypes.RegisterSuccess: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case ContinueActionTypes.CancelRegister: {
      return {
        ...state,
        loginOrRegisterPending: false
      };
    }

    case ContinueActionTypes.OpenSaveContinueDialog: {
      return {
        ...state,
        loginSaveContinueDialogOpen: true
      };
    }

    case ContinueActionTypes.CloseSaveContinueDialog: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false
      };
    }

    case ContinueActionTypes.Save: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false
      };
    }

    case ContinueActionTypes.OpenSaveDialog: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false,
        savePending: true
      };
    }

    case ContinueActionTypes.SaveSuccess: {
      return {
        ...state,
        savePending: false
      };
    }

    case ContinueActionTypes.CancelSave: {
      return {
        ...state,
        savePending: false
      };
    }

    case ContinueActionTypes.SaveContinueSubmit: {
      return {
        ...state,
        error: null,
        saveContinuePending: true
      };
    }

    case ContinueActionTypes.SaveContinueFailure: {
      return {
        ...state,
        error: action.payload,
        saveContinuePending: false
      };
    }

    case ContinueActionTypes.SaveContinueSuccess: {
      return {
        ...state,
        error: null,
        saveContinuePending: false
      };
    }

    case ContinueActionTypes.ContinueWithoutSave: {
      return {
        ...state,
        loginSaveContinueDialogOpen: false
      };
    }

    case ContinueActionTypes.ContinueToUrl: {
      return {
        ...state,
        navigationHandled: true
      };
    }

    default: {
      return state;
    }
  }
}

export const selectContinueState = createFeatureSelector<ContinueState>('continue');

export const getError = createSelector(selectContinueState, (state: ContinueState) => state.error);

export const getSavePending = createSelector(selectContinueState, (state: ContinueState) => state.savePending);

export const getSaveContinuePending = createSelector(
  selectContinueState,
  (state: ContinueState) => state.saveContinuePending
);

export const getLoginOrRegisterPending = createSelector(
  selectContinueState,
  (state: ContinueState) => state.loginOrRegisterPending
);

export const getLoginSaveContinueDialogOpen = createSelector(
  selectContinueState,
  (state: ContinueState) => state.loginSaveContinueDialogOpen
);

export const getNavigationHandled = createSelector(
  selectContinueState,
  (state: ContinueState) => state.navigationHandled
);
