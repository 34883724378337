import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TextComponent } from './text/text.component';
import { TextMobComponent } from './text/text-mob.component';
import { CategoriesComponent } from './libraries/image-library/categories.component';
import { ImageLibraryComponent } from './libraries/image-library/image-library.component';
import { BackgroundPanelComponent } from './background-panel/background-panel.component';
import { BackgroundMobComponent } from './background-panel/background-mob.component';
import { BackgroundMobNewComponent } from './background-panel/background-mob-new.component';
import { EditBackgroundComponent } from './background-panel/edit-background/edit-background.component';
import { EditBackgroundMobComponent } from './background-panel/edit-background/edit-background-mob.component';
import { EditBackgroundMobNewComponent } from './background-panel/edit-background/edit-background-mob-new.component';
import { ImageThumbComponent } from './libraries/image-library/image-thumb.component';
import { ImageComponent } from './image/image.component';
import { ImageMobComponent } from './image/image-mob.component';
import { ImageMobNewComponent } from './image/image-mob-new/image-mob.component';
import { ImageUploadMobComponent } from './upload/image-upload-mob.component';
import { ImageCropMobComponent } from './edit-image/image-crop-mob.component';
import { EditImageComponent } from './edit-image/edit-image.component';
import { EditImageMobComponent } from './edit-image/edit-image-mob.component';
import { EditImageMobNewComponent } from './edit-image/edit-image-mob-new.component';
import { EditTextComponent } from './edit-text/edit-text.component';
import { EditInlineTextComponent } from './edit-inline-text/edit-inline-text.component';
import { EditInlineTextMobComponent } from './edit-inline-text/edit-inline-text-mob.component';
import { EditInlineTextMobGeneralOptionsComponent } from './edit-inline-text/edit-inline-text-mob-general.component';
import { EditInlineTextMobShortComponent } from './edit-inline-text/edit-inline-text-mob-short.component';
import { EditTextMobComponent } from './edit-text/edit-text-mob.component';
import { UploadComponent } from './upload/upload.component';
import { EditOptionComponent } from './edit-option/edit-option.component';
import { EditBoxComponent } from './edit-box/edit-box.component';
import { CoordinatesComponent } from './coordinates-and-dimensions/coordinates/coordinates.component';
import { DimensionsComponent } from './coordinates-and-dimensions/dimensions/dimensions.component';
import { EditOptionSelectorComponent } from './edit-option/edit-option-dialog/edit-option-selector.component';
import { EditOptionSelectorMobComponent } from './edit-option/edit-option-dialog/edit-option-selector-mob.component';
import { EditOptionDialogComponent } from './edit-option/edit-option-dialog/edit-option-dialog.component';
import { EditInlineTextMobNewComponent } from './edit-inline-text/edit-inline-text-mob-new.component';
import { ImageUploadMobNewComponent } from './upload/image-upload-mob-new.component';

const COMPONENTS = [
  TextComponent,
  TextMobComponent,
  CategoriesComponent,
  ImageLibraryComponent,
  BackgroundPanelComponent,
  ImageThumbComponent,
  BackgroundMobComponent,
  BackgroundMobNewComponent,
  EditBackgroundComponent,
  EditBackgroundMobComponent,
  EditBackgroundMobNewComponent,
  ImageComponent,
  ImageMobComponent,
  ImageMobNewComponent,
  ImageUploadMobComponent,
  ImageUploadMobNewComponent,
  ImageCropMobComponent,
  EditImageComponent,
  EditImageMobComponent,
  EditImageMobNewComponent,
  EditTextComponent,
  EditInlineTextComponent,
  EditInlineTextMobComponent,
  EditInlineTextMobNewComponent,
  EditInlineTextMobShortComponent,
  EditInlineTextMobGeneralOptionsComponent,
  EditTextMobComponent,
  UploadComponent,
  EditOptionComponent,
  EditBoxComponent,
  CoordinatesComponent,
  DimensionsComponent,
  EditOptionSelectorComponent,
  EditOptionSelectorMobComponent,
  EditOptionDialogComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: []
})
export class EditModule {}
