import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BackgroundImageElement,
  CanvasElement,
  Color,
  CUT_THROUGH_BASE_COLOR,
  Design,
  disabledPermissionsMap,
  disablingPermissionsWhenFalse,
  disablingPermissionsWhenTrue,
  ElementPermission,
  FunctionPermission,
  getRouteForPermission,
  ImageElement,
  LayerableElement,
  layerPermissions,
  LayerType,
  operationsPermissions,
  pagePermissions,
  propertyPermissions,
  SPOT_LAYERS,
  TrimTypes
} from '../../../models';
import * as CanvasActions from '../../../actions/canvas-actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GetTextService } from '../../../services';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { getPermittedLayerTypes } from '../../../reducers/permissions.reducer';
import { DropdownOption } from '../edit-element-setting/element-setting-dropdown.component';
import { LayerTypeText } from '../../../models/text';
import { pageIsFoilable, pageIsSpotUvable } from '../../../selectors';
import { resetSpecialColorAction } from '../../../utils/special-color.utils';
import { uniq } from 'lodash-es';

@Component({
  selector: 'ed-element-settings',
  templateUrl: 'element-settings.component.html',
  styleUrls: ['element-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementSettingsComponent implements OnInit, OnDestroy {
  elementPermission = ElementPermission;
  setElementPermission = ElementPermission;
  functionPermission = FunctionPermission;
  layerPermissions = layerPermissions;
  imageElement: ImageElement | BackgroundImageElement;
  tooltipShowDelay$: Observable<number>;
  canAddTextInline$: Observable<boolean>;
  layerTypeText: LayerTypeText;
  permittedLayerTypes: DropdownOption[];
  tags: DropdownOption[];
  isVector: boolean;
  pageIsFoilable: boolean;
  pageIsSpotUvable: boolean;
  disabledPermissions: ElementPermission[] = [];
  private _selectedElement: CanvasElement;
  private unsubscribe$ = new Subject<void>();

  @Input() set selectedElement(element: CanvasElement) {
    this._selectedElement = element;
    this.imageElement = element.firstChild
      ? (element.firstChild as ImageElement)
      : element.isImage()
      ? element
      : element.isSpreadBackgroundImage()
      ? element
      : undefined;
    this.isVector = !this.imageElement || (this.imageElement.isSvg && this.imageElement.isVectorImage);
    this.setDisabledPermissions();
  }

  get selectedElement() {
    return this._selectedElement;
  }

  @Input() design: Design;

  get isPageOrBackground() {
    return (
      this.selectedElement.isBackgroundElement() ||
      this.selectedElement.isBackgroundImage() ||
      this.selectedElement.isPageBackgroundImage()
    );
  }

  constructor(private store: Store<AppState>, public getTextService: GetTextService) {
    this.store
      .select(getPermittedLayerTypes)
      .pipe(withLatestFrom(this.store.select(s => s.config)), takeUntil(this.unsubscribe$))
      .subscribe(([permittedLayerTypes, config]) => {
        this.tags = config.tags.map(tag => new DropdownOption(tag, tag));
        this.layerTypeText = config.text.edit.setting.layerTypes;
        this.permittedLayerTypes = permittedLayerTypes.map(type => {
          return new DropdownOption(this.layerTypeText[type].title, type, this.layerTypeText[type].tooltip);
        });
      });

    combineLatest([this.store.select(pageIsFoilable), this.store.select(pageIsSpotUvable)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isFoilable, isSpotUvable]) => {
        this.pageIsFoilable = isFoilable;
        this.pageIsSpotUvable = isSpotUvable;
      });
  }

  get selectedElementLayerType(): LayerType {
    return this.selectedElement.isLayerableElement() ? this.selectedElement.layerType : undefined;
  }

  ngOnInit() {
    this.tooltipShowDelay$ = this.store.pipe(
      select(s => s.config),
      map(config => config.tooltipShowDelay)
    );
    this.canAddTextInline$ = this.store.pipe(select(fromPermissions.canAddTextInline));
  }

  showElementPermission(permission: ElementPermission) {
    return Object.keys(this.selectedElement.combinedPermissions).indexOf(permission) > -1;
  }

  setDisabledPermissions(): void {
    const truthyDisablingPermissions = Object.keys(this.selectedElement.permissions).filter(
      (permission: ElementPermission) =>
        (disablingPermissionsWhenTrue.includes(permission) && this.selectedElement.permissions[permission]) ||
        (disablingPermissionsWhenFalse.includes(permission) && !this.selectedElement.permissions[permission])
    );

    if (this.selectedElement.isLayerableElement() && this.selectedElement.layerType !== LayerType.standardRgb) {
      truthyDisablingPermissions.push('layerType');
    }

    this.disabledPermissions = uniq(
      truthyDisablingPermissions.flatMap(permission => disabledPermissionsMap[permission])
    );
  }

  disabled(permission: ElementPermission): boolean {
    return this.disabledPermissions.includes(permission);
  }

  disableLayers(): boolean {
    return this.selectedElement.combinedPermissions.isLocked;
  }

  toggleElementPermission(permission: ElementPermission) {
    const route = getRouteForPermission(this.selectedElement, permission);
    if (route) {
      this.dispatchToggleElementPermission(route, permission);
    }
  }

  dispatchToggleElementPermission(route: number[], permission: ElementPermission) {
    this.store.dispatch(new CanvasActions.ToggleElementPermission(permission, route));
  }

  toggleDesignPermission(permission: ElementPermission) {
    this.store.dispatch(new CanvasActions.ToggleElementPermission(permission));
  }

  setTag(tag: string) {
    this.store.dispatch(new CanvasActions.AddTag(this.selectedElement.route, tag));
  }

  toggleElementPreset(permission: ElementPermission) {
    const elementHasFoil = this.selectedElement.isSpecialColorElement() && this.selectedElement.foilType;
    const elementHasSpotUv = this.selectedElement.isSpecialColorElement() && this.selectedElement.spotUv;
    if (
      (permission === ElementPermission.isFoilable && elementHasFoil) ||
      (permission === ElementPermission.isSpotUvable && elementHasSpotUv)
    ) {
      this.store.dispatch(resetSpecialColorAction(this.selectedElement, permission));
    }

    const route = getRouteForPermission(this.selectedElement, permission);

    if (route) {
      this.dispatchTogglePreset(route, permission);
    }
  }

  dispatchTogglePreset(route: number[], permission: ElementPermission) {
    this.store.dispatch(new CanvasActions.ToggleElementPreset(route, permission));
  }

  get showPropertiesTitle(): boolean {
    return !!propertyPermissions.find(p => this.showElementPermission(p));
  }

  get showOperationsTitle(): boolean {
    return !!operationsPermissions.find(p => this.showElementPermission(p));
  }

  get showPagePropertiesTitle(): boolean {
    return this.isPageOrBackground && !!pagePermissions.find(p => this.showElementPermission(p));
  }

  get showAddTrimPermission() {
    // For now it is only possible to change trim on single or double page cards
    return (
      this.isPageOrBackground &&
      (this.design.numberOfPages <= 2 ||
        // allow wrongly trimmed designs to be changed back
        (!this.design.permissions.adjustTrim && this.design.trimType !== TrimTypes.borderStraight))
    );
  }

  get showSetElementsAsFoilable(): boolean {
    return this.isVector && this.pageIsFoilable && this.showElementPermission(ElementPermission.isFoilable);
  }

  get showSetElementsAsSpotUvable(): boolean {
    return (
      this.pageIsSpotUvable &&
      this.showElementPermission(ElementPermission.isSpotUvable) &&
      (this.isVector || (this.imageElement && this.imageElement.isJpg))
    );
  }

  get showAddTag(): boolean {
    return !!this.tags.length || !!this.selectedElement.tag;
  }

  get showSelectLayer(): boolean {
    const layerTypeIsReadonly =
      this.selectedElement.isBackgroundImage() ||
      this.selectedElement.parent.isBox() ||
      (this.selectedElement.isImage() && this.selectedElement.parent.isPhotoFrame());
    const isVectorImage = this.imageElement && this.imageElement.isSvg && this.imageElement.isVectorImage;
    return this.selectedElement.isLayerableElement() && !layerTypeIsReadonly && isVectorImage;
  }

  selectLayerType(type: string): void {
    this.changeColorForCutThrough(type as LayerType);
    this.store.dispatch(new CanvasActions.SetLayerType(this.selectedElement.route, type as LayerType));
  }

  changeColorForCutThrough(type: LayerType): void {
    const isCutThrough = [LayerType.cutThrough, LayerType.cutThroughInverted].includes(type);
    const wasCutThrough = [LayerType.cutThrough, LayerType.cutThroughInverted].includes(
      (this.selectedElement as LayerableElement).layerType
    );

    if (isCutThrough) {
      if (!wasCutThrough) {
        const color = new Color('', CUT_THROUGH_BASE_COLOR);
        this.store.dispatch(new CanvasActions.ChangeColor(this.selectedElement.route, color));
      }
    } else if (wasCutThrough) {
      this.store.dispatch(new CanvasActions.ResetImageColor(this.selectedElement.route));
    }
  }

  get showIsVisibleOutsideCropArea(): boolean {
    return (
      this.selectedElement &&
      this.selectedElement.isPhotoFrame() &&
      !this.selectedElement.permissions.isFoilable &&
      !SPOT_LAYERS.includes(this.selectedElement.layerType) &&
      this.showElementPermission(ElementPermission.isVisibleOutsideCropArea)
    );
  }

  setCharacterLimit(value: string): void {
    const valueAsNumber = value === '' ? undefined : Math.max(0, Number(value));
    this.store.dispatch(new CanvasActions.SetCharacterLimit(this.selectedElement.route, valueAsNumber));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
