import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { SharedModule } from '../shared/shared.module';
import { ContinueEffects } from './continue.effects';
import { SaveService } from '../save/save.service';
import { ContinueService } from './continue.service';
import { TransformService } from '../save/transform.service';
import { LoginSaveContinueDialogComponent } from './login-save-continue-dialog.component';

@NgModule({
  imports: [SharedModule, StoreModule.forFeature('continue', reducer), EffectsModule.forFeature([ContinueEffects])],
  declarations: [LoginSaveContinueDialogComponent],
  providers: [SaveService, ContinueService, TransformService]
})
export class ContinueModule {}
