/**
 * Fabric object route property name.
 */
export const X_ROUTE = 'x-route';

/**
 * Fabric object bound box route property name.
 */
export const X_BOUND_BOX_ROUTE = 'x-bound-box-route';

/**
 * Fabric object replace control route property name.
 */
export const X_REPLACE_CONTROL_ROUTE = 'x-replace-control-route';

/**
 * Fabric object crop route property name.
 */
export const X_CROP_ROUTE = 'x-crop-route';

/**
 * Fabric object coat route property name.
 */
export const X_COAT_ROUTE = 'x-coat-route';

/**
 * Fabric object foil route property name.
 */
export const X_FOIL_ROUTE = 'x-foil-route';

/**
 * Fabric object foil image route property name.
 */
export const X_FOIL_IMAGE_ROUTE = 'x-foil-image-route';

/**
 * Fabric object spot uv route property name.
 */
export const X_SPOT_UV_ROUTE = 'x-spot-uv-route';

/**
 * Fabric object cut through route property name.
 */
export const X_CUT_THROUGH_ROUTE = 'x-cut-through-route';

/**
 * Fabric object temp key previx.
 */
export const X_TEMP_KEY_PREFIX = 'x-temp-';

/**
 * Fabric object temp transform matrix property name.
 */
export const X_TRANSFORM_MATRIX = 'x-transform-matrix';

/**
 * Fabric object temp transform property name.
 */
export const X_TEMP_TRANSFORM = X_TEMP_KEY_PREFIX + 'transform';
