import { round } from 'lodash-es';
import { calculateRotatedPoint, OUTPUT_DECIMALS, roundToHalf } from '../utils/element.utils';
import { BackgroundElement, BoxElement } from './box-element';
import { CanvasCoordinate, CanvasElement, ElementType, LayerableElement, LayerType } from './canvas';
import { BackgroundImageElement, ImageElement } from './image-element';
import { InlineTextElement } from './inline-text-element';
import { LAYERS, sortLayer } from './layers';
import { PageElement } from './page-element';
import { ElementPermissions } from './permissions';
import { PhotoFrameElement } from './photo-frame-element';
import { TextElement } from './text-element';

/**
 * This class represents the elements on a design
 */

export abstract class BaseElement {
  readonly type: ElementType;

  // id of freeform template, when element is part of the freeform template id is added here,
  // so backend knows which element to remove when converting to different format
  templateId: number;

  _color: string;
  set color(color: string) {
    this._color = color;
  }

  get color(): string {
    return this._color;
  }

  visible = false;
  name: string;
  id: number;
  bleed = 0;
  safety = 0;
  private _tag: string;
  public get tag(): string {
    return this._tag;
  }

  public set tag(value: string) {
    this._tag = value;
  }

  parent: any;
  flipVertical = false;
  flipHorizontal = false;
  private _maxSize: number;
  public get maxSize(): number {
    return this._maxSize;
  }

  public set maxSize(value: number) {
    this._maxSize = value;
  }

  order: number;
  isFoilablePermissionWasChanged = false;

  permissions: ElementPermissions = {};

  active = false;
  activatedClientX: number;
  activatedClientY: number;
  x = 0;
  y = 0;
  width = 1;
  height = 1;

  naturalWidth: number;
  naturalHeight: number;

  transparency = 0;
  rotation = 0;
  selected = false;
  children: Array<CanvasElement> = [];
  imgSource: string;

  get combinedPermissions() {
    return this.permissions;
  }

  get page(): PageElement {
    if (this.isPage()) {
      return this;
    }
    return this.parent.page;
  }

  get backSidePage(): PageElement {
    let design = this.page.parent;
    let backSidePageId = this.page.backSidePageId;
    return design.pages.find(page => page.id === backSidePageId);
  }

  get pageWidth() {
    return this.parent.pageWidth;
  }

  get screenBleed() {
    return this.bleed;
  }

  get screenSafety() {
    return this.safety;
  }

  get route(): number[] {
    return [this.id, ...this.parent.route];
  }

  get isBackgroundChild() {
    if (!this.parent?.isBackground) return;
    return this.parent.isBackground();
  }

  get isPhotoFrameChild() {
    if (!this.parent?.isPhotoFrame) return;
    return this.parent.isPhotoFrame();
  }

  get isBoxChild() {
    if (!this.parent?.isBox) return;
    return this.parent.isBox();
  }

  get parentIsCropping() {
    return this.parent.inCroppingMode;
  }

  get isClickable() {
    return !this.isPhotoFrameChild || this.parentIsCropping;
  }

  get inCroppingMode(): boolean {
    return false;
  }

  get isCutThrough(): boolean {
    return this.isLayerableElement() && this.layerType === LayerType.cutThrough;
  }

  get isCutThroughInverted(): boolean {
    return this.isLayerableElement() && this.layerType === LayerType.cutThroughInverted;
  }

  isPage(): this is PageElement {
    return this.type === ElementType.page;
  }

  isBackgroundElement(): this is BackgroundElement {
    return this.type === ElementType.background;
  }

  isImage(): this is ImageElement {
    return this.type === ElementType.image;
  }

  isText(): this is TextElement {
    return this.type === ElementType.text;
  }

  isInlineText(): this is InlineTextElement {
    return this.type === ElementType.inlineText;
  }

  isPhotoFrame(): this is PhotoFrameElement {
    return this.type === ElementType.photoFrame;
  }

  isBackgroundImage(): this is ImageElement | BackgroundImageElement {
    return this.isPageBackgroundImage() || this.isSpreadBackgroundImage();
  }

  isBackground(): this is BackgroundElement | BackgroundImageElement {
    return this.isBackgroundElement() || this.isBackgroundImage();
  }

  // pageBackgroundImage has backgroundElement as parent
  isPageBackgroundImage(): this is ImageElement {
    return this.isImage() && this.parent && this.parent.type === ElementType.background;
  }

  // spreadBackgroundImage has pageElement as parent
  isSpreadBackgroundImage(): this is BackgroundImageElement {
    return this.type === ElementType.backgroundImage;
  }

  isBox(): this is BoxElement {
    return this.type === ElementType.box;
  }

  isSpecialColorElement(): this is InlineTextElement | ImageElement | PhotoFrameElement | TextElement {
    return [ElementType.inlineText, ElementType.image, ElementType.photoFrame, ElementType.text].includes(this.type);
  }

  isLayerableElement(): this is LayerableElement {
    return [ElementType.inlineText, ElementType.image, ElementType.photoFrame, ElementType.text].includes(this.type);
  }

  get designX(): number {
    return this.parent.designX + this.screenX;
  }

  get designY(): number {
    return this.parent.designY + this.screenY;
  }

  // (page unrotated with zoom relative to design) + (element position unrotated with zoom relative to page)
  get overlayPosX() {
    return this.pageX + this.pagePositionX;
  }

  get overlayPosY() {
    return this.pageY + this.pagePositionY;
  }

  get pagePositionX() {
    return this.center.x - this.width / 2;
  }

  get pagePositionY() {
    return this.center.y - this.height / 2;
  }

  get pageX() {
    return this.parent.pageX;
  }

  get pageY() {
    return this.parent.pageY;
  }

  get screenX() {
    return this.x;
  }

  set screenX(x: number) {
    this.x = x;
  }

  get screenY() {
    return this.y;
  }

  set screenY(y: number) {
    this.y = y;
  }

  get screenHeight() {
    return this.height;
  }

  set screenHeight(height: number) {
    this.height = height;
  }

  get screenWidth() {
    return this.width;
  }

  set screenWidth(width: number) {
    this.width = width;
  }

  get opacity() {
    return (100 - this.transparency) / 100;
  }

  get ratio(): number {
    return this.height / this.width;
  }

  // parent.rotatePoint(center)
  get center(): CanvasCoordinate {
    const point = { x: this.x + this.width / 2, y: this.y + this.height / 2 };
    return this.parent ? this.parent.rotateCenter(point) : point;
  }

  get leftTop() {
    return this.roundPoint(this.rotatePoint({ x: -this.width / 2, y: -this.height / 2 }));
  }

  get rightTop() {
    return this.roundPoint(this.rotatePoint({ x: this.width / 2, y: -this.height / 2 }));
  }

  get leftBottom() {
    return this.roundPoint(this.rotatePoint({ x: -this.width / 2, y: this.height / 2 }));
  }

  get rightBottom() {
    return this.roundPoint(this.rotatePoint({ x: this.width / 2, y: this.height / 2 }));
  }

  get polygon(): CanvasCoordinate[] {
    return [this.leftTop, this.rightTop, this.rightBottom, this.leftBottom];
  }

  roundPoint(point: CanvasCoordinate, decimals = OUTPUT_DECIMALS): CanvasCoordinate {
    return { x: round(point.x, decimals), y: round(point.y, decimals) };
  }

  get safetyPolygon(): CanvasCoordinate[] {
    return [
      { x: this.leftTop.x + this.safety, y: this.leftTop.y + this.safety },
      { x: this.rightTop.x - this.safety, y: this.rightTop.y + this.safety },
      { x: this.rightBottom.x - this.safety, y: this.rightBottom.y - this.safety },
      { x: this.leftBottom.x + this.safety, y: this.leftBottom.y - this.safety }
    ];
  }

  get bleedPolygon(): CanvasCoordinate[] {
    return [
      { x: this.leftTop.x - this.bleed, y: this.leftTop.y - this.bleed },
      { x: this.rightTop.x + this.bleed, y: this.rightTop.y - this.bleed },
      { x: this.rightBottom.x + this.bleed, y: this.rightBottom.y + this.bleed },
      { x: this.leftBottom.x - this.bleed, y: this.leftBottom.y + this.bleed }
    ];
  }

  constructor(id: number = null) {
    this.id = id;
  }

  get firstChild() {
    return this.children[0];
  }

  get screenRotation() {
    const parentRotation = this.parent ? this.parent.screenRotation : 0;
    return this.rotation + parentRotation;
  }

  /*
   * used to calculate de polygon/bounding-box of element,
   * rotate (total screen rotation of element and parents),
   * the corners of an element around the center coordinate of the page
   */
  rotatePoint(point: CanvasCoordinate) {
    const relX = point.x;
    const relY = point.y;

    const newPoint = calculateRotatedPoint(-this.screenRotation, relX, relY);

    return {
      x: this.center.x + newPoint.x,
      y: this.center.y + newPoint.y
    };
  }

  /*
   * used to calculate the position of the center of an element on the page,
   */
  rotateCenter(point: CanvasCoordinate) {
    const relX = point.x - this.width / 2;
    const relY = point.y - this.height / 2;

    const deltaCenter = calculateRotatedPoint(-this.rotation, relX, relY);

    return {
      x: this.center.x + deltaCenter.x,
      y: this.center.y + deltaCenter.y
    };
  }

  resize(screenX: number, screenY: number, screenWidth: number, screenHeight: number) {
    if (this.parent.type === ElementType.photoFrame) {
      // const screenDimensions = this.setNestedElementScreenSize(screenX, screenY, screenWidth, screenHeight);
      const screenDimensions = { x: screenX, y: screenY, width: screenWidth, height: screenHeight };
      screenWidth = screenDimensions.width;
      screenHeight = screenDimensions.height;
      screenX = screenDimensions.x;
      screenY = screenDimensions.y;
    }

    const scaleHeight = screenHeight / this.screenHeight;
    const scaleWidth = screenWidth / this.screenWidth;

    this.children.map(child => {
      child._scaleHeight(scaleHeight, this.designY);
      child._scaleWidth(scaleWidth, this.designX);
    });

    this.x = screenX;
    this.y = screenY;
    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;
  }

  setNestedElementScreenSize(screenX: number, screenY: number, screenWidth: number, screenHeight: number) {
    let slope = (screenY - this.screenY) / (screenX - this.screenX);

    if (slope === Infinity) {
      slope = 0;
    }

    const cross = screenY - slope * screenX;

    if (screenX > 0) {
      screenWidth = screenX + screenWidth;
      screenHeight = screenWidth * this.ratio;
      screenY = screenY - slope * screenX;
      screenX = 0;
    }

    if (screenY > 0) {
      screenHeight = screenY + screenHeight;
      screenWidth = screenHeight / this.ratio;
      screenY = 0;
      screenX = -cross / slope;

      if (screenX === Infinity || screenX === -Infinity) {
        screenX = this.screenX;
      }
    }
    if (screenWidth + screenX < this.parent.screenWidth) {
      screenWidth = this.parent.screenWidth - screenX;
      screenHeight = screenWidth * this.ratio;

      // javascript floating point numbers generate false true's if not rounded
      if (round(screenY, 5) !== round(this.screenY, 5)) {
        screenY = this.screenY + (this.screenHeight - screenHeight);
      }
    }

    if (screenHeight + screenY < this.parent.screenHeight) {
      screenHeight = this.parent.screenHeight - screenY;
      screenWidth = screenHeight / this.ratio;

      // javascript floating point numbers generate false true's if not rounded
      if (round(screenX, 5) !== round(this.screenX, 5)) {
        screenX = this.screenX + (this.screenWidth - screenWidth);
      }
    }
    return { x: screenX, y: screenY, width: screenWidth, height: screenHeight };
  }

  crop(screenX: number, screenY: number, screenWidth: number, screenHeight: number) {
    const oldLeftTop = this.leftTop;

    this.x = screenX;
    this.y = screenY;
    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;

    // rotate back oldLeftTop of parent around the new center,
    // to calculate deltaX/Y of parent without roration relative to new position of parent
    const oldRotatedLeftTop = calculateRotatedPoint(
      this.rotation,
      this.center.x - oldLeftTop.x,
      this.center.y - oldLeftTop.y
    );

    // child should not move on screen so move child back with deltaX/Y
    const deltaX = oldRotatedLeftTop.x - this.width / 2;
    const deltaY = oldRotatedLeftTop.y - this.height / 2;

    this.children.map(child => {
      child.x = child.x - deltaX;
      child.y = child.y - deltaY;
    });
  }

  translate(screenX: number, screenY: number, screenWidth: number, screenHeight: number, precise: boolean) {
    this.x = precise ? screenX : roundToHalf(screenX);
    this.y = precise ? screenY : roundToHalf(screenY);

    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;
  }

  _scaleWidth(scaleWidth: number, parentX: number) {
    this.screenX = this.screenX * scaleWidth;
    this.screenWidth = this.screenWidth * scaleWidth;
  }

  _scaleHeight(scaleHeight: number, parentY: number) {
    this.screenY = this.screenY * scaleHeight;
    this.screenHeight = this.screenHeight * scaleHeight;
  }

  get selectedElement(): BaseElement {
    if (this.selected) {
      return this;
    } else {
      return this.selectedChildElement;
    }
  }

  get selectedChildElement(): BaseElement {
    const selectedChild = this.children.find(element => !!element.selectedElement);
    return selectedChild ? selectedChild.selectedElement : undefined;
  }

  get newId() {
    return this.children.reduce((maxId, nextChild) => Math.max(maxId, nextChild.id), 0) + 1;
  }

  get newOrder() {
    return this.children.reduce((maxId, nextChild) => Math.max(maxId, nextChild.order), 0) + 1;
  }

  select(): void {
    this.selected = true;
  }

  deselectAll() {
    this.selected = false;
    this.children.map(element => element.deselectAll());
  }

  cleanUpElements(): void {
    this.children = this.children.filter((childElement: TextElement | InlineTextElement) => {
      childElement.cleanUpElements();
      const emptyTextElement = childElement.type === ElementType.text && childElement.text.trim() === '';
      const emptyInlineTextElement = childElement.type === ElementType.inlineText && !childElement.hasText;
      return !(emptyTextElement || emptyInlineTextElement);
    });
  }

  getElement(route: Array<number>): BaseElement {
    const id = route[route.length - 1];
    if (this.id === id) {
      if (route.length === 1) {
        return this;
      } else {
        const newRoute = route.slice(0, -1);
        const element = this.children.find(_element => !!_element.getElement(newRoute));
        return element ? element.getElement(newRoute) : undefined;
      }
    }
  }

  /**
   * should be called everytime a element is added or order is changed of element
   * should also be called when an element changes layer
   */
  sortChildren() {
    this.children.sort((a, b) => {
      for (const layer of LAYERS) {
        const order = sortLayer(a, b, layer);
        if (order !== undefined) {
          return order;
        }
      }
      console.warn('2 elements compared, which are both not specified in a layer');
    });
  }
}
