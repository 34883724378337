import { NgModule } from '@angular/core';
import { EditorComponent } from './editor/editor.component';
import { PanelsModule } from './panels/panels.module';
import { NavModule } from './nav/nav.module';
import { OutputService } from '../services';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { ImageLibraryEffects } from '../image-library/image-library.effects';
import { ImageLibraryService } from '../image-library/image-library.service';
import { FontLibraryEffects } from '../font-library/font-library.effects';
import { FontLibraryService } from '../font-library/font-library.service';
import { DesignModule } from './design/design.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EditModule } from './edit/edit.module';
import { EditorContainerComponent } from './editor-container.component';
import { EditorMobComponent } from './editor/editor-mobile/editor-mob.component';
import { ElementSettingsModule } from './element-settings/element-settings.module';
import { StyleMenuModule } from './style-menu/style-menu.module';
import { CanvasToolsComponent } from './design/tools/tools.component';

@NgModule({
  imports: [
    SharedModule,
    PanelsModule,
    NavModule,
    EffectsModule.forFeature([ImageLibraryEffects, FontLibraryEffects]),
    DesignModule,
    MatSidenavModule,
    EditModule,
    ElementSettingsModule,
    StyleMenuModule
  ],
  declarations: [EditorContainerComponent, EditorComponent, EditorMobComponent, CanvasToolsComponent],
  providers: [OutputService, ImageLibraryService, FontLibraryService],
  exports: [EditorContainerComponent, EditorComponent, EditorMobComponent]
})
export class EditorModule {}
