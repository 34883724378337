import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../reducers';
import { GetTextService, UiService } from '../../../services';
import { select, Store } from '@ngrx/store';
import { CanvasElement, Design, PageElement } from '../../../models';
import { DesignSet } from '../../../models/design-set';
import * as Save from '../../../save/actions';
import { Observable, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducer';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { CanvasActions } from '../../../actions';

export const DEFAULT_PANEL_HEIGHT = '120px';
export const DEFAULT_IMAGE_LIBRARY_HEIGHT = '85vh';

export type panelName =
  | 'navMenu'
  | 'pagesMenu'
  | 'addMenu'
  | 'addTextMenu'
  | 'addImageMenu'
  | 'addBackgroundMenu'
  | 'editTextMenu'
  | 'editImageMenu'
  | 'editBackgroundMenu'
  | 'imageCroppingMenu';

export class Panel {
  constructor(
    public name: panelName,
    public open = false,
    public showGreyBackLayer = false,
    public showAddMenuButton = false,
    public showConfirmButton = false,
    public height = DEFAULT_PANEL_HEIGHT
  ) {}
}

export type Panels = { [P in panelName]: Panel };

@Component({
  selector: 'ed-panels-mobile-new',
  templateUrl: 'panels-mobile-new.component.html',
  styleUrls: ['panels-mobile-new.component.scss']
})
export class PanelsMobileNewComponent implements OnInit, OnDestroy {
  panels: Panels = {
    navMenu: new Panel('navMenu', true, false, false, false),
    pagesMenu: new Panel('pagesMenu', undefined, false, false, true, DEFAULT_PANEL_HEIGHT),
    addMenu: new Panel('addMenu', undefined, true, false, false, '50vh'),
    addTextMenu: new Panel('addTextMenu', undefined, false, false, true),
    addImageMenu: new Panel('addImageMenu', undefined, true, false, false, DEFAULT_IMAGE_LIBRARY_HEIGHT),
    addBackgroundMenu: new Panel('addBackgroundMenu', undefined, true, false, false, DEFAULT_IMAGE_LIBRARY_HEIGHT),
    editTextMenu: new Panel('editTextMenu', undefined, false, true, true),
    editImageMenu: new Panel('editImageMenu', undefined, false, true, true),
    editBackgroundMenu: new Panel('editBackgroundMenu', undefined, false, true, true),
    imageCroppingMenu: new Panel('imageCroppingMenu', undefined, false, false, false)
  };

  pages: PageElement[];

  selectedElement: CanvasElement;
  visiblePage: PageElement;
  previousSelectedElementRoute: number[];
  addChildElements: boolean;
  showGreyBackLayer = false;
  greyBackLayerBottomValue = '0px';
  showAddMenuButton = false;
  showConfirmButton = false;

  _design: Design;
  _designSet: DesignSet;

  isLoggedIn$: Observable<boolean>;

  public canSaveSubscription$: Subscription;
  public canSave: boolean;

  @HostBinding('style.height') componentHeight = DEFAULT_PANEL_HEIGHT;

  @Input()
  set designSet(designSet: DesignSet) {
    this._designSet = designSet;
    this._design = designSet.activeDesign;
    if (this._design && this._design.visiblePage) {
      this.visiblePage = this._design.visiblePage;
      const selectedElement = this._design.selectedElement;

      let selectedElementRoute: number[];

      if (selectedElement) {
        this.onSelectedElement(selectedElement);
        this.selectedElement = selectedElement;
        selectedElementRoute = selectedElement ? selectedElement.route : null;
      }

      if (!selectedElementRoute && this.previousSelectedElementRoute) {
        this.openPanel(this.panels.navMenu);
      }

      this.previousSelectedElementRoute = selectedElementRoute;

      if (this.visiblePage) {
        this.addChildElements = this.visiblePage.permissions.addChildElements;
      }
    }
    this.pages = designSet.designs.flatMap(d => d.pages);
  }

  get designSet() {
    return this._designSet;
  }

  get design() {
    return this._design;
  }

  constructor(
    public store: Store<AppState>,
    public getTextService: GetTextService,
    public uiService: UiService,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.store.pipe(select(fromAuth.isLoggedIn));
    this.canSaveSubscription$ = this.store
      .pipe(select(fromPermissions.getCanSave))
      .subscribe(permission => (this.canSave = permission));
  }

  getOffsetTop(): number {
    return this.elementRef.nativeElement.offsetTop;
  }

  openBackgroundMenu(): void {
    this.selectedElement = this.visiblePage.background;
    if (!this.visiblePage.background.isBackgroundImage() && this.addChildElements) {
      this.openPanel(this.panels.addBackgroundMenu);
    } else {
      this.openPanel(this.panels.editBackgroundMenu);
    }
  }

  openPanel(panel: Panel): void {
    Object.keys(this.panels).map(name => (this.panels[name].open = name === panel.name));
    this.componentHeight = panel.height;
    this.showGreyBackLayer = panel.showGreyBackLayer;
    this.greyBackLayerBottomValue = panel.height;
    this.showAddMenuButton = panel.showAddMenuButton;
    this.showConfirmButton = panel.showConfirmButton;
  }

  onBackLayerClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
    // Timeout prevents click on underlaying element while closing
    setTimeout(() => this.closeAddMenu(), 300);
  }

  closeAllPanels(): void {
    Object.keys(this.panels).map(name => (this.panels[name].open = false));
    this.openPanel(this.panels.navMenu);
  }

  closeAddMenu(): void {
    Object.keys(this.panels).map(name => (this.panels[name].open = false));
    if (this._design.selectedElement) {
      this.onSelectedElement(this.selectedElement);
    } else {
      this.closeAllPanels();
    }
  }

  deselectElement(): void {
    this.closeAllPanels();
    this.store.dispatch(new CanvasActions.Deselect());
  }

  addElement(): void {
    this.openPanel(this.panels.addMenu);
  }

  onSelectedElement(selectedElement: CanvasElement): void {
    const textElementSelected = selectedElement.isText();
    const inlineTextElementSelected = selectedElement.isInlineText();

    const imageElementSelected = selectedElement.isImage() && !selectedElement.parent.isBackgroundElement();
    const imageInCroppingMode = selectedElement.inCroppingMode;
    const boxElementSelected = selectedElement.isBox();
    const photoFrameElementSelected = selectedElement.isPhotoFrame();
    const backgroundGroundSelected = selectedElement.isBackgroundElement() || selectedElement.isBackgroundImage();

    if ((imageElementSelected || boxElementSelected || photoFrameElementSelected) && !imageInCroppingMode) {
      this.openPanel(this.panels.editImageMenu);
    } else if (imageInCroppingMode) {
      this.openPanel(this.panels.imageCroppingMenu);
    } else if (textElementSelected || inlineTextElementSelected) {
      this.openPanel(this.panels.editTextMenu);
    } else if (backgroundGroundSelected) {
      this.openPanel(this.panels.editBackgroundMenu);
    } else {
      this.openPanel(this.panels.navMenu);
    }
  }

  openSaveModal() {
    if (this.isLoggedIn$ && this.canSave) {
      this.store.dispatch(new Save.Save());
    } else {
      this.store.dispatch(new Save.OpenSaveDialog());
    }
  }

  ngOnDestroy() {
    this.canSaveSubscription$.unsubscribe();
  }
}
