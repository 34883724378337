import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { UiActions } from '../../actions';
import {
  DARKEN_COLOR_PERCENTAGE,
  FontNames,
  LIGHTEN_COLOR_PERCENTAGE,
  ThemeDataBaseColors,
  ThemeDataBorderRadii,
  ThemeDataBrandColors,
  ThemeDataTextAndIconsColors,
  ThemeItem,
  ThemeItemNames
} from '../../data/theme.data';
import { ThemeService } from '../../services';
import { colorShade } from '../../utils/color-utils';

export type subMenuName =
  | 'colorOverview'
  | 'primaryColor'
  | 'textColor'
  | 'baseColor'
  | 'borderRadius'
  | 'font'
  | 'json';

export class SubMenu {
  public open = false;

  constructor(public name: string) {}
}

export type SubMenus = { [E in subMenuName]: SubMenu };

@Component({
  selector: 'ed-style-menu',
  templateUrl: 'style-menu.component.html',
  styleUrls: ['style-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleMenuComponent {
  @Input() showStyleMenu: boolean;

  @Output() closeMenu = new EventEmitter();

  subMenus: SubMenus = {
    colorOverview: new SubMenu('colorOverview'),
    primaryColor: new SubMenu('primaryColor'),
    textColor: new SubMenu('textColor'),
    baseColor: new SubMenu('baseColor'),
    borderRadius: new SubMenu('borderRadius'),
    font: new SubMenu('font'),
    json: new SubMenu('json')
  };

  public themeDataTextAndIconsColors = ThemeDataTextAndIconsColors;
  public themeDataPrimaryColors = ThemeDataBrandColors;
  public themeDataBaseColors = ThemeDataBaseColors;
  public themeDataBorderRadii = ThemeDataBorderRadii;
  public themeDataFonts = FontNames;

  showExport = true;
  showImport = false;

  constructor(private store: Store<AppState>, private themeService: ThemeService) {}

  toggleSubMenu(subMenu: SubMenu) {
    if (subMenu.open) {
      subMenu.open = false;
    } else {
      Object.keys(this.subMenus).map(name => (this.subMenus[name].open = name === subMenu.name));
    }
  }

  setLightColor(color: string) {
    return colorShade(color, LIGHTEN_COLOR_PERCENTAGE);
  }

  setDarkColor(color: string) {
    return colorShade(color, DARKEN_COLOR_PERCENTAGE);
  }

  changeToolbarTextColor(textColor: ThemeItem) {
    this.themeService.changeToolbarTextColor(textColor);
  }

  changeTheme(themeItem: ThemeItem) {
    this.themeService.updateThemeItem(themeItem.name, themeItem.value);
  }

  changeFont(fontName: string) {
    this.themeService.updateThemeItem(ThemeItemNames.font, fontName);
  }

  closeStyleMenu() {
    this.closeMenu.emit();
    this.store.dispatch(new UiActions.ToggleStyleMenu(false));
  }

  toggleExportImport() {
    this.showExport = !this.showExport;
    this.showImport = !this.showImport;
  }

  exportCss() {
    this.themeService.exportCssVariablesAsJson();
  }

  importCss(json: string) {
    this.themeService.importCssVariablesFromJson(json);
  }

  getJson() {
    return this.themeService.getJson();
  }
}
