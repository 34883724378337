import { fileExtensionIsJpg, fileExtensionIsSvg } from '../utils/element.utils';
import { BaseElement } from './base-element';
import { BackgroundElement, BoxElement } from './box-element';
import { ElementType, LayerType } from './canvas';
import { FoilTypes } from './color';
import { PageElement } from './page-element';
import { ElementPermissions } from './permissions';
import { PhotoFrameElement } from './photo-frame-element';

export class ImageElement extends BaseElement {
  readonly type: ElementType = ElementType.image;
  name = 'image';
  url: string;
  parent: PhotoFrameElement | BoxElement | PageElement | BackgroundElement;
  imgSource: string;
  flipHorizontal: boolean;
  flipVertical: boolean;
  foilType: FoilTypes;
  spotUv = false;
  sid: string;
  isVectorImage = false; // is different from isSvg, a svg can contain non vector images
  _layerType: LayerType = LayerType.standardRgb;

  // for photoframechildren, this is on the parent. for box images it should have its own
  get layerType(): LayerType {
    return this.isPhotoFrameChild ? (this.parent as PhotoFrameElement).layerType : this._layerType;
  }

  set layerType(type: LayerType) {
    this._layerType = type;
  }

  permissions: ElementPermissions = {
    isResizable: true, // image in photoframe should still be resizable
    isMovable: true // image in photoframe should still be moveable
  };

  /**
   * for box images and old-style images (not in photoframes)
   */
  setRegularImagePermissions() {
    this.permissions = {
      isLocked: false,
      isMovable: true,
      isRemovable: true,
      isRotatable: true,
      isResizable: true,
      isDuplicatable: true,
      isRecolorable: true,
      isFoilable: false,
      isFlippable: true,
      isPrintable: true,
      isTopLayer: false,
      isUntargetable: false,
      adjustTransparency: true,
      isReplaceable: true,
      hasCoating: false,
      isSpotUvable: false,
      isFillable: true,
      isOrderable: true,
      excludeFromPreviews: false,
      isVisibleOutsideCropArea: false,
      isExternallyEditable: false
    };
  }

  /**
   * for background images
   */
  setBackgroundImagePermissions() {
    this.permissions = {
      isMovable: true,
      isRemovable: true,
      isRotatable: true,
      isResizable: true,
      isDuplicatable: true,
      isRecolorable: true,
      isFlippable: true,
      isPrintable: true,
      adjustTransparency: true,
      isReplaceable: true,
      hasCoating: false,
      isSpotUvable: false,
      isFillable: true,
      excludeFromPreviews: false,
      isVisibleOutsideCropArea: false,
      isExternallyEditable: false
    };
  }

  get combinedPermissions() {
    return this.parent.isBackgroundElement() ? { ...this.permissions, ...this.parent.permissions } : this.permissions;
  }

  get inCroppingMode(): boolean {
    return this.selected && this.isPhotoFrameChild;
  }

  get isSvg() {
    return fileExtensionIsSvg(this.sid || this.url);
  }

  get isJpg() {
    return fileExtensionIsJpg(this.sid || this.url);
  }

  get imgParams(): Record<string, string | number> {
    return {
      foilType: this.foilType,
      sid: this.sid,
      url: this.url,
      fg_color: this.color || ''
    };
  }

  get maxSize() {
    // use fibonacci times 100 for maxsize with limit of 3400 for now
    // maxsize should never be larger then natural size
    const largestSide = Math.max(this.screenWidth, this.screenHeight);
    let result = 100;
    let temp = 0;
    let prev = 100;
    while (result < largestSide && result < 3400) {
      result = temp + prev;
      temp = prev;
      prev = result;
    }
    return result;
  }

  get minZoom(): number {
    return 100;
  }

  get maxZoom(): number {
    return 400;
  }

  get calculatedZoomLevel(): number {
    if (!this.isPhotoFrameChild) {
      return this.minZoom;
    }
    return Math.min(this.width / this.parent.width, this.height / this.parent.height) * 100;
  }

  get zoomLevel(): number {
    return Math.min(this.maxZoom, Math.max(this.minZoom, this.calculatedZoomLevel))
  }

  get isCropped(): boolean {
    if (!this.isPhotoFrameChild) {
      return false;
    }
    return Math.round(this.width / this.parent.width * 100) !== this.minZoom || Math.round(this.height / this.parent.height * 100) !== this.minZoom;
  }

  constructor(
    id: number,
    { name = '', width = 10, height = 8, x = 0, y = 0, color = '', sid = '', url = '', transparency = 0, tag = '' } = {}
  ) {
    super(id);
    this.name = name;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.color = color;
    this.transparency = transparency;
    this.sid = sid;
    this.url = url;
    this.tag = tag;
  }
}

// BackgroundImageElement is used for spreadBackgroundImages, parent is always a pageElement
export class BackgroundImageElement extends ImageElement {
  readonly type = ElementType.backgroundImage;

  get layerType(): LayerType {
    return LayerType.standardRgb;
  }

  name = 'spread-bg-image';
}
