import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { EditInlineTextComponent } from './edit-inline-text.component';
import { CanvasActions, UiActions } from '../../../actions';
import { ConfigService, CopyService, GetTextService, UiService } from '../../../services';
import { TextEditorService } from '../../../text-editor/text-editor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { BrowserStorageService } from '../../../services/browser-storage.service';

export class EditOption {
  public open = false;
  public enabled = true;

  constructor(public name: string) {}
}

@Component({
  selector: 'ed-edit-inline-text-mob',
  templateUrl: './edit-inline-text-mob.component.html',
  styleUrls: ['../edit-mob.component.scss', './edit-inline-text-mob.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInlineTextMobComponent extends EditInlineTextComponent {
  @Input() closeShortMenu: any;
  @Output() closeEditMode: EventEmitter<boolean> = new EventEmitter();
  @HostBinding('class.hide-edit-menu') displayEditMenu = false;

  editOptions = {};

  showGeneralMenu = true;
  isAndroid: boolean;

  constructor(
    public getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected store: Store<AppState>,
    protected config: ConfigService,
    public uiService: UiService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(getTextService, textEditorService, store, config, uiService);
    this.isAndroid = config.isAndroid;
  }

  handleDialogChanges(isOpen: boolean) {
    if (!isOpen) {
      this.focusEditor();
    }
    this.changeDetector.detectChanges();
  }

  closeEditTextMode() {
    this.store.dispatch(new CanvasActions.SetInlineTextEditMode(this.element.route, true));

    if (this.useAlternativeInputInlineText && !this.uiService.alternativeInputValue.length) {
      this.store.dispatch(new CanvasActions.RemoveElement(this.element.route));
    }
  }

  get optionSelected() {
    return Object.keys(this.editOptions).some(name => this.editOptions[name].open);
  }

  set editFullRange(editFullRange: boolean) {
    this._editFullRange = editFullRange;
    if (!editFullRange && this.useAlternativeInputInlineText) {
      Object.keys(this.editOptions).map(name => (this.editOptions[name].open = false));
    }
  }

  get editFullRange() {
    return this._editFullRange;
  }

  openEditOption(editMenuOption: EditOption) {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = name === editMenuOption.name));
    this.blurEditor();
  }

  closeEditMenu() {
    this.closeEditMode.emit(false);
    this.focusEditor();
  }

  focusEditor(): void {
    if (this.editFullRange) {
      return;
    }

    this.editor?.focus();
  }

  closeAllEditOptions() {
    // Blur needed on Samsung
    this.blurEditor();
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = false));
    this.focusEditor();
  }

  openSettingsMenu() {
    this.store.dispatch(new UiActions.ToggleSettingsMenu(true));
  }

  toggleShowGeneralMenu(show: boolean) {
    this.showGeneralMenu = show;
  }
}
