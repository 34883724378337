import { fabric } from "fabric";
import { BaseElement, ImageElement, defaultMaterialType } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/image-element" {
  interface ImageElement { }
  interface BackgroundImageElement { }
}

ImageElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: ImageElement = this;

  if (!(element instanceof ImageElement) || !element.imgSource) {
    return null;
  }

  object ??= await new Promise((resolve: (image: fabric.Image) => void) => fabric.Image.fromURL(element.imgSource, (image: fabric.Image) => resolve(image), {
    crossOrigin: "anonymous",
  }));

  if (!(object instanceof fabric.Image)) {
    return null;
  }

  if (object.getSrc() !== element.imgSource) {
    await new Promise((resolve: (image: fabric.Image) => void) => (object as fabric.Image).setSrc(element.imgSource, (image: fabric.Image) => resolve(image), {
      crossOrigin: "anonymous",
    }));
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

ImageElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: ImageElement = this;

  if (!(element instanceof ImageElement) || !(object instanceof fabric.Image)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    scaleX: element.width / object.width,
    scaleY: element.height / object.height,
    lockMovementX: !element.permissions.isMovable,
    lockMovementY: !element.permissions.isMovable
  };
}

ImageElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: ImageElement = this;

  if (!(element instanceof ImageElement) || !(object instanceof fabric.Image)) {
    return null;
  }

  if (element.isBackgroundChild && element.page.parent.material.type !== defaultMaterialType) {
    return {
      ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    };
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    "tl": !!element.permissions.isResizable,
    "tr": !!element.permissions.isResizable,
    "br": !!element.permissions.isResizable,
    "bl": !!element.permissions.isResizable,
    "ml": !!element.permissions.isCroppable && !element.isPhotoFrameChild,
    "mr": !!element.permissions.isCroppable && !element.isPhotoFrameChild,
    "mt": !!element.permissions.isCroppable && !element.isPhotoFrameChild,
    "mb": !!element.permissions.isCroppable && !element.isPhotoFrameChild,
    "mtr": !!element.permissions.isRotatable,
    "remove": !!element.permissions.isRemovable
  };
}
