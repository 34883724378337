import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { CanvasActions } from '../../../actions';
import { map } from 'rxjs/operators';
import { GetTextService } from '../../../services';
import { FunctionPermission } from '../../../models';
import { Subscription } from 'rxjs';
import { selectDesign } from '../../../selectors';

@Component({
  selector: 'ed-text',
  templateUrl: './text.component.html',
  styleUrls: ['../edit.component.scss', './text.component.scss', '../../nav/nav-buttons.component.scss']
})
export class TextComponent implements OnInit, OnDestroy {
  @Output() textEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

  useAbsoluteFontSizeConfig: boolean;
  useAbsoluteFontSizeDesign: boolean;

  functionPermission = FunctionPermission;
  canAddTextInlineSubscription: Subscription;

  canAddTextInline: boolean;

  constructor(protected store: Store<AppState>, public getTextService: GetTextService) {}

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    const design$ = this.store.pipe(select(selectDesign));

    config$.pipe(map(config => config.useAbsoluteFontSize)).subscribe(useAbsoluteFontSizeConfig => {
      this.useAbsoluteFontSizeConfig = useAbsoluteFontSizeConfig;
    });

    design$.pipe(map(design => design.useAbsoluteFontSize)).subscribe(useAbsoluteFontSizeDesign => {
      this.useAbsoluteFontSizeDesign = useAbsoluteFontSizeDesign;
    });

    this.canAddTextInlineSubscription = this.store
      .pipe(select(s => s.permissions.functionPermissions.canAddTextInline))
      .subscribe(canAddTextInline => (this.canAddTextInline = canAddTextInline));
  }

  addText() {
    this.store.dispatch(
      new CanvasActions.AddText(
        this.getTextService.text.edit.textInput.placeholder,
        this.useAbsoluteFontSizeConfig && this.useAbsoluteFontSizeDesign
      )
    );
  }

  addTextInline() {
    this.store.dispatch(new CanvasActions.AddTextInline(this.getTextService.text.edit.textInput.placeholder));
  }

  addTextSwitchable(event: MouseEvent) {
    if (event.button !== undefined && event.button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }

    event.preventDefault(); // prevent event end happening on overlay after textelement is initialized
    if (this.canAddTextInline) {
      this.addTextInline();
    } else {
      this.textEditing.emit(true);
      this.addText();
    }
  }

  ngOnDestroy() {
    this.canAddTextInlineSubscription.unsubscribe();
  }
}
