/**
 * This class represents the main application component.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'ed-editor-app',
  templateUrl: 'editor-container.component.html'
})
export class EditorContainerComponent {}
