import { EditorAction } from '../action';
import { FLIP_HORIZONTAL } from './canvas.action.types';

export class FlipHorizontal implements EditorAction {
  readonly type = FLIP_HORIZONTAL;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public flip: boolean) {}
}
