import { Component, Input } from '@angular/core';
import { UploadComponent } from './upload.component';
import { ImageUploadService } from '../../../services/image-upload.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { MatDialog } from '@angular/material/dialog';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-image-upload-mob',
  templateUrl: 'image-upload-mob.component.html',
  styleUrls: ['image-upload-mob.component.scss', '../../nav/nav-buttons.component.scss'],
  providers: [ImageUploadService]
})
export class ImageUploadMobComponent extends UploadComponent {
  @Input() icon: string;

  constructor(
    protected imageUploadService: ImageUploadService,
    protected store: Store<AppState>,
    public dialog: MatDialog,
    public getTextService: GetTextService
  ) {
    super(imageUploadService, dialog, getTextService);
  }
}
