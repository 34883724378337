import { ImageLibraryActions, ImageLibraryActionsTypes } from './actions';
import { ImageCategory } from '../models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImageLibrary, ImageLibraryType } from './image-library';
import { imageLibrary } from '../data';

export interface ImageLibraryState {
  imagelibrary: ImageLibrary;
  error: string | null;
  pending: boolean;
  open: boolean;
  savedLocation: number[]; // category path
  savedSearchTerm: string;
  savedImagelibrary: ImageLibrary;
}

export const initialState: ImageLibraryState = {
  imagelibrary: new ImageLibrary(new Array<ImageCategory>()),
  error: null,
  pending: true,
  open: false,
  savedLocation: [],
  savedSearchTerm: '',
  savedImagelibrary: null
};

export const demoLibrary: ImageLibraryState = {
  imagelibrary: new ImageLibrary([
    new ImageCategory({ imageType: ImageLibraryType.image, subcategories: imageLibrary.categories }, [0])
  ]),
  error: null,
  pending: true,
  open: false,
  savedLocation: [0, 0],
  savedSearchTerm: '',
  savedImagelibrary: null
};

export function reducer(state = initialState, action: ImageLibraryActions): ImageLibraryState {
  switch (action.type) {
    case ImageLibraryActionsTypes.LoadImageLibrary: {
      return {
        ...state,
        pending: false,
        imagelibrary: action.imageLibrary
      };
    }

    case ImageLibraryActionsTypes.LoadImageLibraryFailure: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ImageLibraryActionsTypes.ToggleImageLibrary: {
      return {
        ...state,
        open: action.open
      };
    }

    case ImageLibraryActionsTypes.Update: {
      const categoryIndex = state.imagelibrary.categories.findIndex(cat => cat.imageType === action.imageType);
      const categories = [...state.imagelibrary.categories];
      const newCategory = new ImageCategory(
        {
          imageType: action.imageType,
          subcategories: [
            { imageType: action.imageType, name: action.name, images: action.images },
            ...categories[categoryIndex].subcategories
          ]
        },
        [...categories[categoryIndex].path]
      );

      categories.splice(categoryIndex, 1, newCategory);
      return {
        ...state,
        imagelibrary: new ImageLibrary(categories)
      };
    }

    case ImageLibraryActionsTypes.SaveLocation: {
      return {
        ...state,
        savedLocation: action.location
      };
    }

    case ImageLibraryActionsTypes.SaveSearch: {
      return {
        ...state,
        savedSearchTerm: action.searchTerm,
        savedImagelibrary: action.imageLibrary
      };
    }

    default: {
      return state;
    }
  }
}

export const selectLibraryState = createFeatureSelector<ImageLibraryState>('imagelibrary');

export const getError = createSelector(selectLibraryState, (state: ImageLibraryState) => state.error);

export const getPending = createSelector(selectLibraryState, (state: ImageLibraryState) => state.pending);

export const getImageLibraryOpen = createSelector(selectLibraryState, (state: ImageLibraryState) => state.open);

export const getMainCategory = (type: ImageLibraryType) =>
  createSelector(selectLibraryState, (state: ImageLibraryState) =>
    state.imagelibrary.categories.find(cat => cat.imageType === type)
  );

export const getSavedSearchTerm = createSelector(
  selectLibraryState,
  (state: ImageLibraryState) => state.savedSearchTerm
);

export const getSavedImagelibrary = createSelector(
  selectLibraryState,
  (state: ImageLibraryState) => state.savedImagelibrary
);
