import { ImageElement } from './image-element';
import { TextElement } from './text-element';
import { CanvasElement, ElementType, LayerType } from './canvas';
import { PhotoFrameElement } from './photo-frame-element';

// paper (white for now)
// background -> color + background image
// background image (spread could have background with background image per background and background image of spread)
// normal elements images/text/photoframes etc
// toplayer elements
// foil elements
// not printable elements (only elements which could be on top of foil)
// inside each layer order property of element will determine order

const isImage = (el: any): el is ImageElement | TextElement =>
  el.type === ElementType.image || el.type === ElementType.text || el.type === ElementType.inlineText;
const isPhotoFrame = (el: any): el is PhotoFrameElement => el.type === ElementType.photoFrame;

const isNotPrintable = (el: CanvasElement) => !el.combinedPermissions.isPrintable && el.combinedPermissions.isTopLayer;
const isCutThrough = (el: CanvasElement) => el.isCutThrough || el.isCutThroughInverted;
const isKissCut = (el: CanvasElement) => el.isLayerableElement() && el.layerType === LayerType.kissCut;
const isCrease = (el: CanvasElement) => el.isLayerableElement() && el.layerType === LayerType.crease;
const isPerforation = (el: CanvasElement) => el.isLayerableElement() && el.layerType === LayerType.perforation;
export const hasFoil = (el: CanvasElement) =>
  (isImage(el) && !!el.foilType) || (isPhotoFrame(el) && !!el.firstChild && !!el.firstChild.foilType);
export const hasSpotUv = (el: CanvasElement) =>
  (isImage(el) && !!el.spotUv) || (isPhotoFrame(el) && !!el.firstChild && !!el.firstChild.spotUv);
const isTopLayer = (el: CanvasElement) => el.combinedPermissions.isTopLayer;
const isBackgroundImage = (el: CanvasElement) => el.isSpreadBackgroundImage();
const isBackground = (el: CanvasElement) => el.isBackgroundElement();

// none of the other layers
const isNormalElement = (el: CanvasElement) =>
  !(el.isLayerableElement() && el.layerType !== LayerType.standardRgb) &&
  !isBackground(el) &&
  !isBackgroundImage(el) &&
  !isTopLayer(el) &&
  !hasFoil(el) &&
  !hasSpotUv(el) &&
  !isNotPrintable(el);

// loop over layers, order in layers array is order on canvas, if both elements are in same layer, element.order will be used.
export const LAYERS = [
  isNotPrintable,
  isCutThrough,
  isKissCut,
  isCrease,
  isPerforation,
  hasFoil,
  hasSpotUv,
  isTopLayer,
  isNormalElement,
  isBackgroundImage,
  isBackground
];

export function sortLayer(a: CanvasElement, b: CanvasElement, compareFunction: (el: CanvasElement) => boolean): number {
  const firstElementInLayer = compareFunction(a);
  const secondElementInLayer = compareFunction(b);

  if (firstElementInLayer && !secondElementInLayer) {
    return 1;
  } else if (!firstElementInLayer && secondElementInLayer) {
    return -1;
  } else if (firstElementInLayer && secondElementInLayer) {
    return a.order - b.order;
  } else {
    // !propertyA && !propertyB
    return undefined;
  }
}

export function getLayerSiblings(element: CanvasElement): Array<CanvasElement> {
  // find layer of selected element
  const layer = LAYERS.find(_layer => _layer(element));

  // find siblings in same layer
  const siblings = element.parent.children.filter((el: CanvasElement) => layer(el)) as CanvasElement[];

  return siblings.sort((a, b) => a.order - b.order);
}
