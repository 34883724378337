import { EditorAction } from '../action';
import { INIT_VISIBLE_PAGE_IMAGES } from './canvas.action.types';

export class InitVisiblePageImages implements EditorAction {
  readonly type = INIT_VISIBLE_PAGE_IMAGES;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: number[]) {}
}
