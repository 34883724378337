import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { editGeneralOption, editStyleOption } from '../../../../../react-text-editor/models/text-editor.model';
import { EditOption } from './edit-inline-text-mob.component';
import { EditInlineTextMobComponent } from './edit-inline-text-mob.component';
import { CanvasActions } from '../../../actions';
import { InlineTextElement } from '../../../models';
import { ConfigService, GetTextService, UiService } from '../../../services';
import { TextEditorService } from '../../../text-editor/text-editor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { DomSanitizer } from '@angular/platform-browser';

export type editOptionName =
  | editStyleOption.font
  | editStyleOption.fontColor
  | editStyleOption.fontSize
  | editStyleOption.fontStyle
  | editStyleOption.lineHeight
  | editStyleOption.transparency
  | editStyleOption.horizontalAlign
  | editStyleOption.text
  | editGeneralOption.rotate
  | editGeneralOption.flippable
  | editGeneralOption.order;

export type EditElementOptions = { [E in editOptionName]: EditOption };

@Component({
  selector: 'ed-edit-inline-text-mob-general-options',
  templateUrl: './edit-inline-text-mob-general.component.html',
  styleUrls: ['../edit-mob.component.scss', './edit-inline-text-mob-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInlineTextMobGeneralOptionsComponent extends EditInlineTextMobComponent {
  editOptions: EditElementOptions = {
    flippable: new EditOption(editGeneralOption.flippable),
    font: new EditOption(editStyleOption.font),
    fontColor: new EditOption(editStyleOption.fontColor),
    fontSize: new EditOption(editStyleOption.fontSize),
    fontStyle: new EditOption(editStyleOption.fontStyle),
    horizontalAlign: new EditOption(editStyleOption.horizontalAlign),
    lineHeight: new EditOption(editStyleOption.lineHeight),
    order: new EditOption(editGeneralOption.order),
    rotate: new EditOption(editGeneralOption.rotate),
    text: new EditOption(editStyleOption.text),
    transparency: new EditOption(editStyleOption.transparency)
  };
  buttonCount: number;
  fontListHeight = 200;
  fontListButtonHeight = 38;

  // this is an input element, to be able to trigger the keyboard on ios on initFocus
  @ViewChild('fakeInput', { static: false }) fakeInput: ElementRef;

  @Input()
  set element(element: InlineTextElement) {
    this._element = element;
    this._inlineTextElement = element;
    this.editFullRange = element.editFullRange;

    this.editOptions.flippable.enabled = element.permissions.isFlippable;
    this.editOptions.font.enabled = element.permissions.fontChangeable;
    this.editOptions.fontColor.enabled = element.permissions.isRecolorable;
    this.editOptions.fontSize.enabled = element.permissions.fontResizable;
    this.editOptions.order.enabled = element.permissions.isOrderable;
    this.editOptions.rotate.enabled = element.permissions.isRotatable;
    this.editOptions.transparency.enabled = !element.foilType && element.permissions.adjustTransparency;

    this.buttonCount = Object.keys(this.editOptions).filter(option => this.editOptions[option].enabled).length;
  }

  get element() {
    return this._element;
  }

  constructor(
    public getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected store: Store<AppState>,
    protected config: ConfigService,
    public uiService: UiService,
    protected changeDetector: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) {
    super(getTextService, textEditorService, store, config, uiService, changeDetector);
  }

  openShortMenu() {
    // on ios the only way to manualy trigger the keyboard, is to call focus in a touchstart event handler
    this.fakeInput.nativeElement.focus();
    this.store.dispatch(new CanvasActions.SetInlineTextEditMode(this.element.route, false));
  }

  onTouchMoveGrabber(event: TouchEvent) {
    event.preventDefault();
    this.fontListHeight = window.innerHeight - event.touches[0].clientY - this.fontListButtonHeight / 2;
  }
}
