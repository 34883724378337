import { Injectable } from '@angular/core';
import { AuthenticateEmail, RecoverUser } from '../models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';

@Injectable()
export class RecoverService {
  recoverPasswordUrl: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.recoverPasswordUrl = config.recoverPasswordUrl;
    });
  }

  recoverPassword(userEmail: AuthenticateEmail): Observable<RecoverUser> {
    return this.http.post<RecoverUser>(this.recoverPasswordUrl, userEmail);
  }
}
