import { PageElement } from './page-element';
import { TextElement } from './text-element';
import { ImageElement } from './image-element';
import { BackgroundElement, BoxElement } from './box-element';
import { PhotoFrameElement } from './photo-frame-element';
import { InlineTextElement } from './inline-text-element';

export enum ElementType {
  image,
  text,
  page,
  box,
  photoFrame,
  background,
  backgroundImage,
  inlineText
}

export enum ImgBaseFunc {
  svg = '/svg',
  img = '/img/',
  bg = '/bg?data=',
  txt = '/txt_new',
  inlineTxt = '/rich_text',
  get_font_locations = '/get_font_location_family'
}

export const VA_NEW = 'N';

export type CanvasElement =
  | PageElement
  | TextElement
  | ImageElement
  | BoxElement
  | PhotoFrameElement
  | BackgroundElement
  | InlineTextElement;

export enum DropZone {
  add,
  replace
}

export interface CanvasCoordinate {
  x: number;
  y: number;
}

export interface CanvasDimensions {
  width: number;
  height: number;
}

export type SpecialColorElement = TextElement | ImageElement | InlineTextElement | PhotoFrameElement;

export const RAINBOW_FOIL_PATTERN_ID = 'rainbow_foil_pattern';
export const FOIL_GRADIENT_ID_PREFIX = 'foil_gradient_';
export const SPOT_UV_GRADIENT_ID = 'spot_uv';

export enum LayerType {
  kissCut = 'kissCut',
  crease = 'crease',
  perforation = 'perforation',
  cutThrough = 'cutThrough',
  cutThroughInverted = 'cutThroughInverted',
  standardRgb = 'standardRgb'
}

export const SPOT_LAYERS = [
  LayerType.crease,
  LayerType.kissCut,
  LayerType.cutThrough,
  LayerType.cutThroughInverted,
  LayerType.perforation
];

export type LayerableElement = ImageElement | PhotoFrameElement | TextElement | InlineTextElement;

export const PERMISSION_TO_LAYER_MAPPING = {
  isCutThrough: LayerType.cutThrough,
  cutThroughInverted: LayerType.cutThroughInverted,
  isKissCut: LayerType.kissCut,
  isCrease: LayerType.crease,
  isPerforation: LayerType.perforation
};

export const ROTATION_RANGE = [-180, -90, 0, 90, 180];
