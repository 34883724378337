export const backspaceKey = 'Backspace';
export const enterKey = 'Enter';
export const spacebarKey = 'Spacebar';
export const whiteSpace = ' ';
export const arrowUpKey = 'ArrowUp';
export const arrowDownKey = 'ArrowDown';
export const arrowLeftKey = 'ArrowLeft';
export const arrowRightKey = 'ArrowRight';
export const deleteKey = 'Delete';
export const yKey = 'y';
export const zKey = 'z';
export const cKey = 'c';
export const vKey = 'v';
export const xKey = 'x';
export const sKey = 's';
