import { fabric } from "fabric";
import { isTinyObject } from "../../../utils/object.utils";

const size = 24;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle fill="#0008" cx="12" cy="12" r="7" />
    <circle fill="#FFF" cx="12" cy="12" r="6" stroke="#0008" stroke-width="0.2" stroke-opacity="0.7" />
  </svg>
`);

const tl: Partial<fabric.Control> = {
  getVisibility,
  render
};

Object.assign(fabric.Object.prototype.controls.tl, tl);
Object.assign(fabric.Textbox.prototype.controls.tl, tl);

const tr: Partial<fabric.Control> = {
  getVisibility,
  render
};

Object.assign(fabric.Object.prototype.controls.tr, tr);
Object.assign(fabric.Textbox.prototype.controls.tr, tr);

const br: Partial<fabric.Control> = {
  getVisibility,
  render
};

Object.assign(fabric.Object.prototype.controls.br, br);
Object.assign(fabric.Textbox.prototype.controls.br, br);

const bl: Partial<fabric.Control> = {
  render
};

Object.assign(fabric.Object.prototype.controls.bl, bl);
Object.assign(fabric.Textbox.prototype.controls.bl, bl);

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isTinyObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}
