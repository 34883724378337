import { BaseElement } from './base-element';
import { ElementType, LayerType } from './canvas';
import { FoilTypes } from './color';
import { ElementPermissions } from './permissions';
import { uniq } from 'lodash-es';
import { DEFAULT_INLINE_TEXT_PADDING, SVG_VIEWBOX_SCALE } from '../../../react-text-editor/models/text-editor.model';

export const DEFAULT_INLINE_TEXT_DESCENDER = 0.2;
export const DEFAULT_INLINE_TEXT_ASCENDER = 1;
export const MINIMUM_FONTSIZE_FOR_SCALING = 35; // absolute
export const MAX_CHAR_TEXT = 10000;
export const MAX_CHAR_TEXT_SPAN = MAX_CHAR_TEXT / 10;
export const SHRINK_TEXT_RATIO = 0.95;
export const GROW_TEXT_RATIO = 1 / 0.95;

export class SelectionPoint {
  path: number[] = [0, 0, 0];
  offset = 0;
}

export class EditorSelection {
  start = new SelectionPoint();
  end = new SelectionPoint();
  isFocused = false;
}

export class SpanPart {
  constructor(public text: string, public x: number) {}
}

export const DefaultFont = {
  name: 'Arial',
  ascender: 0.9,
  descender: 0.2
};

export const DefaultFontSize = 15;
export const DefaultFontColor = '#000000';

export class TextSpan {
  public text = '';
  public fontSize = DefaultFontSize;
  public font = DefaultFont.name;
  public color = DefaultFontColor;
  public bold = false;
  public italic = false;
  public underlined = false;
  public opacity = 1;
  public spanParts: SpanPart[] = [];
  public foil: FoilTypes = undefined;
  public spotUv = false;
}

export class Line {
  public y = 20;
  public wordSpacing: number = undefined;
  public textSpans: TextSpan[] = [new TextSpan()];
  public currentWidth?: number;
  public previousWidth?: number;
  public initialFontsize?: number;
}

export class Paragraph {
  public textAlign: 'start' | 'middle' | 'end' = 'middle';
  public lineHeight = 1;
  public isJustified = false;
  public lines: Line[] = [new Line()];
}

export const MarkTypes = {
  Bold: 'bold',
  Italic: 'italic',
  Underlined: 'underlined',
  Font: 'font',
  Fontsize: 'fontSize',
  Color: 'color',
  Lineheight: 'lineHeight',
  Opacity: 'opacity',
  Foil: 'foil',
  SpotUv: 'spotUv'
};

// page width for which padding is equal to DEFAULT_INLINE_TEXT_PADDING
export const BASELINE_PAGEWIDTH = 85;

export class InlineTextElement extends BaseElement {
  readonly type = ElementType.inlineText;
  readonly isSvg = true;
  textNotEdited: boolean;
  _foilType: FoilTypes;
  _spotUv: boolean;
  selection = new EditorSelection();
  editFullRange = true;
  init = false;
  resized = false;
  padding = DEFAULT_INLINE_TEXT_PADDING;
  layerType: LayerType = LayerType.standardRgb;
  characterLimit: number;

  get fonts(): string[] {
    return uniq(this.text.flatMap(paragraph => paragraph.lines.flatMap(line => line.textSpans.map(span => span.font))));
  }

  constructor(id: number, { width = 300, height = 44, x = 0, y = 0, text = [new Paragraph()], trans = 0 } = {}) {
    super(id);
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.text = text;
    this.transparency = trans;
    this.tag = '';
  }

  set color(color: string) {
    this.text.forEach(par => par.lines.forEach(line => line.textSpans.forEach(span => (span.color = color))));
  }

  get colors(): string[] {
    return uniq(this.text.flatMap(par => par.lines.flatMap(line => line.textSpans.map(span => span.color))));
  }

  set foilType(foil: FoilTypes) {
    this.text.forEach(par => par.lines.forEach(line => line.textSpans.forEach(span => (span.foil = foil))));
    this._foilType = foil;
  }

  get foilType() {
    return this._foilType;
  }

  set spotUv(spotUv: boolean) {
    this.text.forEach(par => par.lines.forEach(line => line.textSpans.forEach(span => (span.spotUv = spotUv))));
    this._spotUv = spotUv;
  }

  get spotUv(): boolean {
    return this._spotUv;
  }

  get hasText(): boolean {
    return !!this.text.find(par => par.lines.find(line => line.textSpans.find(span => span.text.trim() !== '')));
  }

  get textValue(): string {
    return this.text
      .reduce(
        (acc, par) => acc.concat('\n', par.lines.flatMap(line => line.textSpans.map(span => span.text)).join('')),
        ''
      )
      .slice(1);
  }

  get fontsizes(): number[] {
    return uniq(this.text.flatMap(par => par.lines.flatMap(line => line.textSpans.map(span => span.fontSize))));
  }

  permissions: ElementPermissions = {
    isLocked: false,
    isMovable: true,
    isRemovable: true,
    isRotatable: true,
    isResizable: true,
    isDuplicatable: true,
    isFoilable: false,
    isFlippable: true,
    isPrintable: true,
    isTopLayer: false,
    isUntargetable: false,
    adjustTransparency: true,
    hasCoating: false,
    isSpotUvable: true,
    isOrderable: true,
    shrinkToFit: false,
    excludeFromPreviews: false,
    isVisibleOutsideCropArea: false,
    isExternallyEditable: false,
    fontChangeable: true,
    fontResizable: true,
    isRecolorable: true,
    textEditable: true
  };

  // paragraph -> line -> span
  // a paragraph is a piece of text containing 1 or more lines and starts
  // after an explicit line break from a user (pressing enter)
  // a line in a paragraph is a piece of text of a paragraph that fits on a
  // line in a text element, after implicit line breaks. (changing size of text element, fontsize, font etc)
  // a span is the actual piece of text, text is always in a span. it's a piece of text on a line containing the same style

  // text align is always for a whole paragraph
  // line height always for a whole line
  // all other styling is part of the span for now.
  text: Paragraph[] = [];

  get regularScalePadding() {
    return this.padding / SVG_VIEWBOX_SCALE;
  }

  get imgParams() {
    return {
      text: this.text,
      width: this.width,
      height: this.height,
      padding: this.padding
    };
  }

  select(): void {
    super.select();
  }

  deselectAll() {
    this.editFullRange = true;
    super.deselectAll();
  }

  resize(x: number, y: number, width: number, height: number, scaleToRatio?: boolean) {
    const scaleWidth = width / this.screenWidth;
    const smallestFontSize = Math.min(...this.fontsizes);
    const minFontSizeReached = smallestFontSize >= MINIMUM_FONTSIZE_FOR_SCALING;
    const sizeIncreasing = scaleWidth > 1;

    if (scaleToRatio && (minFontSizeReached || sizeIncreasing)) {
      this.text.forEach(p =>
        p.lines.forEach(l => {
          l.y = l.y * scaleWidth;
          l.textSpans.forEach(s => (s.fontSize = s.fontSize * scaleWidth));
        })
      );
    }

    this.screenHeight = height;
    this.screenWidth = width;
    this.x = x;
    this.y = y;
  }
}
