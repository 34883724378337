import {
  BackgroundElement,
  BackgroundImageElement,
  Color,
  FunctionPermission,
  ImageCategory,
  ImageElement,
  Material,
  PageElement,
  Trim,
  TrimTypes
} from '../../../models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageData } from '../../../services/image-upload.service';
import { CanvasActions } from '../../../actions';
import { AppState } from '../../../reducers';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GetTextService } from '../../../services';
import { DialogItem, PreviewType } from '../edit-option/edit-option-dialog/edit-option-dialog.component';
import { getImageBase, getShowMaterialOption, getShowTrimOption } from '../../../config/reducer';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { map } from 'rxjs/operators';
import { getMainCategory } from '../../../image-library/reducer';
import { ImageLibraryType } from '../../../image-library/image-library';
import { TooltipPosition } from '@angular/material/tooltip';
import { selectDesign } from '../../../selectors';

@Component({
  selector: 'ed-background-panel',
  templateUrl: 'background-panel.component.html',
  styleUrls: ['background-panel.component.scss', '../edit.component.scss', '../../../shared/button/buttons.scss']
})
export class BackgroundPanelComponent implements OnInit {
  mainCategory$: Observable<ImageCategory>;
  imageLibrarySavedLocation$: Observable<number[]>;

  functionPermissions = FunctionPermission;

  designSubscription$: Subscription;

  showTrimOption$: Observable<boolean>;
  showMaterialOption$: Observable<boolean>;
  selectedTrimType: TrimTypes;
  material: Material;
  trimOptions$: Observable<Trim[]>;

  materialOptions$: Observable<Material[]>;
  imageBase$: Observable<string>;

  tooltipShowDelay$: Observable<number>;
  tooltipPosition: TooltipPosition = 'below';

  private _visiblePage: PageElement;

  @Input() closeColorDialog: boolean;
  @Input() imageLibraryOpen = false;
  @Input() backgroundLocked: boolean;
  @Output() toggleImageLibrary = new EventEmitter<boolean>();

  @Input() showMoreOptions = false;
  @Output() showMoreOptionsChange = new EventEmitter<boolean>();

  @Input() set visiblePage(visiblePage: PageElement) {
    if (visiblePage) {
      this._visiblePage = visiblePage;
      this._background = visiblePage.background;
    }
  }

  get visiblePage() {
    return this._visiblePage;
  }

  _background: BackgroundElement | ImageElement | BackgroundImageElement;

  get backgroundColor() {
    if (this.background && this.background.isBackgroundElement()) {
      return new Color('', this.background.color);
    } else if (this.background && this.background.isPageBackgroundImage()) {
      return new Color('', this.background.parent.color);
    } else if (
      (!this.background || this.background.isSpreadBackgroundImage()) &&
      this.identicalColorsBackgroundElements
    ) {
      return new Color('', this.visiblePage.backgroundElements[0].color);
    } else if (
      (!this.background || this.background.isSpreadBackgroundImage()) &&
      !this.identicalColorsBackgroundElements
    ) {
      return new Color('', '', undefined, true);
    } else {
      return new Color('', '');
    }
  }

  get identicalColorsBackgroundElements() {
    if (this.visiblePage) {
      return this.visiblePage.backgroundElements.every((element, index, array) => element.color === array[0].color);
    }
  }

  get background() {
    return this._background;
  }

  get backgroundImage() {
    const isBgImage = this.background && this.background.isBackgroundImage();
    const isBgElement = this.background && this.background.isBackgroundElement();
    const spreadImage = this.visiblePage && this.visiblePage.spreadBackgroundImage;

    return isBgImage ? this.background : isBgElement && spreadImage ? spreadImage : undefined;
  }

  get imageSrc() {
    return this.backgroundImage && this.backgroundImage.imgSource
      ? this.sanitizer.bypassSecurityTrustUrl(this.backgroundImage.imgSource)
      : '';
  }

  get backgroundImageIsReplaceable() {
    return this.backgroundImage ? this.backgroundImage.permissions.isReplaceable : true;
  }

  constructor(
    protected store: Store<AppState>,
    protected sanitizer: DomSanitizer,
    public getTextService: GetTextService
  ) {}

  ngOnInit() {
    this.imageLibrarySavedLocation$ = this.store.pipe(select(s => s.imagelibrary.savedLocation));
    this.mainCategory$ = this.store.pipe(select(getMainCategory(ImageLibraryType.background)));

    const design$ = this.store.pipe(select(selectDesign));
    this.designSubscription$ = design$.subscribe(design => {
      this.selectedTrimType = design.trimType;
      this.material = design.material;
    });

    this.trimOptions$ = this.store.pipe(select(s => s.config.trimData));

    // for now design with only 1 page has fold too, because logic is the same
    const designHasFold$ = design$.pipe(map(design => design.numberOfPages !== 2));
    this.materialOptions$ = combineLatest([this.store.pipe(select(s => s.config.materials)), designHasFold$]).pipe(
      map(([materials, hasFold]) => materials.filter(material => !hasFold || material.isFoldable))
    );

    this.imageBase$ = this.store.pipe(select(getImageBase));

    this.showMaterialOption$ = combineLatest([
      this.store.pipe(select(fromPermissions.getFunctionPermissions)),
      this.store.pipe(select(getShowMaterialOption))
    ]).pipe(map(([permissions, showMaterial]) => permissions.canChangeMaterial && showMaterial));

    this.showTrimOption$ = combineLatest([
      this.store.pipe(select(fromPermissions.getFunctionPermissions)),
      this.store.pipe(select(getShowTrimOption))
    ]).pipe(map(([permissions, showTrim]) => permissions.canChangeBorderTrim && showTrim));
  }

  activateImageLibrary(event: boolean) {
    this.toggleImageLibrary.emit(event);
  }

  chooseColor(color: Color) {
    this.store.dispatch(new CanvasActions.ChangeBackgroundColor(color.colorValue));
  }

  chooseImage(event: any) {
    this.store.dispatch(new CanvasActions.CheckDoubleBackgroundImage(event.width, event.height, event.sid, event.url));
    this.activateImageLibrary(false);
  }

  addUploadedImage() {
    return (imageData: ImageData) => {
      this.store.dispatch(
        new CanvasActions.CheckDoubleBackgroundImage(imageData.width, imageData.height, imageData.sid)
      );
      this.activateImageLibrary(false);
    };
  }

  removeBackgroundImage() {
    this.store.dispatch(new CanvasActions.DeActivateOverlay(this.backgroundImage.route));
    this.store.dispatch(new CanvasActions.RemoveElement(this.backgroundImage.route));
  }

  changeTrim(trim: Trim) {
    this.store.dispatch(new CanvasActions.ChangeTrim(trim));
  }

  changeMaterial(material: DialogItem) {
    this.store.dispatch(new CanvasActions.SetMaterial(material as Material));
  }

  toggleMoreOptions() {
    this.showMoreOptionsChange.emit(this.showMoreOptions);
  }
}
