import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { InlineTextElement } from '../models';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as fromPermissions from '../reducers/permissions.reducer';
import { selectDesign } from '../selectors';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class UiService {
  public inlineTextOnMobile$: Observable<boolean>;
  public shortEditingInlineText$: Observable<boolean>;
  public showElementSettings$: Observable<boolean>;
  public showStyleMenu$: Observable<boolean>;
  public useAlternativeInputInlineText$: Observable<boolean>;

  public textEditorActive$ = new BehaviorSubject<boolean>(false);

  private alternativeInputValue$ = new BehaviorSubject<string>('');
  private _alternativeInputValue: string;

  public isResizing$ = new BehaviorSubject<boolean>(false);
  private _isResizing = false;

  constructor(private store: Store<AppState>, public breakpointObserver: BreakpointObserver) {
    const selectedElement$ = store.pipe(
      select(selectDesign),
      filter(d => !!d),
      map(d => d.selectedElement)
    );

    // TODO: Replace hard coded media queries with media queries from config or css variables

    const isMobile$ = this.breakpointObserver.observe('(max-width: 991.92px)').pipe(
      map(result => result.matches),
      distinctUntilChanged()
    );

    const isAndroid$ = store.pipe(
      select(s => s.config.isAndroid),
      distinctUntilChanged()
    );

    const canAddTextInline$ = store.pipe(select(fromPermissions.canAddTextInline), distinctUntilChanged());

    this.inlineTextOnMobile$ = combineLatest([selectedElement$, isMobile$]).pipe(
      map(([selectedElement, isMobile]) => !!(isMobile && selectedElement && selectedElement.isInlineText())),
      distinctUntilChanged()
    );

    this.shortEditingInlineText$ = combineLatest([selectedElement$, this.inlineTextOnMobile$]).pipe(
      map(
        ([element, inlineTextOnMobile]) =>
          !!inlineTextOnMobile && element && !(element as InlineTextElement).editFullRange
      ),
      distinctUntilChanged()
    );

    this.showElementSettings$ = store.pipe(
      select(s => s.uiState.showElementSettingsMenu),
      distinctUntilChanged()
    );

    this.showStyleMenu$ = store.pipe(
      select(s => s.uiState.showStyleMenu),
      distinctUntilChanged()
    );

    this.useAlternativeInputInlineText$ = combineLatest([canAddTextInline$, isAndroid$]).pipe(
      map(([canAddTextInline, isAndroid]) => isAndroid && canAddTextInline),
      distinctUntilChanged()
    );

    this.alternativeInputValue$.subscribe(value => (this._alternativeInputValue = value));

    this.isResizing$.subscribe(value => (this._isResizing = value));
  }

  set alternativeInputValue(value: string) {
    this.alternativeInputValue$.next(value);
  }

  get alternativeInputValue() {
    return this._alternativeInputValue;
  }

  get isResizing() {
    return this._isResizing;
  }
}
