import { UiState } from '../models';
import { UiActions, UiActionTypes } from '../actions';

export function uiReducer(state: UiState, action: UiActions.All): UiState {
  switch (action.type) {
    case UiActionTypes.TOGGLE_SETTINGS_MENU: {
      return {
        ...state,
        showElementSettingsMenu: action.showElementSettingsMenu
      };
    }

    case UiActionTypes.TOGGLE_STYLE_MENU: {
      return {
        ...state,
        showStyleMenu: action.showStyleMenu
      };
    }

    default: {
      return state;
    }
  }
}
