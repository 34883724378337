import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Inject, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { debounce } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { CanvasActions } from 'src/app/actions';
import { CanvasCoordinate, CanvasElement, Design, ElementType, FunctionPermission, PageElement, RAINBOW_FOIL_PATTERN_ID } from 'src/app/models';
import { DesignSet } from 'src/app/models/design-set';
import { IRectangle } from 'src/app/models/ngresizable.interfaces';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services';
import { Label } from 'src/app/shared/label/label.component';
import { ButtonStyles } from 'src/app/shared/navigation-buttons/navigation-buttons.component';
import { environment } from 'src/environments/environment';
import * as fromPermissions from '../../reducers/permissions.reducer';
import { ViewportBoundaries } from './canvas/models/viewport-boundaries';
import * as CanvasElementUtils from './canvas/utils/canvas-element.utils';
import { AiService } from 'src/app/services/';

export interface DesignArea extends IRectangle {
  pixelsPerMm: number;
}

@Component({
  selector: 'ed-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements AfterViewInit {
  @HostBinding('style.background-color')
  designBackgroundColor = '#f5f5f5';

  readonly environment = environment;
  readonly permissions = FunctionPermission;
  readonly elementType = ElementType;

  private _design: Design;
  private _designSet: DesignSet;
  fabricCanvas: fabric.Canvas;

  visiblePage: PageElement;
  allDesignPages: PageElement[];
  childrenFullRange: CanvasElement[];

  showPageOverlay: boolean;
  showPageOverlayTimeout: number;
  isTouchDevice: boolean;
  buttonStyles = ButtonStyles;
  viewInit = false;
  debounceFillPage = debounce(() => this.store.dispatch(new CanvasActions.FillPage(this.getDesignRectangle())), 200);
  debounceInitDesign = debounce(() => this.store.dispatch(new CanvasActions.InitDesign(this.getDesignRectangle())), 100);
  configSubscription: Subscription;

  patternImage: string;
  patternId = RAINBOW_FOIL_PATTERN_ID;

  labelOffset = 30;
  pageToggleButtonOffset = 60;

  zoom: number;

  viewportTransform: number[];

  viewportBoundaries: ViewportBoundaries;

  canAddLabels$: Observable<boolean>;
  useInlineText$: Observable<boolean>;


  removeBackground() {
    console.log('aiService:', this.aiService); // This should not be undefined
    console.log('selectedElement:', this.selectedElement); // Verify this is also defined
    console.log('blob file: ', this.selectedElement.imgSource);
  
    if (this.aiService && this.selectedElement && this.selectedElement.imgSource) {
      this.aiService.removeBackground(this.selectedElement.imgSource).subscribe({
        next: (response: any) => {
          console.log('Background removal response:', response);
          if (response && response.image) {
            console.log('Processed image URL:', response.image);
  
          // Assuming newImageData should have specific properties that your app needs
          // Since a blob doesn't directly give these properties, you must handle them separately
          const newImageData = {
            width: 100,  // Example width
            height: 100, // Example height
            sid: 'newSid', // Example session id or unique identifier
            url:  response.image, // URL created from the blob
            x: 0, // Example x position
            y: 0, // Example y position
            isFoilable: false // Example property
          };
  
          // Now use the image URL from the response
          this.store.dispatch(new CanvasActions.AddImage(200, 200, undefined, newImageData.url));
        } else {
          console.error('No image URL in the response');
        }
      },
      error: (error) => {
        console.error('Failed to remove background:', error);
      }
    });
    } else {
      console.error('aiService or selectedElement is undefined');
    }
  }
  
  

  @Input()
  set designSet(designSet: DesignSet) {
    const design = designSet.activeDesign;
    this._design = design;
    this._designSet = designSet;

    const visiblePage = design.visiblePage;
    this.visiblePage = visiblePage;

    const allDesignPages = designSet.designs.flatMap(d => d.pages);
    this.allDesignPages = allDesignPages;
    this.childrenFullRange = allDesignPages.reduce((acc, page) => {
      const canvasElements = CanvasElementUtils.getChildrenFullRange(page);
      return [...acc, ...canvasElements];
    }, []);

    if (design.selectedElement && design.selectedElement.active) {
      // get active element at the moment callback is executed, active element might have changed during timeout.
      this.showPageOverlayTimeout = setTimeout(() => (this.showPageOverlay = this.hasActiveElement()), 300);
    } else {
      this.showPageOverlay = false;
      clearTimeout(this.showPageOverlayTimeout);
    }

    // if new design fill out page
    if (!this.designSet.init && this.viewInit) {
      this.debounceInitDesign()
    }
  }

  @Input()
  showNewMobileDesign: boolean;

  get designSet() {
    return this._designSet;
  }

  get design() {
    return this._design;
  }

  get selectedElement() {
    return this.visiblePage.selectedElement as CanvasElement;
  }

  constructor(
    private store: Store<AppState>,
    private elementRef: ElementRef<HTMLElement>,
    public config: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private aiService: AiService // Make sure aiService is added here

  ) {
    this.canAddLabels$ = this.store.pipe(select(fromPermissions.getCanAddLabels));
    this.useInlineText$ = this.store.pipe(select(fromPermissions.canAddTextInline));
    this.isTouchDevice = this.config.isTouchDevice;
  }

  ngOnInit() {
    this.configSubscription = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.patternImage = config.rainbowBackgroundUrl;
    });
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
    if (!this.designSet.init) {
      this.store.dispatch(new CanvasActions.InitDesign(this.getDesignRectangle()));
    } else {
      this.debounceFillPage();
    }
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
  }

  @HostListener('window:resize')
  recalculateDesignArea() {
    if (this.viewInit) {
      this.designSet.init = false;
      this.debounceFillPage();
    }
  }

  setLabel(label: Label) {
    this.store.dispatch(new CanvasActions.SetLabel(label.value, label.index));
  }

  trackById(index: number, item: CanvasElement) {
    return item.id;
  }

  trackBySetId(index: number, item: Design) {
    return item.setId;
  }

  trackByRoute(_index: number, item: CanvasElement) {
    return item.route.join('');
  }

  private hasActiveElement(): boolean {
    return this.design.selectedElement && this.design.selectedElement.active;
  }

  private getDesignRectangle(correctionValue = 0) {
    let boundingClientRect = this.elementRef.nativeElement.getBoundingClientRect();
    let absolutePosition = this.getAbsolutePosition();

    let el = document.createElement('div');
    el.style.width = '1cm';
    document.body.appendChild(el);

    let pixelsPerMm = el.offsetWidth / 10;
    document.body.removeChild(el);

    return {
      x: absolutePosition.x + correctionValue,
      y: absolutePosition.y,
      width: boundingClientRect.width - correctionValue,
      height: boundingClientRect.height,
      pixelsPerMm
    };
  }

  private getAbsolutePosition(): CanvasCoordinate {
    let x = 0;
    let y = 0;
    let el = this.elementRef.nativeElement;

    while (el !== null) {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent as HTMLElement;
    }

    let scrollLeft = window.pageXOffset ? window.pageXOffset : this.document.body.scrollLeft;
    let scrollTop = window.pageYOffset ? window.pageYOffset : this.document.body.scrollTop;

    return {
      x: x + scrollLeft,
      y: y + scrollTop
    };
  }
}
