import { Action } from '@ngrx/store';
import { Color } from '../../models';
import { UPDATE_DESIGN_COLORS } from './canvas.action.types';

export class UpdateDesignColors implements Action {
  readonly type = UPDATE_DESIGN_COLORS;
  undoable = false;
  readonly save = true;

  constructor(public setId: number, public colors: Color[]) {}
}
