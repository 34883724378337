import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, timeout} from 'rxjs/operators';


interface StepResult {
  last_step_of_collection: boolean;
  image: string;
}


@Injectable({
  providedIn: 'root'
})
export class AiService {
  private clipDropUrl = 'https://designer.abracadata.ai/api/clipdrop/image_manipulation/';
  private baseUrl = 'https://designer.abracadata.ai/api';

  constructor(private http: HttpClient) {}

  private getHeaders() {
    return new HttpHeaders({
      'accept': 'application/json',
      'Authorization': 'Token 4d81784df67706c6cf274e64a97aed2b04e9e647', // Ensure this token is securely managed and not hardcoded in production
      'Content-Type': 'application/json',  // Changed to 'application/json' based on your scenario and 'multipart/form-data' for file uploads
      'X-CSRFTOKEN': 'J38swvhrTKOq9lZCuReweOXbBmTvfa9KmN15zaR2EQhxZVZRlnuhfB1noQIHEdgl' // Ensure CSRF token is refreshed/retrieved as needed
    });
  }


  // Method to remove background from an image
  removeBackground(blobUrl: string): Observable<any> {
    return this.convertBlobUrlToBase64(blobUrl)
      .pipe(
        switchMap(base64Image => this.sendImageToApi('remove_bg', base64Image)),
        catchError(this.handleError)
      );
  }

  // Helper method to convert blob URL to Base64
  private convertBlobUrlToBase64(blobUrl: string): Observable<string> {
    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          observer.next(base64String); // No longer stripping the prefix like "data:image/png;base64,"
          observer.complete();
        };
        reader.onerror = error => observer.error('Error reading blob: ' + error);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = error => observer.error('Error fetching blob: ' + error);
      xhr.open('GET', blobUrl);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  // Helper method to send image data to API
  private sendImageToApi(operation: string, base64Image: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Token 4d81784df67706c6cf274e64a97aed2b04e9e647', // Replace with actual token
      'X-CSRFTOKEN': 'Rg3EjnNHuXIywoeLtKAGVScJafFlt1b2u0Whm2nif3bFmYe0kgQrWFgVXJuxS4iD' // Replace with actual CSRF token
    });
    return this.http.post(this.clipDropUrl, { version : "v1" , operation, base64_image: base64Image }, { headers })
      .pipe(
        map(response => response as any),
        catchError(this.handleError)
      );
  }

  sendPrompt(prompt: string): Observable<string> {
    const requestBody = {
      source: "openai",
      prompt: prompt,
      image_size: "1024x1024",
      collection_id: 5
    };
    return this.http.post<{ collection_uuid: string }>(`${this.baseUrl}/collection/execute_collection/`, requestBody, { headers: this.getHeaders() })
      .pipe(
        map(response => response.collection_uuid),
        catchError(this.handleError)
      );
  }

  checkImageStatus(collection_uuid: string): Observable<string> {
    const maxDuration = 90000; // 1.5 minutes in milliseconds

    return timer(0, 5000).pipe(
      switchMap(() => this.http.get<any>(`${this.baseUrl}/collection/collection_outcome/${collection_uuid}/`, { headers: this.getHeaders() })),
      map(data => {
        const result = data.step_results["First Image"]?.find((step: StepResult) => step.last_step_of_collection === true && step.image);
        if (result) {
          return result.image;
        }
        throw 'Image not ready yet'
      }),
      timeout(maxDuration), // Automatically throws an error if maxDuration is exceeded
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error.message || error.error.message);
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}

