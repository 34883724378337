import { fabric } from 'fabric';
import { isSmallObject } from '../../utils/object.utils';

const size = 20;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.75 16.7188C18.0625 17.0312 18.0625 17.5 17.75 17.7812C17.625 17.9375 17.4375 18 17.25 18C17.0312 18 16.8438 17.9375 16.7188 17.7812L12.5312 13.5938C11.4062 14.5 10 15 8.5 15C4.90625 15 2 12.0938 2 8.5C2 4.9375 4.90625 2 8.5 2C12.0625 2 15 4.9375 15 8.5C15 10.0312 14.4688 11.4375 13.5625 12.5312L17.75 16.7188ZM8.5 13.5C11.25 13.5 13.5 11.2812 13.5 8.5C13.5 5.75 11.25 3.5 8.5 3.5C5.71875 3.5 3.5 5.75 3.5 8.5C3.5 11.2812 5.71875 13.5 8.5 13.5ZM11 7.75C11.4062 7.75 11.75 8.09375 11.75 8.5C11.75 8.9375 11.4062 9.25 10.9688 9.25H5.96875C5.5625 9.25 5.25 8.9375 5.25 8.5C5.25 8.09375 5.5625 7.75 5.96875 7.75H11Z"
      fill="#292929" />
  </svg>
`);

const zoomInnerMinus = new fabric.Control({
  actionName: 'zoomInnerMinus',
  x: 0,
  y: .5,
  offsetX: -16,
  offsetY: 28,
  sizeX: 34,
  sizeY: 24,
  cursorStyle: 'pointer',
  visible: false,
  getVisibility,
  render,
  mouseDownHandler,
});

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isSmallObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function mouseDownHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  const options = getOptions(e, transform, x, y);
  transform.target.canvas.fire('object:zoomInnerMinus', options);
  transform.target.fire('zoomInnerMinus', options);
  return true;
}

function getOptions(e: MouseEvent, transform: fabric.Transform, x: number, y: number) {
  const target = transform.target;
  const pointer = new fabric.Point(x, y);

  // @ts-expect-error
  transform.zoomScale = 1 / 1.1;

  return {
    e,
    transform,
    target,
    pointer,
  };
}

fabric.Object.prototype.controls.zoomInnerMinus = zoomInnerMinus;
