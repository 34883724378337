import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationButtonsComponent } from './navigation-buttons.component';

@Component({
  selector: 'ed-navigation-buttons-mob',
  templateUrl: 'navigation-buttons-mob.component.html',
  styleUrls: ['../button/buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationButtonsMobComponent extends NavigationButtonsComponent {}
