import { Injectable } from '@angular/core';
import Hammer from '@egjs/hammerjs';
import propagating from 'propagating-hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';

if (typeof window !== 'undefined') {
  (window as any).Hammer = propagating(Hammer);
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  private _events: string[] = ['pinch pan', 'doubletap', 'tap'];

  constructor() {
    super();
    this.events = this.events.concat(this._events);
  }

  buildHammer(element: HTMLElement): any {
    const mc = super.buildHammer(element) as any;

    mc.set({ touchAction: 'none' });
    mc.get('pinch').set({ enable: true });
    mc.get('doubletap').set({ enable: true, taps: 2 });
    mc.get('tap').set({ enable: true });
    mc.get('rotate').set({ enable: true });
    mc.get('doubletap').recognizeWith(mc.get('tap'));

    // a lot of designers use wacom tablets, press breaks with pen events
    mc.get('press').set({ enable: false });

    mc.get('tap').requireFailure(mc.get('doubletap'));

    return mc;
  }
}
