import { EditorAction } from '../action';
import { FILL_PAGE } from './canvas.action.types';
import { DesignArea } from '../../editor/design/design.component';

export class FillPage implements EditorAction {
  readonly type = FILL_PAGE;
  readonly undoable = false;
  readonly save = true;

  constructor(public rectangle: DesignArea) {}
}
