import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as Auth from '../auth/actions';
import { tap, map, switchMap, catchError } from 'rxjs/operators';

import { RecoverActionTypes, Recover, RecoverSuccess, RecoverFailure } from './actions';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticateEmail, RecoverUser } from '../models/user';
import { RecoverDialogComponent } from './recover-dialog.component';
import { RecoverSuccessDialogComponent } from './recover-success-dialog.component';
import { RecoverService } from './recover.service';
import { GetTextService } from '../services';

@Injectable()
export class RecoverEffects {
  @Effect()
  recover$ = this.actions$.pipe(
    ofType(RecoverActionTypes.Recover),
    map((action: Recover) => action.payload),
    switchMap((email: AuthenticateEmail) =>
      this.recoverService.recoverPassword(email).pipe(
        map(recoverUser => new RecoverSuccess(new RecoverUser(recoverUser.email))),
        catchError(error => of(new RecoverFailure(error.error)))
      )
    )
  );

  @Effect()
  openRecoverModal$ = this.actions$.pipe(
    ofType(RecoverActionTypes.OpenRecoverDialog),
    tap(
      () =>
        (this.recoverDialog = this.dialog.open(RecoverDialogComponent, {
          id: 'recover-password-dialog',
          data: {
            text: {
              dialogText: this.getTextService.text.dialog.recoverPassword,
              buttonText: this.getTextService.text.dialog.button
            }
          }
        }))
    ),
    map(() => new Auth.CloseLoginDialog())
  );

  @Effect({ dispatch: false })
  closeRecoverModal$ = this.actions$.pipe(
    ofType(RecoverActionTypes.CloseRecoverDialog),
    tap(() => this.recoverDialog.close())
  );

  @Effect({ dispatch: false })
  openRecoverSuccessModal$ = this.actions$.pipe(
    ofType(RecoverActionTypes.RecoverSuccess),
    tap(() => this.recoverDialog.close()),
    tap(
      () =>
        (this.recoverSuccesDialog = this.dialog.open(RecoverSuccessDialogComponent, {
          id: 'recover-password-success-dialog',
          data: {
            message: this.getTextService.text.dialog.recoverPasswordSuccess.message,
            loginButtonText: this.getTextService.text.dialog.button.login
          }
        }))
    )
  );

  @Effect({ dispatch: false })
  closeRecoverSuccesModal$ = this.actions$.pipe(
    ofType(RecoverActionTypes.CloseRecoverSuccessDialog),
    tap(() => this.recoverSuccesDialog.close())
  );

  recoverDialog: MatDialogRef<RecoverDialogComponent>;
  recoverSuccesDialog: MatDialogRef<RecoverSuccessDialogComponent>;

  constructor(
    private recoverService: RecoverService,
    private dialog: MatDialog,
    private actions$: Actions,
    private getTextService: GetTextService
  ) {}
}
