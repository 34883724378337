import { Action } from '@ngrx/store';
import { Design } from '../models';
import { ClientError } from '../models/client-error';
import { DesignSet } from '../models/design-set';

export enum ContinueActionTypes {
  Navigate = '[Continue] Navigate',
  Continue = '[Continue] Continue',

  OpenLoginContinueDialog = '[Continue] Open Login Continue Dialog',
  CloseLoginContinueDialog = '[Continue] Close Login Continue Dialog',

  Login = '[Continue] Login',
  LoginSuccess = '[Continue] Login Success',
  CancelLogin = '[Continue] Cancel Login',

  Register = '[Continue] Register',
  RegisterSuccess = '[Continue] Register Success',
  CancelRegister = '[Continue] Cancel Register',

  OpenSaveContinueDialog = '[Continue] Open Save Continue Dialog',
  CloseSaveContinueDialog = '[Continue] Close Save Continue Dialog',
  Save = '[Continue] Save',
  OpenSaveDialog = '[Continue] Open Save Dialog',
  SaveSuccess = '[Continue] Save Success',
  CancelSave = '[Continue] Cancel Save',

  SaveContinueSubmit = '[Continue] Save Continue Submit',
  SaveContinueFailure = '[Continue] Save Continue Failure',
  SaveContinueSuccess = '[Continue] Save Continue Success',

  ContinueWithoutSave = '[Continue] Without Save',

  ContinueToUrl = '[Continue] Continue To URL'
}

export class Navigate implements Action {
  readonly type = ContinueActionTypes.Navigate;

  constructor(public url: string, public retainParams = false) {}
}

export class Continue implements Action {
  readonly type = ContinueActionTypes.Continue;
}

export class OpenLoginContinueDialog implements Action {
  readonly type = ContinueActionTypes.OpenLoginContinueDialog;
}

export class CloseLoginContinueDialog implements Action {
  readonly type = ContinueActionTypes.CloseLoginContinueDialog;
}

export class Login implements Action {
  readonly type = ContinueActionTypes.Login;
}

export class LoginSuccess implements Action {
  readonly type = ContinueActionTypes.LoginSuccess;
}

export class CancelLogin implements Action {
  readonly type = ContinueActionTypes.CancelLogin;
}

export class Register implements Action {
  readonly type = ContinueActionTypes.Register;
}

export class RegisterSuccess implements Action {
  readonly type = ContinueActionTypes.RegisterSuccess;
}

export class CancelRegister implements Action {
  readonly type = ContinueActionTypes.CancelRegister;
}

export class OpenSaveContinueDialog implements Action {
  readonly type = ContinueActionTypes.OpenSaveContinueDialog;
}

export class CloseSaveContinueDialog implements Action {
  readonly type = ContinueActionTypes.CloseSaveContinueDialog;
}

export class Save implements Action {
  readonly type = ContinueActionTypes.Save;
}

export class OpenSaveDialog implements Action {
  readonly type = ContinueActionTypes.OpenSaveDialog;
}

export class SaveSuccess implements Action {
  readonly type = ContinueActionTypes.SaveSuccess;
}

export class CancelSave implements Action {
  readonly type = ContinueActionTypes.CancelSave;
}

export class SaveContinueSubmit implements Action {
  readonly type = ContinueActionTypes.SaveContinueSubmit;
}

export class SaveContinueFailure implements Action {
  readonly type = ContinueActionTypes.SaveContinueFailure;

  constructor(public payload: ClientError[]) {}
}

export class SaveContinueSuccess implements Action {
  readonly type = ContinueActionTypes.SaveContinueSuccess;

  constructor(public designSet: DesignSet) {}
}

export class ContinueWithoutSave implements Action {
  readonly type = ContinueActionTypes.ContinueWithoutSave;
}

export class ContinueToUrl implements Action {
  readonly type = ContinueActionTypes.ContinueToUrl;
}

export type ContinueActions =
  | Navigate
  | Continue
  | OpenLoginContinueDialog
  | CloseLoginContinueDialog
  | Login
  | LoginSuccess
  | CancelLogin
  | Register
  | RegisterSuccess
  | CancelRegister
  | OpenSaveContinueDialog
  | CloseSaveContinueDialog
  | Save
  | OpenSaveDialog
  | SaveSuccess
  | CancelSave
  | SaveContinueSubmit
  | SaveContinueFailure
  | SaveContinueSuccess
  | ContinueWithoutSave
  | ContinueToUrl;
