import { CanvasElement, PageElement } from "src/app/models";

export function getChildrenFullRange(element: CanvasElement): CanvasElement[] {
  if (!element) {
    return [];
  }
  return [element, ...element.children.flatMap((childElement) => getChildrenFullRange(childElement))];
}

export function getRotation(angle: number): number {
  return angle > 180 ? (180 - (angle - 180)) * -1 : angle;
}

export function hasCoating(element: CanvasElement) {
  return (
    (element.isImage() && !element.isVectorImage && element.permissions.hasCoating) ||
    (element.isPhotoFrame() && !element.firstChild.isVectorImage && element.combinedPermissions.hasCoating)
  );
}

export function getCutThroughElements(page: PageElement) {
  return findCutThroughElements(page.children);
}

export function getInvertedCutThroughElements(page: PageElement) {
  return findInvertedCutThroughElements(page.children);
}

export function getBackSideCutThroughElements(page: PageElement) {
  return findCutThroughElements(page.backSidePage?.children);
}

export function getBackSideInvertedCutThroughElements(page: PageElement) {
  return findInvertedCutThroughElements(page.backSidePage?.children);
}

function findCutThroughElements(elements: CanvasElement[]) {
  return elements
    ?.filter(element => element.isCutThrough)
    ?.flatMap((element) => element.children) ?? [];
}

function findInvertedCutThroughElements(elements: CanvasElement[]) {
  return elements
    ?.filter(element => element.isCutThroughInverted)
    ?.flatMap((element) => element.children) ?? [];
}
