import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { UndoActions } from '../../actions';
import { UndoRedoTooltipText } from 'src/app/models/text';

export class UndoRedoShortcut {
  constructor(public undo = '', public redo = '') {}
}

@Component({
  selector: 'ed-undo-redo',
  templateUrl: 'undo-redo.component.html',
  styleUrls: ['undo-redo.component.scss', '../../shared/button/buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UndoRedoComponent {
  @Input() tooltipShowDelay: number;
  @Input() tooltipPosition: TooltipPosition;
  @Input() tooltipText: UndoRedoTooltipText;

  @Input() set isMac(isMac: boolean) {
    const prefix = isMac ? 'Cmd' : 'Ctrl';
    this.shortcut = new UndoRedoShortcut(
      this.tooltipText.undo + ' (' + prefix + ' \u002B Z)',
      this.tooltipText.redo + ' (' + prefix + ' \u002B Y)'
    );
  }

  shortcut: UndoRedoShortcut = new UndoRedoShortcut();

  constructor(private store: Store<AppState>) {}

  undo() {
    this.store.dispatch(new UndoActions.Undo());
  }

  redo() {
    this.store.dispatch(new UndoActions.Redo());
  }
}
