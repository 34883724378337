import {
  Component,
  Input,
  HostListener,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../../reducers';
import { CanvasActions } from '../../../actions';
import { TextElement, Font, CanvasElement, Color, CanvasCoordinate } from '../../../models';
import { GetTextService, ConfigService } from '../../../services';
import { Translate } from '../../../actions/canvas-actions';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { DomSanitizer } from '@angular/platform-browser';
import { selectDesign } from '../../../selectors';

const LEFT = 'L';
const CENTER = 'C';
const RIGHT = 'R';

@Component({
  selector: 'ed-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['../edit.component.scss', './edit-text.component.scss']
})
export class EditTextComponent implements OnInit {
  public tooltipShowDelay$: Observable<number>;
  public tooltipPosition: TooltipPosition = 'below';

  _textElement: TextElement;

  get textElement() {
    return this._textElement;
  }

  @Input() selectedElement: CanvasElement;
  fontLibrary$: Observable<Font[]>;
  designFonts$: Observable<Font[]>;
  positioningTooltipText = this.getTextService.text.edit.order.tooltip;
  showDimensionsInputField$: Observable<boolean>;
  fontItemHeight = 33;
  elementColor: Color;
  canEditSettings$: Observable<boolean>;
  @Input() showMoreOptions = false;
  @Output() showMoreOptionsChange = new EventEmitter<boolean>();

  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  @Input()
  set element(element: TextElement) {
    if (element && element.isText()) {
      // blur textarea if other text element is selected
      if (this.textElement !== undefined && element.id !== this.textElement.id) {
        this.textarea.nativeElement.blur();
      }

      this._textElement = element;

      if (element.enlargeTextarea) {
        setTimeout(() => this.store.dispatch(new CanvasActions.EnlargeTextarea(this.textElement.route, false)), 1000);
        // sets focus on textarea when highlighted
        // focus on textarea disables shortcuts, so don't put focus on textarea directly
        this.textarea.nativeElement.focus();
      }

      // when a new text element is added, textNotEdited is true
      if (element.textNotEdited) {
        this.clearTextarea();
      }

      this.elementColor = new Color('', this.textElement.color, this.textElement.foilType);
    }
  }

  clearTextarea() {
    this.textarea.nativeElement.select();
  }

  constructor(
    public store: Store<AppState>,
    public getTextService: GetTextService,
    public configService: ConfigService,
    public changeDetector: ChangeDetectorRef,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.initStoreSelections();
  }

  initStoreSelections() {
    const config$ = this.store.pipe(select(s => s.config));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    this.fontLibrary$ = this.store.pipe(select(i => i.fontlibrary.fontlibrary));
    this.designFonts$ = this.store.pipe(
      select(selectDesign),
      map(d => d.designFonts)
    );
    this.showDimensionsInputField$ = this.store.pipe(select(fromPermissions.getHasDimensionsInputField));
    this.canEditSettings$ = this.store.pipe(select(fromPermissions.canEditElementSettings));
  }

  get leftSelected() {
    return !this.textElement.isJustify && this.textElement.ha === LEFT;
  }

  get rightSelected() {
    return this.textElement.ha === RIGHT;
  }

  get centerSelected() {
    return this.textElement.ha === CENTER;
  }

  get justifySelected() {
    return this.textElement.isJustify;
  }

  @HostListener('click')
  deselectTextarea() {
    if (this.textElement.highlightTextarea) {
      this.store.dispatch(new CanvasActions.HighlightTextarea(this.textElement.route, false));
    }
  }

  alignLeft() {
    this.alignText(LEFT, false);
  }

  alignCenter() {
    this.alignText(CENTER, false);
  }

  alignRight() {
    this.alignText(RIGHT, false);
  }

  alignJustify() {
    this.alignText(LEFT, true);
  }

  changeFontColor(color: Color) {
    if (color.foil || color.spotUv) {
      this.store.dispatch(new CanvasActions.CheckSpecialColor(this.textElement.route, color));
    } else {
      this.store.dispatch(new CanvasActions.ChangeColor(this.textElement.route, color));
    }
  }

  alignText(ha: string, is_justify: boolean) {
    this.store.dispatch(new CanvasActions.AlignText(this.textElement.route, ha, is_justify));
  }

  changeFont(font: Font) {
    this.store.dispatch(new CanvasActions.ChangeFont(this.textElement.route, font.name));
  }

  changeFontsize(size: number) {
    this.store.dispatch(new CanvasActions.ChangeFontsize(this.textElement.route, size));
  }

  changeText(text: string) {
    this.store.dispatch(new CanvasActions.ChangeText(this.textElement.route, text));
  }

  changeTransparency(transparency: number) {
    this.store.dispatch(new CanvasActions.ChangeTransparency(this.textElement.route, transparency));
  }

  rotate(rotation: number) {
    if (this.textElement.rotation !== rotation) {
      this.store.dispatch(
        new CanvasActions.Rotate(
          this.textElement.route,
          this.textElement.screenWidth,
          this.textElement.screenHeight,
          this.textElement.screenX,
          this.textElement.screenY,
          rotation
        )
      );
    }
  }

  flipHorizontal() {
    this.store.dispatch(new CanvasActions.FlipHorizontal(this.textElement.route, !this.textElement.flipHorizontal));
  }

  flipVertical() {
    this.store.dispatch(new CanvasActions.FlipVertical(this.textElement.route, !this.textElement.flipVertical));
  }

  translate(event: CanvasCoordinate) {
    const screenX = this.textElement.x + event.x;
    const screenY = this.textElement.y + event.y;

    this.store.dispatch(
      new Translate(
        this.textElement.route,
        this.textElement.screenWidth,
        this.textElement.screenHeight,
        screenX,
        screenY
      )
    );
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
    this.showMoreOptionsChange.emit(this.showMoreOptions);
  }
}
