import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { round, cloneDeep } from 'lodash-es';
import { Observable, forkJoin, of, pipe } from 'rxjs';
import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { Value, Block, BlockJSON } from 'slate';
import {
  BASELINE_PAGEWIDTH,
  BoxElement,
  CanvasElement,
  DEFAULT_INLINE_TEXT_ASCENDER,
  DEFAULT_INLINE_TEXT_DESCENDER,
  Design,
  Font,
  InlineTextElement,
  Line,
  PageElement,
  Paragraph,
  TextElement,
  TextSpan,
  VA_NEW
} from '../models';
import { ConfigService } from './config-service';
import { ConvertToInlineText } from '../actions/canvas-actions';
import {
  ABSOLUTE_FONT_SIZE_SCALE,
  DEFAULT_INLINE_TEXT_PADDING,
  OLD_TEXT_MARGIN,
  POINTS_PER_PIXEL,
  SVG_VIEWBOX_SCALE
} from '../../../react-text-editor/models/text-editor.model';
import { TextEditorService } from '../text-editor/text-editor.service';
import {
  assignSpanParts,
  flattenParagraph,
  getTextLines,
  getWordSpacing,
  mergeWords
} from '../../../react-text-editor/utils/inline-text.utils';
import { OUTPUT_DECIMALS } from '../utils/element.utils';
import { select, Store } from '@ngrx/store';
import { getFontLibrary } from '../font-library/reducer';
import { canAddTextInline } from '../reducers/permissions.reducer';
import { AppState } from '../reducers';
import { DesignSet } from '../models/design-set';

export class ConvertKCTextResponse {
  layout_height: number;
  delta_y: number;
}

@Injectable()
export class TextService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private config: ConfigService,
    private textEditorService: TextEditorService
  ) {}

  setNewVerticalAlignment(element: TextElement): Observable<ConvertKCTextResponse> {
    const params = element.imgParams;
    params.h = round(element.height, OUTPUT_DECIMALS);
    return this.http.post(this.config.imgBase + '/get_text_height_new', params).pipe(
      map((response: ConvertKCTextResponse) => {
        const centre_shift = (response.layout_height - element.height) / 2 - response.delta_y;

        // because rotate center also shifts
        const radians = element.rotation * (Math.PI / 180);
        const translation_center_rotate_x = Math.sin(radians) * centre_shift;
        const translation_center_rotate_y = Math.cos(radians) * centre_shift;

        element.y = element.y - response.delta_y - centre_shift + translation_center_rotate_y;
        element.x = element.x - translation_center_rotate_x;
        element.height = response.layout_height;
        element.va = VA_NEW;

        return response;
      })
    );
  }

  inlineTextCorrectionRequest(element: TextElement) {
    const params = element.imgParams;
    const fontSizeScale = element.useAbsoluteFontSize ? 1 : ABSOLUTE_FONT_SIZE_SCALE;

    params.use_absolute_font_size = true;
    params.s = element.fontSize * fontSizeScale;
    return this.http.post(this.config.imgBase + '/get_text_metrics', params);
  }

  convertAllTextForFont(design: Design, font: Font): Observable<ConvertToInlineText>[] {
    // return an array of observables of actions to update all images/text and nested images/text
    return this.getTextElementsFont(design.pages, font).map(textElement =>
      this.convertInlineTextElement(textElement, font).pipe(
        map(inlineTextElement => this.getConvertTextAction(inlineTextElement, textElement.route))
      )
    );
  }

  convertInlineTextElement(textElement: TextElement, font: Font): Observable<InlineTextElement> {
    return this.inlineTextCorrectionRequest(textElement).pipe(
      map((correction: { y: number }) => this.createInlineTextElement(textElement, correction.y, font))
    );
  }

  getTextElementsFont(elements: Array<CanvasElement>, font: Font): Array<TextElement> {
    return this.getAllTextElements(elements).filter(
      element => !!font.oldNames.find(oldName => element.font === oldName)
    );
  }

  convertTextElementsForFont(design: Design, font: Font) {
    return this.getTextElementsFont(design.pages, font).map(textElement =>
      this.inlineTextCorrectionRequest(textElement).pipe(
        map((correction: { y: number }) => this.createInlineTextElement(textElement, correction.y, font)),
        map(inlineTextElement => this.replaceTextElement(design, textElement, inlineTextElement))
      )
    );
  }

  replaceTextElement(design: Design, textElement: TextElement, inlineTextElement: InlineTextElement) {
    const parent = design.getElement(textElement.route.slice(1, textElement.route.length)) as PageElement;
    design.removeElement(textElement.route);
    parent.addElement(inlineTextElement, inlineTextElement.id, inlineTextElement.order);
  }

  convertRemainingText(design: Design, fontLib: Font[]) {
    return (
      this.getAllTextElements(design.pages)
        // fonts not found in old names, so no font file found
        .filter(textElement => !fontLib.find(libFont => libFont.oldNames.find(oldName => oldName === textElement.font)))
        .map(textElement => {
          const inlineTextElement = this.createInlineTextElement(textElement, 0, new Font(textElement.font));
          return this.getConvertTextAction(inlineTextElement, textElement.route);
        })
    );
  }

  designTextConversion() {
    return pipe(
      mergeMap((design: Design) => this.adjustTextDesign(design)),
      withLatestFrom(this.store.pipe(select(getFontLibrary))),
      withLatestFrom(this.store.pipe(select(canAddTextInline))),
      mergeMap(([[design, fonts], convertToInlineText]) =>
        convertToInlineText ? this.convertAllTextDesign(design, fonts) : of(design)
      )
    );
  }

  convertAllTextDesign(design: Design, fonts: Font[]): Observable<Design> {
    let textElements = fonts.flatMap(font => this.convertTextElementsForFont(design, font));
    if (textElements.length === 0) {
      textElements = [of(null)];
    }

    return forkJoin(textElements).pipe(map(() => design));
  }

  createInlineTextElement(textElement: TextElement, correction: number, font: Font): InlineTextElement {
    const padding = (textElement.pageWidth / BASELINE_PAGEWIDTH) * DEFAULT_INLINE_TEXT_PADDING;
    const paddingCorrection = OLD_TEXT_MARGIN - padding / SVG_VIEWBOX_SCALE;
    const correctionY = (correction * POINTS_PER_PIXEL) / SVG_VIEWBOX_SCALE;

    const fontSizeScale = textElement.useAbsoluteFontSize ? 1 : ABSOLUTE_FONT_SIZE_SCALE;
    const fontSize = textElement.fontSize * fontSizeScale;

    const inlineText = new InlineTextElement(textElement.id);
    inlineText.text = [];
    inlineText.textNotEdited = false;
    inlineText.order = textElement.order;
    inlineText.parent = textElement.parent;
    inlineText.flipHorizontal = textElement.flipHorizontal;
    inlineText.flipVertical = textElement.flipVertical;
    inlineText.width = textElement.width - 2 * paddingCorrection;
    inlineText.height = textElement.height; // height is set at the end of this function
    inlineText.x = textElement.x + paddingCorrection;
    inlineText.y = textElement.y + paddingCorrection - correctionY;
    inlineText.rotation = textElement.rotation;
    inlineText.tag = textElement.tag;
    inlineText.permissions = textElement.permissions;
    inlineText.padding = padding;
    inlineText.foilType = textElement.foilType;
    const convertAlign = {
      L: 'start',
      C: 'middle',
      R: 'end'
    };

    // create paragraphs on new line breaks
    const paragraphsText = textElement.text.split('\n');
    paragraphsText.forEach(parText => {
      const textSpan = new TextSpan();
      textSpan.text = parText;
      textSpan.fontSize = fontSize;
      textSpan.font = font ? font.name : textElement.font;
      textSpan.bold = textElement.font.endsWith(' Bold') || textElement.font.endsWith(' Bold Italic');
      textSpan.italic = textElement.font.endsWith(' Italic');
      textSpan.color = textElement.color;
      textSpan.opacity = 1 - textElement.transparency / 100;
      textSpan.foil = inlineText.foilType;
      const textLine = new Line();
      textLine.textSpans = [textSpan];
      const par = new Paragraph();
      par.lines = [textLine];
      par.textAlign = convertAlign[textElement.ha];
      par.isJustified = textElement.isJustify;
      inlineText.text.push(par);
    });

    // create text editor value, to calculate word wrap
    const value = this.textEditorService.convertToEditorValue(
      inlineText.text,
      inlineText.width,
      inlineText.height,
      inlineText.flipVertical,
      inlineText.flipHorizontal,
      inlineText.padding
    );
    const textValue = Value.fromJS(value);
    const textWidth = inlineText.width * SVG_VIEWBOX_SCALE - 2 * padding;

    inlineText.text.forEach((par, index) => {
      const textEditorPar = textValue.document.nodes.get(index) as Block;
      const spanParts = flattenParagraph(textEditorPar);
      const words = mergeWords(spanParts);
      const textLines = getTextLines(words, textWidth);

      const firstLine = par.lines[0];
      par.lines = [];
      textLines.forEach(line => {
        const newLine = cloneDeep(firstLine);
        newLine.textSpans[0].text = line.text;
        par.lines.push(newLine);
      });
    });

    // if justify, calculate wordspacing
    if (textElement.isJustify) {
      this.setWordSpacing(inlineText, textWidth, padding);
    }

    // calulate y of lines and new height.
    let y = padding;
    const ascender = (font ? font.ascender : DEFAULT_INLINE_TEXT_ASCENDER) * fontSize;
    const descender = (font ? font.descender : DEFAULT_INLINE_TEXT_DESCENDER) * fontSize;
    let isFirstLine = true;
    inlineText.text.forEach(par =>
      par.lines.forEach(line => {
        if (!isFirstLine) {
          y = y + descender;
        }
        y = y + ascender;
        line.y = y;
        isFirstLine = false;
      })
    );
    inlineText.height = (y + descender + padding) / SVG_VIEWBOX_SCALE;
    return inlineText;
  }

  setWordSpacing(inlineText: InlineTextElement, textWidth: number, padding: number) {
    const textValue = this.textEditorService.convertToEditorValue(
      inlineText.text,
      inlineText.width,
      inlineText.height,
      inlineText.flipVertical,
      inlineText.flipHorizontal,
      inlineText.padding
    );
    textValue.document.nodes.forEach((paragraph: BlockJSON) => {
      paragraph.nodes.forEach((line: BlockJSON, index, array) => {
        const isLastLine = index === array.length - 1;
        const newSpacing = isLastLine ? 0 : getWordSpacing(line, textWidth);
        Object.assign(line.data, { wordSpacing: newSpacing });
      });
    });

    inlineText.text = this.textEditorService.convertToInlineText(textValue);
    assignSpanParts(textValue, inlineText.text, padding);
  }

  getConvertTextAction(inlineTextElement: InlineTextElement, route: number[]) {
    return new ConvertToInlineText(inlineTextElement, route);
  }

  adjustTextSet(designSet: DesignSet): Observable<DesignSet> {
    return forkJoin(this.getTextElements(designSet.designs.flatMap(d => d.pages))).pipe(map(() => designSet));
  }

  adjustTextDesign(design: Design): Observable<Design> {
    return forkJoin(this.getTextElements(design.pages)).pipe(map(() => design));
  }

  getTextElements(pages: Array<PageElement | BoxElement>): Observable<ConvertKCTextResponse>[] {
    const textElementsWithOldVerticalAllignment = this.getAllTextElements(pages).filter(
      (element: TextElement) => element.va !== VA_NEW
    ) as Array<TextElement>;

    if (textElementsWithOldVerticalAllignment.length === 0) {
      return [of(null)];
    }

    return textElementsWithOldVerticalAllignment.map((element: TextElement) => this.setNewVerticalAlignment(element));
  }

  getAllTextElements(elements: Array<CanvasElement>): Array<TextElement> {
    return elements
      .flatMap(element => [element, ...this.getAllTextElements(element.children)])
      .filter(element => element.isText()) as Array<TextElement>;
  }
}
