const TYPE = "[FabricCanvas]";

const TEXTBOX = "[Textbox]";

const PHOTOFRAME = "[PhotoFrame]";

export const CHANGE_TEXTBOX_FONT_FAMILY = TYPE + TEXTBOX + " Change font family";

export const CHANGE_TEXTBOX_FONT_SIZE = TYPE + TEXTBOX + " Change font size";

export const CHANGE_TEXTBOX_FONT_STYLE = TYPE + TEXTBOX + " Change font style";

export const CHANGE_TEXTBOX_FONT_WEIGHT = TYPE + TEXTBOX + " Change font weight";

export const CHANGE_TEXTBOX_LINE_HEIGHT = TYPE + TEXTBOX + " Change line height";

export const CHANGE_TEXTBOX_TEXT_ALIGN = TYPE + TEXTBOX + " Change text align";

export const CHANGE_TEXTBOX_UNDERLINE = TYPE + TEXTBOX + " Change underline";

export const CHANGE_PHOTOFRAME_ZOOM = TYPE + PHOTOFRAME + " Change zoom"
