import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Auth from './actions';
import * as RecoverPassword from '../recover/actions';
import { select, Store } from '@ngrx/store';
import * as fromAuth from './reducer';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientError } from '../models/client-error';
import { CustomFormText, DialogButtonText, LoginFormText } from '../models/text';

@Component({
  selector: 'ed-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['../shared/dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() buttonText: DialogButtonText;
  @Input() loginText: LoginFormText;
  @Input() customFormText: CustomFormText;
  @Input() showSaveText: boolean;

  @Output() openRegister = new EventEmitter();

  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  pending$: Observable<boolean>;
  errors$: Observable<ClientError[]>;
  errors: ClientError[];
  dataLayerIdPrefix = 'login-popup-';
  errorSubscription$: Subscription;
  username = 'username';
  password = 'password';

  constructor(private store: Store<fromAuth.State>) {}

  ngOnInit() {
    this.pending$ = this.store.pipe(
      select(fromAuth.getPending),
      tap(pending => (pending ? this.form.disable() : this.form.enable()))
    );
    this.errors$ = this.store.pipe(select(fromAuth.getError));
    this.errorSubscription$ = this.errors$.subscribe(errors => (this.errors = errors));
  }

  onNoClick(): void {
    this.store.dispatch(new Auth.CloseLoginDialog());
  }

  submit(password: string, username: string) {
    // neccesary for chrome on ios, because autofill does not work properly
    this.form.setValue({ username, password });

    const errors = [];
    if (this.form.valid) {
      this.store.dispatch(new Auth.Login(this.form.value));
      return;
    }
    if (this.form.get(this.username).invalid) {
      errors.push({
        message: this.loginText.userNameInputTitle + ' ' + this.customFormText.requiredText,
        code: '',
        field: this.username
      });
    }
    if (this.form.get(this.password).invalid) {
      errors.push({
        message: this.loginText.passwordInputTitle + ' ' + this.customFormText.requiredText,
        code: '',
        field: this.password
      });
    }
    if (errors.length) {
      this.store.dispatch(new Auth.LoginFailure(errors));
    }
  }

  hasInputErrors(field: string): boolean {
    if (this.errors?.length) {
      return this.errors.some(error => error.field === field);
    }
  }

  openRegisterForm(e: Event) {
    // there is an empty href attribute on the link, so we need to prevent the default action
    // the empty href is needed to get an underline on the link with boottrap
    e.preventDefault();
    this.openRegister.emit();
  }

  openPasswordRecovery() {
    this.store.dispatch(new RecoverPassword.OpenRecoverDialog());
  }

  resetError() {
    if (this.errors?.length) {
      this.store.dispatch(new Auth.LoginErrorReset());
    }
  }

  ngOnDestroy() {
    this.errorSubscription$.unsubscribe();
  }
}
