import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { ParamsService } from '../services/params.service';

@Injectable()
export class ContinueService {
  redirectUrl: string;
  retainParams: boolean;

  constructor(private store$: Store<AppState>, private paramsService: ParamsService) {
    this.store$.pipe(select(s => s.continue)).subscribe(c => {
      this.redirectUrl = c.redirectUrl;
      this.retainParams = c.retainParams;
    });
  }

  continueToUrl() {
    window.location.href = this.retainParams ? this.getUrlWithParams(this.redirectUrl) : this.redirectUrl;
  }

  getUrlWithParams(url: string) {
    const params = this.paramsService.httpParams;
    if (params && params.keys().length > 0) {
      url = `${url}?${params.toString()}`;
    }
    return url;
  }
}
