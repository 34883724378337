import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavComponent } from './nav.component';
import { PageElement } from '../../models';

@Component({
  selector: 'ed-nav-mob',
  templateUrl: 'nav-mob.component.html',
  styleUrls: ['nav-mob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMobComponent extends NavComponent {
  get pages(): Array<PageElement> {
    return this.designSet.designs.flatMap(d => d.pages);
  }
}
