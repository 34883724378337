import { Component, Input, OnInit } from '@angular/core';
import { TextComponent } from './text.component';

@Component({
  selector: 'ed-text-mob',
  templateUrl: 'text-mob.component.html',
  styleUrls: ['../../../shared/mob-menu.scss']
})
export class TextMobComponent extends TextComponent {
  @Input() text: string;
  @Input() disabled: boolean;
}
