import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColorMenuDialogText } from '../../models/text';
import { colorExistsInArray } from '../../utils/color-utils';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Color } from '../../models';

export type menuName = 'colorMenu' | 'colorPicker';

export type MenuOptions = { [E in menuName]: MenuOption };

export class MenuOption {
  constructor(public name: string, public open = false) {}
}

@Component({
  selector: 'ed-color-menu-dialog',
  templateUrl: 'color-menu-dialog.component.html',
  styleUrls: ['../menu-dialog.component.scss', 'color-menu-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorMenuDialogComponent {
  @Output() selectedColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() customColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() customColorMenuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() originalColor: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showColorHeader: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelChangeColor: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateAllSpecialColorElements: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() resetAllSpotUvElements = new EventEmitter<boolean>();

  // this is an input element, to be able to trigger the keyboard on ios on initFocus
  @ViewChild('fakeInput', { static: false }) fakeInput: ElementRef;

  currentColor$: Observable<Color>;
  colorData: Color[];
  showFoilPanel: boolean;
  showSpotUvColors: boolean;
  originalColorOption: boolean;
  customColors$: Observable<Color[]>;
  designColors$: Observable<Color[]>;
  spotUvColorData: Color[];
  foilColorData: Color[];
  rainbowBackgroundImage: string;
  text: ColorMenuDialogText;
  colorPickerWidth: number;
  tooltipShowDelay: number;
  dataLayerIdPrefix: string;
  foilInfoUrl: string;
  spotUvInfoUrl: string;
  isMobile: boolean;
  designHasFoiledElement$: Observable<boolean>;
  designHasSpotUvElement$: Observable<boolean>;
  colorPickerSpotUv: boolean;

  @HostBinding('style.height') height = 'auto';

  menuOptions: MenuOptions = {
    colorMenu: new MenuOption('colorMenu', true),
    colorPicker: new MenuOption('colorPicker', false)
  };

  constructor(public dialogRef: MatDialogRef<ColorMenuDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currentColor$ = data.currentColor$;
    this.colorData = data.colorData;
    this.showFoilPanel = data.showFoilPanel;
    this.showSpotUvColors = data.showSpotUvColors;
    this.originalColorOption = data.originalColorOption;
    this.customColors$ = data.customColors$;
    this.designColors$ = data.designColors$;
    this.spotUvColorData = data.spotUvColorData;
    this.foilColorData = data.foilColorData;
    this.rainbowBackgroundImage = data.rainbowBackgroundImage;
    this.text = data.text;
    this.colorPickerWidth = data.colorPickerWidth;
    this.tooltipShowDelay = data.tooltipShowDelay;
    this.dataLayerIdPrefix = data.dataLayerIdPrefix;
    this.foilInfoUrl = data.foilInfoUrl;
    this.spotUvInfoUrl = data.spotUvInfoUrl;
    this.isMobile = data.isMobile;
    this.designHasFoiledElement$ = data.designHasFoiledElement$;
    this.designHasSpotUvElement$ = data.designHasSpotUvElement$;
    this.height = this.isMobile ? '30vh' : '50vh';
  }

  changeColor(color: Color) {
    this.selectedColor.emit(color);
  }

  resetColor() {
    this.originalColor.emit(true);
  }

  addCustomColor(color: Color) {
    this.customColor.emit(color);
  }

  openMenu(menuOption: MenuOption) {
    Object.keys(this.menuOptions).map(name => (this.menuOptions[name].open = name === menuOption.name));
  }

  saveCurrentCustomColor(color: Color) {
    this.customColors$.pipe(first()).subscribe(colors => {
      const colorExist = colorExistsInArray(color, colors);
      if (!colorExist) {
        this.addCustomColor(color);
      }
    });
  }

  backToColorMenu() {
    this.customColorMenuClosed.emit(true);
    this.openMenu(this.menuOptions.colorMenu);
  }

  confirmCustomColor() {
    this.customColorMenuClosed.emit(true);
    this.closeMenu();
  }

  closeMenu() {
    this.fakeInput.nativeElement.focus();
    this.dialogRef.close();
  }

  updateAllSpecialColors($event: Color) {
    this.updateAllSpecialColorElements.emit($event);
  }

  resetAllSpotUvColors() {
    this.resetAllSpotUvElements.emit();
  }

  openColorPicker(spotUv: boolean) {
    this.colorPickerSpotUv = spotUv;
    this.openMenu(this.menuOptions.colorPicker);
  }
}
