import { Design } from './design';
import { CanvasElement } from './canvas';
import { Font } from './font';
import { CategoryImage } from './category-image';
import { Color } from './color';
import { TextElement } from './text-element';

export class DesignSet {
  init = false;
  designs: Array<Design> = [];
  version = 0;
  id: string;
  title: string;
  description: string;
  isProposedDesign = false;
  userCollectionTitle: string;
  userCollectionId: number;
  useAbsoluteFontSize: boolean;
  untargetableElementsTargetable = false;
  showGrid = false;
  initialPageImagesLoaded = false;

  x = 0;
  y = 0;
  width: number;
  height: number;
  pixelsPerMm: number;

  marginPercentage = 10;

  get marginLeft() {
    return (this.width * this.marginPercentage) / 100 / 2;
  }

  get marginTop() {
    return (this.height * this.marginPercentage) / 100 / 2;
  }

  get designSetFonts(): Font[] {
    return this.designs.flatMap(d => d.designFonts);
  }

  get customSetColors(): Color[] {
    return this.designs.flatMap(d => d.customColors);
  }

  get designSetImages(): CategoryImage[] {
    return this.designs.flatMap(d => d.designImages);
  }

  get designSetColors(): Color[] {
    return this.designs.flatMap(d => d.designColors);
  }

  get activeDesign() {
    return this.designs.find(d => d.active);
  }

  get visiblePage() {
    return this.activeDesign.visiblePage;
  }

  getDesign(route: Array<number>) {
    return this.designs.find(d => d.setId === route[route.length - 1]);
  }

  getElement(route: Array<number>): CanvasElement {
    return this.getDesign(route).getElement(route);
  }

  select(route: Array<number>): CanvasElement {
    return this.getDesign(route).select(route);
  }

  get selectedElement(): CanvasElement {
    return this.activeDesign.selectedElement;
  }

  removeElement(route: Array<number>): void {
    return this.getDesign(route).removeElement(route);
  }

  getTextElement(route: Array<number>): TextElement {
    return this.getDesign(route).getTextElement(route);
  }

  togglePage(route: Array<number>): void {
    this.designs.forEach(d => {
      d.active = d.setId === route[1];
      d.pages.forEach(page => (page.visible = d.active && page.id === route[0]));
      d.cleanUpAndDeselectElements();
    });
  }

  cleanUpAndDeselectElements() {
    this.designs.forEach(d => d.cleanUpAndDeselectElements());
  }

  addElement(parentRoute: Array<number>, element: CanvasElement) {
    this.getDesign(parentRoute).addElement(parentRoute, element);
  }
}
