import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFormField, FormFieldType } from '../models';
import { CustomValidators } from '../shared/custom-validations.directive';

@Injectable()
export class CustomFormControlService {
  toFormGroup(fields: CustomFormField[], passwordFields: string[]) {
    const formGroup = new FormGroup({});

    fields.forEach(field => {
      if (field.type !== FormFieldType.Textbox) {
        this.addFormControl(formGroup, field);
      }
      if (field.type === 'password' && passwordFields.length === 2) {
        formGroup.setValidators(CustomValidators.passwordMatchValidator(formGroup, passwordFields));
      }
    });

    return formGroup;
  }

  addFormControl(formGroup: FormGroup, field: CustomFormField) {
    formGroup.addControl(
      field.name,
      new FormControl(
        field.value,
        Validators.compose([
          field.required ? Validators.required : undefined,
          field.minlength ? Validators.minLength(field.minlength) : undefined,
          field.type === 'email' ? Validators.email : undefined
        ])
      )
    );
  }

  removeFormControl(formGroup: FormGroup, field: CustomFormField) {
    formGroup.removeControl(field.name);
    formGroup.addControl(field.name, new FormGroup(field.value));
  }

  formGroupIsValid(fields: CustomFormField[], formGroup: FormGroup): boolean {
    if (formGroup.touched && !formGroup.pristine) {
      this.hideFormControlsIfValue(fields, formGroup);
      return formGroup.valid;
    } else {
      return false;
    }
  }

  hideFormControlsIfValue(fields: CustomFormField[], formGroup: FormGroup) {
    fields
      .filter(f => f.hideIfValue)
      .forEach(field => {
        const targetControl = formGroup.get(field.hideIfValue.key);
        if (targetControl) {
          if (targetControl.value === field.hideIfValue.value) {
            this.removeFormControl(formGroup, field);
          } else {
            this.addFormControl(formGroup, field);
          }
        }
      });
  }
}
