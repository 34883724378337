import {
  BackgroundElement,
  BoxElement,
  defaultMaterialType,
  Design,
  Fold,
  ImageElement,
  InlineTextElement,
  PageElement,
  PhotoFrameElement,
  Spread,
  TextElement,
  TrimTypes,
  View
} from '../models';
import { cloneDeep } from 'lodash-es';
import { defaultText } from './default-inline-text.data';
import { DesignSet } from '../models/design-set';

const page1 = new PageElement(1, {
  width: 1000,
  height: 1000,
  order: 1
});
page1.visible = true;
page1.name = 'p1';
page1.bleed = 11.37;
page1.safety = 11.37;
page1.label = ['Voorkant'];
page1.position = 1;

const background = new BackgroundElement({
  width: page1.width,
  height: page1.height,
  color: '#ffffff'
});

background.bleed = page1.bleed;
const bgImage = new ImageElement(-1, {
  name: 'bg_p1',
  sid: '',
  url: 'https://img.kaart-o-matic.nl/img/L.dd3e8dbf38e475b1a6a85fce2e58393e.jpg?maxsize=1200',
  width: 1680,
  height: 1050,
  x: -267.78,
  y: -11.34
});
bgImage.setBackgroundImagePermissions();
background.addElement(bgImage);

page1.addElement(background);

const photoFrame1 = new PhotoFrameElement(-1, {
  height: 400,
  width: 400,
  x: 100,
  y: 400
});
photoFrame1.name = 'foxy';
photoFrame1.permissions.isInstantReplaceable = true;

const photoFrameImage1 = new ImageElement(-1, {
  height: 400,
  width: 400,
  y: 0,
  x: 0,
  sid: '',
  url: 'https://img.kaart-o-matic.nl/img/L.b72a4e1b139f155afd9752f9b74307f9.png'
});
photoFrameImage1.tag = 'tag 4';

page1.addElement(photoFrame1);
photoFrame1.addElement(photoFrameImage1);

const text1 = new TextElement(-1, {
  height: 200,
  width: 500,
  x: 20,
  y: 20,
  text: 'abcd',
  font: 'Boston Traffic',
  markup: '',
  fontSize: 100,
  trans: 0,
  color: '#e9b146',
  ha: 'C',
  va: 'M',
  isJustify: false
});
text1.name = 'abcd text';

page1.addElement(text1);

const photoFrame2 = new PhotoFrameElement(-1, {
  height: 40,
  width: 40,
  x: 165,
  y: 360
});
photoFrame2.name = 'heart';

const photoFrameImage2 = new ImageElement(-1, {
  height: 40,
  width: 40,
  y: 0,
  x: 0,
  color: '#f22c87',
  sid: 'None.359a1c3f4a3c2d8af7dddce605461214.svg'
});

photoFrameImage2.isVectorImage = true;
page1.addElement(photoFrame2);
photoFrame2.addElement(photoFrameImage2);

const page2 = new PageElement(2, {
  width: 1200,
  height: 600,
  order: 2
});
page2.visible = false;
page2.name = 'p2';
page2.label = ['Binnenkant links'];
page2.position = 2;

const background2 = new BackgroundElement({
  width: page2.width,
  height: page2.height,
  color: '#000000'
});
const bgImage2 = new ImageElement(-1, {
  name: 'bg_p2',
  sid: 'None.e923689ee15a5852de621ce5b8865dfd.svg',
  color: '#ffff99',
  width: 1200,
  height: 1200,
  x: 0,
  y: -143
});
bgImage2.setBackgroundImagePermissions();
bgImage2.isVectorImage = true;
background2.addElement(bgImage2);
page2.addElement(background2);

const box = new BoxElement(-1, {
  width: 400,
  height: 500,
  x: 10,
  y: 10
});

// box images cannot (yet) be photoframes because of double clippath
const boxImage1 = new ImageElement(-1, {
  height: 500,
  width: 500,
  x: 70,
  y: 55,
  sid: 'None.e2b8fdd7f61a5d98320f51ea0f327343.svg'
});
boxImage1.name = 'pinguïn';
boxImage1.isVectorImage = true;

const boxText2 = new TextElement(-1, {
  height: 132,
  width: 500,
  x: 20,
  y: 20,
  text: 'efgh',
  font: 'Boston Traffic',
  markup: '',
  fontSize: 100,
  trans: 0,
  color: '#1f4dff',
  ha: 'C',
  va: 'M',
  isJustify: false
});
boxText2.name = 'efgh text';

page2.addElement(box);
box.addElement(boxImage1);
box.addElement(boxText2);
boxImage1.setRegularImagePermissions();

const page3 = new PageElement(3, {
  width: 1000,
  height: 1000,
  order: 3
});
page3.name = 'p3';
page3.permissions.addChildElements = false;
page3.label = ['Achterkant'];
page3.position = 3;

const background3 = new BackgroundElement({
  width: page3.width,
  height: page3.height,
  color: '#AFD5DB'
});
const bgImage3 = new ImageElement(-1, {
  name: 'bg_p3',
  sid: 'None.89ce79cdf7e27abe24199599e598e984.svg',
  color: '#c9e4d6',
  width: 1020,
  height: 1020,
  x: -10,
  y: -10
});
bgImage3.isVectorImage = true;
bgImage3.setBackgroundImagePermissions();
background3.addElement(bgImage3);

page3.addElement(background3);

const text3 = new TextElement(-1, {
  height: 200,
  width: 700,
  x: 270,
  y: 20,
  text: 'edit text',
  font: 'Boston Traffic',
  markup: '',
  fontSize: 100,
  trans: 0,
  color: '#009c47',
  ha: 'C',
  va: 'M',
  isJustify: false
});
text3.name = 'edit_text';
page3.addElement(text3);

const photoFrame3 = new PhotoFrameElement(-1, {
  width: 400,
  height: 400,
  x: 300,
  y: 300
});
photoFrame3.name = 'bear';

const photoFrameImage3 = new ImageElement(-1, {
  height: 536,
  width: 800,
  x: -70,
  y: -20,
  sid: 'None.18eb87714899358b5b43b695afd2b091.jpg'
});

const photoFrame4 = new PhotoFrameElement(-1, {
  height: 980,
  width: 750,
  x: 0,
  y: 0
});
photoFrame4.permissions.isUntargetable = true;
photoFrame4.permissions.isTopLayer = true;
photoFrame4.permissions.isCroppable = false;
photoFrame4.name = 'stans example';

const photoFrameImage4 = new ImageElement(-1, {
  height: 982,
  width: 760,
  x: -9,
  y: -30,
  sid: 'L.d768459387cd66fe4e80ccdef3251f7b.png',
  transparency: 50
});

page3.addElement(photoFrame3);
photoFrame3.addElement(photoFrameImage3);
page3.addElement(photoFrame4);
photoFrame4.addElement(photoFrameImage4);

const page4 = new PageElement(4, {
  width: 85,
  height: 55,
  order: 4
});
page4.name = 'p4';
page4.bleed = 3;
page4.safety = 3;
page4.label = ['Visite Kaartje'];
page4.position = 4;
page4.permissions.isFoilable = false;
const background4 = new BackgroundElement({
  width: page4.width,
  height: page4.height,
  color: '#ffffff'
});
background4.bleed = page4.bleed;
page4.addElement(background4);

const inlineText: InlineTextElement = Object.assign(new InlineTextElement(-1), {
  name: 'inline text example',
  height: 13.194102,
  width: 45,
  x: 10,
  y: 10,
  transparency: 0,
  rotation: 10,
  padding: 2.8346
});

inlineText.text = defaultText;
inlineText.textNotEdited = false;
page4.addElement(inlineText);

const page5 = new PageElement(5, {
  width: 100,
  height: 400,
  order: 5
});
page5.name = 'p5';
page5.label = ['Portrait'];
page5.position = 5;
page5.addElement(
  new BackgroundElement({
    width: page5.width,
    height: page5.height,
    color: '#ffffff'
  })
);

export function demoCanvas() {
  const demo = new Design();
  demo.labels = {
    pages: [['Voorkant'], ['Binnenkant links'], ['Achterkant'], ['Visite Kaartje'], ['Portrait']]
  };
  // demo.id must be 2 because of de e2e test 'save title and description, when you have admin permissions'
  // in the fakebackend, designsAdmin only has designs with id 2 and 4
  demo.id = '2';
  demo.useAbsoluteFontSize = true;
  demo.view = View.pages;
  demo.foldType = Fold.none;
  demo.trimType = TrimTypes.borderStraight;
  demo.setId = 0;
  demo.material = {
    type: defaultMaterialType,
    needsCoating: false,
    isFoilable: true,
    isSpotUvable: true,
    isFoldable: true,
    name: 'Papier'
  };
  demo.addPage(page1);
  demo.addPage(page2);
  demo.addPage(page3);
  demo.addPage(page4);
  demo.addPage(page5);

  demo.pages.map((page, index) => demo.addSpread(new Spread(index, [page])));

  return cloneDeep(demo);
}

export function getDemoDesignSet() {
  const designSet = new DesignSet();
  designSet.width = 1200;
  designSet.height = 1200;
  designSet.id = '2';
  designSet.title = 'Demo - Canvas';
  designSet.description = 'Demo - description';
  designSet.userCollectionTitle = 'Mijn Titel';
  designSet.designs = [demoCanvas()];
  return cloneDeep(designSet);
}

export function mockTextPlaceHolder() {
  const textElem = new TextElement(-1);
  textElem.textNotEdited = true;
  return textElem;
}
