import { fabric } from 'fabric';

export const IMAGE_REPLACE_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_d_758_1923)">
      <rect x="6" y="4" width="28" height="28" rx="14" fill="white" shape-rendering="geometricPrecision" />
      <path d="M21 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H21C22.1 20 23 19.1 23 18V6C23 4.9 22.1 4 21 4M21 18H3V6H21V18M14.5 11L11 15.5L8.5 12.5L5 17H19L14.5 11Z" transform="translate(11, 9) scale(.75)" fill="#292929" />
    </g>
    <defs>
      <filter id="filter0_d_758_1923" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_758_192" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_758_192" result="shape" />
      </filter>
    </defs>
  </svg>`;

const size = 38;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(IMAGE_REPLACE_SVG);

const ml_imageReplaceControl = new fabric.Control({
  actionName: 'imageReplace',
  x: -0.5,
  offsetX: -24,
  cursorStyle: 'pointer',
  mouseUpHandler,
  render,

  // @ts-expect-error
  cornerSize: size,
});

function mouseUpHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  const options = {
    e,
    transform,
    target: transform.target,
    pointer: new fabric.Point(x, y),
  };

  options.target.canvas.fire('object:before:imageReplace', options);
  options.target.fire('before:imageReplace', options);

  return true;
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -this.cornerSize / 2, -this.cornerSize / 2, this.cornerSize, this.cornerSize);
  ctx.restore();
}

fabric.Object.prototype.controls.ml_imageReplaceControl = ml_imageReplaceControl;
