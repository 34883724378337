import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ErrorDialogComponent } from '../shared/dialogs/error-dialog/error-dialog.component';
import { GetTextService } from './get-text.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  errorMessageOpen = false;

  openDialog(message: string) {
    if (!this.errorMessageOpen) {
      this.errorMessageOpen = true;
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        maxWidth: '500px',
        data: {
          title: this.getTextService.text.dialog.error.title,
          message,
          button: this.getTextService.text.dialog.button.ok
        }
      });
      dialogRef.afterClosed().subscribe(() => (this.errorMessageOpen = false));
    }
  }

  constructor(private dialog: MatDialog, private getTextService: GetTextService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
          if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
            // https://github.com/angular/angular/issues/19888
            // When request of type Blob, the error is also in Blob instead of object of the json data
            error.error.text().then((err: string) => this.openDialog(err));
          } else {
            try {
              this.openDialog(error.error);
            } catch (Error) {
              this.openDialog(this.getTextService.text.dialog.error.defaultMessage);
            }
          }
          return of();
        } else {
          return throwError(error);
        }
      })
    );
  }
}
