import { Action } from '@ngrx/store';
import { User, Authenticate, RegisterUser } from '../models/user';
import { ClientError } from '../models/client-error';

export enum RegisterActionTypes {
  Register = '[Register] Register',
  RegisterSuccess = '[Register] Register Success',
  RegisterFailure = '[Register] Register Failure',
  CloseRegisterSuccessDialog = '[Register] Close Register Success Dialog',
  ResetValidationErrors = '[Register] Reset Validation Errors'
}

export class Register implements Action {
  readonly type = RegisterActionTypes.Register;

  constructor(public payload: RegisterUser) {}
}

export class RegisterSuccess implements Action {
  readonly type = RegisterActionTypes.RegisterSuccess;

  constructor(public user: User) {}
}

export class RegisterFailure implements Action {
  readonly type = RegisterActionTypes.RegisterFailure;

  constructor(public payload: ClientError[]) {}
}

export class CloseRegisterSuccessDialog implements Action {
  readonly type = RegisterActionTypes.CloseRegisterSuccessDialog;
}

export class ResetValidationErrors implements Action {
  readonly type = RegisterActionTypes.ResetValidationErrors;
}

export type RegisterActions =
  | Register
  | RegisterSuccess
  | RegisterFailure
  | CloseRegisterSuccessDialog
  | ResetValidationErrors;
