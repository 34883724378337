import { fabric } from 'fabric';
import { isTinyObject } from '../../utils/object.utils';

const size = 30;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 28 28" fill="none">
    <rect x="4" y="3" width="20" height="20" rx="10" fill="#FF0000" shape-rendering="geometricPrecision" />
    <path d="M18.6667 9.27334L17.7267 8.33334L14 12.06L10.2733 8.33334L9.33333 9.27334L13.06 13L9.33333 16.7267L10.2733 17.6667L14 13.94L17.7267 17.6667L18.6667 16.7267L14.94 13L18.6667 9.27334Z" fill="white" />
  </svg>
`);

const remove = new fabric.Control({
  actionName: 'remove',
  x: 0.5,
  y: -0.5,
  offsetX: 0,
  offsetY: 0,
  cursorStyle: 'pointer',
  visible: false,
  getVisibility,
  render,
  mouseDownHandler,
});

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isTinyObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function mouseDownHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  const options = getOptions(e, transform, x, y);

  transform.target.canvas.fire('object:remove', options);
  transform.target.fire('remove', options);
  return true;
}

function getOptions(e: MouseEvent, transform: fabric.Transform, x: number, y: number) {
  const target = transform.target;
  const pointer = new fabric.Point(x, y);

  return {
    e,
    transform,
    target,
    pointer,
  };
}

fabric.Object.prototype.controls.remove = remove;
fabric.Textbox.prototype.controls.remove = remove;
