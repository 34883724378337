import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorMenuService } from '../color-menu.service';
import { ColorButtonComponent } from './color-button.component';

@Component({
  selector: 'ed-color-button-mob',
  templateUrl: './color-button-mob.component.html',
  styleUrls: ['./color-button-mob.component.scss'],
  providers: [ColorMenuService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorButtonMobComponent extends ColorButtonComponent {
  @Input() buttonText: string;
}
