import { EditorAction } from '../action';
import { RESET_ALL_SPOT_UV_ELEMENTS } from './canvas.action.types';

export class ResetAllSpotUvElements implements EditorAction {
  readonly type = RESET_ALL_SPOT_UV_ELEMENTS;
  readonly undoable = true;
  readonly save = true;

  constructor() {}
}
