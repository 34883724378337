import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CanvasActions } from '../../actions';
import { CanvasElement, getLayerSiblings } from '../../models';
import { PositioningTooltipText } from '../../models/text';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ed-positioning-tools',
  templateUrl: 'positioning-tools.component.html',
  styleUrls: ['positioning-tools.component.scss', '../button/buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositioningToolsComponent {
  @Input() tooltipShowDelay: number;
  @Input() tooltipPosition: TooltipPosition;
  @Input() dataLayerIdPrefix: string;

  @Input() tooltipText: PositioningTooltipText;

  @Output() isChanged = new EventEmitter<boolean>();

  @Input() set element(element: CanvasElement) {
    this._element = element;
    const siblings = getLayerSiblings(element);
    const index = siblings.indexOf(element);
    this.isOnBottomOfLayer = index === 0;
    this.isOnTopOfLayer = index === siblings.length - 1;
  }

  get element() {
    return this._element;
  }

  isOnTopOfLayer = false;
  isOnBottomOfLayer = false;
  _element: CanvasElement;

  constructor(private store: Store<AppState>) {}

  moveStepForward() {
    this.store.dispatch(new CanvasActions.MoveStepForward(this.element.route));
    this.isChanged.emit();
  }

  moveStepBackward() {
    this.store.dispatch(new CanvasActions.MoveStepBackward(this.element.route));
    this.isChanged.emit();
  }

  moveToFront() {
    this.store.dispatch(new CanvasActions.MoveToFront(this.element.route));
    this.isChanged.emit();
  }

  moveToBack() {
    this.store.dispatch(new CanvasActions.MoveToBack(this.element.route));
    this.isChanged.emit();
  }
}
