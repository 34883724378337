import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ed-edit-element-setting',
  styleUrls: ['edit-element-setting.component.scss'],
  templateUrl: 'edit-element-setting.component.html'
})
export class EditElementSettingComponent {
  @Input() label = '';
  @Input() tooltipText = '';
  @Input() tooltipShowDelay: number;
  @Input() checked: boolean;
  @Input() disabled = false;
  @Output() toggleCheckBox = new EventEmitter();
  public tooltipPosition: TooltipPosition = 'below';

  change() {
    this.toggleCheckBox.emit();
  }
}
