import { Injectable } from '@angular/core';
import { Authenticate, ResponseUser, User } from '../models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { map } from 'rxjs/operators';
import { FunctionPermissions, SetElementPermissions } from '../models';

const httpOptions = {
  withCredentials: true,
};

@Injectable()
export class AuthService {
  isAuthenticatedUrl: string;
  loginUrl: string;
  logoutUrl: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.isAuthenticatedUrl = config.isAuthenticatedUrl;
      this.loginUrl = config.loginUrl;
      this.logoutUrl = config.logoutUrl;
    });
  }

  init(): Observable<User> {
    return this.request(this.http.get<ResponseUser>(this.isAuthenticatedUrl, httpOptions));
  }

  login(auth: Authenticate): Observable<User> {
    return this.request(this.http.post<ResponseUser>(this.loginUrl, auth));
  }

  request(baseRequest: Observable<ResponseUser>) {
    return baseRequest.pipe(
      map(responseUser => {
        const functionPermissions = Object.assign(new FunctionPermissions(), responseUser.FunctionPermissions);
        const setElementPermissions = Object.assign(new SetElementPermissions(), responseUser.SetElementPermissions);
        return new User(
          responseUser.Username,
          responseUser.Shop,
          responseUser.Email,
          responseUser.BasketItems,
          functionPermissions,
          setElementPermissions,
          responseUser.IsAuthenticated
        );
      })
    );
  }

  logout() {
    return this.request(this.http.get<ResponseUser>(this.logoutUrl));
  }
}
