import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { reducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { AdminDesignerEffects } from './admin-designer.effects';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('adminDesigner', reducer),
    EffectsModule.forFeature([AdminDesignerEffects])
  ]
})
export class AdminDesignerModule {}
