import { EditorAction } from '../action';
import { CHANGE_COLOR } from './canvas.action.types';
import { Color } from '../../models';

/**
 * params
 * route: image element or background image element route
 */

export class ChangeColor implements EditorAction {
  readonly type = CHANGE_COLOR;
  readonly save = true;

  constructor(public route: Array<number>, public color: Color, public undoable = true) {}
}
